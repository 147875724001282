import React, { useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import styled from 'styled-components';

import axios from 'axios';

import { APIBackend as API } from '../api';
import { useAuth } from '../contexts/User';

import prepareErrorMessage from '../libs/ErrorHelper.js';

import { Form, Input, Checkbox, Button, Row, Col, Card, Alert, message } from 'antd';

import '../assets/scss/login.scss';

import msLogo from '../assets/images/ms-symbollockup_mssymbol_19.svg';

axios.defaults.withCredentials = true;

const MicrosoftButton = styled.button`
	font: 600 14px 'Segoe UI', Arial, sans-serif;
	background: #FFF;
	color: #5E5E5E;
	border: 1px solid #8C8C8C;
	padding: 0 12px;
	height: 36px;
	display: flex;
	align-items: center;
	border-radius: 0;
	cursor: pointer;
`;

const MicrosoftButtonLogo = styled.img`
	margin-right: 12px;
	width: 17px;
	height: 17px;
`;

const Login = (props) => {
    const [isError, setError] = useState(false);
	const history = useHistory();

	const auth = useAuth();

	const search = props.location.search;
	const query = new URLSearchParams(search);
	const code = query.get('code');

	const onFormFinished = (values) => {
		const referer = (props.location.state) ? props.location.state.from : null || '/';

		auth.login(values).then(data => {
			history.push(referer);
		}).catch(err => {
			setError(err);
		});
	};

	const onAzureLogin = (e) => {
		e.preventDefault();
		auth.azureLogin();
		// message.info('Funkcja jest aktualnie niedostępna');
	};

	return (<main className="main h-100 w-100">
		<div className="container h-100">
			<Row className="h-100">
				<Col sm={20} md={16} lg={12} className="d-table mx-auto h-100">
					<div className="d-table-cell align-middle login-pane">
						{ isError && <Alert message={prepareErrorMessage(isError)} type="error" style={{ marginBottom: '15px' }} /> }
						<Card title={<><div className="login-card-title">Witaj ponownie!</div><p className="login-card-subtitle">Zaloguj się do systemu, aby kontynuować</p></>} bordered={false} className="login-card">
							<Form name="login-form" layout="vertical" onFinish={onFormFinished}>
								<Form.Item
									label="Adres e-mail:"
									name="email"
									rules={[{ required: true, message: 'Proszę wprowadzić adres e-mail' },/*{ type: 'email', message: 'Proszę wprowadzić poprawny adres e-mail' }*/]}>
									<Input />
								</Form.Item>

								<Form.Item
									label="Hasło:"
									name="password"
									rules={[{ required: true, message: 'Proszę wprowadzić hasło' }]}>
									<Input.Password />
								</Form.Item>

								<Row style={{ alignItems: 'center' }}>
									<Col sm={24} lg={12}>
										{code && code === '123' ? <Form.Item>
											<MicrosoftButton type="button" onClick={onAzureLogin}>
												<MicrosoftButtonLogo src={msLogo} />
												Zaloguj się przez Microsoft
											</MicrosoftButton>
										</Form.Item> : null}
									</Col>

									<Col sm={24} lg={12} style={{ textAlign: 'right' }}>
										<Form.Item>
											<Button type="primary" htmlType="submit">
												Zaloguj się
											</Button>
										</Form.Item>
									</Col>
								</Row>

								<Row>
									<Col span={24}>
										<Link to="/forgot-password">
											Zapomniałeś hasła?
										</Link>
									</Col>
								</Row>
							</Form>
						</Card>
					</div>
				</Col>
			</Row>
		</div>
	</main>);
};

export default Login;
