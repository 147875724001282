import React from 'react';

import Moment from 'react-moment';

import Record from '../../components/Record/index';

import TabbedCard from '../../components/TabbedCard';

import { Row, Col, Card, Button } from 'antd';

import '../../assets/scss/dashboard.scss';

const Dashboard_Users = (props) => {
	const [currentActiveTab, setCurrentActiveTab] = React.useState('general');

	return (<div className="content">
		<Record
			controller="users"
			relations={[ 'permissions', 'contractors', 'profiles', 'worktime' ]}
			mode={props?.mode}
			defaultEditing={props?.editing}
			filter={[
				{ field: 'is_client', value: 'N' }
			]}
			customButtons={[
				{ title: 'Resetuj hasło', endpoint: '/reset-password-token', modal: { title: 'Potwierdź akcję', content: 'Czy na pewno chcesz zresetować hasło użytkownika?' }, formDataFromRecord: ['email'], successText: 'Hasło zostało pomyślnie zresetowane', errorText: 'Wystąpił błąd podczas resetowania hasła' }
			]}
			filters={[
				{
					title: 'Zablokowany',
					name: 'is_locked',
					type: 'preenum',
					enum: [
						{ label: '- wybierz -', value: null },
						{ label: 'TAK', value: 1 },
						{ label: 'NIE', value: 0 }
					]
				}
			]}
			columns={[
				{
					name: 'id',
					title: '#',
					type: 'number'
				},
				{
					name: 'first_name',
					title: 'Imię',
					searchable: true
				},
				{
					name: 'last_name',
					title: 'Nazwisko',
					searchable: true
				},
				{
					name: 'email',
					title: 'Adres e-mail',
					searchable: true
				},
				{
					name: 'permission_name',
					title: 'Uprawnienia',
					searchable: true,
					sortname: 'permissions.name'
				},
				{
					name: 'is_locked',
					title: 'Zablokowany',
					datatype: 'icbool',
					searchable: false
				},
				{
					name: 'actions',
					data: null,
					sortable: false,
					title: '',
					render: function(data, type, row) {
						return `<a class="btn btn-table" href="/users/${data.id}">Podgląd</a><a class="btn btn-table" href="/users/${data.id}/edit">Edytuj</a>`;
					},
					className: 'text-right'
				}
			]}
			viewTitle="Podgląd użytkownika"
			listTitle="Lista użytkowników"
			createTitle="Tworzenie użytkownika"
			createButtonText="Dodaj użytkownika"
		>
			<Record.Consumer>
				{({ isLoading }) => (
					<TabbedCard key="generalCard" loading={isLoading} tabContent={{
						general: [
							<Record.Field.Input title={'Imię'} name="first_name" rules={[ { required: true, message: 'Proszę uzupełnić imię użytkownika' } ]} />,
							<Record.Field.Input title={'Nazwisko'} name="last_name" rules={[ { required: true, message: 'Proszę uzupełnić nazwisko użytkownika' } ]} />,
							<Record.Field.Input title={'Adres e-mail'} name="email" rules={[{ type: 'email', message: 'Proszę podać prawidłowy adres e-mail' }]} />,
							<Record.Field.Input title={'PESEL'} name="pesel" rules={[{ len: 11, message: 'Numer PESEL musi się składać z 11 cyfr!' }]} />,
							<Record.Field.Switch title={'Zablokowane'} name="is_locked" />,
							<Record.Field.Switch title={'Aktywne'} name="is_activated" hidePredicate={ (value, rawData, recordData, mode) => { return (mode !== 'create' && (rawData?.is_activated === true)) } } />,
							<Record.Field.Input title={'Telefon kontaktowy'} name="phone" rules={[{ pattern: /^(?<!\w)(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}(?!\w)$/, message: 'Proszę wprowadzić prawidłowy numer telefonu' }]} />,
							<Record.Field.Relation title={'Uprawnienia'} name="permission_id" relation="permissions" filterData={ (data, recordData) => { if(Array.isArray(data)) {
								return data.filter(el => el.code >= 50);
							} return data; } } rules={[ { required: true, message: 'Proszę wybrać uprawnienia użytkownika' } ]} />,
							<Record.Field.Textarea title={'Dodatkowe notatki'} name="remarks" span={{ span: 24 }} />
						],
						contractors: [
							<Record.Field.TableRelation title={'Kontrahenci'} name="contractors" span={{ span: 24 }} fields={[
								{ name: 'id', title: 'Nazwa', span: { lg: 13, xl: 14 }, type: 'relation', relation: 'contractors', relationValueColumn: 'name', relationIdColumn: 'id' },
								{ name: 'worktime_id', title: 'Wymiar pracy', span: { lg: 5, xl: 6 }, type: 'relation', relation: 'worktime' }
							]} showTitle={ false } data={(data, record, rawValue) => {
								return data.map(row => {
									if(row && record?.worktime) {
										row.worktime_id = record.worktime.filter(el => el.contractor_id === row.id)[0]?.id ?? null;
									}

									return row;
								});
							}} />
						],
						rights: [
							<Record.Field.TableRelation title={'Uprawnieni'} name="rightusers" span={{ span: 24 }} fields={[
								{ name: 'contractor_id', title: 'Kontrahent', span: { lg: 18, xl: 20 }, type: 'relation', relation: 'contractors', relationValueColumn: 'name', relationIdColumn: 'id' }
							]} showTitle={ false } />
						]
					}} tabList={[
						{ key: 'general', tab: 'Dane podstawowe' },
						{ key: 'contractors', tab: 'Kontrahenci' },
						{ key: 'rights', tab: 'Uprawnieni' },
					]} activeTabKey={currentActiveTab} onTabChange={(key) => { setCurrentActiveTab(key) }} forceRender={true} />
				)}
			</Record.Consumer>
		</Record>
	</div>);
};

export default Dashboard_Users;
