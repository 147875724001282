import React from 'react';
import { useHistory } from 'react-router-dom';

import Moment from 'react-moment';

import TabbedCard from '../../../../components/TabbedCard';
import CardCollapse from '../../../../components/CardCollapse';
import DataTable from '../../../../components/DataTable';

import { Row, Col, Card, Button, Alert, Affix } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

import moment from 'moment';
import axios from 'axios';

import { useAuth, PERMISSIONS as USER_PERMISSIONS } from '../../../../contexts/User';

import prepareErrorMessage from '../../../../libs/ErrorHelper.js';

import { captureException, captureMessage } from '@sentry/react';

import '../../../../assets/scss/dashboard.scss';

const Dashboard_Regulations_General = (props) => {
	const controller = 'regulations';

	const auth = useAuth();
	const history = useHistory();

	const [filtersVisibility, setFiltersVisibility] = React.useState(false);
	const [affixedActions, setAffixedActions] = React.useState(false);

	const [filter, setFilter] = React.useState([]);

	const [error, setError] = React.useState(false);

	const buttons = <>
		<Button type="primary" onClick={() => { setFiltersVisibility(!filtersVisibility); }}>
			<FontAwesomeIcon icon={faFilter} />
		</Button>
		<Button type="primary">Eksportuj</Button>
		<Button type="primary" onClick={() => { history.push('/regulations/create'); }}>Dodaj regulamin</Button>
	</>;

	const columns = [
		{
			name: 'id',
			title: '#',
			type: 'number'
		},
		{
			name: 'name',
			title: 'Nazwa',
			searchable: true
		},
		{
			name: 'contractor_name',
			title: 'Kontrahent',
			sortname: 'contractors.name'
		},
		{
			name: 'from',
			title: 'Obowiązuje od'
		},
		{
			name: 'is_active',
			title: 'Aktywny',
			datatype: 'bool'
		},
		{
			name: 'actions',
			data: null,
			sortable: false,
			title: '',
			render: function (data, type, row) {
				return `<a class="btn btn-table" href="/regulations/${data.id}">Podgląd</a>${auth.can(USER_PERMISSIONS.ADMIN) ? `<a class="btn btn-table" href="/regulations/${data.id}/edit">Edytuj</a><a class="btn btn-table" href="/regulations/${data.id}/clone">Kopiuj</a>` : ''}`;
			},
			className: 'text-right'
		}
	];

	return (
		<div className="content">
			{
				auth.can(USER_PERMISSIONS.ADMIN)
				? <>
					<div className="subheader">
						<div className="d-flex">
							<h1 className="title">Lista regulaminów</h1>
							<span className="subtitle">
								Dzisiaj jest <Moment locale="pl" format="dddd, D MMMM YYYY" />
							</span>
						</div>

						{buttons ?
							<Affix offsetTop={54} onChange={(affixed) => { setAffixedActions(affixed); }}>
								<div className="subheader_actions">
									{
										(affixedActions ?? false) ?
											<div className="container">
												<h1 className="title m-0">Lista regulaminów</h1>
												<div className="d-flex">
													{buttons}
												</div>
											</div>
											:
											buttons
									}
								</div>
							</Affix>
							:
							null}
					</div>

					<div>
						{error ? <Row style={{ marginBottom: '15px' }}>
							<Col sm={24} md={16}>
								<Alert type="error" message={prepareErrorMessage(error)} />
							</Col>
						</Row> : ''}

						<Card bodyStyle={{ padding: 0 }}>
							<div id="tableFilters" className={filtersVisibility ? 'shown' : ''}>
								<Row gutter={[16, 20]}>

								</Row>
							</div>
							<div key={['dataTable']}>
								<DataTable controller={controller} columns={columns} perPage={20} filters={filter} />
							</div>
						</Card>
					</div>
				</>
				:
				<Row style={{ marginBottom: '15px' }}>
					<Col sm={24}>
						<Alert type="error" message="Brak uprawnień do zasobu" />
					</Col>
				</Row>
			}
		</div>);
};

export default Dashboard_Regulations_General;
