import React, { useState } from 'react';

const IBAN_REGEX = /^([A-Z]{2}[ \-]?)([0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/;

export function validateIban(str) {
	return IBAN_REGEX.test(str);
}

export function validateIbanChecksum(iban) {
	const stripped = iban.replace(/[^A-Z0-9]+/gi, '').toUpperCase();
	const m = stripped.match(/^([A-Z]{2})([0-9]{2})([A-Z0-9]{9,30})$/);

	if(!m) return false;

	const numed = (m[3] + m[1] + m[2]).replace(/[A-Z]/g, ch => {
		return (ch.charCodeAt(0) - 55);
	});

	const mod97 = numed.match(/\d{1,7}/g).reduce((total, curr) => {
		return Number(total + curr) % 97
	}, '');

	return (mod97 === 1);
}

export function filterObject(obj, predicate) {
	let result = {}, key;

	for(key in obj) {
		if(obj.hasOwnProperty(key) && !predicate(key, obj[key])) {
			result[key] = obj[key];
		}
	}

	return result;
}

export function objectToFormData(obj, form, namespace) {
	var fd = form || new FormData();
	var formKey;

	for(var property in obj) {
		if(obj.hasOwnProperty(property)) {
			if(namespace) {
				formKey = namespace + '[' + property + ']';
			} else {
				formKey = property;
			}

			if(typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
				objectToFormData(obj[property], fd, property);
			} else {
				fd.append(formKey, obj[property]);
			}
		}
	}

	return fd;
}

export function inspectFormData(fd) {
	if(!(fd instanceof FormData)) return false;

	let obj = {};

	try {
		for(let pair of fd.entries()) {
			obj[pair[0]] = pair[1];
		}
	} catch(e) {
		console.log(e);
		return null;
	}

	return obj;
}

export function compareValues(key, dir = 'ASC') {
	return function innerSort(a, b) {
		if(!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;

		const aVar = (typeof a[key] === 'string') ? a[key].toUpperCase().split(' ')[0] : a[key];
		const bVar = (typeof b[key] === 'string') ? b[key].toUpperCase().split(' ')[0] : b[key];

		let result = 0;

		if(aVar > bVar) {
			result = 1;
		} else if(aVar < bVar) {
			result = -1;
		}

		return (dir === 'DESC') ? (result * -1) : result;
	};
}

export function useCounter(initialState = 0) {
	const [state, setState] = useState(initialState);

	const increment = (amount = 1) => setState(state => state + amount);
	const decrement = (amount = 1) => setState(state => state - amount);

	return [state, increment, decrement];
}

export const useAnimationFrame = callback => {
	const requestRef = React.useRef();
	const previousTimeRef = React.useRef();

	const animate = time => {
		if(previousTimeRef.current !== undefined) {
			const deltaTime = time - previousTimeRef.current;
			callback(deltaTime);
		}

		previousTimeRef.current = time;
		requestRef.current = requestAnimationFrame(animate);
	};

	React.useEffect(() => {
		requestRef.current = requestAnimationFrame(animate);
		return () => cancelAnimationFrame(requestRef.current);
	}, []);
};

export const getValueFromArray = (data, field) => {
	let value = null;

	const BreakException = {};

	if(Array.isArray(field) && typeof data === 'object' && data) {
		try {
			field.forEach(f => {
				if(value === null) {
					value = data[f];
				} else {
					if(value && value.hasOwnProperty(f)) {
						value = value[f];
					} else {
						throw BreakException;
					}
				}
			});
		} catch(e) {
			if(e !== BreakException) throw e;
			value = null;
		}
	}

	return value;
};

export const objectMap = (obj, fn) => Object.fromEntries(
	Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)])
);

export const assignDataToString = (str, data, emptyValue = null) => {
	return str.replace(/{#([^}]*)}/g, (fullmatch, match) => {
		if(data && data?.hasOwnProperty(match)) return data[match] ?? emptyValue;

		return emptyValue;
	});
};

export const openInNewTab = (pathname = null) => {
	const {
		protocol,
		host
	} = window.location;

	pathname = (pathname && pathname.substr(0, 1) === '/') ? pathname.substr(1) : pathname;

	window.open(`${protocol ?? ''}//${host}/${pathname ?? ''}`, '_blank').focus();
};
