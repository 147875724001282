import RecordFieldInput from './Input';
import RecordFieldNumber from './Number';
import RecordFieldTextarea from './Textarea';
import RecordFieldDate from './Date';
import RecordFieldSelect from './Select';
import RecordFieldAttachments from './Attachments';
import RecordFieldGroup from './Group';
import RecordFieldEmpty from './Empty';

import RecordFieldSwitch from './Switch';
import RecordFieldCheckbox from './Checkbox';

import RecordFieldRelation from './Relation';
import RecordFieldTableRelation from './TableRelation';
import RecordFieldTable from './Table';
import RecordFieldTableCheck from './TableCheck';
import RecordFieldRelatedField from './RelatedField';
import RecordFieldRecord from './Record';
import RecordFieldAccordion from './Accordion';

export default {
	Input: RecordFieldInput,
	Number: RecordFieldNumber,
	Textarea: RecordFieldTextarea,
	Date: RecordFieldDate,
	Select: RecordFieldSelect,
	Attachments: RecordFieldAttachments,
	Group: RecordFieldGroup,
	Empty: RecordFieldEmpty,

	Switch: RecordFieldSwitch,
	Checkbox: RecordFieldCheckbox,

	Relation: RecordFieldRelation,
	TableRelation: RecordFieldTableRelation,
	Table: RecordFieldTable,
	TableCheck: RecordFieldTableCheck,
	RelatedField: RecordFieldRelatedField,
	Record: RecordFieldRecord,
	Accordion: RecordFieldAccordion,
};
