import React from 'react';
import PropTypes from 'prop-types';

import Record_Field from './Field';

import { Form, Input, Collapse, Row, Col } from 'antd';

class RecordFieldAccordion extends Record_Field {
	static propTypes = Object.assign({}, Record_Field.propTypes, {
		name: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.array
		]),
		accords: PropTypes.array.isRequired
	});

	static defaultProps = Object.assign({}, Record_Field.defaultProps, {

	});

	static displayName = 'RecordFieldAccordion';

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	getValue() {
		const {
			title,
			name,
			disabled,
			accords,
			namePrefix = [],
			accordFieldPrefix = []
		} = this.props;

		return <Collapse>
			{accords.map(accord => {
				return <Collapse.Panel
					header={accord.title}
					key={[name ?? this.key, 'accordion', accord.name]}
					forceRender
				>
					<Row>
						<Col span={24}>
							<Row className="form-group-row" gutter={[16,24]}>
								{React.Children.map(accord.fields ?? [], _accordField => {
									return React.cloneElement(_accordField, {
										name: [...accordFieldPrefix, ...namePrefix, _accordField.props.name],
										key: ['fieldContainer', _accordField.props.name, ...namePrefix]
									});
								})}
							</Row>
						</Col>
					</Row>
				</Collapse.Panel>
			})}
		</Collapse>;
	}

	renderEdit() {
		const {
			title,
			name,
			disabled,
			accords,
			namePrefix = []
		} = this.props;

		return <Form.Item
			{...this.globalItemProps}
		>
			<Collapse>
				{accords.map(accord => {
					return <Collapse.Panel
						header={accord.title}
						key={[name ?? this.key, 'accordion', accord.name]}
						forceRender
					>
						<Row>
							<Col span={24}>
								<Row className="form-group-row" gutter={[16,24]}>
									{React.Children.map(accord.fields ?? [], _accordField => {
										return React.cloneElement(_accordField, {
											name: [...namePrefix, _accordField.props.name],
											key: ['fieldContainer', _accordField.props.name, ...namePrefix]
										});
									})}
								</Row>
							</Col>
						</Row>
					</Collapse.Panel>
				})}
			</Collapse>
		</Form.Item>;
	}
}

export default RecordFieldAccordion;
