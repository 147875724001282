import React from 'react';
import PropTypes from 'prop-types';

import Record_Field from './Field';

import { Form, Select } from 'antd';

import _ from 'lodash';

const { Option } = Select;

class RecordFieldSelect extends Record_Field {
	static propTypes = Object.assign({}, Record_Field.propTypes, {
		options: PropTypes.array.isRequired,
		allowSearch: PropTypes.bool,
		multiple: PropTypes.bool,
		dataType: PropTypes.string
	});

	static defaultProps = Object.assign({}, Record_Field.defaultProps, {
		allowSearch: false,
		placeholder: 'Wybierz...',
		multiple: false,
		dataType: 'array'
	});

	static displayName = 'RecordFieldSelect';

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	getValue() {
		const {
			options,
			dataType,
			multiple
		} = this.props;

		if(multiple) {
			let data = dataType === 'string' ? this.getRawValue()?.split?.(',') : this.getRawValue();

			if(data && Array.isArray(data)) {
				data = options.map(el => {
					if(data.includes(el.value)) {
						return el.label;
					}
				});

				return data.join(', ');
			}

			return '(brak)';
		} else {
			let opt = options.find(el => el.value === this.getRawValue());

			return opt ? opt.label : '(brak)';
		}
	}

	renderEdit() {
		const {
			title,
			name,
			disabled,

			options,
			allowSearch,
			placeholder,
			multiple
		} = this.props;

		return <Form.Item
			{...this.globalItemProps}
		>
			<Select
				autoComplete="nope"
				disabled={disabled}
				showSearch={allowSearch}
				placeholder={placeholder}
				optionFilterProp="children"
				mode={multiple ? 'multiple' : undefined}
			>
				{options.map(opt => (
					<Option key={_.uniqueId('option_')} value={opt.value}>{opt.label}</Option>
				))}
			</Select>
		</Form.Item>;
	}
}

export default RecordFieldSelect;
