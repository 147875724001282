import React from "react";

import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

export function RecordSubRoutes({ readonly = false, cloneAllowed = false, customPaths = [], controller, component: Component, ...props }) {
	const { path, url } = useRouteMatch();

	const routes = [];
	const isReadOnly = readonly ? true : false;

	if(customPaths.length && Array.isArray(customPaths)) {
		customPaths.forEach((_path, idx) => {
			routes.push(<PrivateRoute key={`proute~${idx}~${controller}`} path={`${path}${_path.path}`}>
				<Component key={`routes~${_path.mode}~${controller}`} mode={`${_path.mode}`} editing={_path?.editing ?? false} readonly={isReadOnly} />
			</PrivateRoute>);
		});
	} else {
		if(!readonly) {
			routes.push(<PrivateRoute key={`proute~create~${controller}`} path={`${path}/create`}>
				<Component key={`routes~create~${controller}`} mode="create" editing={true} readonly={isReadOnly} />
			</PrivateRoute>);

			routes.push(<PrivateRoute key={`proute~edit~${controller}`} path={`${path}/:id/edit`}>
				<Component key={`routes~edit~${controller}`} mode="edit" editing={true} readonly={isReadOnly} />
			</PrivateRoute>);

			if(cloneAllowed) {
				routes.push(<PrivateRoute key={`proute~edit~${controller}`} path={`${path}/:id/clone`}>
					<Component key={`routes~clone~${controller}`} mode="clone" />
				</PrivateRoute>);
			}
		}

		routes.push(<PrivateRoute key={`proute~view~${controller}`} path={`${path}/:id`}>
			<Component key={`routes~view~${controller}`} mode="view" readonly={isReadOnly} />
		</PrivateRoute>);

		routes.push(<PrivateRoute key={`proute~list~${controller}`} path={`${path}`}>
			<Component key={`routes~list~${controller}`} mode="list" editing={false} readonly={isReadOnly} />
		</PrivateRoute>);
	}

	return (<Switch>
		{ routes }
	</Switch>);
}

export function RecordRoute({ controller, component: Component, ...rest }) {
	return (
		<Route path={`/${controller}`} render={(match) => {
			return <RecordSubRoutes controller={controller} component={Component} {...match} />;
		}} />
	);
}
