import React from "react";
import { Route, Redirect } from 'react-router-dom';
import { useAuth, PERMISSIONS } from '../contexts/User';

function PermissionRoute({ component: Component, permission: permissionCode, ...rest }) {
	const auth = useAuth();

	if(auth.loading || !auth.user) {
		return null;
	}

	if(!auth.token) {
		return <Redirect
			to={{
				pathname: '/login',
				state: { from: rest.location }
			}} />;
	}

	if(!auth.can(permissionCode ?? PERMISSIONS.ADMIN)) {
		return <Redirect
			to={{
				pathname: '/404',
				state: { from: rest.location }
			}} />;
	}

	return (
		<Route {...rest} render={props => (Component ?? null) ? (typeof Component === 'function' ? <Component {...props} /> : Component) : rest.children} />
	);
}

export default PermissionRoute;
