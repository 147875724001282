import React from 'react';

const Observer = ({ dependencies = [], didUpdate, args = [] }) => {
	React.useEffect(() => {
		if(typeof didUpdate === 'function') {
			didUpdate(...args);
		} else {
			throw new Error('didUpdate prop has to be a function');
		}
	}, dependencies);

	return null;
};

export default Observer;
