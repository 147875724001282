import React from 'react';
import PropTypes from 'prop-types';

import { RecordContext, RecordProvider } from '../../contexts/Record';

import Record from './record';
import ModalRecord from './modal';
import RecordFields from './Fields';

import _ from 'lodash';

class RecordContainer extends React.Component {
	static propTypes = {
		mode: PropTypes.string,
		controller: PropTypes.string.isRequired,
		referrer: PropTypes.string,
		relations: PropTypes.array,
		/**
		 * Filters which are applied to the list
		 * @type {Array}
		 */
		filter: PropTypes.array,
		/**
		 * Available filters for user (applied to the list)
		 * @type {Array}
		 */
		filters: PropTypes.array,
		/**
		 * Object of props passed to the data table component
		 * @type {Object}
		 */
		listProps: PropTypes.object,
		listTitle: PropTypes.string,
		viewTitle: PropTypes.string,
		editTitle: PropTypes.string,
		createTitle: PropTypes.string,
		createButtonText: PropTypes.string,
		customButtons: PropTypes.array,
	};

	static defaultProps = {
		mode: 'list',
		createButtonText: 'Dodaj pozycję',
	};

	render() {
		const technicalFields = [
			<RecordContainer.Field.Date key={_.uniqueId('techField_')} title="Data ostatniej aktualizacji" name="updated_at" readonly span={{ span: 24 }} format="YYYY-MM-DD HH:mm" />,
			<RecordContainer.Field.Input key={_.uniqueId('techField_')} title="Osoba aktualizująca" name="updater_full_name" readonly span={{ span: 24 }} />,
			<RecordContainer.Field.Date key={_.uniqueId('techField_')} title="Data utworzenia" name="created_at" readonly span={{ span: 24 }} />,
		].concat(this.props?.technicalFields ?? []);

		const {
			children,
			mode,
			controller,
			referrer,
			relations,
			listProps,
			filter,
			filters,
			listTitle,
			viewTitle,
			editTitle,
			createTitle,
			createButtonText,
			customButtons,

			modal = false,
			defaultValues = {}
		} = this.props;

		const recordProps = {
			modal: modal ? true : false,
			controller,
			referrer,
			listProps,
			filter,
			listTitle,
			viewTitle,
			editTitle,
			createTitle,
			createButtonText,
			defaultValues,
			technicalFields,
			customButtons,
		};

		return (<>
			<RecordProvider
				modal={modal ? true : false}
				controller={controller}
				referrer={referrer}
				relations={relations}
				mode={mode}
			>
				{
					modal
						? <ModalRecord
							{...recordProps}
						>
							{children}
						</ModalRecord>
						: <Record
							{...recordProps}
						>
							{children}
						</Record>
				}
			</RecordProvider>
		</>);
	}
}

RecordContainer.Field = RecordFields;
RecordContainer.Consumer = RecordContext.Consumer;

export default RecordContainer;
