import React from 'react';

import { Upload, notification } from 'antd';
import { MBtoBytes, MAX_FILE_SIZE } from "../config/constants";

import { DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';

import '../assets/scss/upload.scss';
import isValidFilename2 from "valid-filename";

const isValidFilename = (filename) => {
	return isValidFilename2(filename) && !/[\[\]]/g.test(filename);
}

const { Dragger } = Upload;

const DraggerUpload = ({data, updateAttachments, fileType, ...props}) => {
	const [attachments, setAttachments] = React.useState({
		list: Array.isArray(data?.list) ? data.list : [],
		add: [],
		del: []
	});

	React.useEffect(() => {
		updateAttachments(attachments);
	}, [attachments, setAttachments]);

	const uploadProps = Object.assign({}, {
		beforeUpload: (file) => {
			if(!isValidFilename(file.name)) {
				notification.error({
					description: `Nazwa pliku ${file.name} jest niepoprawna.`,
					duration: 8,
				});
				return false;
			}

			if(file.size > MBtoBytes(MAX_FILE_SIZE)) {
				notification.error({
					description: `Rozmiar pliku przekracza limit rozmiaru załącznika (${MAX_FILE_SIZE}MB)`,
					duration: 8,
				});
				return false;
			}

			if(fileType?.id) {
				setAttachments({
					list: attachments.list,
					add: [...attachments.add, {
						name: file.name,
						category: fileType.id,
						uid: file.uid,
						file: file
					}],
					del: attachments.del
				});
			}
			return false;
		},
		onRemove: (_file) => {
			if(!fileType?.id) return false;

			const file = {
				name: _file.name,
				category: fileType.id,
				uid: _file.uid,
				file: _file
			};

			let inList = attachments.list.findIndex(el => el.uid === file.uid) !== -1 ? true : false;

			if(inList) {
				const idx = attachments.list.findIndex(el => el.uid === file.uid);
				const newFileList = attachments.list.slice();
				newFileList.splice(idx, 1);

				setAttachments({
					list: newFileList,
					add: attachments.add,
					del: [...attachments.del, file]
				});
			} else {
				const idx = attachments.add.findIndex(el => el.uid === file.uid);
				const newFileList = attachments.add.slice();
				newFileList.splice(idx, 1);

				setAttachments({
					list: attachments.list,
					add: newFileList,
					del: [...attachments.del, file]
				});
			}

			return false;
		},
		fileList: [...attachments.list, ...attachments.add]
	}, props);

	return (<Dragger {...uploadProps}>

	</Dragger>);
};

export default DraggerUpload;
