import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { RecordContext, RecordProvider } from './context';

import Moment from 'react-moment';

import DataTable from '../../components/DataTable';

import { RecordSaveError, RecordCloneError, RecordViewError, ChangeStatusError } from '../../errors';

import { Row, Col, Card, Alert, Button, FormSpace, Tooltip, Tag, Modal, Menu, Form, Input, Dropdown, message, Affix, Select } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

import { DeleteOutlined, PlusOutlined, InboxOutlined, DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { backendRoot, backendApi, csrfCookie, apiData } from '../../config/paths';

import { filterObject, objectToFormData, getValueFromArray, objectMap, assignDataToString } from '../../libs/Helpers.js';
import prepareErrorMessage from '../../libs/ErrorHelper.js';

import _ from 'lodash';

import axios from 'axios';

import moment from 'moment';

import Record_Fields from './Fields';

import { captureException, captureMessage } from '@sentry/react';
import SearchSelect from "../SearchSelect";
import { APIBackend as API } from '../../api';
import * as pluralize from 'pluralize';
import {FILTER_RELATIONS, RECORD_ID_RELATIONS} from "../../config/constants";
import {relationAliases, relationParams, relationQueries} from "./Fields/utils/relations";

const {
	Option
} = Select;

class Record extends React.PureComponent {
	static propTypes = {
		controller: PropTypes.string.isRequired,
		referrer: PropTypes.string,
		debugLevel: PropTypes.number,
		listTitle: PropTypes.string,
		viewTitle: PropTypes.string,
		createTitle: PropTypes.string,
		editTitle: PropTypes.string,
		createButtonText: PropTypes.string,
		defaultMode: PropTypes.string,
		customButtons: PropTypes.array,
		filters: PropTypes.array,
		filter: PropTypes.array,
		alerts: PropTypes.array,
		includeFields: PropTypes.array,

		pagination: PropTypes.bool,
		perPage: PropTypes.number,
		info: PropTypes.bool,
		columns: PropTypes.array.isRequired,

		disableEditPredicate: PropTypes.func,
		onValuesChange: PropTypes.func,
	};

	static defaultProps = {
		debugLevel: 0,
		listTitle: 'Lista rekordów',
		viewTitle: 'Podgląd rekordu',
		createTitle: 'Tworzenie rekordu',
		editTitle: 'Edycja rekordu',
		createButtonText: 'Utwórz',
		defaultMode: 'list',
		includeFields: [],
		removeRecordRelation: [],

		// List props
		pagination: true,
		perPage: 20,
		info: true,

		disableEditPredicate: () => null,
		onValuesChange: null,
	};

	static contextType = RecordContext;

	/**
	 * ID of record given in the URL
	 * @type {String}
	 */
	id = null;

	/**
	 * ID of parent record given in the URL
	 * @type {String}
	 */
	parentId = null;

	constructor(props) {
		super(props);

		this.state = {
			changes: null,
			relationData: null,
			saving: false,
			clonedId: null,
			error: false,
			filtersVisibility: false,
			activeTab: null,
			isModalVisible: false,
			isAffixedActions: false,
			draw: 0,
			filter: this.props?.filter ? [...this.props.filter] : [],
			fetchingDependencies: false,
		};

		this.onRecordSave = this.onRecordSave.bind(this);
		this.onRecordError = this.onRecordError.bind(this);
		this.onFilterChange = this.onFilterChange.bind(this);
		this.onValuesChange = this.onValuesChange.bind(this);
		this.fetchFilterOptions = this.fetchFilterOptions.bind(this);
	}

	componentDidMount() {
		const {
			childRef
		} = this.props;

		this.id = this.props?.match?.params?.id ?? null;
		this.parentId = this.props?.match?.params?.parentId ?? null;

		if(childRef && typeof childRef === 'function') {
			childRef(this);
		}

		if(this.mode && this.mode !== 'clone') {
			this.getRecordData();
			this.getRelationData();
			if(this.mode === 'create') {
				this.getDependencyData();
			}
		}

		if(this.mode === 'clone') {
			axios.get(backendRoot + csrfCookie).then(() => {
				if(!this.state.saving) {
					this.setState({ saving: true });
					axios.get(backendApi + '/' + this.cloneController + '/clone/' + this.id).then((response) => {
						message.success('Rekord został skopiowany');
						this.props.history.push('/'+this.link+'/'+response.data.id);
						this.setState({
							clonedId: response.data.id
						});
					}).catch((err) => {
						message.error('Wystąpił błąd podczas kopiowania rekordu');

						this.setState({
							error: {
								errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED',
								message: err?.response?.data?.message
							}
						});

						// captureException(new RecordCloneError(`Error occured while cloning record`), {
						// 	contexts: {
						// 		record: {
						// 			controller: this.props.controller,
						// 			cloneController: this.cloneController,
						// 			id: this.id,
						// 			mode: this.mode,
						// 			dataType: 'relationData',
						// 			relations: this.props?.relations?.join?.(', ')
						// 		},
						// 		error: {
						// 			message: err?.response?.data?.message,
						// 			details: err?.response?.data?.details,
						// 			errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED'
						// 		}
						// 	}
						// });
					});
				}
			}).catch((err) => {
				message.error('Wystąpił błąd podczas kopiowania rekordu');

				this.setState({
					error: {
						errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED',
						message: err?.response?.data?.message
					}
				});

				// captureException(new RecordCloneError(`Error occured while cloning record`), {
				// 	contexts: {
				// 		record: {
				// 			controller: this.props.controller,
				// 			cloneController: this.cloneController,
				// 			id: this.id,
				// 			mode: this.mode,
				// 			dataType: 'relationData',
				// 			relations: this.props?.relations?.join?.(', ')
				// 		},
				// 		error: {
				// 			message: err?.response?.data?.message,
				// 			details: err?.response?.data?.details,
				// 			errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED'
				// 		}
				// 	}
				// });
			});
		}
	}

	componentWillUnmount() {
		const {
			childRef
		} = this.props;

		if(childRef && typeof childRef === 'function') {
			childRef(this);
		}
	}

	get isLoading() {
		return this.props?.relations?.length ?
			(this.mode === null || this.relationData === null || this.state.fetchingDependencies)
			:
			this.mode === null;
	}

	get link() {
		return this.props?.referrer ?? this.props.controller;
	}

	get fields() {
		return (this.context && this.context.reducer) ? this.context.reducer.state.fields : [];
	}

	get data() {
		return (this.context && this.context.data) ? this.context.data.value : null;
	}

	set data(value) {
		this.context && this.context.data && this.context.data.set(value);
	}

	get relationData() {
		return (this.context && this.context.relationData) ? this.context.relationData.value : null;
	}

	set relationData(value) {
		this.context && this.context.relationData && this.context.relationData.set(value);
	}

	get dependencies() {
		return (this.context && this.context.dependenciesData) ? this.context.dependenciesData.value : null;
	}

	set dependencies(value) {
		this.context && this.context.dependenciesData && this.context.dependenciesData.set(value);
	}

	get changes() {
		return (this.context && this.context.changes) ? this.context.changes.value : null;
	}

	set changes(value) {
		this.context && this.context.changes && this.context.changes.set(value);
	}

	get mode() {
		return (this.context && this.context.mode) ? this.context.mode.value : (this.props?.mode ?? this.props?.defaultMode);
	}

	set mode(value) {
		this.context && this.context.mode && this.context.mode.set(value);
	}

	get errorField() {
		return (this.context && this.context.errorField) ? this.context.mode.errorField : null;
	}

	set errorField(value) {
		this.context && this.context.errorField && this.context.errorField.set(value);
	}

	get editing() {
		return (this.context && this.context.editing) ? this.context.editing.value : false;
	}

	set editing(value) {
		this.context && this.context.editing && this.context.editing.set(value);
	}

	get form() {
		return (this.context && this.context.form) ? this.context.form : null;
	}

	get cloneController() {
		return this.props?.cloneController ?? this.props.controller;
	}

	debug(level, cb = null) {
		const {
			debugLevel
		} = this.props;

		if(debugLevel < level) return;

		if(typeof cb === 'function') {
			cb();
		} else {
			if(arguments.length > 1) {
				let args = Array.prototype.slice.call(arguments).slice(1);
				console.log(...args);
			} else {
				console.trace();
			}
		}
	}

	getFieldByName(name) {
		let field = this.fields.find(el => el.name === name);

		if(field) {
			return {
				name: Array.isArray(field.name) ? field.name.reduce((acc, v) => `${acc}[${v}]`) : field.name,
				tab: field.props.tab,
				scrollTo: field?.scrollTo ?? function() {}
			};
		}

		return false;
	}

	onValuesChange(val, allValues) {
		const {
			onValuesChange
		} = this.props;

		this.changes = allValues;

		const updateRelations = () => {
			this.getRelationData();
		};

		const updateDependency = (relation) => {
			this.getDependencyData(relation);
		}

		if(onValuesChange && typeof onValuesChange === 'function') {
			const field = Object.keys(val).length ? {
				name: Object.keys(val)[0],
				value: Object.values(val)[0]
			} : {};
			setTimeout(() => {
				onValuesChange(field, allValues, updateRelations.bind(this), updateDependency.bind(this));
			}, 500);
		}
	}

	handleConfirmOk() {

	}

	handleConfirmCancel() {

	}

	onRecordError(data) {
		const {
			values,
			errorFields,
			outOfDate
		} = data;

		if(errorFields.length) {
			let errorField = errorFields[0];

			errorFields.forEach(el => {
				if(el.errors[0]) {
					message.error(`${el.errors[0]}`);
				}
			});
		}
	}

	onRecordSave(values) {
		const {
			confirmSave = false,
			includeFields
		} = this.props;

		const self = this;

		const data = Object.assign({}, includeFields.map(el => this.data.hasOwnProperty(el) ? [el, this.data[el]] : [el, null]).reduce((acc, el) => Object.assign({}, acc, {
			[el[0]]: el[1]
		}), {}), {...values});

		if(confirmSave) {
			if(confirmSave.hasOwnProperty('message')) {
				if(
					!confirmSave.hasOwnProperty('predicate')
					|| (typeof confirmSave.predicate === 'function' && confirmSave.predicate(this.data, data))
				) {
					Modal.confirm({
						title: 'Potwierdź akcję',
						content: confirmSave.message,
						okText: 'Dalej',
						cancelText: 'Anuluj',
						onOk() {
							self.preSave(data);
						}
					});
				} else {
					this.preSave(data);
				}
			}
		} else {
			this.preSave(data);
		}
	}

	preSave(values) {
		const {
			includeFields
		} = this.props;

		const _data = new FormData();
		const _debugData = {};

		this.debug(1, 'Saving record[0]', values);

		this.debug(1, 'Preparing included fields[0]', includeFields);

		includeFields.forEach(fieldName => {
			if(values.hasOwnProperty(fieldName)) {
				const field = values[fieldName];

				switch(typeof field) {
					case 'bigint':
					case 'boolean':
					case 'number':
					case 'string':
						_data.append(fieldName, field ?? null);
						break;
					case 'object':
						if(moment.isMoment(field)) {
							_data.append(fieldName, field ? field.format('YYYY-MM-DD') : null);
						} else if((field instanceof File)) {
							_data.append(fieldName, field);
						} else {
							_data.append(fieldName, JSON.stringify(field));
						}
						break;
					case 'symbol':
						_data.append(fieldName, field.toString());
						break;
					default:
						break;
				}
			}
		});

		this.debug(1, 'Preparing fields[0]');

		this.fields.map(field => {
			const fieldValue = Array.isArray(field.name) ? getValueFromArray(values, field.name) : (values[field.name] ?? null),
				fieldName = Array.isArray(field.name) ? field.name.reduce((acc, v) => `${acc}[${v}]`) : field.name;
				let tmpValue;

			// if(fieldValue === null && !['RecordFieldAttachments', 'RecordFieldGroup'].includes(field.displayName)) return;

			switch(field.displayName) {
				case 'RecordFieldDate':
					_data.append(fieldName, !!fieldValue ? fieldValue.format('YYYY-MM-DD') : null);
					break;
				case 'RecordFieldTableRelation':
					// Prepare value of field
					if(fieldValue && Array.isArray(fieldValue)) {
						tmpValue = fieldValue.map((el) => {
							for(let _k in el) {
								let subfield = field.props.fields.find((sf => sf.name === _k));

								let _v = el[_k];

								switch(subfield?.type) {
									case 'date':
										_v = el[_k] ? el[_k].format('YYYY-MM-DD') : null;
										break;
									case 'checkbox':
										_v = el[_k] ? 1 : 0;
										break;
									default:
										break;
								}

								el[_k] = _v;

								if(subfield) {
									// If field is empty and it's not allowed to be empty unset it
									if(subfield.removeEmpty && (_v === '' || _v === null)) {
										delete el[_k];
									}
								} else {
									// Unset field if it doesn't exists
									delete el[_k];
								}
							}

							if(field.props?.defaultValues) {
								for(let _k in field.props.defaultValues) {
									if((el[_k] ?? null) === null) {
										el[_k] = assignDataToString(field.props.defaultValues[_k], this.data);
									}
								}
							}

							// Check if whole row is empty
							let emptyRow = Object.keys(el).every(i => {
								return el[i] === '' || el[i] === null || el[i] === 0;
							});

							// If row isn't empty, then add it to data
							if(!emptyRow) return el;
						}).filter(_v => (_v && Object.keys(_v).length)); // Remove empty entries
					}

					_data.append(field.props?.transformDataKey ?? fieldName, JSON.stringify(tmpValue ?? []));
					break;
				case 'RecordFieldAttachments':
					const newFieldValue = values[`${fieldName}~${field.props?.fileCategory}`] ?? null;

					if(newFieldValue !== null) {
						newFieldValue.add.forEach(el => {
							if(el) _data.append(`files[${el.category}/${el.name}]`, el.file);
						});

						newFieldValue.del.forEach(el => {
							if(el) _data.append(`delete[${el.category}/${el.name}]`, el.uid);
						});
					}
					break;
				case 'RecordFieldGroup':
					field.props.fields.forEach(el => {
						if(values[el.name] ?? null !== null) {
							_data.append(el.name, values[el.name] ?? '');
						}
					});
					break;
				case 'RecordFieldSwitch':
				case 'RecordFieldCheckbox':
					_data.append(fieldName, fieldValue ? 1 : 0);
					break;
				case 'RecordFieldRelation':
					if(field.props?.transformDataKey) {
						_data.append(field.props.transformDataKey, fieldValue);
					} else {
						_data.append(fieldName, fieldValue);
					}
					break;
				case 'RecordFieldTableCheck':
					if(field.props?.transformDataKey) {
						_data.append(field.props.transformDataKey, fieldValue.map(el => el.checked ? el.id : null).filter(el => el ?? null !== null));
					} else {
						_data.append(fieldName, fieldValue.map(el => el.checked ? el.id : null).filter(el => el ?? null !== null));
					}
					break;
				case 'RecordFieldRecord':
					tmpValue = [...fieldValue];

					tmpValue.forEach((_tmp, idx) => {
						field.props.fields.forEach(_f => {
							let _fName = _f.props.name,
								_fType = _f.type.displayName;

							if(_fType === 'RecordFieldRelation' && _f.props.castToString) {
								if(tmpValue[idx][_fName]) {
									tmpValue[idx][_fName] = tmpValue[idx][_fName].filter(el => !(el === '' || el === null)).join(',');
								}
							} else if(_fType === 'RecordFieldDate') {
								if(tmpValue[idx][_fName]) {
									// const tmpDate = tmpValue[idx][_fName];
									// tmpValue[idx][_fName] = moment.isMoment(tmpDate) ? tmpDate : moment(tmpDate);
									tmpValue[idx][_fName] = moment(new Date(tmpValue[idx][_fName].format('YYYY-MM-DD')));
								}
							}
						});
					});

					_data.append(fieldName, JSON.stringify(tmpValue));
					break;
				default:
					_data.append(fieldName, (fieldValue && Object.keys(fieldValue.toString()).length) ? fieldValue : '');
					break;
			}
		});

		if(this.props.debugLevel) {
			for(let pair of _data.entries()) {
				_debugData[pair[0]] = pair[1];
			}
		}

		this.debug(1, 'Saving record[1]', this.mode, _debugData);
		if(this.props.debugLevel >= 3) return;

		this.save(_data);
	}

	save(data) {
		const {
			controller
		} = this.props;

		/**
		 * Options for axios, provided while saving or creating record.
		 * @type {Object}
		 */
		const axiosOptions = {
			transformRequest: function(data, headers) {
				// Browser will detect proper Content-Type
				headers['Content-Type'] = undefined;

				return data;
			}
		};

		const endpoint = (this.mode === 'edit') ? `${backendApi}/${controller}/${this.id}` : `${backendApi}/${controller}`;

		this.setState({
			saving: true
		});

		// Make a request
		axios.get(backendRoot + csrfCookie).then(() => {
			axios.post(endpoint, data, axiosOptions).then((response) => {
				this.setState({
					saving: false,
					error: false
				});
				this.mode = 'view';
				this.editing = false;
				this.props.history.push('/'+this.link+'/'+response.data.id);
				window.location.reload();
			}).catch((err) => {
				if(err.response) {
					if(err.response.data.errcode) {
						this.setState({
							saving: false,
							error: {
								errcode: err.response.data.errcode,
								message: err.response.data?.message
							}
						});
					} else {
						this.setState({
							saving: false,
							error: {
								errcode: 'ERR-UNDEFINED',
								message: err.response.data?.message
							}
						});
					}
				} else {
					this.setState({
						saving: false,
						error: {
							errcode: 'ERR-UNDEFINED',
							message: err?.response?.data?.message
						}
					});
				}

				// captureException(new RecordSaveError(`Error occured while saving record`), {
				// 	contexts: {
				// 		record: {
				// 			controller: this.props.controller,
				// 			id: this.id,
				// 			mode: this.mode
				// 		},
				// 		error: {
				// 			message: err?.response?.data?.message,
				// 			details: err?.response?.data?.details,
				// 			errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED'
				// 		}
				// 	}
				// });
			});
		}).catch((err) => {
			if(err.response) {
				if(err.response.data.errcode) {
					this.setState({
						saving: false,
						error: {
							errcode: err.response.data.errcode,
							message: err.response.data?.message
						}
					});
				} else {
					this.setState({
						saving: false,
						error: {
							errcode: 'ERR-UNDEFINED',
							message: err.response.data?.message
						}
					});
				}
			} else {
				this.setState({
					saving: false,
					error: {
						errcode: 'ERR-UNDEFINED',
						message: err?.response.data?.message
					}
				});
			}

			// captureException(new RecordSaveError(`Error occured while saving record`), {
			// 	contexts: {
			// 		record: {
			// 			controller: this.props.controller,
			// 			id: this.id,
			// 			mode: this.mode
			// 		},
			// 		error: {
			// 			message: err?.response?.data?.message,
			// 			details: err?.response?.data?.details,
			// 			errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED'
			// 		}
			// 	}
			// });
		});
	}

	/**
	 * Gets data from backend and saves it to the state
	 * @return {void}
	 */
	getRecordData() {
		// Get data only when mode is equal to "view" or "edit"
		if(['view', 'edit'].includes(this.mode)) {
			// Getting CSRF key
			axios.get(backendRoot+csrfCookie).then(() => {
				// Getting record data
				axios.get(`${backendApi}/${this.props.controller}/${this.id}`).then((response) => {
					this.data = response.data;

					this.getDependencyData();

					if(this.props.relations.filter(el => (typeof el === 'object')).length) {
						this.getRelationData();
					}
				}).catch(err => {
					this.data = false;
					console.log(err);
					if(err.response) {
						if(err.response.data.errcode) {
							this.setState({ error: err.response.data.errcode });
						} else {
							this.setState({ error: 'ERR-UNDEFINED' });
						}
					} else {
						this.setState({ error: 'ERR-UNDEFINED' });
					}

					// captureException(new RecordViewError(`Error occured while getting record data`), {
					// 	contexts: {
					// 		record: {
					// 			controller: this.props.controller,
					// 			cloneController: this.cloneController,
					// 			id: this.id,
					// 			mode: this.mode,
					// 			dataType: 'recordData',
					// 			relations: this.props?.relations?.join?.(', ')
					// 		},
					// 		error: {
					// 			message: err?.response?.data?.message,
					// 			details: err?.response?.data?.details,
					// 			errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED'
					// 		}
					// 	}
					// });
				});
			}).catch(err => {
				this.data.set = false;
				console.log(err);
				if(err.response) {
					if(err.response.data.errcode) {
						this.setState({ error: err.response.data.errcode });
					} else {
						this.setState({ error: 'ERR-UNDEFINED' });
					}
				} else {
					this.setState({ error: 'ERR-UNDEFINED' });
				}

				// captureException(new RecordViewError(`Error occured while getting record data`), {
				// 	contexts: {
				// 		record: {
				// 			controller: this.props.controller,
				// 			cloneController: this.cloneController,
				// 			id: this.id,
				// 			mode: this.mode,
				// 			dataType: 'recordData',
				// 			relations: this.props?.relations?.join?.(', ')
				// 		},
				// 		error: {
				// 			message: err?.response?.data?.message,
				// 			details: err?.response?.data?.details,
				// 			errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED'
				// 		}
				// 	}
				// });
			});
		} else {
			// If it isn't a preview or edit mode, then set empty data.
			this.data = {};
		}
	}

	/**
	 * Gets data from backend and saves it to the state
	 * @return {void}
	 */
	getRelationData(pid = null) {
		// If view should get data of related records then get it
		if(this.props.relations?.length) {
			const recordData = Object.assign({}, this.data, this.changes);

			const relationsQuery = new URLSearchParams({
				tables: this.props.relations.filter(el => (typeof el === 'object') ? true : !FILTER_RELATIONS.includes(el)).filter(el => (typeof el === 'object') ? (pid ?? recordData?.profile_id) ? true : false : true).map(el => (typeof el === 'object') ? el.name : el)
			});

			this.props.relations.filter(el => typeof el === 'object').forEach(el => {
				if(pid ?? recordData?.profile_id) {
					relationsQuery.append(`reg[${el.name}][table]`, el.table);
					relationsQuery.append(`reg[${el.name}][field]`, el.field);
					relationsQuery.append(`reg[${el.name}][profile]`, pid ?? recordData.profile_id);
				}
			});

			if(this.props.relationsLimit) {
				relationsQuery.append('perPage', +this.props.relationsLimit);
			}

			// relationsQuery.append(`count_limit`, '30');

			if(this.context?.relationsQuery) {
				this.context.relationsQuery.set(relationsQuery.toString());
			}

			// Getting CSRF key
			if(relationsQuery.has('tables') && relationsQuery.get('tables') !== '') {
				axios.get(backendRoot + csrfCookie).then(() => {
					// Getting data of related records
					axios.get(`${backendApi}${apiData}?${relationsQuery.toString()}`).then((response) => {
						// Assigning data of related records to state
						this.relationData = response.data;
					}).catch((err) => {
						this.relationData = null;

						// captureException(new RecordViewError(`Error occured while getting record's relations data`), {
						// 	contexts: {
						// 		record: {
						// 			controller: this.props.controller,
						// 			cloneController: this.cloneController,
						// 			id: this.id,
						// 			mode: this.mode,
						// 			dataType: 'relationData',
						// 			relations: this.props?.relations?.join?.(', ')
						// 		},
						// 		error: {
						// 			message: err?.response?.data?.message,
						// 			details: err?.response?.data?.details,
						// 			errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED'
						// 		}
						// 	}
						// });
					});
				}).catch((err) => {
					this.relationData = null;

					// captureException(new RecordViewError(`Error occured while getting record's relations data`), {
					// 	contexts: {
					// 		record: {
					// 			controller: this.props.controller,
					// 			cloneController: this.cloneController,
					// 			id: this.id,
					// 			mode: this.mode,
					// 			dataType: 'relationData',
					// 			relations: this.props?.relations?.join?.(', ')
					// 		},
					// 		error: {
					// 			message: err?.response?.data?.message,
					// 			details: err?.response?.data?.details,
					// 			errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED'
					// 		}
					// 	}
					// });
				});
			} else if (relationsQuery.has('tables') && relationsQuery.get('tables') === '') {
				this.relationData = [];
			} else {
				this.relationData = null;
			}
		}
	}

	/**
	 * Gets data from backend and saves it to the state
	 * @return {void}
	 */
	getDependencyData(singleRelation = null) {
		const {
			relations
		} = this.props;

		const labelFormats = {
			users: ['first_name', 'last_name'],
			profiles: ['registration_number', 'full_name', 'contractor_symbol'],
			offtrips: ['resort', 'from', 'to']
		};

		const recordData = Object.assign({}, this.data, this.changes);

		if(relations?.length) {
			if(!singleRelation) {
				this.setState({
					fetchingDependencies: true
				});
			}

			let additionalRecords = [];

			if(recordData.profile_id) {
				additionalRecords.push({
					name: 'profiles',
					id: recordData.profile_id,
				})
			}

			const _relations = singleRelation ? [singleRelation] : relations;

			if(!singleRelation) {
				for(let relation of relations) {
					if(typeof relation === 'object') {
						continue;
					}

					if(relationAliases.hasOwnProperty(relation)) {
						relation = relationAliases[relation];
					}

					let relationVariableName = `${pluralize(relation, 1)}_id`;

					if(relationVariableName === 'dctview_id') {
						relationVariableName = 'dctview_tbl_id';
					}

					const STRING_IDS = ['dctview_tbl_id'];

					if(recordData && recordData.hasOwnProperty(relationVariableName)) {
						additionalRecords.push({
							name: relation,
							id: STRING_IDS.includes(relationVariableName) ? this.data?.[relationVariableName] : +this.data?.[relationVariableName]
						});
					}
				}
			}

			if (this.props.removeRecordRelation.length) {
				additionalRecords = additionalRecords.filter(object => {
					return !this.props.removeRecordRelation.includes(object.name);
				});
			}

			const tables = _relations.filter(el => typeof el !== 'object').filter(el => FILTER_RELATIONS.includes(el)).map(relation => {
				if(relation === 'committees') {
					return {
						name: 'users',
						limit: -1,
						query: '',
						customQuery: 'code=30 or code=35'
					};
				}

				if(RECORD_ID_RELATIONS.includes(relation)) {
					const RELATION_CUSTOM_QUERY = {
						apptripmembers: `apptrip_id = ${this.id}`,
						appreftripmembers: `appreftrip_id = ${this.id}`,
					};

					return {
						name: relation,
						limit: -1,
						query: '',
						customQuery: RELATION_CUSTOM_QUERY[relation]
					}
				}

				const paramName = relationParams.hasOwnProperty(relation) ? relationParams[relation] : relationParams.DEFAULT;
				const params = paramName && (recordData?.[paramName] ?? null);

				if(relationQueries.hasOwnProperty(relation)) {
					return relationQueries[relation](params);
				}

				return relationQueries.DEFAULT(relation);
			});

			API.datacustom(tables, additionalRecords).then(response => {
				const currentOptions = this.dependencies;
				let newOptions = {...currentOptions};

				for(let relation of _relations) {
					if(relation === 'committees') {
						relation = 'users';
					}

					const opts = response?.[relation] ? response[relation] : [];

					newOptions = {...newOptions, [relation]: opts};
				}

				this.dependencies = newOptions;

				this.setState({
					fetchingDependencies: false
				});
			}).catch(error => {
				console.log(error);
				this.setState({
					fetchingDependencies: false
				});
			});
		}
	}

	fetchFilterOptions(value, relation, relationKey, relationName) {
		if(!relation) {
			return Promise.reject();
		}

		return new Promise((resolve, reject) => {
			API.dataset([
				{
					name: relation,
					fields: [relationKey, relationName]
				}
			], [], [
				{
					name: relation,
					order: `${relationName} asc`
				}
			], [
				{
					name: relation,
					filter: `${relationName} like '%${value}%'`

				}
			]).then(response => {
				const currentOptions = this.dependencies;
				let newOptions = {...currentOptions};

				const opts = response?.[relation] ? response[relation].map(el => {
					return {
						label: el?.[relationName] ?? '',
						value: el?.[relationKey] ?? '',
					};
				}) : [];

				newOptions = {...newOptions, [relation]: opts};

				this.dependencies = newOptions;

				resolve(opts);
			}).catch(error => {
				reject(error);
			});
		});
	}

	/**
	 * Prepare title for view
	 * @return {ReactDOM.Node}
	 */
	prepareTitle() {
		switch(this.mode) {
			case 'list':
				return assignDataToString(this.props.listTitle, this.data, '(brak)');
				break;
			case 'view':
				return assignDataToString(this.props.viewTitle, this.data, '(brak)');
				break;
			case 'create':
				return assignDataToString(this.props.createTitle, this.data, '(brak)');
				break;
			case 'edit':
				return assignDataToString(this.props.editTitle, this.data, '(brak)');
				break;
			case 'clone':
				return 'Kopiowanie rekordu';
				break;
		}
	}

	/**
	 * Prepare action buttons for view
	 * @return {ReactDOM.Node}
	 */
	prepareButtons() {
		const {
			extraListActions = null
		} = this.props;

		const makeRequest = (endpoint, successText, errorText, onSuccess = null, data = null, method = 'post', errMsg = false) => {
			return axios.request({
				method: method ?? 'post',
				url: `${backendApi}${assignDataToString(endpoint, this.data)}`,
				data: data
			}).then(() => {
				message.success(successText ?? 'Akcja została wykonana');
				this.getRecordData();
				if(onSuccess === null) {
					if(this.mode === 'view') {
						window.location.reload();
					}
				} else {
					onSuccess();
				}
			}).catch(err => {
				message.error(errorText ?? 'Wystąpił błąd podczas wykonywana akcji');
				if(err.response) {
					this.setState({ error: {
							errcode: err.response.data.errcode,
							message: err.response.data.message
						} });
				} else {
					this.setState({ error: 'ERR-UNDEFINED' });
				}

				if(endpoint.search('/status/') >= 0) {
					// captureException(new ChangeStatusError(`Error occured while chaning status for ${endpoint.split('/status/')[0]}`), {
					// 	contexts: {
					// 		status_request: {
					// 			endpoint: `${backendApi}${assignDataToString(endpoint, this.data)}`,
					// 			data: JSON.stringify(data ?? {})
					// 		},
					// 		record: {
					// 			controller: this.props.controller,
					// 			cloneController: this.cloneController,
					// 			id: this.id,
					// 			mode: this.mode,
					// 			dataType: 'relationData',
					// 			relations: this.props?.relations?.join?.(', ')
					// 		},
					// 		error: {
					// 			message: err?.response?.data?.message,
					// 			details: err?.response?.data?.details,
					// 			errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED'
					// 		}
					// 	}
					// });
				}
			});
		};

		switch(this.mode) {
			case 'list':
				return (<>
					<Button type="primary" onClick={() => { this.setState({ filtersVisibility: !this.state.filtersVisibility }) }}>
						<FontAwesomeIcon icon={faFilter} />
					</Button>
					{/*<Button type="primary">Eksportuj</Button>*/}
					{extraListActions}
					{
						!this.props?.noCreate
							? <Button type="primary" onClick={() => { this.mode = 'create'; this.props.history.push('/'+this.link+'/create'); }}>{this.props.createButtonText}</Button>
							: null
					}
				</>);
				break;
			case 'create':
				return(<>
					<Button type="danger" onClick={ () => { this.mode = 'list'; this.props.history.push('/'+this.link); } } disabled={ this.state.saving }>Anuluj</Button>
					<Button type="primary" onClick={ () => { this.form.current?.submit(); } } loading={ this.state.saving }>Utwórz</Button>
				</>);
				break;
			case 'view':
			case 'edit':
				/**
				 * Custom buttons on preview/edit mode
				 * @type {Array}
				 */
				let customButtons = [];

				if(this.props?.customButtons) {
					this.props.customButtons.forEach((el, _) => {
						let predicateResult = true;
						if(typeof el.predicate === 'function') {
							predicateResult = el.predicate(el, this.data, this.relationData, this.mode);
						}

						// Skip item due to predicate result
						if(!predicateResult) return;

						if(el?.dropdown) {
							// Item have a dropdown

							/**
							 * Subitems of an item
							 * @type {Array}
							 */
							let menu = [];

							el.dropdown.forEach((_el, _) => {
								let subpredicateResult = true;
								if(typeof _el.predicate === 'function') {
									subpredicateResult = _el.predicate(_el, this.data, this.relationData, this.mode);
								}

								// Skip subitem due to predicate result
								if(!subpredicateResult) return;

								// Push subitem to the item
								menu.push((<Menu.Item key={[el.title, _]} onClick={() => {
									if(this.data && _el.endpoint) {
										const formData = new FormData();
										// Append ID of the current record
										formData.append('id', this.data?.id);

										if(_el.modal) {
											// Subitem have a modal
											Modal.confirm({
												title: _el.modal.title,
												icon: <ExclamationCircleOutlined />,
												okText: _el.modal?.okText ?? 'Wyślij',
												cancelText: 'Anuluj',
												content: <>
													{_el.modal.content}

													{(_el.modal?.fields ?? []).map((modalField, midx) => {
														let _modalField = this.fields.find(f => f.name === modalField);
														if(_modalField) {
															const modalValue = Object.assign(this.data, this.changes)[modalField];

															if(!_el.modal.values) {
																_el.modal.values = {};
															}

															return <Form.Item
																label={<strong>{_modalField.props.title}</strong>}
																labelCol={{span: 24}} name={['modal', modalField]}
																fieldKey={['modal', midx, modalField]}
																onChange={(e) => { _el.modal.values[modalField] = e?.target?.value; }}
															>
																<Input.TextArea defaultValue={modalValue} showCount />
															</Form.Item>;
														}
													})}
												</>,
												onOk(e) {
													for(let key in _el.modal.values) {
														formData.append(key, _el.modal.values[key]);
													}

													return makeRequest(_el.endpoint, el.successText, el.errorText, el?.onSuccess ?? null, formData, _el?.requestMethod ?? 'post');
												},
												onCancel() {}
											});
										} else {
											// Subitem doesn't have a modal
											makeRequest(_el.endpoint, el.successText, el.errorText, el?.onSuccess ?? null, formData, _el?.requestMethod ?? 'post');
										}
									}
								}}>
									{_el.title}
								</Menu.Item>));
							});

							customButtons.push((<Dropdown key={`dropdown~${_}`} overlay={(<Menu>{menu}</Menu>)}>
								<Button type="primary">{el.title} <DownOutlined /></Button>
							</Dropdown>));
						} else {
							// Item doesn't have a dropdown
							let onClick = () => {};

							if(this.data && el.hasOwnProperty('onClick') && typeof el.onClick === 'function') {
								onClick = (e) => {
									el.onClick(e, this.id, this.data);
								};
							}

							customButtons.push((<Button type={el?.buttonType ?? 'primary'} onClick={() => {
								onClick();
								if(this.data && el.endpoint) {
									const formData = new FormData();

									if(el?.formDataFromRecord) {
										el.formDataFromRecord.forEach((dataKey) => {
											if(this.data?.hasOwnProperty(dataKey)) {
												formData.append(dataKey, this.data[dataKey]);
											}
										});
									}

									if(el.modal) {
										// Item have a modal

										Modal.confirm({
											title: el.modal.title,
											icon: <ExclamationCircleOutlined />,
											okText: el.modal?.okText ?? 'Wyślij',
											cancelText: 'Anuluj',
											content: <>
												{el.modal.content}

												{(el.modal?.fields ?? []).map((modalField, midx) => {
													let _modalField = this.fields.find(f => f.name === modalField)
													if(_modalField) {
														const modalValue = Object.assign(this.data, this.changes)[modalField];

														if(!el.modal.values) {
															el.modal.values = {};
														}

														return <Form.Item
															label={<strong>{_modalField.props.title}</strong>}
															labelCol={{span: 24}} name={['modal', modalField]}
															fieldKey={['modal', midx, modalField]}
															onChange={(e) => { el.modal.values[modalField] = e?.target?.value; }}
														>
															<Input.TextArea defaultValue={modalValue} showCount />
														</Form.Item>;
													}
												})}
											</>,
											onOk(e) {
												for(let key in el.modal.values) {
													formData.append(key, el.modal.values[key]);
												}

												return makeRequest(el.endpoint, el.successText, el.errorText, el?.onSuccess ?? null, formData, el?.requestMethod ?? 'post');
											},
											onCancel() {}
										});
									} else {
										// Item doesn't have a modal
										makeRequest(el.endpoint, el.successText, el.errorText, el?.onSuccess ?? null, formData, el?.requestMethod ?? 'post');
									}
								}
							}}>
								{el.title}
							</Button>));
						}
					});
				}

				return (<>
					{(!this.editing || this.props?.readonly) ?
						<>
							{
								(this.isLoading || this.props.disableEditPredicate(this.data))
									? null
									: this.props.linkButtons
										? <Button type="primary" onClick={() => { this.props.history.push(`/${this.link}/${this.id}/edit`); }}>Edytuj</Button>
										: (!this.props?.readonly ? <Button type="primary" onClick={ () => { this.mode = 'edit'; this.editing = true; } }>Edytuj</Button> : null)
							}

							{customButtons}

							{
								this.props?.modalRecord
									? <Button type="link" onClick={() => {
										this.mode = 'list';
									}}>Wróć do listy</Button>
									: <Link to={`/${this.link}`} className="btn-link">Wróć do listy</Link>
							}
						</>
						:
						<>
							<Button type="danger" onClick={() => {
								if(this.props?.modalRecord) {
									this.mode = 'view';

									this.editing = false;
									this.changes = null;
									this.getRecordData();
								} else {
									this.mode = 'view';
									this.editing = false;
									this.changes = null;
									this.props.history.push(`/${this.link}/${this.id}`);
									this.getRecordData();
								}
							}} disabled={this.state.saving}>Anuluj</Button>
							<Button type="primary" onClick={() => { this.form.current?.submit(); }} loading={this.state.saving}>Zapisz</Button>
						</>
					}
				</>);
				break;
		}
	}

	/**
	 * Prepare filter inputs for list view
	 * @return {[type]} [description]
	 */
	prepareFilters() {
		const {
			filters
		} = this.props;

		return filters ? filters.map((filter, index) => {
			let input = null,
				options = [];

			switch(filter?.type) {
				case 'enum':
					if(this.relationData && filter?.relation && this.relationData.hasOwnProperty(filter.relation)) {
						this.relationData[filter.relation].forEach(el => {
							options.push({
								label: el[filter?.relationName ?? 'name'],
								value: el[filter?.relationKey ?? 'id']
							});
							// <Option key={_.uniqueId('option_')} value={el[filter?.relationKey ?? 'id']}>{el[filter?.relationName ?? 'name']}</Option>
						});
					}

					input = <SearchSelect fetch={this.fetchFilterOptions} relation={filter.relation} relationKey={filter?.relationKey} relationName={filter.relationName} style={{ minWidth: 200 }} defaultValue={null} placeholder="Wybierz..." onChange={(value) => {
						this.onFilterChange(filter.name, value?.value ?? value);
					}} />;
					break;
				case 'preenum':
					if(filter?.enum && Array.isArray(filter.enum)) {
						options = filter.enum.map(el => <Option key={_.uniqueId('option_')} value={el?.value}>
							{el.label}
						</Option>);
					}

					input = <Select allowClear showSearch optionFilterProp="children" style={{ minWidth: 200 }} defaultValue={null} placeholder="Wybierz..." onChange={(value) => {
						this.onFilterChange(filter.name, value);
					}}>
						{options}
					</Select>;
					break;
				default:
					input = <Input allowClear defaultValue={null} placeholder="Wyszukaj..." onChange={(e) => {
						this.onFilterChange(filter.name, e.target.value);
					}} />;
					break;
			}

			return (<Col key={index} className="form-group-row">
				<div className="form-group">
					<label>{filter.title}</label>
					<span>
						{input}
					</span>
				</div>
			</Col>);
		}) : null;
	}

	onFilterChange(field, value) {
		const {
			draw,
			filter
		} = this.state;

		let filters = [...filter];

		const alreadyDefined = filter.findIndex(el => el.field === field);

		if(alreadyDefined >= 0) {
			filters[alreadyDefined].value = value;
		} else {
			filters.push({
				field,
				value
			});
		}

		filters.filter(el => el.value ?? false);

		this.setState({
			draw: draw + 1,
			filter: filters
		});
	}

	/**
	 * Prepare content of page for view
	 * @return {ReactDOM.Node}
	 */
	prepareContent() {
		const {
			technicalFields = []
		} = this.props;

		const {
			filtersVisibility,
			draw,
			filter
		} = this.state;

		switch(this.mode) {
			case 'list':
				return (<Card bodyStyle={{ padding: 0 }}>
					<div id="tableFilters" className={filtersVisibility ? 'shown' : ''}>
						<Row gutter={[16,20]}>
							{this.prepareFilters()}
						</Row>
					</div>
					<div key={['dataTable', draw]}>
						<DataTable controller={this.props.controller} columns={this.props.columns} perPage={this.props.perPage} info={this.props.info} pagination={this.props.pagination} filters={filter} defaultSort={this.props.defaultSort} onCreatedRow={this.props?.onCreatedRow} />
					</div>
				</Card>);
				break;
			case 'edit':
			case 'create':
				return (<Row justify="space-between">
					<Col sm={24} lg={16}>
						<Form
							ref={this.context.form}
							scrollToFirstError={true}
							onFinish={this.onRecordSave}
							onFinishFailed={this.onRecordError}
							onValuesChange={this.onValuesChange}
							layout="vertical"
							className="record"
							autoComplete="nope"
						>
							{this.isLoading ? <Card loading={true} /> : this.props.children}
						</Form>
					</Col>

					<Col sm={24} lg={6}>
						<Card loading={this.isLoading}>
							<Row>
								<Col span={24}>
									<Row className="form-group-row" gutter={[16,24]}>
										{technicalFields.map(Field => {
											return React.cloneElement(Field, Object.assign({}, Field.props, {
												registerField: false
											}));
										})}
									</Row>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>);
				break;
			case 'view':
				return (<Row justify="space-between">
					<Col sm={24} lg={16}>
						{this.props.children}
					</Col>

					<Col sm={24} lg={6}>
						<Card loading={this.isLoading}>
							<Row>
								<Col span={24}>
									<Row className="form-group-row" gutter={[16,24]}>
										{technicalFields}
									</Row>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>);
				break;
			case 'clone':
				return (<Card>
					{ !this.state.clonedId ? <>Kopiowanie rekordu...</> : <>Rekord został skopiowany, jeśli nie zostaniesz przekierowany automatycznie <a href={`/${this.link}/${this.state.clonedId}`}>kliknij tutaj</a></> }
					{ this.state.error && <div className="mt-2"><Link to={`/${this.link}`}>Wróć do listy</Link></div> }
				</Card>);
				break;
		}
	}

	render() {
		const {
			error
		} = this.state;

		const {
			alerts
		} = this.props;

		const buttons = this.prepareButtons();

		return (<div className="content">
			<div className="subheader">
				<div className="d-flex">
					<h1 className="title">{this.prepareTitle()}</h1>
					<span className="subtitle">
						Dzisiaj jest <Moment locale="pl" format="dddd, D MMMM YYYY" />
					</span>
				</div>

				{buttons ?
					<Affix offsetTop={54} onChange={(affixed) => { this.setState({ isAffixedActions: affixed }); }}>
						<div className="subheader_actions">
							{
								(this.state?.isAffixedActions ?? false) ?
									<div className="container">
										<h1 className="title m-0">{this.prepareTitle()}</h1>
										<div className="d-flex">
											{buttons}
										</div>
									</div>
									:
									buttons
							}
						</div>
					</Affix>
					:
					null}
			</div>

			<div>
				{ alerts?.length ? <Row style={{ marginBottom: '15px' }}>
					<Col sm={24} md={16}>
						{ alerts.map((alert, idx) => {
							if(!alert.modes.includes(this.mode)) return;

							return (alert?.condition && typeof alert.condition === 'function' && alert.condition(this.data)) ? <Alert type={ alert.type ?? 'info' } message={ alert.message } /> : (!alert?.condition) ? <Alert type={ alert.type ?? 'info' } message={ alert.message } /> : null;
						}) }
					</Col>
				</Row> : '' }

				{ error ? <Row style={{ marginBottom: '15px' }}>
					<Col sm={24} md={16}>
						<Alert type="error" message={ prepareErrorMessage(error) } />
					</Col>
				</Row> : '' }
				{this.prepareContent()}
			</div>
		</div>);
	}
}

export default withRouter(Record);
