import React from 'react';

import Moment from 'react-moment';

import Record from '../../../components/Record/index';

import TabbedCard from '../../../components/TabbedCard';

import { Row, Col, Card, Button } from 'antd';

import '../../../assets/scss/dashboard.scss';

const Dashboard_Offers_Trips = (props) => {
	const [currentActiveTab, setCurrentActiveTab] = React.useState('general');

	return (<div className="content">
		<Record
			controller="offtrips"
			referrer="trips"
			relations={['services', 'dcttriptypes', 'dctunits', 'contractors', 'dctresorts']}
			relationsLimit={-1}
			mode={props?.mode}
			defaultEditing={props?.editing}
			columns={[
				{
					name: 'id',
					title: '#',
					type: 'number'
				},
				{
					name: 'dctresort_name',
					title: 'Nazwa ośrodka',
					searchable: true
				},
				{
					name: 'dcttriptype_name',
					title: 'Rodzaj wyjazdu',
					searchable: true,
					sortname: 'dcttriptypes.name',
					searchname: 'dcttriptypes.name'
				},
				{
					name: 'place',
					title: 'Lokalizacja',
					searchable: true
				},
				{
					name: 'from',
					title: 'Terminy od',
					searchable: true
				},
				{
					name: 'to',
					title: 'Terminy do',
					searchable: true
				},
				{
					name: 'count_nights',
					title: 'Liczba noclegów',
					searchable: true
				},
				{
					name: 'is_active',
					title: 'Aktywna',
					datatype: 'bool'
				},
				{
					name: 'actions',
					data: null,
					sortable: false,
					title: '',
					sticky: true,
					render: function(data, type, row) {
						return `<a class="btn btn-table" href="/trips/${data.id}">Podgląd</a><a class="btn btn-table" href="/trips/${data.id}/edit">Edytuj</a><a class="btn btn-table" href="/trips/${data.id}/clone">Skopiuj</a>`;
					},
					className: 'text-right last-col-sticky'
				}
			]}
			viewTitle="Podgląd oferty wyjazdu"
			listTitle="Wyjazdy"
			createTitle="Tworzenie oferty wyjazdu"
			editTitle="Edycja oferty wyjazdu"
		>
			<Record.Consumer>
				{({ isLoading }) => (
					<TabbedCard key="generalCard" loading={isLoading} tabContent={{
						general: [
							<Record.Field.Relation title={'Rodzaj wyjazdu'} name="dcttriptype_id" relation="dcttriptypes" />,
							<Record.Field.Relation title={'Ośrodek wczasowy'} name="dctresort_id" relation="dctresorts" />,
							<Record.Field.Input title={'Ośrodek wczasowy - opis'} name="resort" />,
							<Record.Field.Input title={'Lokalizacja ośrodka'} name="place" />,
							<Record.Field.Input title={'Sposób dojazdu'} name="transport" />,
							<Record.Field.Date title={'Termin od'} name="from" />,
							<Record.Field.Date title={'Termin do'} name="to" />,
							<Record.Field.Switch title={'Aktywna'} name="is_active" />,
							<Record.Field.Textarea title={'Uwagi'} name="description" span={{ span: 24 }} />
						],
						prices: [
							<Record.Field.TableRelation title={'Cennik'} showTitle={false} name="tripprices" span={{ span: 24 }} fields={[
								{ name: 'id', title: 'ID', isHidden: true, removeEmpty: true },
								{ name: 'service_id', title: 'Usługa', type: 'creatableRelation', relation: 'services', filterData: (data, record) => {
									return data.filter(el => +el.servicegroup_id === 1);
								}, span: { lg: 9, xl: 9 }, controller: "services", field: 'name', fields: [
									{ name: 'servicegroup_id', value: 1 },
									{ name: 'is_active', value: 1 },
									{ name: 'name', value: '' }
								] },
								{ name: 'price', type: 'number', title: 'Cena', span: { lg: 5, xl: 5 } },
								{ name: 'dctunit_id', title: 'Jednostka miary', type: 'relation', relation: 'dctunits', span: { lg: 6, xl: 6 }, copyValueFromPreviousRow: true }
							]} />
						],
						availability: [
							<Record.Field.TableCheck title={'Dostępność'} showTitle={false} name="contractors" span={{ span: 24 }} relatedTable="contractors" transformDataKey="contractors[id]" fields={[
								{ name: 'name', title: 'Kontrahent' },
							]} />
						]
					}} tabList={[
						{ key: 'general', tab: 'Dane podstawowe'},
						{ key: 'prices', tab: 'Cennik' },
						{ key: 'availability', tab: 'Dostępność' }
					]} activeTabKey={currentActiveTab} onTabChange={(key) => { setCurrentActiveTab(key) }} forceRender={true} />
				)}
			</Record.Consumer>
		</Record>
	</div>);
};

export default Dashboard_Offers_Trips;
