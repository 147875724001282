import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Record_Field from './Field';

import { Form, Input, Checkbox, Tag } from 'antd';

import _ from 'lodash';

import { prepareFieldInput, prepareFieldValue } from './utils/Helper';

class RecordFieldTableCheck extends Record_Field {
	static propTypes = Object.assign({}, Record_Field.propTypes, {
		fields: PropTypes.array.isRequired,
		relatedTable: PropTypes.string.isRequired
	});

	static defaultProps = Object.assign({}, Record_Field.defaultProps, {

	});

	static displayName = 'RecordFieldTableCheck';

	get relation() {
		const {
			relatedTable
		} = this.props;

		if(this.relationData && relatedTable && this.relationData.hasOwnProperty(relatedTable)) {
			return this.relationData[relatedTable] ?? [];
		}

		return [];
	}

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	getValue() {
		const {
			name,
			fields,
			relatedTable
		} = this.props;

		return <div className="ant-table" key={`tableRelation~preview~${name}`}>
			<div className="ant-table-container">
				<div className="ant-table-content">
					<table style={{ tableLayout: 'auto' }}>
						<thead className="ant-table-thead">
							<tr>
								{ fields.map((field, idx) => (<React.Fragment key={`theadCol~${idx}`}>
									<th className="ant-table-cell">{ field.title }</th>
								</React.Fragment>)) }
								<th>Aktywne</th>
							</tr>
						</thead>
						<tbody className="ant-table-tbody">
							{
								this.relation.map(row => {
									const isChecked = this.getRawValue() ? (this.getRawValue().find(el => el.id === row.id) ? true : false) : false;

									return <tr key={_.uniqueId('row_')}>
										{
											fields.map(field => row[field.name] ? <td key={_.uniqueId('col_')}>
												{row[field.name]}
											</td> : null)
										}
										<td>
											{isChecked ?
												<Tag color="green">TAK</Tag>
												:
												<Tag color="red">NIE</Tag>
											}
										</td>
									</tr>;
								})
							}
						</tbody>
					</table>
				</div>
			</div>
		</div>;
	}

	renderEdit() {
		const {
			name,
			fields,
			relatedTable,
			disabled
		} = this.props;

		return <div className="ant-table" key={`tableRelation~preview~${name}`}>
			<div className="ant-table-container">
				<div className="ant-table-content">
					<table style={{ tableLayout: 'auto' }}>
						<thead className="ant-table-thead">
							<tr>
								{ fields.map((field, idx) => (<React.Fragment key={['headCol', this.key, field.name]}>
									<th className="ant-table-cell">{ field.title }</th>
								</React.Fragment>)) }
								<th>Aktywne</th>
							</tr>
						</thead>
						<tbody className="ant-table-tbody">
							{
								this.relation.map((row, idx) => {
									const isChecked = this.getRawValue() ? (this.getRawValue().find(el => el.id === row.id) ? true : false) : false;

									return <tr key={['row', idx, this.key]}>
										{
											fields.map(field => row[field.name] ? <td key={['col', idx, this.key, field.name]}>
												{row[field.name]}
											</td> : null)
										}
										<td>
											<Form.Item
												name={[name, idx, 'checked']}
												valuePropName="checked"
												fieldKey={[name, idx, 'checked']}
												initialValue={isChecked}
											>
												<Checkbox disabled={disabled} />
											</Form.Item>

											<Form.Item
												name={[name, idx, 'id']}
												fieldKey={[name, idx, 'id']}
												initialValue={row.id}
												hidden
											>
												<Input value={row.id} />
											</Form.Item>
										</td>
									</tr>;
								})
							}
						</tbody>
					</table>
				</div>
			</div>
		</div>;
	}
}

export default RecordFieldTableCheck;
