import React from 'react';
import PropTypes from 'prop-types';

import Record_Field from './Field';

import { Form, Switch } from 'antd';

class RecordFieldSwitch extends Record_Field {
	static propTypes = Object.assign({}, Record_Field.propTypes, {
		checkedChildren: PropTypes.string,
		unCheckedChildren: PropTypes.string
	});

	static defaultProps = Object.assign({}, Record_Field.defaultProps, {
		checkedChildren: 'Tak',
		unCheckedChildren: 'Nie'
	});

	static displayName = 'RecordFieldSwitch';

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	getValue() {
		return this.getRawValue() ? 'Tak' : 'Nie';
	}

	renderEdit() {
		const {
			title,
			name,
			disabled,

			checkedChildren,
			unCheckedChildren
		} = this.props;

		return <Form.Item
			{...this.globalItemProps}
			valuePropName="checked"
		>
			<Switch style={{ width: 55 }} autoComplete="nope" disabled={disabled} checkedChildren={checkedChildren} unCheckedChildren={unCheckedChildren} />
		</Form.Item>;
	}
}

export default RecordFieldSwitch;
