import React from 'react';
import PropTypes from 'prop-types';

import reactComponentDebounce from 'react-component-debounce';

import Record_Field from './Field';

import { Form, InputNumber } from 'antd';

const DebouncedInput = reactComponentDebounce({
	valuePropName: 'value',
	triggerMs: 250
})(InputNumber);

class RecordFieldNumber extends Record_Field {
	static propTypes = Object.assign({}, Record_Field.propTypes, {
		decimalSeparator: PropTypes.string,
		formatter: PropTypes.func,
		max: PropTypes.number,
		min: PropTypes.number,
		parser: PropTypes.func,
		precision: PropTypes.number,
		step: PropTypes.number
	});

	static defaultProps = Object.assign({}, Record_Field.defaultProps, {
		decimalSeparator: '.',
		formatter: (value) => value.replace(/,/, '.'),
		max: Number.MAX_SAFE_INTEGER,
		min: Number.MIN_SAFE_INTEGER,
		parser: (value) => value.replace(/,/, '.'),
		precision: undefined,
		step: 1
	});

	static displayName = 'RecordFieldNumber';

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	renderEdit() {
		const {
			title,
			name,
			disabled = false,
			debounced,

			decimalSeparator,
			formatter,
			max,
			min,
			parser,
			precision,
			step
		} = this.props;

		const inputProps = {
			decimalSeparator,
			formatter,
			max,
			min,
			parser,
			precision,
			step
		};

		return <Form.Item
			{...this.globalItemProps}
		>
			{debounced ? <DebouncedInput style={{ width: '100%' }} autoComplete="nope" disabled={disabled} {...inputProps} /> : <InputNumber style={{ width: '100%' }} autoComplete="nope" disabled={disabled} {...inputProps} />}
		</Form.Item>;
	}
}

export default RecordFieldNumber;
