import { APIInstance } from '../../';
import { API } from '../../api';

export default {
	importPesels(type, file, cid) {
		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.post(`/api/pesels/${type === 'pensioners' ? 'pensioners' : 'employees'}/import`, {
					peselfile: file,
					contractor_id: cid
				}).then(r => {
					resolve(r.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	checkPesels(type, file, cid) {
		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.post(`/api/pesels/${type === 'pensioners' ? 'pensioners' : 'employees'}/check`, {
					peselfile: file,
					contractor_id: cid
				}).then(r => {
					resolve(r.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},
};
