import React from 'react';

import Moment from 'react-moment';
import moment from 'moment';

import TabbedCard from '../../components/TabbedCard';

import axios from 'axios';

import { backendRoot, backendApi, csrfCookie, apiData } from '../../config/paths';

import { filterObject, objectToFormData } from '../../libs/Helpers.js';
import prepareErrorMessage from '../../libs/ErrorHelper.js';

import { Row, Col, Form, Input, Button, Switch, Alert, Tooltip, message } from 'antd';
import { PlusOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import '../../assets/scss/dashboard.scss';

const tabs = [
	{
		key: 'general',
		tab: 'Ogólne'
	},
	{
		key: 'mailing',
		tab: 'Mailing'
	}
];

const Dashboard_Config = ({...props}) => {
	/**
	 * Data of record
	 * @type {Object}
	 */
	const [data, setData] = React.useState(null);

	/**
	 * Data of mailings
	 * @type {Object}
	 */
	const [mailingData, setMailingData] = React.useState(null);

	/**
	 * State of process - do error has occurred?
	 * @type {Boolean}
	 */
	const [isError, setError] = React.useState(false);


	/**
	 * State of view - is record being saved at this moment?
	 * @type {Boolean}
	 */
	const [isSaving, setSaving] = React.useState(false);

	/**
	 * Key of active tab if any
	 * @type {String}
	 */
	const [activeTab, setActiveTab] = React.useState(tabs.length ? tabs[0].key : null);

	/**
	 * Instance of Ant Design Form
	 * @type {Form.Instance}
	 */
	const [form] = Form.useForm();

	React.useEffect(() => {
		getConfigData();
		getMailingData();
	}, []);

	const tabsContent = {
		general: <>
			<Col sm={24} md={12}>
				<Form.Item label={<strong>Dane administratora:</strong>} initialValue={data?.admin_name} name="admin_name">
					<Input />
				</Form.Item>
			</Col>

			<Col sm={24} md={12}>
				<Form.Item label={<strong>Numer kontaktowy administratora:</strong>} initialValue={data?.admin_phone} name="admin_phone">
					<Input />
				</Form.Item>
			</Col>

			<Col sm={24} md={12}>
				<Form.Item label={<strong>Adres e-mail administratora:</strong>} initialValue={data?.admin_email} name="admin_email">
					<Input />
				</Form.Item>
			</Col>

			<Col sm={24} md={12}>
				<Form.Item label={<strong>Nazwa firmy:</strong>} initialValue={data?.company_name} name="company_name">
					<Input />
				</Form.Item>
			</Col>

			<Col sm={24} md={12}>
				<Form.Item label={<strong>Ulica firmy:</strong>} initialValue={data?.company_street} name="company_street">
					<Input />
				</Form.Item>
			</Col>

			<Col sm={24} md={4}>
				<Form.Item label={<strong>Kod pocztowy firmy:</strong>} initialValue={data?.company_zip} name="company_zip">
					<Input />
				</Form.Item>
			</Col>

			<Col sm={24} md={8}>
				<Form.Item label={<strong>Miejscowość firmy:</strong>} initialValue={data?.company_town} name="company_town">
					<Input />
				</Form.Item>
			</Col>

			<Col sm={24} md={12}>
				<Form.Item label={<strong>NIP firmy:</strong>} initialValue={data?.company_nip} name="company_nip">
					<Input />
				</Form.Item>
			</Col>

			<Col sm={24} md={12}>
				<Form.Item label={<strong>Adres e-mail firmy:</strong>} initialValue={data?.company_email} name="company_email">
					<Input />
				</Form.Item>
			</Col>

			<Col sm={24} md={12}>
				<Form.Item label={<strong>Numer kontaktowy firmy:</strong>} initialValue={data?.company_phone} name="company_phone">
					<Input />
				</Form.Item>
			</Col>

			<Col sm={24} md={12}>
				<Form.Item label={<strong>Numer FAX firmy:</strong>} initialValue={data?.company_fax} name="company_fax">
					<Input />
				</Form.Item>
			</Col>

			<Col sm={24} md={12}>
				<Form.Item label={<strong>Numer konta bankowego</strong>} initialValue={data?.company_bank} name="company_bank">
					<Input />
				</Form.Item>
			</Col>

			<Col sm={24} md={12}>
				<Form.Item label={<strong>Walidacja numeru PESEL</strong>} initialValue={data?.is_pesel_checking} name="is_pesel_checking" valuePropName="checked">
					<Switch checkedChildren="Wł." unCheckedChildren="Wył." />
				</Form.Item>
			</Col>
		</>,
		mailing: <><Col span={24}>
		<div className="ant-table" key={`table~preview~${props.field}`}>
			<div className="ant-table-container">
				<div className="ant-table-content">
					<table style={{ tableLayout: 'auto' }}>
						<thead className="ant-table-thead">
							<tr>
								<th>Rodzaj wiadomości</th>
								<th>Odbiorcy</th>
							</tr>
						</thead>
						<tbody className="ant-table-tbody">
							{ (mailingData && mailingData.map((el, idx) => {
								return <tr key={['mailingRow', idx]}>
									<td>{el.name} <Tooltip title={el.remarks}><QuestionCircleOutlined style={{ marginLeft: '5px' }} /></Tooltip></td>
									<td>
										<Form.List name={['mailings', el.id]}>
											{(_fields, { add, remove }) => (
												<>
													<Row align="middle" gutter={[8,8]}>
														<Col span={24}>
															{_fields.map((field, _idx) => (
																<Row key={`wrapper~${field.key}`}>
																	<React.Fragment key={field.key}>
																		<Col xl={21} lg={20}>
																			<Form.Item name={[field.name, 'email']} fieldKey={[field.fieldKey, 'email']}>
																				<Input placeholder="Adres e-mail" />
																			</Form.Item>

																			<Form.Item hidden={true} name={[field.name, 'id']} fieldKey={[field.fieldKey, 'id']} initialValue={el.id}>
																				<Input />
																			</Form.Item>
																		</Col>
																	</React.Fragment>
																	<React.Fragment key={`actions~${idx}`}>
																		<Col xl={3} lg={4}>
																			<Button danger style={{ marginLeft: '5px' }} onClick={() => remove(field.name)}><DeleteOutlined /></Button>
																		</Col>
																	</React.Fragment>
																</Row>
															))}
														</Col>

														<Col span={24}>
															<Form.Item>
																<Button type="dashed" onClick={ () => add() } block icon={<PlusOutlined />}>
																	Dodaj pozycję
																</Button>
															</Form.Item>
														</Col>
													</Row>
												</>
											)}
										</Form.List>
									</td>
								</tr>;
							})) }
						</tbody>
					</table>
				</div>
			</div>
		</div>
		</Col></>
	};

	const getConfigData = () => {
		axios.get(backendRoot + csrfCookie).then(() => {
			// Getting record data
			axios.get(`${backendApi}/configs/1`).then((response) => {
				// Assigning record data to state
				setData(response.data);
				setError(false);
			}).catch((err) => {
				setData(false);
				if(err.response) {
					if(err.response.data.errcode) {
						setError(err.response.data.errcode);
					} else {
						setError('ERR-UNDEFINED');
					}
				} else {
					setError('ERR-UNDEFINED');
				}
			});
		}).catch((err) => {
			setData(false);
			if(err.response) {
				if(err.response.data.errcode) {
					setError(err.response.data.errcode);
				} else {
					setError('ERR-UNDEFINED');
				}
			} else {
				setError('ERR-UNDEFINED');
			}
		});
	};

	const getMailingData = () => {
		axios.get(backendRoot + csrfCookie).then(() => {
			// Getting record data
			axios.get(`${backendApi}/mailings`).then((response) => {
				// Assigning record data to state
				setMailingData(response.data);
				setError(false);
			}).catch((err) => {
				setMailingData(false);
				if(err.response) {
					if(err.response.data.errcode) {
						setError(err.response.data.errcode);
					} else {
						setError('ERR-UNDEFINED');
					}
				} else {
					setError('ERR-UNDEFINED');
				}
			});
		}).catch((err) => {
			setMailingData(false);
			if(err.response) {
				if(err.response.data.errcode) {
					setError(err.response.data.errcode);
				} else {
					setError('ERR-UNDEFINED');
				}
			} else {
				setError('ERR-UNDEFINED');
			}
		});
	};

	const onFinish = (values) => {
		const _mailingData = new FormData();
		const _data = new FormData();

		for(let field in values) {
			let fieldValue = values[field];

			switch(field) {
				case 'mailings':
					break;
				default:
					_data.append(field, fieldValue);
					break;
			}
		}
		Array.isArray(values.mailings) && values.mailings.forEach((el, idx) => {
			if(!Array.isArray(el) || !el.length) return;

			let _mailData = {
				id: el[0].id,
				receivers: el.map(_el => _el.email).join(';')
			};

			_mailingData.append(`mailings[${idx}][id]`, _mailData.id);
			_mailingData.append(`mailings[${idx}][receivers]`, _mailData.receivers);
		});

		axios.get(backendRoot + csrfCookie).then(() => {
			// Save config
			Promise.all([axios.post(backendApi + '/batch/update', _mailingData), axios.post(backendApi + '/configs/1', _data)]).then((response) => {
				getConfigData();
				getMailingData();
				setSaving(false);
				setError(false);
				message.success('Konfiguracja została zapisana');
			}).catch((err) => {
				if(err.response) {
					if(err.response.data.errcode) {
						setError(err.response.data.errcode);
					} else {
						setError('ERR-UNDEFINED');
					}
					setSaving(false);
				} else {
					if(err.response) {
						if(err.response.data.errcode) {
							setError(err.response.data.errcode);
						} else {
							setError('ERR-UNDEFINED');
						}
					} else {
						setError('ERR-UNDEFINED');
					}
					setSaving(false);
				}
				message.error('Wystąpił błąd podczas zapisu konfiguracji');
			});
		}).catch((err) => {
			if(err.response) {
				if(err.response.data.errcode) {
					setError(err.response.data.errcode);
				} else {
					setError('ERR-UNDEFINED');
				}
			} else {
				setError('ERR-UNDEFINED');
			}
			setSaving(false);
		});
	};

	React.useEffect(() => {
		let mailings = [];

		if(Array.isArray(mailingData)) {
			mailingData.forEach((el) => {
				let item = (el?.receivers || '').split(';').map(_el => { return { email: _el, id: el?.id } });

				mailings[el.id] = item ?? [];
			});
		}

		if(form) {
			form.setFieldsValue({
				mailings: mailings
			});
		}
	}, [mailingData]);

	return (<div className="content">
		<div className="subheader">
			<div className="d-flex">
				<h1 className="title">Konfiguracja systemu</h1>
				<span className="subtitle">
					Dzisiaj jest <Moment locale="pl" format="dddd, D MMMM YYYY" />
				</span>
			</div>

			<div className="subheader_actions">
				<Button type="primary" onClick={ () => { form.submit(); setSaving(true); } } loading={ isSaving }>Zapisz</Button>
			</div>
		</div>

		{ isError ? <Row style={{ marginBottom: '15px' }}>
			<Col sm={24} md={16}>
				<Alert type="error" message={ prepareErrorMessage(isError) } />
			</Col>
		</Row> : '' }

		<Form form={form} scrollToFirstError={true} onFinish={onFinish} layout="vertical">
			<TabbedCard
				loading={(data === null || mailingData === null)}
				tabList={tabs}
				tabContent={tabsContent}
				activeTabKey={activeTab}
				onTabChange={(key) => setActiveTab(key)}
				// forceRender={true}
			/>
		</Form>
	</div>);
};

export default Dashboard_Config;
