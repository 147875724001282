import React from 'react';

import axios from 'axios';

import { backendRoot, backendApi, csrfCookie, apiData } from '../../config/paths';

const RecordContext = React.createContext();

const RecordReducer = (state, action) => {
	switch(action.type) {
		case 'addField':
			if(!state.fields.find(el => el.props.name === action.payload.props.name)) {
				return {
					fields: [...state.fields, action.payload]
				};
			} else {
				return {
					fields: state.fields
				}
			}
			break;
		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
};

const RecordProvider = (props) => {
	const [state, dispatch] = React.useReducer(RecordReducer, {
		fields: []
	});

	// FIX WARNING : Can't perform a React state update on an unmounted component.
	const controller = new AbortController();
	React.useEffect(() => {
		updateRelationData();
		return () => {
			setRelationData(null);
			controller.abort();
		};
	}, [backendApi, backendRoot]);

	const updateRelationData = () => {
		if(_relationsQuery) {
			axios.get(backendRoot + csrfCookie).then(() => {
				// Getting data of related records
				axios.get(`${backendApi}${apiData}?${_relationsQuery}`).then((response) => {
					// Assigning data of related records to state
					setRelationData(response.data);
				}).catch(() => {
					setRelationData(null);
				});
			}).catch(() => {
				setRelationData(null);
			});
		}
	};

	const [_data, setData] = React.useState(null);
	const [_changes, setChanges] = React.useState(null);
	const [_relationData, setRelationData] = React.useState(null);
	const [_dependenciesData, setDependenciesData] = React.useState(null);
	const [_relationsQuery, setRelationsQuery] = React.useState(null);
	const [_mode, setMode] = React.useState(props.mode ?? props.defaultMode);
	const [_editing, setEditing] = React.useState(props?.editing);

	const [_errorField, setErrorField] = React.useState(null);

	const form = React.useRef(null);

	const reducer = {state, dispatch};

	const data = {value: _data, set: setData};
	const changes = {value: _changes, set: setChanges};
	const relationData = {value: _relationData, set: setRelationData};
	const dependenciesData = {value: _dependenciesData, set: setDependenciesData};
	const relationsQuery = {value: _relationsQuery, set: setRelationsQuery};
	const mode = {value: _mode, set: setMode};
	const editing = {value: _editing, set: setEditing};

	const errorField = {value: _errorField, set: setErrorField};

	const isLoading = props.withRelations ? (_data === null || _relationData === null) : _data === null;

	return (<RecordContext.Provider value={{ form, reducer, data, changes, relationData, dependenciesData, relationsQuery, mode, editing, isLoading, updateRelationData, errorField }} {...props} />);
};

const useRecord = () => React.useContext(RecordContext);

export { RecordContext, RecordProvider, useRecord };
