import React from 'react';

import Moment from 'react-moment';

import Record from '../../../components/Record/index';

import TabbedCard from '../../../components/TabbedCard';

import { useAuth, PERMISSIONS as USER_PERMISSIONS } from '../../../contexts/User';

import { Row, Col, Card, Button } from 'antd';

import moment from 'moment';

import '../../../assets/scss/dashboard.scss';

const Dashboard_Offers_Multisports = (props) => {
	const [currentActiveTab, setCurrentActiveTab] = React.useState('general');

	const auth = useAuth();

	return (<div className="content">
		<Record
			controller="offmultis"
			referer="multis"
			relations={['dctmultitypes', 'dctpaymethods', 'services']}
			mode={props?.mode}
			defaultEditing={props?.editing}
			filters={[
				{
					title: 'Kontrahent',
					name: 'contractors.id',
					relation: 'contractors',
					type: 'enum'
				}
			]}
			columns={[
				{
					name: 'id',
					title: '#',
					type: 'number'
				},
				{
					name: 'dctresort_name',
					title: 'Nazwa ośrodka',
					searchable: true
				},
				{
					name: 'dctcamptype_name',
					title: 'Rodzaj wypocznku',
					searchable: true,
					sortname: 'dctcamptypes.name'
				},
				{
					name: 'place',
					title: 'Lokalizacja',
					searchable: true
				},
				{
					name: 'from',
					title: 'Terminy od',
					searchable: true
				},
				{
					name: 'to',
					title: 'Terminy do',
					searchable: true
				},
				{
					name: 'count_nights',
					title: 'Liczba noclegów',
					searchable: true
				},
				{
					name: 'is_active',
					title: 'Aktywna',
					datatype: 'bool'
				},
				{
					name: 'actions',
					data: null,
					sortable: false,
					title: '',
					render: function(data, type, row) {
						return `<a class="btn btn-table" href="/camps/${data.id}">Podgląd</a><a class="btn btn-table" href="/camps/${data.id}/edit">Edytuj</a><a class="btn btn-table" href="/camps/${data.id}/clone">Skopiuj</a>`;
					},
					className: 'text-right'
				}
			]}
			viewTitle="Podgląd oferty kart Sportowe"
			listTitle="Karty Sportowe"
			editTitle="Oferta kart Sportowe - EDYCJA"
			createTitle="Oferta kart Sportowe - TWORZENIE"
		>
			<Record.Consumer>
				{({ isLoading, mode }) => (
					<TabbedCard key="generalCard" loading={isLoading} tabContent={{
						general: [
							<Record.Field.Relation title="Rodzaj karty" name="dctmultitype_id" relation="dctmultitypes" />,
							<Record.Field.Relation title="Sposób płatności" name="dctpaymethod_id" relation="dctpaymethods" />,
							<Record.Field.Textarea title={'Informacje'} name="description" span={{ span: 24 }} />
						],
						prices: [
							<Record.Field.TableRelation title={'Cennik'} showTitle={false} name="multiprices" span={{ span: 24 }} relation="services" fields={[
								{ name: 'id', title: 'ID', isHidden: true, removeEmpty: true },
								{ name: 'service_id', title: 'Usługa', type: 'relation', relation: 'services', filterData: (data, record) => {
										return data.filter(el => el.servicegroup_id === 4);
									}, span: { lg: 9, xl: 9 } },
								{ name: 'price', type: 'number', title: 'Cena', span: { lg: 5, xl: 5 } },
								{ name: 'dctunit_id', title: 'Jednostka miary', type: 'relation', relation: 'dctunits', span: { lg: 6, xl: 6 } }
							]} />
						]
					}} tabList={[
						{ key: 'general', tab: 'Dane podstawowe' },
						{ key: 'prices', tab: 'Cennik' }
					]} activeTabKey={currentActiveTab} onTabChange={(key) => { setCurrentActiveTab(key) }} forceRender={true} />
				)}
			</Record.Consumer>
		</Record>
	</div>);
};

export default Dashboard_Offers_Multisports;
