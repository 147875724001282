export default function prepareErrorMessage(error) {
	let err;

	let errcode, message;

	if(typeof error === 'object') {
		errcode = error.errcode || error.response?.data?.errcode;
		message = error.message || error.response?.data?.message;
	} else {
		errcode = error;
	}

	switch(errcode) {
		case 'VALIDATE-ERRORS':
			err = 'Podane dane są nieprawidłowe, prosimy o ich poprawienie i spróbowanie ponownie.';
			break;
		case 'PESEL-NOT-MATCH':
			err = 'Podany numer PESEL jest niezgodny.';
			break;
		case 'NAME-NOT-MATCH':
			err = 'Podane dane osobowe są niezgodne.';
			break;
		case 'CONTRACTOR-NOT-MATCH':
			err = 'Żaden kontrahent nie został przypisany do podanych danych.';
			break;
		case 'DATA-MATCHED':
			err = 'Podane dane są zgodne.';
			break;
		case 'CLIENT-NO-CONFIG':
			err = 'Brak informacji o wersji systemu klienckiego.';
			break;
		case 'CLIENT-DENIED-ADMIN':
			err = 'Brak wymaganych uprawnień.';
			break;
		case 'CLIENT-DENIED-CLIENT':
			err = 'Brak wymaganych uprawnień.';
			break;
		case 'ACCOUNT-IS-LOCKED':
			err = 'Konto zostało zablokowane, prosimy o kontakt z administratorem serwisu w celu ewentualnego wyjaśniania problemu.';
			break;
		case 'ACCOUNT-NOT-ACTIVATED':
			err = 'Konto nie zostało jeszcze aktywowane.';
			break;
		case 'WRONG-EMAIL-PASSWORD':
			err = 'Podany adres e-mail lub hasło są nieprawidłowe';
			break;
		case 'USER-LOGGED-IN':
			err = 'Zostałeś pomyślnie zalogowany do systemu.';
			break;
		case 'USER-LOGGED-OUT':
			err = 'Zostałeś pomyślnie wylogowany z systemu.';
			break;
		case 'WRONG-EMAIL':
		case 'PERMISSION-FAILURE':
			err = 'Podany adres e-mail jest nieprawidłowy.';
			break;
		case 'USER-NOT-EXISTS':
			err = 'Użytkownik o podanym adresie e-mail nie istnieje.';
			break;
		case 'LOGIN-FAILURE':
			err = 'Wystąpił błąd podczas uwierzytelniania. Sprawdź poprawność danych.';
			break;
		case 'EMAIL-WAS-SEND':
			err = 'Wiadomość została wysłana na przypisany do konta adres e-mail.';
			break;
		case 'USER-NOT-TOKEN':
			err = 'Podany token jest nieprawidłowy lub wygasł.';
			break;
		case 'TOKEN-NOT-MATCH':
			err = 'Podany token jest nieprawidłowy lub wygasł.';
			break;
		case 'TOKEN-IS-TIMEOUTED':
			err = 'Podany token jest nieprawidłowy lub wygasł.';
			break;
		case 'PASSWORD-WAS-CHANGED':
			err = 'Hasło użytkownika zostało pomyślnie zmienione.';
			break;
		case 'TOKEN-NOT-EXISTS':
			err = 'Podany token jest nieprawidłowy lub wygasł.';
			break;
		case 'TOKEN-IS-VALID':
			err = 'Podany token jest prawidłowy.';
			break;
		case 'GET-DATA-ERROR':
			err = 'Wystąpił błąd podczas pobierania danych, prosimy spróbować ponownie później lub skontaktować się z administratorem serwisu.';
			break;
		case 'UPDATE-ERROR':
			err = 'Wystąpił błąd podczas aktualizacji danych, prosimy spróbować ponownie później lub skontaktować się z administratorem serwisu.';
			break;
		case 'CREATE-ERROR':
			err = 'Wystąpił błąd podczas zapisywania danych, prosimy spróbować ponownie później lub skontaktować się z administratorem serwisu.';
			break;
		case 'SHOW-DATA-ERROR':
			err = 'Wystąpił błąd podczas pobierania danych, prosimy spróbować ponownie później lub skontaktować się z administratorem serwisu.';
			break;
		case 'PROFILE-EXISTS':
			err = 'Profil klienta dla tego kontrahenta już istnieje, nie można utworzyć nowego profilu. Sprawdź inne profile o statusie "W trakcie wprowadzania" itp.';
			break;
		case 'PROFILE-READONLY':
			err = 'Nie można edytować wybranego profilu - należy skopiować profil do edycji.';
			break;
		case 'APP-NUMBER-NOTEXISTS':
			err = 'Brak nadanego numeru wniosku, proszę nadać numer i spróbować ponownie.';
			break;
		case 'PAYMENT-WAS-REGISTERED':
			err = 'Wpłata za podany okres została już zarejestrowana'
			break;
		case 'REGULATION-NOT-EXISTS':
		case 'REGULATION-NOT-MEET':
			err = message;
			break;
		case 'IN-WORKFLOW-PROFILE':
			err = message;
			break;
		case 'USER-ALREADY-EXISTS':
			err = message;
			break;
		case 'ERR-UNDEFINED':
			err = 'Wystąpił nieznany błąd, prosimy spróbować ponownie lub skontaktować się z administratorem serwisu.';
			break;
		default:
			err = message ?? 'Wystąpił nieznany błąd, prosimy spróbować ponownie lub skontaktować się z administratorem serwisu.';
			break;
	}

	return err;
};
