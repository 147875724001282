import { APIInstance } from './';

import * as APIEndpoints from './endpoints';

const API = {
	csrfCookie() {
		return APIInstance.get(`/sanctum/csrf-cookie`);
	},

	/**
	 * Get counts of unseen applications and profiles
	 *
	 * @param  {Number} [bit=3]        Which bit of is_seen should be checked (1 - admins | 2 - committee | 3 - all)
	 * @param  {Object} [filters=null]
	 *
	 * @return {Object}
	 */
	appsUnseen(bit = 3, filters = null) {
		const serializeURI = (obj, prefix) => {
			let str = [],
				p;

			for(p in obj) {
				if(obj.hasOwnProperty(p)) {
					let k = prefix ? `${prefix}[${p}]` : p,
						v = obj[p];

					str.push(
						(v !== null && typeof v === 'object')
							? serializeURI(v, k)
							: encodeURIComponent(k)+'='+encodeURIComponent(v)
					);
				}
			}

			return str.join('&');
		};

		return APIInstance.get(`/api/data/checkseen/${bit}${(filters && Object.keys(filters).length) ? `?${serializeURI(filters)}` : ''}`);
	},

	apps(filters, sort = null) {
		const serializeURI = (obj, prefix) => {
			let str = [],
				p;

			for(p in obj) {
				if(obj.hasOwnProperty(p)) {
					let k = prefix ? `${prefix}[${p}]` : p,
						v = obj[p];

					str.push(
						(v !== null && typeof v === 'object')
							? serializeURI(v, k)
							: encodeURIComponent(k)+'='+encodeURIComponent(v)
					);
				}
			}

			return str.join('&');
		};

		return APIInstance.get(`/api/appviews${(filters && Object.keys(filters).length) ? `?${serializeURI({filter: filters, sort})}` : ''}`);
	},

	setVisibility(values) {
		return APIInstance.post(`/api/data/setvisibility`, values);
	},

	setForceIncomeChange(values) {
		return APIInstance.post(`/api/forceincomechange`, values);
	},

	list(name) {
		return APIInstance.get(`/api/${name}`);
	},

	relations(relation_list, pid = null) {
		if(!Array.isArray(relation_list)) relation_list = [relation_list];

		if(!relation_list.length) {
			return Promise.resolve({});
		}

		const relationsQuery = new URLSearchParams({
			tables: relation_list.filter(el => (typeof el === 'object') ? (pid) ? true : false : true).map(el => (typeof el === 'object') ? el.name : el)
		});

		relation_list.filter(el => typeof el === 'object').forEach(el => {
			if(pid) {
				relationsQuery.append(`reg[${el.name}][table]`, el.table);
				relationsQuery.append(`reg[${el.name}][field]`, el.field);
				relationsQuery.append(`reg[${el.name}][profile]`, pid);
			}
		});

		return new Promise((resolve, reject) => {
			this.csrfCookie().then(() => {
				APIInstance.get(`/api/data?${relationsQuery.toString()}`).then(r => {
					resolve(r.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	dataset(tables, _limits = [], _orders = [], _filters = [], _additionalRecords = []) {
		if(!Array.isArray(tables)) tables = [{ name: tables }];

		if(!tables.length) {
			return Promise.resolve({});
		}

		const LIMIT = 100;

		for(let tbl of tables) {
			if(!_limits.find(table => table.name === tbl.name)) {
				_limits.push({
					name: tbl.name,
					limit: LIMIT
				});
			}
		}

		const limits = _limits?.length ? _limits.map(table => `${table.name}.[${table.limit}]`) : tables.map(table => `${table.name}.[${LIMIT}]`)

		const dataQuery = new URLSearchParams({
			selects: tables.map(table => `${table.name}.[${table?.fields ? table.fields.join(',') : '*'}]`).join(';'),
			limits: limits.join(';'),
			orders: _orders.map(table => `${table.name}.[${table.order}]`).join(';'),
			filters: _filters.map(table => `${table.name}.[${table.filter}]`).join(';'),
			records: _additionalRecords.map(table => `${table.name}.[${table?.fields ? table.fields.join(',') : '*'}].${table.id}`).join(';')
		});

		return new Promise((resolve, reject) => {
			this.csrfCookie().then(() => {
				APIInstance.get(`/api/dataset?${dataQuery.toString()}`).then(r => {
					resolve(r.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	datacustom(tables, additionalRecords = [], pid = null) {
		if(!Array.isArray(tables)) tables = [{ name: tables }];

		if(!tables.length) {
			return Promise.resolve({});
		}

		const EXCLUDE_TABLES = [];

		tables = tables.filter(table => !EXCLUDE_TABLES.includes(table.name));

		const LIMIT = 100;

		const dataQuery = new URLSearchParams({
			tables: tables.map(table => {
				const query = (typeof table.query !== 'undefined') ? `^[${table.query ?? ''}]` : '';
				const customQuery = table?.customQuery ? `^[${table.customQuery}]` : '';

				return `${table.name}^${table?.limit ?? LIMIT}${query}${customQuery}`
			}).join(';'),
			records: additionalRecords.map(table => `${table.name}^${table.id}`).join(';')
		});

		return new Promise((resolve, reject) => {
			this.csrfCookie().then(() => {
				APIInstance.get(`/api/datacustom?${dataQuery.toString()}`).then(r => {
					resolve(r.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	controller(name, id) {
		if(id) {
			return APIInstance.get(`/api/${name}/${id}`);
		} else {
			return Promise.reject({
				message: 'No ID provided'
			});
		}
	},
};

export { API };

export default Object.assign({}, API, ...Object.values(APIEndpoints));
