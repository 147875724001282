import React from 'react';

import Moment from 'react-moment';

import Record from '../../../components/Record/index';

import TabbedCard from '../../../components/TabbedCard';
import CardCollapse from '../../../components/CardCollapse';

import { Row, Col, Card, Button, Alert } from 'antd';

import { useAuth, PERMISSIONS as USER_PERMISSIONS } from '../../../contexts/User';

import moment from 'moment';

import '../../../assets/scss/dashboard.scss';

const Dashboard_Regulations_General = (props) => {
	const [currentActiveTab, setCurrentActiveTab] = React.useState('general');
	const [currentActiveTab_general, setCurrentActiveTab_general] = React.useState('general_rg11');
	const [currentActiveTab_pears, setCurrentActiveTab_pears] = React.useState('pears_rg06');
	const [currentActiveTab_trips, setCurrentActiveTab_trips] = React.useState('trips_rg07');
	const [currentActiveTab_tickets, setCurrentActiveTab_tickets] = React.useState('tickets_rg08');
	const [currentActiveTab_camps, setCurrentActiveTab_camps] = React.useState('camps_rg17_regular');
	const [currentActiveTab_multis, setCurrentActiveTab_multis] = React.useState('multis_rg10');
	const [currentActiveTab_loans, setCurrentActiveTab_loans] = React.useState('loans_rg29');
	const [currentActiveTab_infants, setCurrentActiveTab_infants] = React.useState('infants_rg01');
	const [currentActiveTab_tourists, setCurrentActiveTab_tourists] = React.useState('tourists_rg01');
	const [currentActiveTab_rehabs, setCurrentActiveTab_rehabs] = React.useState('rehabs_rg01');
	const [currentActiveTab_grants, setCurrentActiveTab_grants] = React.useState('grants_rg02');
	const [currentActiveTab_extras, setCurrentActiveTab_extras] = React.useState('extras_rg01');

	const [isCollapsed_general, setCollapsed_general] = React.useState(true);
	const [isCollapsed_pears, setCollapsed_pears] = React.useState(true);
	const [isCollapsed_trips, setCollapsed_trips] = React.useState(true);
	const [isCollapsed_tickets, setCollapsed_tickets] = React.useState(true);
	const [isCollapsed_camps, setCollapsed_camps] = React.useState(true);
	const [isCollapsed_multis, setCollapsed_multis] = React.useState(true);
	const [isCollapsed_loans, setCollapsed_loans] = React.useState(true);
	const [isCollapsed_infants, setCollapsed_infants] = React.useState(true);
	const [isCollapsed_tourists, setCollapsed_tourists] = React.useState(true);
	const [isCollapsed_rehabs, setCollapsed_rehabs] = React.useState(true);
	const [isCollapsed_grants, setCollapsed_grants] = React.useState(true);
	const [isCollapsed_extras, setCollapsed_extras] = React.useState(true);

	const auth = useAuth();

	return (
		<div className="content">
		{
		auth.can(USER_PERMISSIONS.ADMIN)
		?
		<Record
			controller="batchregall"
			cloneController="regulations"
			referrer="regulations"
			relations={['contractors', 'dctincometypes', 'dcteducations', 'dctdisabilities', 'dctpaymethods', 'dctmultitypes', 'dctmultioperators', 'cnfapps', 'dctloanpurposes', 'dctgranttypes', 'dctextratypes', 'dctikotypes', 'dctirotypes']}
			mode={props?.mode}
			defaultEditing={props?.editing}
			linkButtons={props?.linkButtons}
			disableEditPredicate={() => !auth.can(USER_PERMISSIONS.ADMIN)}
			filters={[
				{
					title: 'Kontrahent',
					name: 'contractor_id',
					type: 'enum',
					relation: 'contractors'
				},
				{
					title: 'Aktywny',
					name: 'is_active',
					type: 'preenum',
					enum: [
						{ label: 'Aktywny', value: 1 },
						{ label: 'Nieaktywny', value: 0 }
					]
				}
			]}
			technicalFields={[

			]}
			columns={[
				{
					name: 'id',
					title: '#',
					type: 'number'
				},
				{
					name: 'name',
					title: 'Nazwa',
					searchable: true
				},
				{
					name: 'contractor_name',
					title: 'Kontrahent',
					sortname: 'contractors.name'
				},
				{
					name: 'from',
					title: 'Obowiązuje od'
				},
				{
					name: 'is_active',
					title: 'Aktywny',
					datatype: 'bool'
				},
				{
					name: 'actions',
					data: null,
					sortable: false,
					title: '',
					render: function(data, type, row) {
						const editButton = auth.can(USER_PERMISSIONS.ADMIN) ? `<a class="btn btn-table" href="/regulations/${data.id}/edit">Edytuj</a><a class="btn btn-table" href="/regulations/${data.id}/clone">Kopiuj</a>` : '';
						return `<a class="btn btn-table" href="/regulations/${data.id}">Podgląd</a>${editButton}`;
					},
					className: 'text-right'
				}
			]}
			viewTitle="Podgląd regulaminu"
			listTitle="Lista regulaminów"
			editTitle="Edycja regulaminu"
			createTitle="Tworzenie regulaminu"
			createButtonText="Dodaj regulamin"
		>
			<Record.Consumer>
				{({ isLoading }) => (<>
					<TabbedCard key="primaryCard" loading={isLoading} tabContent={{
						general: [
							<Record.Field.Input key={['primaryCard', 'name']} title="Nazwa" name="name" rules={[{ required: true, message: 'Proszę uzupełnić to pole' }]} randomUnknownProp />,
							<Record.Field.Relation key={['primaryCard', 'contractor_id']} title="Kontrahent" name="contractor_id" relation="contractors" rules={[{ required: true, message: 'Proszę uzupełnić to pole' }]} />,
							<Record.Field.Date key={['primaryCard', 'from']} title="Obowiązuje od" name="from" rules={[{ required: true, message: 'Proszę uzupełnić to pole' }]} />,
							<Record.Field.Switch key={['primaryCard', 'is_active']} title="Aktywny" name="is_active" />,
							<Record.Field.Textarea key={['primaryCard', 'description']} title="Informacje dodatkowe" name="description" span={{ span: 24 }} randomUnknownProp />,
						],
					}} tabList={[
						{ key: 'general', tab: 'Dane ogólne' },
					]} activeTabKey={currentActiveTab} onTabChange={(key) => { setCurrentActiveTab(key) }} forceRender={true} />

					<CardCollapse title="Reguły ogólne" key="generalCard" loading={isLoading} collapsed={isCollapsed_general} onCollapse={(collapsed) => { setCollapsed_general(collapsed); }} tabContent={{
						general_rg11: [
							<Record.Field.Record
								key={['recordField', 'general_rg11']}
								title="Dostępne świadczenia"
								showTitle={false}
								name="regaccessbenefits"
								cardKey="generalCard"
								tab="general_rg11"
								fields={[
									<Record.Field.Select title="Rodzaj klienta" name={'client_type'} span={{ span: 24 }} options={[
										{ value: 'P', label: 'Pracownik' },
										{ value: 'E', label: 'Emeryt' },
									]} />,
									<Record.Field.Switch title="Wypoczynek dzieci" name={'is_camp'} />,
									<Record.Field.Switch title="Świadczenia dodatkowe" name={'is_extra'} />,
									<Record.Field.Switch title="Zapomogi" name={'is_grant'} />,
									<Record.Field.Switch title="Przedszkola i żłobki" name={'is_infant'} />,
									<Record.Field.Switch title="Pożyczki" name={'is_loan'} />,
									<Record.Field.Switch title="Karty Sportowe" name={'is_multi'} />,
									<Record.Field.Switch title="Wczasy pod gruszą" name={'is_pear'} />,
									<Record.Field.Switch title="Refundacja wypoczynku dzieci" name={'is_refcamp'} />,
									<Record.Field.Switch title="Refundacja świadczeń tur-rek" name={'is_reftourist'} />,
									<Record.Field.Switch title="Refundacja wyjazdów" name={'is_reftrip'} />,
									<Record.Field.Switch title="Zajęcia rekreacyjne" name={'is_rehab'} />,
									<Record.Field.Switch title="Bilety i karnety" name={'is_ticket'} />,
									<Record.Field.Switch title="Świadczenia tur-rek" name={'is_tourist'} />,
									<Record.Field.Switch title="Wyjazdy" name={'is_trip'} />,
								]}
							/>
						],
						general_rg04_employee: [
							<Record.Field.Record
								key={['recordField', 'general_rg04_employee']}
								title="Kwotowe limity świadczeń"
								showTitle={false}
								name="reglimitemployeequotes"
								cardKey="generalCard"
								tab="general_rg04_employee"
								fields={[
									<Record.Field.Number title="Średnie dochody od" name="income_from" rules={[{ required: true, message: 'Proszę uzupełnić to pole' }]} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Średnie dochody do" name="income_to" rules={[{ required: true, message: 'Proszę uzupełnić to pole' }]} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Limit sumy świadczeń" name={'limit_quote'} rules={[{required: true, message: 'Proszę uzupełnić to pole'}]} precision={2} randomUnknownProp />,
									<Record.Field.Switch title="Wypoczynek dzieci" name={'is_camp'} />,
									<Record.Field.Switch title="Świadczenia dodatkowe" name={'is_extra'} />,
									<Record.Field.Switch title="Zapomogi" name={'is_grant'} />,
									<Record.Field.Switch title="Przedszkola i żłobki" name={'is_infant'} />,
									<Record.Field.Switch title="Pożyczki" name={'is_loan'} />,
									<Record.Field.Switch title="Karty Sportowe" name={'is_multi'} />,
									<Record.Field.Switch title="Wczasy pod gruszą" name={'is_pear'} />,
									<Record.Field.Switch title="Refundacja wypoczynku dzieci" name={'is_refcamp'} />,
									<Record.Field.Switch title="Refundacja świadczeń tur-rek" name={'is_reftourist'} />,
									<Record.Field.Switch title="Refundacja wyjazdów" name={'is_reftrip'} />,
									<Record.Field.Switch title="Zajęcia rekreacyjne" name={'is_rehab'} />,
									<Record.Field.Switch title="Bilety i karnety" name={'is_ticket'} />,
									<Record.Field.Switch title="Świadczenia tur-rek" name={'is_tourist'} />,
									<Record.Field.Switch title="Wyjazdy" name={'is_trip'} />,
								]}
							/>
						],
						general_rg04_pensioner: [
							<Record.Field.Record
								key={['recordField', 'general_rg04_pensioner']}
								title="Kwotowe limity świadczeń"
								showTitle={false}
								name="reglimitpensionerquotes"
								cardKey="generalCard"
								tab="general_rg04_pensioner"
								fields={[
									<Record.Field.Number title="Średnie dochody od" name="income_from" rules={[{ required: true, message: 'Proszę uzupełnić to pole' }]} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Średnie dochody do"Number name="income_to" rules={[{ required: true, message: 'Proszę uzupełnić to pole' }]} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Limit sumy świadczeń" name={'limit_quote'} rules={[{required: true, message: 'Proszę uzupełnić to pole'}]} precision={2} randomUnknownProp />,
									<Record.Field.Switch title="Wypoczynek dzieci" name={'is_camp'} />,
									<Record.Field.Switch title="Świadczenia dodatkowe" name={'is_extra'} />,
									<Record.Field.Switch title="Zapomogi" name={'is_grant'} />,
									<Record.Field.Switch title="Przedszkola i żłobki" name={'is_infant'} />,
									<Record.Field.Switch title="Pożyczki" name={'is_loan'} />,
									<Record.Field.Switch title="Karty Sportowe" name={'is_multi'} />,
									<Record.Field.Switch title="Wczasy pod gruszą" name={'is_pear'} />,
									<Record.Field.Switch title="Refundacja wypoczynku dzieci" name={'is_refcamp'} />,
									<Record.Field.Switch title="Refundacja świadczeń tur-rek" name={'is_reftourist'} />,
									<Record.Field.Switch title="Refundacja wyjazdów" name={'is_reftrip'} />,
									<Record.Field.Switch title="Zajęcia rekreacyjne" name={'is_rehab'} />,
									<Record.Field.Switch title="Bilety i karnety" name={'is_ticket'} />,
									<Record.Field.Switch title="Świadczenia tur-rek" name={'is_tourist'} />,
									<Record.Field.Switch title="Wyjazdy" name={'is_trip'} />,
								]}
							/>
						],
						general_rg05_employee: [
							<Record.Field.Record
								key={['recordField', 'general_rg05_employee']}
								title="Ilościowe limity świadczeń"
								showTitle={false}
								name="reglimitemployeecounts"
								cardKey="generalCard"
								tab="general_rg05_employee"
								fields={[
									<Record.Field.Number title="Średnie dochody od" name={'income_from'} rules={[{required: true, message: 'Proszę uzupełnić to pole'}]} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Średnie dochody do" name={'income_to'} rules={[{required: true, message: 'Proszę uzupełnić to pole'}]} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Limit sumy świadczeń" name={'limit_count'} rules={[{required: true, message: 'Proszę uzupełnić to pole'}]} precision={2} randomUnknownProp />,
									<Record.Field.Switch title="Wypoczynek dzieci" name={'is_camp'} />,
									<Record.Field.Switch title="Świadczenia dodatkowe" name={'is_extra'} />,
									<Record.Field.Switch title="Zapomogi" name={'is_grant'} />,
									<Record.Field.Switch title="Przedszkola i żłobki" name={'is_infant'} />,
									<Record.Field.Switch title="Pożyczki" name={'is_loan'} />,
									<Record.Field.Switch title="Karty Sportowe" name={'is_multi'} />,
									<Record.Field.Switch title="Wczasy pod gruszą" name={'is_pear'} />,
									<Record.Field.Switch title="Refundacja wypoczynku dzieci" name={'is_refcamp'} />,
									<Record.Field.Switch title="Refundacja świadczeń tur-rek" name={'is_reftourist'} />,
									<Record.Field.Switch title="Refundacja wyjazdów" name={'is_reftrip'} />,
									<Record.Field.Switch title="Zajęcia rekreacyjne" name={'is_rehab'} />,
									<Record.Field.Switch title="Bilety i karnety" name={'is_ticket'} />,
									<Record.Field.Switch title="Świadczenia tur-rek" name={'is_tourist'} />,
									<Record.Field.Switch title="Wyjazdy" name={'is_trip'} />,
								]}
							/>
						],
						general_rg05_pensioner: [
							<Record.Field.Record
								key={['recordField', 'general_rg05_pensioner']}
								title="Ilościowe limity świadczeń"
								showTitle={false}
								name="reglimitpensionercounts"
								cardKey="generalCard"
								tab="general_rg05_pensioner"
								fields={[
									<Record.Field.Number title="Średnie dochody od" name={'income_from'} rules={[{required: true, message: 'Proszę uzupełnić to pole'}]} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Średnie dochody do" name={'income_to'} rules={[{required: true, message: 'Proszę uzupełnić to pole'}]} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Limit sumy świadczeń" name={'limit_count'} rules={[{required: true, message: 'Proszę uzupełnić to pole'}]} precision={2} randomUnknownProp />,
									<Record.Field.Switch title="Wypoczynek dzieci" name={'is_camp'} />,
									<Record.Field.Switch title="Świadczenia dodatkowe" name={'is_extra'} />,
									<Record.Field.Switch title="Zapomogi" name={'is_grant'} />,
									<Record.Field.Switch title="Przedszkola i żłobki" name={'is_infant'} />,
									<Record.Field.Switch title="Pożyczki" name={'is_loan'} />,
									<Record.Field.Switch title="Karty Sportowe" name={'is_multi'} />,
									<Record.Field.Switch title="Wczasy pod gruszą" name={'is_pear'} />,
									<Record.Field.Switch title="Refundacja wypoczynku dzieci" name={'is_refcamp'} />,
									<Record.Field.Switch title="Refundacja świadczeń tur-rek" name={'is_reftourist'} />,
									<Record.Field.Switch title="Refundacja wyjazdów" name={'is_reftrip'} />,
									<Record.Field.Switch title="Zajęcia rekreacyjne" name={'is_rehab'} />,
									<Record.Field.Switch title="Bilety i karnety" name={'is_ticket'} />,
									<Record.Field.Switch title="Świadczenia tur-rek" name={'is_tourist'} />,
									<Record.Field.Switch title="Wyjazdy" name={'is_trip'} />,
								]}
							/>
						],
						general_rg12: [
							<Record.Field.Record
								key={['recordField', 'general_rg12']}
								title="Dodatkowe dochody"
								showTitle={false}
								name="regincludeincomes"
								cardKey="generalCard"
								tab="general_rg12"
								fields={[
									<Record.Field.Relation title="Rodzaj dochodu" name={'dctincometype_id'} relation="dctincometypes" />,
									<Record.Field.Switch title="Wliczane do średniej" name={'is_taken'} />,
								]}
							/>
						],
						general_rg30: [
							<Record.Field.Record
								key={['recordField', 'general_rg30']}
								title="Dostępne świadczenia"
								showTitle={false}
								name="regtermincomes"
								tab="general_rg30"
								fields={[
									<Record.Field.Switch title="Ważne do końca roku" name={'is_end_year'} />,
									<Record.Field.Number title="Ważność dochodów (w miesiącach)" precision={0} name={'months'} randomUnknownProp />,
								]}
								rowLimit={1}
							/>
						],
						general_rg00: [
							<Record.Field.Record
								key={['recordField', 'general_rg00']}
								title="Okresy rozliczeniowe"
								showTitle={false}
								name="regperiodbenefits"
								cardKey="generalCard"
								tab="general_rg00"
								fields={[
									<Record.Field.Relation title="Rodzaj wniosku" name="cnfapp_id" relation="cnfapps" />,
									<Record.Field.Select title="Okres rozliczeniowy" name="period" options={[
										{ label: 'kwartał', value: 'K' },
										{ label: 'miesiąc', value: 'M' },
										{ label: 'półrocze', value: 'P' },
										{ label: 'rok', value: 'R' },
									]} />,
								]}
							/>
						],
					}} tabList={[
						{ key: 'general_rg11', tab: 'Dostępne świadczenia' },
						{ key: 'general_rg04_employee', tab: 'Kwotowe limity świadczeń (pracownik)' },
						{ key: 'general_rg04_pensioner', tab: 'Kwotowe limity świadczeń (emeryt)' },
						{ key: 'general_rg05_employee', tab: 'Ilościowe limity świadczeń (pracownik)' },
						{ key: 'general_rg05_pensioner', tab: 'Ilościowe limity świadczeń (emeryt)' },
						{ key: 'general_rg12', tab: 'Dodatkowe dochody' },
						{ key: 'general_rg30', tab: 'Terminy ważności dochodów' },
						{ key: 'general_rg00', tab: 'Okresy rozliczeniowe' },
					]} activeTabKey={currentActiveTab_general} onTabChange={(key) => { setCurrentActiveTab_general(key) }} forceRender={true} className="mt-1" />

					<CardCollapse title="Wczasy pod gruszą" key="pearsCard" loading={isLoading} collapsed={isCollapsed_pears} onCollapse={(collapsed) => { setCollapsed_pears(collapsed); }} tabContent={{
						pears_rg06: [
							<Record.Field.Record
								key={['recordField', 'pears_rg06']}
								title="Progi dochodowe"
								showTitle={false}
								name="reglimitpears"
								cardKey="pearsCard"
								tab="pears_rg06"
								fields={[
									<Record.Field.Number title="Średnie dochody od" name={'income_from'} rules={[{required: true, message: 'Proszę uzupełnić to pole'}]} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Średnie dochody do" name={'income_to'} rules={[{required: true, message: 'Proszę uzupełnić to pole'}]} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Maksymalne dofinansowanie" name={'max_subsidy'} precision={2} randomUnknownProp />,
								]}
							/>
						],
					}} tabList={[
						{ key: 'pears_rg06', tab: 'Progi dochodowe' },
					]} activeTabKey={currentActiveTab_pears} onTabChange={(key) => { setCurrentActiveTab_pears(key) }} forceRender={true} className="mt-1" />

					<CardCollapse title="Wycieczki i wczasy z dojazdem własnym" key="tripsCard" loading={isLoading} collapsed={isCollapsed_trips} onCollapse={(collapsed) => { setCollapsed_trips(collapsed); }} tabContent={{
						trips_rg07: [
							<Record.Field.Record
								key={['recordField', 'trips_rg07']}
								title="Progi dochodowe"
								showTitle={false}
								name="reglimittrips"
								cardKey="pearsCard"
								tab="trips_rg07"
								fields={[
									<Record.Field.Number title="Średnie dochody od" name={'income_from'} rules={[{required: true, message: 'Proszę uzupełnić to pole'}]} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Średnie dochody do" name={'income_to'} rules={[{required: true, message: 'Proszę uzupełnić to pole'}]} precision={2} randomUnknownProp />,
									<Record.Field.Switch title="Jednodniowa" name={'is_one_day'} />,
									<Record.Field.Switch title="Więcej, niż jeden dzień" name={'is_more_days'} />,
									<Record.Field.Number title="Ilość dni od" name="many_days_from" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ilość dni do" name="many_days_to" precision={0} randomUnknownProp />,
									<Record.Field.Switch title="Krajowa" name={'is_country'} />,
									<Record.Field.Switch title="Za granicą" name={'is_abroad'} />,
									<Record.Field.Switch title="Rajd" name={'is_ride'} />,
									<Record.Field.Number title="% dofinansowania" name={'subsidy_percent'} precision={2} randomUnknownProp />,
									<Record.Field.Switch title="Dojazd własny" name={'is_own_transport'} />,
									<Record.Field.Switch title="Pobyt" name={'is_stay'} />,
									<Record.Field.Accordion title="Pracownik/emeryt" accords={[
										{ title: 'Pracownik', name: 'trips_rg07_employee', fields: [
											<Record.Field.Number title="Za dzień" name={'employee_person_day'} precision={2} randomUnknownProp />,
											<Record.Field.Number title="Ilość dni dofinansowania" name={'employee_days'} precision={0} randomUnknownProp />,
											<Record.Field.Number title="Za nocleg" name={'employee_accomodation'} precision={2} randomUnknownProp />,
											<Record.Field.Number title="Maksymalne dofinansowanie (pracownik)" name={'employee_max_subsidy_adult'} precision={2} randomUnknownProp />,
											<Record.Field.Number title="Maksymalne dofinansowanie (dziecko)" name={'employee_max_subsidy_child'} precision={2} randomUnknownProp />,
											<Record.Field.Number title="Maksymalna liczba rajdów" name={'employee_max_rides'} precision={0} randomUnknownProp />,
										]},
										{ title: 'Emeryt', name: 'trips_rg07_pensioner', fields: [
											<Record.Field.Number title="Za dzień" name={'pensioner_person_day'} precision={2} randomUnknownProp />,
											<Record.Field.Number title="Ilość dni dofinansowania" name={'pensioner_days'} precision={0} randomUnknownProp />,
											<Record.Field.Number title="Za nocleg" name={'pensioner_accomodation'} precision={2} randomUnknownProp />,
											<Record.Field.Number title="Maksymalne dofinansowanie (emeryt)" name={'pensioner_max_subsidy_adult'} precision={2} randomUnknownProp />,
											<Record.Field.Number title="Maksymalne dofinansowanie (dziecko)" name={'pensioner_max_subsidy_child'} precision={2} randomUnknownProp />,
											<Record.Field.Number title="Maksymalna liczba rajdów" name={'pensioner_max_rides'} precision={0} randomUnknownProp />,
										]}
									]} span={{ span: 24 }} />
								]}
							/>
						],
						trips_rg16: [
							<Record.Field.Record
								key={['recordField', 'trips_rg16']}
								title="Ograniczenia"
								showTitle={false}
								name="regparamtrips"
								cardKey="pearsCard"
								tab="trips_rg16"
								fields={[
									<Record.Field.Switch title="Możliwy kolejny turnus tego samego dnia" name={'is_same_day'} />,
									<Record.Field.Switch title="Pracownik musi być uczestnikiem" name={'is_employee_member'} />,
									<Record.Field.Number title="Maksymalna liczba wycieczek zagranicznych" name={'max_abroad_trips'} precision={0} randomUnknownProp />,
									<Record.Field.Number title="Maksymalna liczba wycieczek krajowych" name={'max_country_trips'} precision={0} randomUnknownProp />,
								]}
							/>
						],
					}} tabList={[
						{ key: 'trips_rg07', tab: 'Progi dochodowe' },
						{ key: 'trips_rg16', tab: 'Ograniczenia' },
					]} activeTabKey={currentActiveTab_trips} onTabChange={(key) => { setCurrentActiveTab_trips(key) }} forceRender={true} className="mt-1" />

					<CardCollapse title="Bilety i karnety" key="ticketsCard" loading={isLoading} collapsed={isCollapsed_tickets} onCollapse={(collapsed) => { setCollapsed_tickets(collapsed); }} tabContent={{
						tickets_rg08: [
							<Record.Field.Record
								key={['recordField', 'tickets_rg08']}
								title="Progi dochodowe IKO"
								showTitle={false}
								name="reglimitikotickets"
								cardKey="pearsCard"
								tab="tickets_rg08"
								fields={[
									<Record.Field.Relation title="Rodzaj imprezy" name="dctikotype_id" relation="dctikotypes" span={{ span: 24 }} />,
									<Record.Field.Number title="Średnie dochody od" name={'income_from'} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Średnie dochody do" name={'income_to'} precision={2} randomUnknownProp />,
									<Record.Field.Accordion title="Pracownik/emeryt" accords={[
										{ title: 'Pracownik', name: 'tickets_rg08_employee', fields: [
											<Record.Field.Number title="% dofinansowania dla pracownika" name={'employee_percent'} precision={2} randomUnknownProp />,
											<Record.Field.Number title="Kwota dofinansowania dla pracownika" name={'employee_quote'} precision={2} randomUnknownProp />,
										]},
										{ title: 'Emeryt', name: 'tickets_rg08_pensioner', fields: [
											<Record.Field.Number title="% dofinansowania dla emeryta" name={'pensioner_percent'} precision={2} randomUnknownProp />,
											<Record.Field.Number title="Kwota dofinansowania dla emeryta" name={'pensioner_quote'} precision={2} randomUnknownProp />,
										]}
									]} span={{ span: 24 }} />
								]}
							/>
						],
						tickets_rg09: [
							<Record.Field.Record
								key={['recordField', 'tickets_rg09']}
								title="Progi dochodowe IRO"
								showTitle={false}
								name="reglimitirotickets"
								cardKey="pearsCard"
								tab="tickets_rg09"
								fields={[
									<Record.Field.Relation title="Rodzaj imprezy" name="dctirotype_id" relation="dctirotypes" span={{ span: 24 }} />,
									<Record.Field.Number title="Średnie dochody od" name={'income_from'} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Średnie dochody do" name={'income_to'} precision={2} randomUnknownProp />,
									<Record.Field.Accordion title="Pracownik/emeryt" accords={[
										{ title: 'Pracownik', name: 'tickets_rg09_employee', fields: [
											<Record.Field.Number title="% dofinansowania dla pracownika" name={'employee_percent'} precision={2} randomUnknownProp />,
											<Record.Field.Number title="Kwota dofinansowania dla pracownika" name={'employee_quote'} precision={2} randomUnknownProp />,
										]},
										{ title: 'Emeryt', name: 'tickets_rg09_pensioner', fields: [
											<Record.Field.Number title="% dofinansowania dla emeryta" name={'pensioner_percent'} precision={2} randomUnknownProp />,
											<Record.Field.Number title="Kwota dofinansowania dla emeryta" name={'pensioner_quote'} precision={2} randomUnknownProp />,
										]}
									]} span={{ span: 24 }} />
								]}
							/>
						],
						tickets_rg18: [
							<Record.Field.Record
								key={['recordField', 'tickets_rg18']}
								title="Ograniczenia"
								showTitle={false}
								name="regticketdays"
								cardKey="pearsCard"
								tab="tickets_rg18"
								fields={[
									<Record.Field.Select title="Typ wniosku" name={'type'} options={[
										{ label: 'IKO', value: 'K' },
										{ label: 'IRO', value: 'R' }
									]} rules={[{required: true, message: 'Proszę uzupełnić to pole'}]} />,
									<Record.Field.Number title="Ważność wniosku w dniach" name={'days'} precision={0} randomUnknownProp />,
								]}
								rowLimit={2}
							/>
						],
					}} tabList={[
						{ key: 'tickets_rg08', tab: 'Progi dochodowe IKO' },
						{ key: 'tickets_rg09', tab: 'Progi dochodowe IRO' },
						{ key: 'tickets_rg18', tab: 'Ograniczenia' },
					]} activeTabKey={currentActiveTab_tickets} onTabChange={(key) => { setCurrentActiveTab_tickets(key) }} forceRender={true} className="mt-1" />

					<CardCollapse title="Wypoczynek dzieci i młodzieży" key="campsCard" loading={isLoading} collapsed={isCollapsed_camps} onCollapse={(collapsed) => { setCollapsed_camps(collapsed); }} tabContent={{
						camps_rg17_regular: [
							<Record.Field.Record
								key={['recordField', 'camps_rg17_regular']}
								title="Ograniczenia"
								showTitle={false}
								name="reglimitregularcamps"
								cardKey="campsCard"
								tab="camps_rg17_regular"
								fields={[
									<Record.Field.Relation title="Stopnie edukacji" name="dcteducation_ids" relation="dcteducations" mode="multiple" relatedIdColumn="id" castToString />,
									<Record.Field.Relation title="Stopnie niepełnosprawności" name="dctdisability_ids" relation="dctdisabilities" mode="multiple" relatedIdColumn="id" castToString />,
									<Record.Field.Number title="Średnie dochody od" name={'income_from'} rules={[{required: true, message: 'Proszę uzupełnić to pole'}]} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Średnie dochody do" name={'income_to'} rules={[{required: true, message: 'Proszę uzupełnić to pole'}]} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Kwota dopłaty do dnia/noclegu" name={'subsidy_quote_per_day'} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Maksymalna ilośc obozów zagranicznych" name={'max_camps_abroad'} precision={0} randomUnknownProp />,
									<Record.Field.Number title="Maks. ilość dni dof. dla dzieci pełnosprawnych" name={'max_days_ability'} precision={0} randomUnknownProp />,
									<Record.Field.Number title="Maks. ilość dni dof. dla dzieci niepełnosprawnych" name={'max_days_disability'} precision={0} randomUnknownProp />,
									<Record.Field.Number title="Maks. ilość dni dof. dla dziecka niepełnosprawnego stopień znaczny" name={'max_days_disability_high'} precision={0} randomUnknownProp />,
									<Record.Field.Number title="Minimalny % odpłatności dla dzieci pełnosprawnych" name={'payment_percent_ability'} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Minimalny % odpłatności dla dzieci niepełnosprawnych" name={'payment_percent_disability'} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Minimalny % odpłatności dla dziecka niepełnosprawnego stopień znaczny" name={'payment_percent_disability_high'} precision={2} randomUnknownProp />,
									<Record.Field.Number title="% odpłatności w przypadku śmierci rodzica" name={'payment_percent_died'} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Maksymalny wiek dziecka pełnosprawnego" name={'max_age_ability'} precision={0} randomUnknownProp />,
									<Record.Field.Number title="Maksymalny wiek dziecka niepełnosprawnego" name={'max_age_disability'} precision={0} randomUnknownProp />,
									<Record.Field.Number title="Maksymalny wiek dziecka niepełnosprawnego stopień znaczny" name={'max_age_disability_high'} precision={0} randomUnknownProp />,
									<Record.Field.Switch title="Czy dziecko się uczy (dot. dzieci pełnosprawnych)" name={'is_education_ability'} />,
									<Record.Field.Switch title="Czy dziecko się uczy (dot. dzieci niepełnosprawnych)" name={'is_education_disability'} />,
									<Record.Field.Switch title="Czy dziecko się uczy (dot. dziecka niepełnosprawnego stopień znaczny)" name={'is_education_disability_high'} />,
									<Record.Field.Switch title="Rozliczenie w" name="is_days_counter" checkedChildren="W dniach" unCheckedChildren="W noclegach" randomUnknownProp />,
								]}
							/>
						],
						camps_rg17_green: [
							<Record.Field.Record
								key={['recordField', 'camps_rg17_green']}
								title="Ograniczenia"
								showTitle={false}
								name="reglimitgreencamps"
								cardKey="campsCard"
								tab="camps_rg17_green"
								fields={[
									<Record.Field.Relation title="Stopnie edukacji" name="dcteducation_ids" relation="dcteducations" mode="multiple" relatedIdColumn="id" castToString />,
									<Record.Field.Relation title="Stopnie niepełnosprawności" name="dctdisability_ids" relation="dctdisabilities" mode="multiple" relatedIdColumn="id" castToString />,
									<Record.Field.Number title="Średnie dochody od" name={'income_from'} rules={[{required: true, message: 'Proszę uzupełnić to pole'}]} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Średnie dochody do" name={'income_to'} rules={[{required: true, message: 'Proszę uzupełnić to pole'}]} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Kwota dopłaty do dnia/noclegu" name={'subsidy_quote_per_day'} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Maksymalna ilośc obozów zagranicznych" name={'max_camps_abroad'} precision={0} randomUnknownProp />,
									<Record.Field.Number title="Maks. ilość dni dof. dla dzieci pełnosprawnych" name={'max_days_ability'} precision={0} randomUnknownProp />,
									<Record.Field.Number title="Maks. ilość dni dof. dla dzieci niepełnosprawnych" name={'max_days_disability'} precision={0} randomUnknownProp />,
									<Record.Field.Number title="Maks. ilość dni dof. dla dziecka niepełnosprawnego stopień znaczny" name={'max_days_disability_high'} precision={0} randomUnknownProp />,
									<Record.Field.Number title="Minimalny % odpłatności dla dzieci pełnosprawnych" name={'payment_percent_ability'} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Minimalny % odpłatności dla dzieci niepełnosprawnych" name={'payment_percent_disability'} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Minimalny % odpłatności dla dziecka niepełnosprawnego stopień znaczny" name={'payment_percent_disability_high'} precision={2} randomUnknownProp />,
									<Record.Field.Number title="% odpłatności w przypadku śmierci rodzica" name={'payment_percent_died'} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Maksymalny wiek dziecka pełnosprawnego" name={'max_age_ability'} precision={0} randomUnknownProp />,
									<Record.Field.Number title="Maksymalny wiek dziecka niepełnosprawnego" name={'max_age_disability'} precision={0} randomUnknownProp />,
									<Record.Field.Number title="Maksymalny wiek dziecka niepełnosprawnego stopień znaczny" name={'max_age_disability_high'} precision={0} randomUnknownProp />,
									<Record.Field.Switch title="Czy dziecko się uczy (dot. dzieci pełnosprawnych)" name={'is_education_ability'} />,
									<Record.Field.Switch title="Czy dziecko się uczy (dot. dzieci niepełnosprawnych)" name={'is_education_disability'} />,
									<Record.Field.Switch title="Czy dziecko się uczy (dot. dziecka niepełnosprawnego stopień znaczny)" name={'is_education_disability_high'} />,
									<Record.Field.Switch title="Rozliczenie w" name="is_days_counter" checkedChildren="W dniach" unCheckedChildren="W noclegach" randomUnknownProp />,
								]}
							/>
						],
						camps_rg17_special: [
							<Record.Field.Record
								key={['recordField', 'camps_rg17_special']}
								title="Ograniczenia"
								showTitle={false}
								name="reglimitspecialcamps"
								cardKey="campsCard"
								tab="camps_rg17_special"
								fields={[
									<Record.Field.Relation title="Stopnie edukacji" name="dcteducation_ids" relation="dcteducations" mode="multiple" relatedIdColumn="id" castToString />,
									<Record.Field.Relation title="Stopnie niepełnosprawności" name="dctdisability_ids" relation="dctdisabilities" mode="multiple" relatedIdColumn="id" castToString />,
									<Record.Field.Number title="Średnie dochody od" name={'income_from'} rules={[{required: true, message: 'Proszę uzupełnić to pole'}]} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Średnie dochody do" name={'income_to'} rules={[{required: true, message: 'Proszę uzupełnić to pole'}]} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Kwota dopłaty do dnia/noclegu" name={'subsidy_quote_per_day'} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Maksymalna ilośc obozów zagranicznych" name={'max_camps_abroad'} precision={0} randomUnknownProp />,
									<Record.Field.Number title="Maks. ilość dni dof. dla dzieci pełnosprawnych / opiekunów dzieci" name={'max_days_ability'} precision={0} randomUnknownProp />,
									<Record.Field.Number title="Maks. ilość dni dof. dla dzieci niepełnosprawnych" name={'max_days_disability'} precision={0} randomUnknownProp />,
									<Record.Field.Number title="Maks. ilość dni dof. dla dziecka niepełnosprawnego stopień znaczny" name={'max_days_disability_high'} precision={0} randomUnknownProp />,
									<Record.Field.Number title="Minimalny % odpłatności dla dzieci pełnosprawnych / opiekunów dzieci" name={'payment_percent_ability'} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Minimalny % odpłatności dla dzieci niepełnosprawnych" name={'payment_percent_disability'} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Minimalny % odpłatności dla dziecka niepełnosprawnego stopień znaczny" name={'payment_percent_disability_high'} precision={2} randomUnknownProp />,
									<Record.Field.Number title="% odpłatności w przypadku śmierci rodzica" name={'payment_percent_died'} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Maksymalny wiek dziecka pełnosprawnego" name={'max_age_ability'} precision={0} randomUnknownProp />,
									<Record.Field.Number title="Maksymalny wiek dziecka niepełnosprawnego" name={'max_age_disability'} precision={0} randomUnknownProp />,
									<Record.Field.Number title="Maksymalny wiek dziecka niepełnosprawnego stopień znaczny" name={'max_age_disability_high'} precision={0} randomUnknownProp />,
									<Record.Field.Switch title="Czy dziecko się uczy (dot. dzieci pełnosprawnych)" name={'is_education_ability'} />,
									<Record.Field.Switch title="Czy dziecko się uczy (dot. dzieci niepełnosprawnych)" name={'is_education_disability'} />,
									<Record.Field.Switch title="Czy dziecko się uczy (dot. dziecka niepełnosprawnego stopień znaczny)" name={'is_education_disability_high'} />,
									<Record.Field.Switch title="Rozliczenie w" name="is_days_counter" checkedChildren="W dniach" unCheckedChildren="W noclegach" randomUnknownProp />,
								]}
							/>
						],
					}} tabList={[
						{ key: 'camps_rg17_regular', tab: 'Obozy kolonie - Ograniczenia' },
						{ key: 'camps_rg17_green', tab: 'Zielona szkoła - Ograniczenia' },
						{ key: 'camps_rg17_special', tab: 'Wypoczynek specjalistyczny - Ograniczenia' },
					]} activeTabKey={currentActiveTab_camps} onTabChange={(key) => { setCurrentActiveTab_camps(key) }} forceRender={true} className="mt-1" />

					<CardCollapse title="Karty Sportowe" key="multisCard" loading={isLoading} collapsed={isCollapsed_multis} onCollapse={(collapsed) => { setCollapsed_multis(collapsed); }} tabContent={{
						multis_rg10: [
							<Record.Field.Record
								key={['recordField', 'multis_rg10']}
								title="Progi dochodowe"
								showTitle={false}
								name="reglimitmultis"
								cardKey="multisCard"
								tab="multis_rg10"
								fields={[
									<Record.Field.Number title="Średnie dochody od" name="income_from" precision={2} randomUnknownProp />,
									<Record.Field.Number title="Średnie dochody do" name="income_to" precision={2} randomUnknownProp />,
									<Record.Field.Accordion title="Pracownik/emeryt" accords={[
										{ title: 'Pracownik', name: 'multis_rg10_employee', fields: [
											<Record.Field.Number title="% dofinansowania dla pracownika" name="employee_percent" precision={2} randomUnknownProp />,
											<Record.Field.Number title="Kwota dofinansowania dla pracownika" name="employee_quote" precision={2} randomUnknownProp />,
										]},
										{ title: 'Emeryt', name: 'multis_rg10_pensioner', fields: [
											<Record.Field.Number title="% dofinansowania dla emeryta" name="pensioner_percent" precision={2} randomUnknownProp />,
											<Record.Field.Number title="Kwota dofinansowania dla emeryta" name="pensioner_quote" precision={2} randomUnknownProp />,
										]}
									]} span={{ span: 24 }} />
								]}
							/>
						],
						multis_rg14: [
							<Record.Field.Record
								key={['recordField', 'multis_rg14']}
								title="Definicje okresów"
								showTitle={false}
								name="regperiodmultis"
								cardKey="multisCard"
								tab="multis_rg14"
								fields={[
									<Record.Field.Relation title="Metoda płatności" name="dctpaymethod_id" relation="dctpaymethods" span={{ span: 24 }} />,
									<Record.Field.Number title="Ostatni dzień terminu (styczeń)" name="month_1" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (luty)" name="month_2" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (marzec)" name="month_3" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (kwiecień)" name="month_4" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (maj)" name="month_5" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (czerwiec)" name="month_6" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (lipiec)" name="month_7" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (sierpień)" name="month_8" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (wrzesień)" name="month_9" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (październik)" name="month_10" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (listopad)" name="month_11" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (grudzień)" name="month_12" precision={0} randomUnknownProp />,
								]}
							/>
						],
						multis_rg141: [
							<Record.Field.Record
								key={['recordField', 'multis_rg141']}
								title="Ograniczenia"
								showTitle={false}
								name="regparammultis"
								cardKey="multisCard"
								tab="multis_rg141"
								fields={[
									<Record.Field.Number title="Maksymalna ilość dorosłych na wniosek" name="max_adults" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Maksymalna ilość dzieci na wniosek" name="max_children" precision={0} randomUnknownProp />,
									<Record.Field.Relation title="Operator karty" name="dctmultioperator_id" relation="dctmultioperators" />,
									<Record.Field.Switch title="Pracownik jest uczestnikiem" name="is_employee_member" />,
									<Record.Field.Switch title="Jeden rodzaj kart w jednym okresie rozliczeniowym" name="is_same_cards" />,
								]}
							/>
						],
						multis_rg00: [
							<Record.Field.Record
								key={['recordField', 'multis_rg00']}
								title="Słownik"
								showTitle={false}
								name="regdctmultis"
								cardKey="multisCard"
								tab="multis_rg00"
								fields={[
									<Record.Field.Relation title="Rodzaj karty" name="dctmultitype_id" relation="dctmultitypes" />,
									<Record.Field.Relation title="Operator karty" name="dctmultioperator_id" relation="dctmultioperators" />,
									<Record.Field.Number title="Cena karty" name="card_price" precision={2} randomUnknownProp />
								]}
							/>
						],
					}} tabList={[
						{ key: 'multis_rg10', tab: 'Progi dochodowe' },
						{ key: 'multis_rg14', tab: 'Definicje okresów' },
						{ key: 'multis_rg141', tab: 'Ograniczenia' },
						{ key: 'multis_rg00', tab: 'Słownik' },
					]} activeTabKey={currentActiveTab_multis} onTabChange={(key) => { setCurrentActiveTab_multis(key) }} forceRender={true} className="mt-1" />

					<CardCollapse title="Pożyczki" key="loansCard" loading={isLoading} collapsed={isCollapsed_loans} onCollapse={(collapsed) => { setCollapsed_loans(collapsed); }} tabContent={{
						loans_rg29: [
							<Record.Field.Record
								key={['recordField', 'loans_rg29']}
								title="Definicje okresów"
								showTitle={false}
								name="regperiodloans"
								cardKey="loansCard"
								tab="loans_rg29"
								fields={[
									<Record.Field.Number title="Ostatni dzień terminu (styczeń)" name="month_1" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (luty)" name="month_2" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (marzec)" name="month_3" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (kwiecień)" name="month_4" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (maj)" name="month_5" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (czerwiec)" name="month_6" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (lipiec)" name="month_7" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (sierpień)" name="month_8" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (wrzesień)" name="month_9" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (październik)" name="month_10" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (listopad)" name="month_11" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (grudzień)" name="month_12" precision={0} randomUnknownProp />,
								]}
							/>
						],
						loans_rg00: [
							<Record.Field.Record
								key={['recordField', 'loans_rg00']}
								title="Progi dochodowe"
								showTitle={false}
								name="reglimitloans"
								cardKey="loansCard"
								tab="loans_rg00"
								fields={[
									<Record.Field.Number title="Średnie dochody od" name={'income_from'} rules={[{required: true, message: 'Proszę uzupełnić to pole'}]} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Średnie dochody do" name={'income_to'} rules={[{required: true, message: 'Proszę uzupełnić to pole'}]} precision={2} randomUnknownProp />,
									<Record.Field.Relation title="Cel pożyczki" name="dctloanpurpose_id" relation="dctloanpurposes" />,
									<Record.Field.Number title="Oprocentowanie" name="percentage" precision={2} randomUnknownProp />
								]}
							/>
						],
						loans_rg03: [
							<Record.Field.Record
								key={['recordField', 'loans_rg03']}
								title="Słownik"
								showTitle={false}
								name="regdctloans"
								cardKey="loansCard"
								tab="loans_rg03"
								fields={[
									<Record.Field.Relation title="Cel pożyczki" name="dctloanpurpose_id" relation="dctloanpurposes" span={{ span: 24 }} />
								]}
							/>
						],
					}} tabList={[
						{ key: 'loans_rg29', tab: 'Definicje okresów' },
						{ key: 'loans_rg00', tab: 'Progi dochodowe' },
						{ key: 'loans_rg03', tab: 'Słownik' },
					]} activeTabKey={currentActiveTab_loans} onTabChange={(key) => { setCurrentActiveTab_loans(key) }} forceRender={true} className="mt-1" />

					<CardCollapse title="Przedszkola i żłobki" key="infantsCard" loading={isLoading} collapsed={isCollapsed_infants} onCollapse={(collapsed) => { setCollapsed_infants(collapsed); }} tabContent={{
						infants_rg01: [
							<Record.Field.Record
								key={['recordField', 'infants_rg01']}
								title="Definicje okresów"
								showTitle={false}
								name="regperiodinfants"
								cardKey="infantsCard"
								tab="infants_rg01"
								fields={[
									<Record.Field.Number title="Ostatni dzień terminu (styczeń)" name="month_1" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (luty)" name="month_2" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (marzec)" name="month_3" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (kwiecień)" name="month_4" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (maj)" name="month_5" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (czerwiec)" name="month_6" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (lipiec)" name="month_7" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (sierpień)" name="month_8" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (wrzesień)" name="month_9" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (październik)" name="month_10" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (listopad)" name="month_11" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (grudzień)" name="month_12" precision={0} randomUnknownProp />,
								]}
								rowLimit={1}
							/>
						],
						infants_rg02: [
							<Record.Field.Record
								key={['recordField', 'infants_rg02']}
								title="Progi dochodowe"
								showTitle={false}
								name="reglimitinfants"
								cardKey="infantsCard"
								tab="infants_rg02"
								fields={[
									<Record.Field.Select title="Rodzaj obiektu" name={'infant_levels'} options={[
										{ value: 'P', label: 'Przedszkole' },
										{ value: 'Z', label: 'Żłobek' },
									]} span={{ span: 24 }} multiple dataType="string" />,
									<Record.Field.Number title="Średnie dochody od" name={'income_from'} rules={[{required: true, message: 'Proszę uzupełnić to pole'}]} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Średnie dochody do" name={'income_to'} rules={[{required: true, message: 'Proszę uzupełnić to pole'}]} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Kwota dofinansowania" name={'subsidy_quote'} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Maksymalne dofinansowanie procentowe" name={'threshold_percent'} precision={2} randomUnknownProp />,
								]}
							/>
						],
					}} tabList={[
						{ key: 'infants_rg01', tab: 'Definicje okresów' },
						{ key: 'infants_rg02', tab: 'Ograniczenia' },
					]} activeTabKey={currentActiveTab_infants} onTabChange={(key) => { setCurrentActiveTab_infants(key) }} forceRender={true} className="mt-1" />

					<CardCollapse title="Świadczenia turystyczno-rekreacyjne" key="touristsCard" loading={isLoading} collapsed={isCollapsed_tourists} onCollapse={(collapsed) => { setCollapsed_tourists(collapsed); }} tabContent={{
						tourists_rg01: [
							<Record.Field.Record
								key={['recordField', 'tourists_rg01']}
								title="Progi dochodowe"
								showTitle={false}
								name="reglimittourists"
								cardKey="touristsCard"
								tab="tourists_rg01"
								fields={[
									<Record.Field.Number title="Średnie dochody od" name={'income_from'} rules={[{required: true, message: 'Proszę uzupełnić to pole'}]} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Średnie dochody do" name={'income_to'} rules={[{required: true, message: 'Proszę uzupełnić to pole'}]} precision={2} randomUnknownProp />,

									<Record.Field.Number title="Kwota dofinansowania dla jednego dnia" name="tourist_day_subsidy" precision={2} randomUnknownProp />,
									<Record.Field.Number title="Ilość dni świadczenia turystycznego" name="tourist_days" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ilość świadczeń turystycznych" name="tourist_numbers" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Minimalny procent odpłatności" name="min_payment_percent" precision={2} randomUnknownProp />,
									<Record.Field.Switch title="Rozliczenie w" name="is_days_counter" checkedChildren="W dniach" unCheckedChildren="W noclegach" randomUnknownProp />,

									<Record.Field.Accordion title="Pracownik/emeryt" accords={[
										{ title: 'Pracownik', name: 'tourists_rg01_employee2', fields: [
											<Record.Field.Number title="Procent dofinansowania do warunków szczególnych (10 LAT/48 M-CY/ niepełn. st. umiarkowany)" name="special_percent_employee" precision={2} randomUnknownProp />,
											<Record.Field.Number title="Procent dofinansowania do niepełnosprawności (POWYŻEJ 50 LAT/ niepełn. st. umiarkowany)" name="disability_percent_employee" precision={2} randomUnknownProp />,
										]},
										{ title: 'Emeryt', name: 'tourists_rg01_pensioner2', fields: [
											<Record.Field.Number title="Procent dofinansowania do warunków szczególnych (10 LAT/48 M-CY/ niepełn. st. umiarkowany)" name="special_percent_pensioner" precision={2} randomUnknownProp />,
											<Record.Field.Number title="Procent dofinansowania do niepełnosprawności (POWYŻEJ 50 LAT/ niepełn. st. umiarkowany)" name="disability_percent_pensioner" precision={2} randomUnknownProp />,
										]}
									]} span={{ span: 24 }} />,
								]}
							/>
						],
					}} tabList={[
						{ key: 'tourists_rg01', tab: 'Progi dochodowe' },
					]} activeTabKey={currentActiveTab_tourists} onTabChange={(key) => { setCurrentActiveTab_tourists(key) }} forceRender={true} className="mt-1" />

					<CardCollapse title="Zajęcia rekreacyjne" key="rehabsCard" loading={isLoading} collapsed={isCollapsed_rehabs} onCollapse={(collapsed) => { setCollapsed_rehabs(collapsed); }} tabContent={{
						rehabs_rg01: [
							<Record.Field.Record
								key={['recordField', 'rehabs_rg01']}
								title="Progi dochodowe"
								showTitle={false}
								name="reglimitrehabs"
								cardKey="rehabsCard"
								tab="rehabs_rg01"
								fields={[
									<Record.Field.Number title="Średnie dochody od" name={'income_from'} rules={[{required: true, message: 'Proszę uzupełnić to pole'}]} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Średnie dochody do" name={'income_to'} rules={[{required: true, message: 'Proszę uzupełnić to pole'}]} precision={2} randomUnknownProp />,

									<Record.Field.Number title="Ilość zajęć rekreacyjne" name="rehab_numbers" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Kwota do dofinansowania" name="rehab_subsidy" precision={2} randomUnknownProp />,
									<Record.Field.Number title="Minimalny procent odpłatności" name="min_payment_percent" precision={2} randomUnknownProp />,

									<Record.Field.Accordion title="Pracownik/emeryt" accords={[
										{ title: 'Pracownik', name: 'tourists_rg01_employee2', fields: [
											<Record.Field.Number title="Procent dofinansowania do warunków szczególnych (10 LAT/48 M-CY/ niepełn. st. umiarkowany)" name="special_percent_employee" precision={2} randomUnknownProp />,
											<Record.Field.Number title="Procent dofinansowania do niepełnosprawności (POWYŻEJ 50 LAT/ niepełń. st. umiarkowany)" name="disability_percent_employee" precision={2} randomUnknownProp />,
										]},
										{ title: 'Emeryt', name: 'tourists_rg01_pensioner2', fields: [
											<Record.Field.Number title="Procent dofinansowania do warunków szczególnych (10 LAT/48 M-CY/ niepełn. st. umiarkowany)" name="special_percent_pensioner" precision={2} randomUnknownProp />,
											<Record.Field.Number title="Procent dofinansowania do niepełnosprawności (POWYŻEJ 50 LAT/ niepełń. st. umiarkowany)" name="disability_percent_pensioner" precision={2} randomUnknownProp />,
										]}
									]} span={{ span: 24 }} />,
								]}
							/>
						],
					}} tabList={[
						{ key: 'rehabs_rg01', tab: 'Progi dochodowe' },
					]} activeTabKey={currentActiveTab_rehabs} onTabChange={(key) => { setCurrentActiveTab_rehabs(key) }} forceRender={true} className="mt-1" />

					<CardCollapse title="Zapomogi" key="grantsCard" loading={isLoading} collapsed={isCollapsed_grants} onCollapse={(collapsed) => { setCollapsed_grants(collapsed); }} tabContent={{
						grants_rg02: [
							<Record.Field.Record
								key={['recordField', 'grants_rg02']}
								title="Definicje okresów"
								showTitle={false}
								name="regperiodgrants"
								cardKey="grantsCard"
								tab="grants_rg02"
								fields={[
									<Record.Field.Number title="Ostatni dzień terminu (styczeń)" name="month_1" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (luty)" name="month_2" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (marzec)" name="month_3" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (kwiecień)" name="month_4" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (maj)" name="month_5" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (czerwiec)" name="month_6" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (lipiec)" name="month_7" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (sierpień)" name="month_8" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (wrzesień)" name="month_9" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (październik)" name="month_10" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (listopad)" name="month_11" precision={0} randomUnknownProp />,
									<Record.Field.Number title="Ostatni dzień terminu (grudzień)" name="month_12" precision={0} randomUnknownProp />,
								]}
								rowLimit={1}
							/>
						],
						grants_rg03: [
							<Record.Field.Record
								key={['recordField', 'grants_rg03']}
								title="Słownik"
								showTitle={false}
								name="regdctgrants"
								cardKey="grantsCard"
								tab="grants_rg03"
								fields={[
									<Record.Field.Relation title="Rodzaj zapomogi" name="dctgranttype_id" relation="dctgranttypes" span={{ span: 24 }} />
								]}
							/>
						],
					}} tabList={[
						{ key: 'grants_rg02', tab: 'Definicje okresów' },
						{ key: 'grants_rg03', tab: 'Słownik' },
					]} activeTabKey={currentActiveTab_grants} onTabChange={(key) => { setCurrentActiveTab_grants(key) }} forceRender={true} className="mt-1" />

					<CardCollapse title="Świadczenia dodatkowe" key="extrasCard" loading={isLoading} collapsed={isCollapsed_extras} onCollapse={(collapsed) => { setCollapsed_extras(collapsed); }} tabContent={{
						extras_rg01: [
							<Record.Field.Record
								key={['recordField', 'extras_rg01']}
								title="Progi dochodowe"
								showTitle={false}
								name="reglimitextras"
								cardKey="extrasCard"
								tab="extras_rg01"
								fields={[
									<Record.Field.Relation title="Rodzaj świadczenia" name="dctextratype_id" relation="dctextratypes" span={{ span: 24 }} />,
									<Record.Field.Number title="Średnie dochody od" name={'income_from'} rules={[{required: true, message: 'Proszę uzupełnić to pole'}]} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Średnie dochody do" name={'income_to'} rules={[{required: true, message: 'Proszę uzupełnić to pole'}]} precision={2} randomUnknownProp />,
									<Record.Field.Number title="Procentowa wartość dofinansowania" name="subsidy_percent" precision={2} randomUnknownProp />,
									<Record.Field.Number title="Kwotowa wartość dofinansowania" name="subsidy_quote" precision={2} randomUnknownProp />,
								]}
							/>
						],
						extras_rg02: [
							<Record.Field.Record
								key={['recordField', 'extras_rg02']}
								title="Definicje okresów"
								showTitle={false}
								name="regparamextras"
								cardKey="extrasCard"
								tab="extras_rg02"
								fields={[
									<Record.Field.Relation title="Rodzaj świadczenia" name="dctextratype_id" relation="dctextratypes" span={{ span: 24 }} />,
									<Record.Field.Date title="Okres od" name="period_from" />,
									<Record.Field.Date title="Okres do" name="period_to" />,
								]}
							/>
						],
						extras_rg03: [
							<Record.Field.Record
								key={['recordField', 'extras_rg03']}
								title="Słownik"
								showTitle={false}
								name="regdctextras"
								cardKey="extrasCard"
								tab="extras_rg03"
								fields={[
									<Record.Field.Relation title="Rodzaj świadczenia" name="dctextratype_id" relation="dctextratypes" span={{ span: 24 }} />
								]}
							/>
						],
					}} tabList={[
						{ key: 'extras_rg01', tab: 'Progi dochodowe' },
						{ key: 'extras_rg02', tab: 'Definicje okresów' },
						{ key: 'extras_rg03', tab: 'Słownik' },
					]} activeTabKey={currentActiveTab_extras} onTabChange={(key) => { setCurrentActiveTab_extras(key) }} forceRender={true} className="mt-1" />
				</>)}
			</Record.Consumer>
		</Record>
		:
		<Row style={{ marginBottom: '15px' }}>
			<Col sm={24}>
				<Alert type="error" message="Brak uprawnień do zasobu" />
			</Col>
		</Row>
		}
	</div>);
};

export default Dashboard_Regulations_General;
