import { APIInstance, APIConfig } from '../../';
import { API } from '../../api';
import APIBackendConfig from '../config';

export default {
	login(login, password) {
		const credentials = {
			[APIConfig.credentialsLoginKey ?? 'username']: login,
			password: password
		};

		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.post(`/api/login`, credentials).then(r => {
					resolve(r.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	azureAuth(code) {
		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.post(`/api/auth/azure`, {
					code: code
				}).then(r => {
					resolve(r);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	logout() {
		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.get(`/api/logout`).then(r => {
					resolve();
				}).catch(err => {
					reject(err);
				}).finally(() => {
					localStorage.removeItem('token');
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	register(values) {
		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIBackendConfig.getConfig().then(cfg => {
					APIInstance.post(`/api/register`, values).then(r => {
						if(r?.data?.errcode) {
							switch(r.data.errcode) {
								case 'PESEL-NOT-MATCH':
									reject(`Nie odnaleziono Twoich danych w naszej bazie. Skontaktuj się z biurem obsługi pod nr ${cfg?.company_phone ?? '(nie ustawiono)'}`);
									break;
								case 'NAME-NOT-MATCH':
									resolve({
										data: r.data,
										msg: 'Podane imię lub nazwisko nie pasuje do odnalezionego numeru PESEL. Konto zostało założone i oczekuje na aktywację, prosimy o sprawdzanie poczty. Za chwile zostaniesz przeniesiony do strony logowania.'
									});
									break;
								case 'DATA-MATCHED':
									resolve({
										data: r.data,
										msg: 'Twoje konto zostało założone i aktywowane. Za chwile zostaniesz przeniesiony do strony logowania.'
									});
									break;
								default:
									reject('Wystąpił nieznany błąd podczas zakładania konta, spróbuj ponownie później lub skontaktuj się z administratorem (Kod błędu: ERR-1000)');
									break;
							}
						} else {
							resolve({
								data: r.data,
								msg: 'Twoje konto zostało założone. Za chwile zostaniesz przeniesiony do strony logowania.'
							});
						}
					}).catch(err => {
						if(err?.response?.data?.errcode) {
							switch(err.response.data.errcode) {
								case 'VALIDATE-ERRORS':
									reject('Podany adres e-mail lub numer PESEL jest już przypisany do istniejącego konta, podaj inny adres e-mail lub numer PESEL i spróbuj ponownie', err);
									break;
								case 'CONTRACTOR-NOT-MATCH':
									reject(`Odnaleziono Twoje dane w naszej bazie, lecz wystąpił błąd podczas ich przypisywania. Skontaktuj się z biurem obsługi pod nr ${cfg?.company_phone ?? '(nie ustawiono)'} lub spróbuj ponownie później`, err);
									break;
								default:
									reject('Wystąpił nieznany błąd podczas zakładania konta, spróbuj ponownie później lub skontaktuj się z administratorem', err);
									break;
							}
						} else {
							reject('Wystąpił nieznany błąd podczas zakładania konta, spróbuj ponownie później lub skontaktuj się z administratorem (Kod błędu: ERR-1001)', err);
						}
					});
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},
};
