export const relationQueries = {
    offtrips: (dcttriptype_id, withCustom = true) => ({
        name: 'offtrips',
        query: '',
        customQuery: withCustom ? `dcttriptype_id = ${dcttriptype_id ?? null}` : null
    }),
    families: (profile_id, withCustom = true) => ({
       name: 'families',
       limit: -1,
       query: '',
       customQuery: withCustom ? `profile_id = ${profile_id ?? null}` : null
    }),
    tripprices: (offtrip_id, withCustom = true) => ({
       name: 'tripprices',
       limit: -1,
       query: '',
       customQuery: withCustom ? `offtrip_id = ${offtrip_id ?? null}` : null,
    }),
    campprices: (offcamp_id, withCustom = true) => ({
       name: 'campprices',
       limit: -1,
       query: '',
       customQuery: withCustom ? `offcamp_id = ${offcamp_id ?? null}` : null,
    }),
    refcampprices: () => ({
       name: 'campprices',
       limit: -1,
    }),
    touristprices: (offtourist_id, withCustom = true) => ({
        name: 'touristprices',
        limit: -1,
        query: '',
        customQuery: withCustom ? `offtourist_id = ${offtourist_id ?? null}` : null,
    }),
    offtourists: () => ({
        name: 'offtourists',
        limit: -1,
    }),
    profiles: (_, onlyActive = true) => ({
        name: 'profiles',
        query: '',
        customQuery: onlyActive ? `staprofile_id = 2` : null
    }),
    DEFAULT: (relation) => ({
       name: relation,
    }),
};

export const relationParams = {
    offtrips: 'dcttriptype_id',
    families: 'profile_id',
    tripprices: 'offtrip_id',
    campprices: 'offcamp_id',
    touristprices: 'offtourist_id',
    refcampprices: null,
    DEFAULT: null,
};

export const relationAliases = {
  refcampprices: 'campprices'
};
