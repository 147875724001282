import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { RecordContext } from './';

import { APIBackend as API } from '../../api';

const RecordReducer = (state, action) => {
	switch(action.type) {
		case 'addField':
			if(!state.fields.find(el => el.props.name === action.payload.props.name)) {
				return {
					fields: [...state.fields, action.payload]
				};
			} else {
				return {
					fields: state.fields
				}
			}
			break;
		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
};

const RecordProvider = ({ children, controller: _controller, referrer: _referrer, relations: _relations, mode: _mode, ...props }) => {
	const { id } = useParams();
	const [_id, setId] = React.useState(null);
	const [controller, setController] = React.useState(_controller);
	const [referrer, setReferrer] = React.useState(_referrer);
	const [data, setData] = React.useState(null);
	const [changes, setChanges] = React.useState(null);
	const [mode, setMode] = React.useState(_mode ?? 'list');
	const [editing, setEditing] = React.useState(false);
	const [relationData, setRelationData] = React.useState(null);
	const [errorField, setErrorField] = React.useState(null);
	const form = React.useRef();
	const [relations, setRelations] = React.useState(_relations ?? []);

	const [error, setError] = React.useState(null);
	const [loading, setLoading] = React.useState(false);

	const [state, dispatch] = React.useReducer(RecordReducer, {
		fields: []
	});

	const history = useHistory();
	const location = useLocation();

	const reducer = { state, dispatch };

	const recordId = props.modal ? _id : (_id ?? id ?? null);

	const getLocationPath = () => {
		return `/${referrer ?? controller}`
	};

	const updateData = (showLoading = false) => {
		if(showLoading) {
			setLoading(true);
		}

		getRelationData().then(r => {
			setRelationData(r);

			if(id) {
				getRecordData().then(r => {
					setData(r.data);
				}).catch(err => {
					// console.log(err);
				}).finally(() => {
					setLoading(false);
				});
			} else {
				setLoading(false);
			}
		}).catch(err => {
			console.log(err);
			setLoading(false);
		});
	};

	const changeMode = (_mode, id = null) => {
		if(!props.modal) {
			switch(_mode) {
				case 'list':
					history.push(`${getLocationPath()}`);
					break;
				case 'view':
					history.push(`${getLocationPath()}/${id}`);
					break;
				case 'edit':
					history.push(`${getLocationPath()}/${id}/edit`);
					break;
				case 'create':
					history.push(`${getLocationPath()}/create`);
					break;
			}
		} else {
			setId(id);
		}

		if(_mode === 'create') {
			setData(null);
		}

		setMode(_mode);
	};

	React.useEffect(() => {
		if(error) setError(null);
	}, [location.pathname, mode]);

	React.useEffect(() => {
		if(['edit', 'create'].includes(mode)) {
			setEditing(true);
		} else {
			setEditing(false);
		}
	}, [mode, setMode]);

	React.useEffect(() => {
		if(controller) {
			setLoading(true);

			updateData();
		}
	}, [recordId, _id, id, controller]);

	React.useEffect(() => {

	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const getRecordData = () => {
		return API.controller(controller, recordId);
	};

	const getRelationData = () => {
		return API.relations(relations);
	};

	const memoedValue = React.useMemo(() => ({
		id: recordId,
		controller,
		loading,
		error,
		data,
		changes,
		editing,
		relationData,
		mode,
		errorField,
		form,
		relations,
		reducer,

		setId,
		getRecordData,
		getRelationData,
		setController,
		setMode,
		setChanges,
		setData,
		setEditing,
		setErrorField,
		setRelations,
		changeMode,
		setError,
		updateData
	}), [loading, error, data, setData, changes, setChanges, relationData, setRelationData, id, recordId, controller, mode, editing, form, errorField, relations, changeMode, reducer, setId, setError]);

	return (
		<RecordContext.Provider value={memoedValue}>
			{children}
		</RecordContext.Provider>
	);
};

export default RecordProvider;

export function useRecord() {
	return React.useContext(RecordContext);
};
