import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Moment from 'react-moment';

import TabbedCard from '../../../../components/TabbedCard';
import CardCollapse from '../../../../components/CardCollapse';

import { RecordViewError, RecordSaveError } from '../../../../errors';

import { Row, Col, Card, Button, Alert, Affix, Form, Input, InputNumber, Switch, Select, DatePicker, Collapse, message } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

import moment from 'moment';
import axios from 'axios';

import { useAuth, PERMISSIONS as USER_PERMISSIONS } from '../../../../contexts/User';

import { backendRoot, backendApi, csrfCookie, apiData } from '../../../../config/paths';

import prepareErrorMessage from '../../../../libs/ErrorHelper.js';
import { getValueFromArray, assignDataToString } from '../../../../libs/Helpers.js';

import { captureException, captureMessage } from '@sentry/react';

import '../../../../assets/scss/dashboard.scss';

const Dashboard_Regulations_General = (props) => {
	const auth = useAuth();
	const controller = 'batchregall';
	const relations = ['contractors', 'dctincometypes', 'dcteducations', 'dctdisabilities', 'dctpaymethods', 'dctmultitypes', 'dctmultioperators', 'cnfapps', 'dctloanpurposes', 'dctgranttypes', 'dctextratypes', 'dctikotypes', 'dctirotypes'];

	const [currentActiveTab, setCurrentActiveTab] = React.useState('general');
	const [currentActiveTab_general, setCurrentActiveTab_general] = React.useState('general_rg11');
	const [currentActiveTab_pears, setCurrentActiveTab_pears] = React.useState('pears_rg06');
	const [currentActiveTab_trips, setCurrentActiveTab_trips] = React.useState('trips_rg07');
	const [currentActiveTab_tickets, setCurrentActiveTab_tickets] = React.useState('tickets_rg08');
	const [currentActiveTab_camps, setCurrentActiveTab_camps] = React.useState('camps_rg17_regular');
	const [currentActiveTab_multis, setCurrentActiveTab_multis] = React.useState('multis_rg10');
	const [currentActiveTab_loans, setCurrentActiveTab_loans] = React.useState('loans_rg29');
	const [currentActiveTab_infants, setCurrentActiveTab_infants] = React.useState('infants_rg01');
	const [currentActiveTab_tourists, setCurrentActiveTab_tourists] = React.useState('tourists_rg01');
	const [currentActiveTab_rehabs, setCurrentActiveTab_rehabs] = React.useState('rehabs_rg01');
	const [currentActiveTab_grants, setCurrentActiveTab_grants] = React.useState('grants_rg02');
	const [currentActiveTab_extras, setCurrentActiveTab_extras] = React.useState('extras_rg01');

	const [isCollapsed_general, setCollapsed_general] = React.useState(true);
	const [isCollapsed_pears, setCollapsed_pears] = React.useState(true);
	const [isCollapsed_trips, setCollapsed_trips] = React.useState(true);
	const [isCollapsed_tickets, setCollapsed_tickets] = React.useState(true);
	const [isCollapsed_camps, setCollapsed_camps] = React.useState(true);
	const [isCollapsed_multis, setCollapsed_multis] = React.useState(true);
	const [isCollapsed_loans, setCollapsed_loans] = React.useState(true);
	const [isCollapsed_infants, setCollapsed_infants] = React.useState(true);
	const [isCollapsed_tourists, setCollapsed_tourists] = React.useState(true);
	const [isCollapsed_rehabs, setCollapsed_rehabs] = React.useState(true);
	const [isCollapsed_grants, setCollapsed_grants] = React.useState(true);
	const [isCollapsed_extras, setCollapsed_extras] = React.useState(true);

	const history = useHistory();
	const { id } = useParams();

	const [filtersVisibility, setFiltersVisibility] = React.useState(false);
	const [affixedActions, setAffixedActions] = React.useState(false);

	const [data, setData] = React.useState(null);
	const [relationData, setRelationData] = React.useState(null);

	const [error, setError] = React.useState(false);

	const loading = data === null || relationData === null;

	const getInitialValue = (name, dataType = 'raw') => {
		let _data = null;

		if(data) {
			if(Array.isArray(name)) {
				_data = getValueFromArray(data, name) ?? null;
			} else {
				_data = data.hasOwnProperty(name) ? data[name] : null;
			}
		}

		switch(dataType) {
			case 'int':
				_data = _data ? parseInt(_data) ?? null : null;
				break;
			case 'date':
				_data = _data ? moment(_data) : null;
				break;
			case 'bool':
				_data = _data ? true : false;
				break;
			case 'string':
				_data = _data ? `${_data}` : null;
				break;
			case 'idArray':
				if(_data && Array.isArray(_data)) {
					_data = _data.map(el => el.id);
				} else {
					_data = null;
				}
				break;
			case 'campsData':
				if(_data && Array.isArray(_data)) {
					_data = _data.map(el => {
						if(el.hasOwnProperty('dcteducation_ids')) {
							el['dcteducation_ids'] = (el?.dcteducation_ids ?? []).map(el => el.id ?? el);
						}
						if(el.hasOwnProperty('dctdisability_ids')) {
							el['dctdisability_ids'] = (el?.dctdisability_ids ?? []).map(el => el.id ?? el);
						}

						return el;
					});
				} else {
					_data = null;
				}
				break;
			case 'extrasData':
				if(_data && Array.isArray(_data)) {
					_data = _data.map(el => {
						if(el.hasOwnProperty('period_from')) {
							el['period_from'] = moment(el['period_from']);
						}
						if(el.hasOwnProperty('period_to')) {
							el['period_to'] = moment(el['period_to']);
						}

						return el;
					});
				} else {
					_data = null;
				}
				break;
			case 'infantsData':
				if(_data && Array.isArray(_data)) {
					_data = _data.map(el => {
						if(el.hasOwnProperty('infant_levels')) {
							el['infant_levels'] = Array.isArray(el['infant_levels'])
								? el['infant_levels']
								: el['infant_levels']?.split?.(',') ?? [];
							el['infant_levels'] = el['infant_levels'].filter(el => (el && Object.keys(el).length));
						}

						return el;
					});
				} else {
					_data = null;
				}
				break;
			case 'raw':
			default:
				break;
		}

		return _data;
	};

	const getViewValue = (name, dataType = 'raw', relation = null) => {
		let _data = null;

		if(data) {
			if(Array.isArray(name)) {
				_data = getValueFromArray(data, name) ?? null;
			} else {
				_data = data.hasOwnProperty(name) ? data[name] : null;
			}
		}

		switch(dataType) {
			case 'date':
				_data = _data ? moment(_data).format('YYYY-MM-DD') : 'Nigdy';
				break;
			case 'datetime':
				_data = _data ? moment(_data).format('YYYY-MM-DD HH:mm') : 'Nigdy';
				break;
			case 'bool':
				_data = _data ? 'Tak' : 'Nie';
				break;
			case 'relation':

				break;
			case 'raw':
			default:
				break;
		}

		return _data ?? '(brak)';
	};

	const prepareOptionsFromRelationData = (relation, idKey = 'id', nameKey = 'name') => {
		if(relationData && relationData.hasOwnProperty(relation)) {
			let rel = relationData[relation];

			return rel.map(el => (
				<Select.Option key={el[idKey] ?? null} value={el[idKey] ?? null}>
					{el[nameKey] ?? null}
				</Select.Option>
			));
		}

		return [];
	};

	React.useEffect(() => {
		axios.get(`${backendApi}/${controller}/${id}`).then(r => {
			setData(r.data);
			setError(false);
		}).catch(err => {
			setError({
				errcode: err?.response?.data?.errcode,
				message: err?.response?.data?.message
			});

			// captureException(new RecordViewError(`Error occured while getting record data`), {
			// 	contexts: {
			// 		record: {
			// 			controller: controller,
			// 			id: id,
			// 			mode: 'view',
			// 			dataType: 'recordData',
			// 			relations: relations.join(', ')
			// 		},
			// 		error: {
			// 			message: err?.response?.data?.message,
			// 			details: err?.response?.data?.details,
			// 			errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED',
			// 			file: err?.response?.data?.file,
			// 			line: err?.response?.data?.line,
			// 			trace: JSON.stringify(err?.response?.data?.trace?.slice?.(0, 3) ?? [])
			// 		}
			// 	}
			// });
		});

		// Get relation data
		axios.get(`${backendApi}${apiData}?tables=${relations.join(',')}`).then(r => {
			setRelationData(r.data);
			setError(false);
		}).catch(err => {
			setError({
				errcode: err?.response?.data?.errcode,
				message: err?.response?.data?.message
			});

			// captureException(new RecordViewError(`Error occured while getting record relation data`), {
			// 	contexts: {
			// 		record: {
			// 			controller: controller,
			// 			id: id,
			// 			mode: 'view',
			// 			dataType: 'recordData',
			// 			relations: relations.join(', ')
			// 		},
			// 		error: {
			// 			message: err?.response?.data?.message,
			// 			details: err?.response?.data?.details,
			// 			errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED',
			// 			file: err?.response?.data?.file,
			// 			line: err?.response?.data?.line,
			// 			trace: JSON.stringify(err?.response?.data?.trace?.slice?.(0, 3) ?? [])
			// 		}
			// 	}
			// });
		});
	}, []);

	const saveRecord = (values) => {
		const data = new FormData();
		const _debugData = {};

		Object.keys(values).forEach(key => {
			let value = values[key] ?? null;

			if(key.startsWith('reg')) {
				if(['reglimitregularcamps', 'reglimitgreencamps', 'reglimitspecialcamps'].includes(key) && value && Array.isArray(value)) {
					value = value.map(el => {
						if(el.hasOwnProperty('dcteducation_ids')) {
							el['dcteducation_ids'] = (el?.dcteducation_ids ?? []).join(',');
						}
						if(el.hasOwnProperty('dctdisability_ids')) {
							el['dctdisability_ids'] = (el?.dctdisability_ids ?? []).join(',');
						}

						return el;
					});
				}

				if(['regparamextras'].includes(key) && value && Array.isArray(value)) {
					value = value.map(el => {
						if(el.hasOwnProperty('period_from') && el['period_from'] instanceof moment) {
							el['period_from'] = el['period_from'].format('YYYY-MM-DD');
						}
						if(el.hasOwnProperty('period_to') && el['period_to'] instanceof moment) {
							el['period_to'] = el['period_to'].format('YYYY-MM-DD');
						}

						return el;
					});
				}

				if(['reglimitinfants'].includes(key) && value && Array.isArray(value)) {
					value = value.map(el => {
						if(el.hasOwnProperty('infant_levels') && Array.isArray(el['infant_levels'])) {
							el['infant_levels'] = el['infant_levels'].join(',');
						}

						return el;
					});
				}

				value = JSON.stringify(value);
			} else {
				if(key === 'from') {
					if(value instanceof moment) {
						value = value.format('YYYY-MM-DD');
					}
				}
			}

			data.append(key, value);
		});

		// for(let pair of data.entries()) {
		// 	_debugData[pair[0]] = pair[1];
		// }
		//
		// console.log(_debugData);

		axios.post(`${backendApi}/${controller}/${id}`, data).then(r => {
			history.push(`/regulations/${id}`);
		}).catch(err => {
			setError({
				errcode: err?.response?.data?.errcode,
				message: err?.response?.data?.message
			});

			// captureException(new RecordSaveError(`Error occured while saving record`), {
			// 	contexts: {
			// 		record: {
			// 			controller: controller,
			// 			id: id,
			// 			mode: 'edit'
			// 		},
			// 		error: {
			// 			message: err?.response?.data?.message,
			// 			details: err?.response?.data?.details,
			// 			errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED',
			// 			file: err?.response?.data?.file,
			// 			line: err?.response?.data?.line,
			// 			trace: JSON.stringify(err?.response?.data?.trace?.slice?.(0, 3) ?? [])
			// 		}
			// 	}
			// });
		});
	};

	const onFormError = (data) => {
		const {
			values,
			errorFields,
			outOfDate
		} = data;

		if(errorFields.length) {
			let errorField = errorFields[0];

			errorFields.forEach(el => {
				if(el.errors[0]) {
					message.error(`${el.errors[0]}`);
				}
			});
		}
	};

	const buttons = <>
		<Button type="danger" onClick={() => { history.push('/regulations/'+id); }}>Anuluj</Button>
		<Button type="primary" htmlType="submit">Zapisz</Button>
	</>;

	return (
		<div className="content">
		{
		auth.can(USER_PERMISSIONS.ADMIN)
		?
		<Form
			name="regulations"
			layout="vertical"
			onFinish={saveRecord}
			onFinishFailed={onFormError}
		>
			<div className="subheader">
				<div className="d-flex">
					<h1 className="title">Edycja regulaminu</h1>
					<span className="subtitle">
						Dzisiaj jest <Moment locale="pl" format="dddd, D MMMM YYYY" />
					</span>
				</div>

				{buttons ?
				<Affix offsetTop={54} onChange={(affixed) => { setAffixedActions(affixed); }}>
					<div className="subheader_actions">
						{
							(affixedActions ?? false) ?
							<div className="container">
								<h1 className="title m-0">Edycja regulaminu</h1>
								<div className="d-flex">
									{buttons}
								</div>
							</div>
							:
							buttons
						}
					</div>
				</Affix>
				:
				null}
			</div>

			<div>
				{error ? <Row style={{ marginBottom: '15px' }}>
					<Col sm={24} md={16}>
						<Alert type="error" message={prepareErrorMessage(error)} />
					</Col>
				</Row> : ''}

				<Row justify="space-between">
					<Col sm={24} lg={16}>
						<TabbedCard
							loading={loading}
							tabContent={{
								general: [
									<Col key="general_col_name" lg={24} xl={12}>
										<Form.Item
											label="Nazwa"
											name="name"
											initialValue={getInitialValue('name')}
											rules={[{ required: true, message: 'Proszę uzupełnić nazwę regulaminu' }]}
										>
											<Input />
										</Form.Item>
									</Col>,
									<Col key="general_col_contractor_id" lg={24} xl={12}>
										<Form.Item
											label="Kontrahent"
											name="contractor_id"
											initialValue={getInitialValue('contractor_id')}
											rules={[{ required: true, message: 'Proszę wybrać kontrahenta' }]}
										>
											<Select>
												{prepareOptionsFromRelationData('contractors')}
											</Select>
										</Form.Item>
									</Col>,
									<Col key="general_col_from" lg={24} xl={12}>
										<Form.Item
											label="Obowiązuje od"
											name="from"
											initialValue={getInitialValue('from', 'date')}
											rules={[{ required: true, message: 'Proszę uzupełnić datę obowiązywania regulaminu' }]}
										>
											<DatePicker style={{ width: '100%' }} />
										</Form.Item>
									</Col>,
									<Col key="general_col_is_active" lg={24} xl={12}>
										<Form.Item
											label="Aktywny"
											name="is_active"
											initialValue={getInitialValue('is_active', 'bool')}
											valuePropName="checked"
										>
											<Switch />
										</Form.Item>
									</Col>,
									<Col key="general_col_description" span={24}>
										<Form.Item
											label="Informacje dodatkowe"
											name="description"
											initialValue={getInitialValue('description')}
										>
											<Input.TextArea showCount rows={5} />
										</Form.Item>
									</Col>,
								],
							}}
							tabList={[
								{ key: 'general', tab: 'Dane ogólne' },
							]}
							activeTabKey={currentActiveTab}
							onTabChange={(key) => { setCurrentActiveTab(key) }}
							forceRender={true}
						/>

						<CardCollapse
						title="Reguły ogólne"
						key="generalCard"
						loading={loading}
						collapsed={isCollapsed_general}
						onCollapse={(collapsed) => {
							setCollapsed_general(collapsed);
						}}
						tabContent={{
							general_rg11: [
								<Row key="general_rg11_row" gutter={[16,16]}>
									<Col span={24}>
										<Form.Item
											name="regaccessbenefits"
											initialValue={getInitialValue('regaccessbenefits')}
										>
											<Form.List
												name="regaccessbenefits"
											>
												{(fields, { add, remove }) => (
													<React.Fragment key="general_rg11_fields">
														{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
															<Card
																key={key}
																style={idx ? {marginTop: 20} : {}}
																extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
															>
																<Row gutter={[16,16]}>
																	<Col span={24}>
																		<Form.Item
																			{...restField}
																			label="Rodzaj klienta"
																			name={[name, "client_type"]}
																			fieldKey={[fieldKey, 'client_type']}
																		>
																			<Select>
																				<Select.Option value="P">Pracownik</Select.Option>
																				<Select.Option value="E">Emeryt</Select.Option>
																			</Select>
																		</Form.Item>
																	</Col>

																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Wypoczynek dzieci"
																			name={[name, "is_camp"]}
																			fieldKey={[fieldKey, 'is_camp']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Świadczenia dodatkowe"
																			name={[name, "is_extra"]}
																			fieldKey={[fieldKey, 'is_extra']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Zapomogi"
																			name={[name, "is_grant"]}
																			fieldKey={[fieldKey, 'is_grant']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Przedszkola i żłobki"
																			name={[name, "is_infant"]}
																			fieldKey={[fieldKey, 'is_infant']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Pożyczki"
																			name={[name, "is_loan"]}
																			fieldKey={[fieldKey, 'is_loan']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Karty Sportowe"
																			name={[name, "is_multi"]}
																			fieldKey={[fieldKey, 'is_multi']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Wczasy pod gruszą"
																			name={[name, "is_pear"]}
																			fieldKey={[fieldKey, 'is_pear']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Refundacja wypoczynku dzieci"
																			name={[name, "is_refcamp"]}
																			fieldKey={[fieldKey, 'is_refcamp']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Refundacja świadczeń tur-rek"
																			name={[name, "is_reftourist"]}
																			fieldKey={[fieldKey, 'is_reftourist']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Refundacja wyjazdów"
																			name={[name, "is_reftrip"]}
																			fieldKey={[fieldKey, 'is_reftrip']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Zajęcia rekreacyjne"
																			name={[name, "is_rehab"]}
																			fieldKey={[fieldKey, 'is_rehab']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Bilety i karnety"
																			name={[name, "is_ticket"]}
																			fieldKey={[fieldKey, 'is_ticket']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Świadczenia tur-rek"
																			name={[name, "is_tourist"]}
																			fieldKey={[fieldKey, 'is_tourist']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Wyjazdy"
																			name={[name, "is_trip"]}
																			fieldKey={[fieldKey, 'is_trip']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																</Row>
															</Card>
														))}

														{ (1 && (fields.length < 2)) ?? true ? <Col span={24}><Form.Item className="mt-2">
															<Button type="dashed" onClick={ () => add() } block>
																Dodaj pozycję
															</Button>
														</Form.Item></Col> : null }
													</React.Fragment>
												)}
											</Form.List>
										</Form.Item>
									</Col>
								</Row>
							],
							general_rg04_employee: [
								<Row key="general_rg04_employee_row" gutter={[16,16]}>
									<Col span={24}>
										<Form.Item
											name="reglimitemployeequotes"
											initialValue={getInitialValue('reglimitemployeequotes')}
										>
											<Form.List
												name="reglimitemployeequotes"
											>
												{(fields, { add, remove }) => (
													<React.Fragment key="general_rg04_employee_fields">
														{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
															<Card
																key={key}
																style={idx ? {marginTop: 20} : {}}
																extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
															>
																<Row gutter={[16,16]}>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Średnie dochody od"
																			name={[name, "income_from"]}
																			fieldKey={[fieldKey, 'income_from']}
																			rules={[{ required: true, message: '[Reguły ogólne] [Kwotowe limity świadczeń (pracownik)] Proszę uzupełnić średnie dochody' }]}
																		>
																			<InputNumber style={{ width: '100%' }} precision={2} decimalSeparator="." />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Średnie dochody do"
																			name={[name, "income_to"]}
																			fieldKey={[fieldKey, 'income_to']}
																			rules={[{ required: true, message: '[Reguły ogólne] [Kwotowe limity świadczeń (pracownik)] Proszę uzupełnić średnie dochody' }]}
																		>
																			<InputNumber style={{ width: '100%' }} precision={2} decimalSeparator="." />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Limit sumy świadczeń"
																			name={[name, "limit_quote"]}
																			fieldKey={[fieldKey, 'limit_quote']}
																			rules={[{ required: true, message: '[Reguły ogólne] [Kwotowe limity świadczeń (pracownik)] Proszę uzupełnić limit sumy świadczeń' }]}
																		>
																			<InputNumber style={{ width: '100%' }} precision={2} decimalSeparator="." />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Wypoczynek dzieci"
																			name={[name, "is_camp"]}
																			fieldKey={[fieldKey, 'is_camp']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Świadczenia dodatkowe"
																			name={[name, "is_extra"]}
																			fieldKey={[fieldKey, 'is_extra']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Zapomogi"
																			name={[name, "is_grant"]}
																			fieldKey={[fieldKey, 'is_grant']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Przedszkola i żłobki"
																			name={[name, "is_infant"]}
																			fieldKey={[fieldKey, 'is_infant']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Pożyczki"
																			name={[name, "is_loan"]}
																			fieldKey={[fieldKey, 'is_loan']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Karty Sportowe"
																			name={[name, "is_multi"]}
																			fieldKey={[fieldKey, 'is_multi']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Wczasy pod gruszą"
																			name={[name, "is_pear"]}
																			fieldKey={[fieldKey, 'is_pear']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Refundacja wypoczynku dzieci"
																			name={[name, "is_refcamp"]}
																			fieldKey={[fieldKey, 'is_refcamp']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Refundacja świadczeń tur-rek"
																			name={[name, "is_reftourist"]}
																			fieldKey={[fieldKey, 'is_reftourist']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Refundacja wyjazdów"
																			name={[name, "is_reftrip"]}
																			fieldKey={[fieldKey, 'is_reftrip']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Zajęcia rekreacyjne"
																			name={[name, "is_rehab"]}
																			fieldKey={[fieldKey, 'is_rehab']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Bilety i karnety"
																			name={[name, "is_ticket"]}
																			fieldKey={[fieldKey, 'is_ticket']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Świadczenia tur-rek"
																			name={[name, "is_tourist"]}
																			fieldKey={[fieldKey, 'is_tourist']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Wyjazdy"
																			name={[name, "is_trip"]}
																			fieldKey={[fieldKey, 'is_trip']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																</Row>
															</Card>
														))}

														{ (null && (fields.length < null)) ?? true ? <Col span={24}><Form.Item className="mt-2">
															<Button type="dashed" onClick={ () => add() } block>
																Dodaj pozycję
															</Button>
														</Form.Item></Col> : null }
													</React.Fragment>
												)}
											</Form.List>
										</Form.Item>
									</Col>
								</Row>
							],
							general_rg04_pensioner: [
								<Row key="general_rg04_pensioner_row" gutter={[16,16]}>
									<Col span={24}>
										<Form.Item
											name="reglimitpensionerquotes"
											initialValue={getInitialValue('reglimitpensionerquotes')}
										>
											<Form.List
												name="reglimitpensionerquotes"
											>
												{(fields, { add, remove }) => (
													<React.Fragment key="general_rg04_pensioner_fields">
														{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
															<Card
																key={key}
																style={idx ? {marginTop: 20} : {}}
																extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
															>
																<Row gutter={[16,16]}>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Średnie dochody od"
																			name={[name, "income_from"]}
																			fieldKey={[fieldKey, 'income_from']}
																			rules={[{ required: true, message: '[Reguły ogólne] [Kwotowe limity świadczeń (emeryt)] Proszę uzupełnić średnie dochody' }]}
																		>
																			<InputNumber style={{ width: '100%' }} precision={2} decimalSeparator="." />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Średnie dochody do"
																			name={[name, "income_to"]}
																			fieldKey={[fieldKey, 'income_to']}
																			rules={[{ required: true, message: '[Reguły ogólne] [Kwotowe limity świadczeń (emeryt)] Proszę uzupełnić średnie dochody' }]}
																		>
																			<InputNumber style={{ width: '100%' }} precision={2} decimalSeparator="." />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Limit sumy świadczeń"
																			name={[name, "limit_quote"]}
																			fieldKey={[fieldKey, 'limit_quote']}
																			rules={[{ required: true, message: '[Reguły ogólne] [Kwotowe limity świadczeń (emeryt)] Proszę uzupełnić limit sumy świadczeń' }]}
																		>
																			<InputNumber style={{ width: '100%' }} precision={2} decimalSeparator="." />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Wypoczynek dzieci"
																			name={[name, "is_camp"]}
																			fieldKey={[fieldKey, 'is_camp']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Świadczenia dodatkowe"
																			name={[name, "is_extra"]}
																			fieldKey={[fieldKey, 'is_extra']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Zapomogi"
																			name={[name, "is_grant"]}
																			fieldKey={[fieldKey, 'is_grant']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Przedszkola i żłobki"
																			name={[name, "is_infant"]}
																			fieldKey={[fieldKey, 'is_infant']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Pożyczki"
																			name={[name, "is_loan"]}
																			fieldKey={[fieldKey, 'is_loan']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Karty Sportowe"
																			name={[name, "is_multi"]}
																			fieldKey={[fieldKey, 'is_multi']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Wczasy pod gruszą"
																			name={[name, "is_pear"]}
																			fieldKey={[fieldKey, 'is_pear']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Refundacja wypoczynku dzieci"
																			name={[name, "is_refcamp"]}
																			fieldKey={[fieldKey, 'is_refcamp']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Refundacja świadczeń tur-rek"
																			name={[name, "is_reftourist"]}
																			fieldKey={[fieldKey, 'is_reftourist']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Refundacja wyjazdów"
																			name={[name, "is_reftrip"]}
																			fieldKey={[fieldKey, 'is_reftrip']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Zajęcia rekreacyjne"
																			name={[name, "is_rehab"]}
																			fieldKey={[fieldKey, 'is_rehab']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Bilety i karnety"
																			name={[name, "is_ticket"]}
																			fieldKey={[fieldKey, 'is_ticket']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Świadczenia tur-rek"
																			name={[name, "is_tourist"]}
																			fieldKey={[fieldKey, 'is_tourist']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Wyjazdy"
																			name={[name, "is_trip"]}
																			fieldKey={[fieldKey, 'is_trip']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																</Row>
															</Card>
														))}

														{ (null && (fields.length < null)) ?? true ? <Col span={24}><Form.Item className="mt-2">
															<Button type="dashed" onClick={ () => add() } block>
																Dodaj pozycję
															</Button>
														</Form.Item></Col> : null }
													</React.Fragment>
												)}
											</Form.List>
										</Form.Item>
									</Col>
								</Row>
							],
							general_rg05_employee: [
								<Row key="general_rg05_employee_row" gutter={[16,16]}>
									<Col span={24}>
										<Form.Item
											name="reglimitemployeecounts"
											initialValue={getInitialValue('reglimitemployeecounts')}
										>
											<Form.List
												name="reglimitemployeecounts"
											>
												{(fields, { add, remove }) => (
													<React.Fragment key="general_rg05_employee_fields">
														{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
															<Card
																key={key}
																style={idx ? {marginTop: 20} : {}}
																extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
															>
																<Row gutter={[16,16]}>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Średnie dochody od"
																			name={[name, "income_from"]}
																			fieldKey={[fieldKey, 'income_from']}
																			rules={[{ required: true, message: '[Reguły ogólne] [Ilościowe limity świadczeń (pracownik)] Proszę uzupełnić średnie dochody' }]}
																		>
																			<InputNumber style={{ width: '100%' }} precision={2} decimalSeparator="." />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Średnie dochody do"
																			name={[name, "income_to"]}
																			fieldKey={[fieldKey, 'income_to']}
																			rules={[{ required: true, message: '[Reguły ogólne] [Ilościowe limity świadczeń (pracownik)] Proszę uzupełnić średnie dochody' }]}
																		>
																			<InputNumber style={{ width: '100%' }} precision={2} decimalSeparator="." />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Limit sumy świadczeń"
																			name={[name, "limit_count"]}
																			fieldKey={[fieldKey, 'limit_count']}
																			rules={[{ required: true, message: '[Reguły ogólne] [Ilościowe limity świadczeń (pracownik)] Proszę uzupełnić limit sumy świadczeń' }]}
																		>
																			<InputNumber style={{ width: '100%' }} precision={2} decimalSeparator="." />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Wypoczynek dzieci"
																			name={[name, "is_camp"]}
																			fieldKey={[fieldKey, 'is_camp']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Świadczenia dodatkowe"
																			name={[name, "is_extra"]}
																			fieldKey={[fieldKey, 'is_extra']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Zapomogi"
																			name={[name, "is_grant"]}
																			fieldKey={[fieldKey, 'is_grant']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Przedszkola i żłobki"
																			name={[name, "is_infant"]}
																			fieldKey={[fieldKey, 'is_infant']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Pożyczki"
																			name={[name, "is_loan"]}
																			fieldKey={[fieldKey, 'is_loan']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Karty Sportowe"
																			name={[name, "is_multi"]}
																			fieldKey={[fieldKey, 'is_multi']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Wczasy pod gruszą"
																			name={[name, "is_pear"]}
																			fieldKey={[fieldKey, 'is_pear']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Refundacja wypoczynku dzieci"
																			name={[name, "is_refcamp"]}
																			fieldKey={[fieldKey, 'is_refcamp']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Refundacja świadczeń tur-rek"
																			name={[name, "is_reftourist"]}
																			fieldKey={[fieldKey, 'is_reftourist']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Refundacja wyjazdów"
																			name={[name, "is_reftrip"]}
																			fieldKey={[fieldKey, 'is_reftrip']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Zajęcia rekreacyjne"
																			name={[name, "is_rehab"]}
																			fieldKey={[fieldKey, 'is_rehab']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Bilety i karnety"
																			name={[name, "is_ticket"]}
																			fieldKey={[fieldKey, 'is_ticket']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Świadczenia tur-rek"
																			name={[name, "is_tourist"]}
																			fieldKey={[fieldKey, 'is_tourist']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Wyjazdy"
																			name={[name, "is_trip"]}
																			fieldKey={[fieldKey, 'is_trip']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																</Row>
															</Card>
														))}

														{ (null && (fields.length < null)) ?? true ? <Col span={24}><Form.Item className="mt-2">
															<Button type="dashed" onClick={ () => add() } block>
																Dodaj pozycję
															</Button>
														</Form.Item></Col> : null }
													</React.Fragment>
												)}
											</Form.List>
										</Form.Item>
									</Col>
								</Row>
							],
							general_rg05_pensioner: [
								<Row key="general_rg05_pensioner_row" gutter={[16,16]}>
									<Col span={24}>
										<Form.Item
											name="reglimitpensionercounts"
											initialValue={getInitialValue('reglimitpensionercounts')}
										>
											<Form.List
												name="reglimitpensionercounts"
											>
												{(fields, { add, remove }) => (
													<React.Fragment key="general_rg05_pensioner_fields">
														{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
															<Card
																key={key}
																style={idx ? {marginTop: 20} : {}}
																extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
															>
																<Row gutter={[16,16]}>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Średnie dochody od"
																			name={[name, "income_from"]}
																			fieldKey={[fieldKey, 'income_from']}
																			rules={[{ required: true, message: '[Reguły ogólne] [Ilościowe limity świadczeń (emeryt)] Proszę uzupełnić średnie dochody' }]}
																		>
																			<InputNumber style={{ width: '100%' }} precision={2} decimalSeparator="." />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Średnie dochody do"
																			name={[name, "income_to"]}
																			fieldKey={[fieldKey, 'income_to']}
																			rules={[{ required: true, message: '[Reguły ogólne] [Ilościowe limity świadczeń (emeryt)] Proszę uzupełnić średnie dochody' }]}
																		>
																			<InputNumber style={{ width: '100%' }} precision={2} decimalSeparator="." />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Limit sumy świadczeń"
																			name={[name, "limit_count"]}
																			fieldKey={[fieldKey, 'limit_count']}
																			rules={[{ required: true, message: '[Reguły ogólne] [Ilościowe limity świadczeń (emeryt)] Proszę uzupełnić limit sumy świadczeń' }]}
																		>
																			<InputNumber style={{ width: '100%' }} precision={2} decimalSeparator="." />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Wypoczynek dzieci"
																			name={[name, "is_camp"]}
																			fieldKey={[fieldKey, 'is_camp']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Świadczenia dodatkowe"
																			name={[name, "is_extra"]}
																			fieldKey={[fieldKey, 'is_extra']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Zapomogi"
																			name={[name, "is_grant"]}
																			fieldKey={[fieldKey, 'is_grant']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Przedszkola i żłobki"
																			name={[name, "is_infant"]}
																			fieldKey={[fieldKey, 'is_infant']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Pożyczki"
																			name={[name, "is_loan"]}
																			fieldKey={[fieldKey, 'is_loan']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Karty Sportowe"
																			name={[name, "is_multi"]}
																			fieldKey={[fieldKey, 'is_multi']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Wczasy pod gruszą"
																			name={[name, "is_pear"]}
																			fieldKey={[fieldKey, 'is_pear']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Refundacja wypoczynku dzieci"
																			name={[name, "is_refcamp"]}
																			fieldKey={[fieldKey, 'is_refcamp']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Refundacja świadczeń tur-rek"
																			name={[name, "is_reftourist"]}
																			fieldKey={[fieldKey, 'is_reftourist']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Refundacja wyjazdów"
																			name={[name, "is_reftrip"]}
																			fieldKey={[fieldKey, 'is_reftrip']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Zajęcia rekreacyjne"
																			name={[name, "is_rehab"]}
																			fieldKey={[fieldKey, 'is_rehab']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Bilety i karnety"
																			name={[name, "is_ticket"]}
																			fieldKey={[fieldKey, 'is_ticket']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Świadczenia tur-rek"
																			name={[name, "is_tourist"]}
																			fieldKey={[fieldKey, 'is_tourist']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Wyjazdy"
																			name={[name, "is_trip"]}
																			fieldKey={[fieldKey, 'is_trip']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																</Row>
															</Card>
														))}

														{ (null && (fields.length < null)) ?? true ? <Col span={24}><Form.Item className="mt-2">
															<Button type="dashed" onClick={ () => add() } block>
																Dodaj pozycję
															</Button>
														</Form.Item></Col> : null }
													</React.Fragment>
												)}
											</Form.List>
										</Form.Item>
									</Col>
								</Row>
							],
							general_rg12: [
								<Row key="general_rg12_row" gutter={[16,16]}>
									<Col span={24}>
										<Form.Item
											name="regincludeincomes"
											initialValue={getInitialValue('regincludeincomes')}
										>
											<Form.List
												name="regincludeincomes"
											>
												{(fields, { add, remove }) => (
													<React.Fragment key="general_rg12_fields">
														{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
															<Card
																key={key}
																style={idx ? {marginTop: 20} : {}}
																extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
															>
																<Row gutter={[16,16]}>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Rodzaj dochodu"
																			name={[name, "dctincometype_id"]}
																			fieldKey={[fieldKey, 'dctincometype_id']}
																		>
																			<Select>
																				{prepareOptionsFromRelationData('dctincometypes')}
																			</Select>
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Wliczane do średniej"
																			name={[name, "is_taken"]}
																			fieldKey={[fieldKey, 'is_taken']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																</Row>
															</Card>
														))}

														{ (null && (fields.length < null)) ?? true ? <Col span={24}><Form.Item className="mt-2">
															<Button type="dashed" onClick={ () => add() } block>
																Dodaj pozycję
															</Button>
														</Form.Item></Col> : null }
													</React.Fragment>
												)}
											</Form.List>
										</Form.Item>
									</Col>
								</Row>
							],
							general_rg30: [
								<Row key="general_rg30_row" gutter={[16,16]}>
									<Col span={24}>
										<Form.Item
											name="regtermincomes"
											initialValue={getInitialValue('regtermincomes')}
										>
											<Form.List
												name="regtermincomes"
											>
												{(fields, { add, remove }) => (
													<React.Fragment key="general_rg30_fields">
														{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
															<Card
																key={key}
																style={idx ? {marginTop: 20} : {}}
																extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
															>
																<Row gutter={[16,16]}>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Ważne do końca roku"
																			name={[name, "is_end_year"]}
																			fieldKey={[fieldKey, 'is_end_year']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Ważność dochodów (w miesiącach)"
																			name={[name, "months"]}
																			fieldKey={[fieldKey, 'months']}
																		>
																			<InputNumber
																				style={{ width: '100%' }}
																				precision={0}
																			/>
																		</Form.Item>
																	</Col>
																</Row>
															</Card>
														))}

														{ (1 && (fields.length < 1)) ?? true ? <Col span={24}><Form.Item className="mt-2">
															<Button type="dashed" onClick={ () => add() } block>
																Dodaj pozycję
															</Button>
														</Form.Item></Col> : null }
													</React.Fragment>
												)}
											</Form.List>
										</Form.Item>
									</Col>
								</Row>
							],
							general_rg00: [
								<Row key="general_rg00_row" gutter={[16,16]}>
									<Col span={24}>
										<Form.Item
											name="regperiodbenefits"
											initialValue={getInitialValue('regperiodbenefits')}
										>
											<Form.List
												name="regperiodbenefits"
											>
												{(fields, { add, remove }) => (
													<React.Fragment key="general_rg00_fields">
														{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
															<Card
																key={key}
																style={idx ? {marginTop: 20} : {}}
																extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
															>
																<Row gutter={[16,16]}>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Rodzaj wniosku"
																			name={[name, "cnfapp_id"]}
																			fieldKey={[fieldKey, 'cnfapp_id']}
																		>
																			<Select>
																				{prepareOptionsFromRelationData('cnfapps')}
																			</Select>
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Okres rozliczeniowy"
																			name={[name, "period"]}
																			fieldKey={[fieldKey, 'period']}
																		>
																			<Select>
																				<Select.Option value="K">kwartał</Select.Option>
																				<Select.Option value="M">miesiąc</Select.Option>
																				<Select.Option value="P">półrocze</Select.Option>
																				<Select.Option value="R">rok</Select.Option>
																			</Select>
																		</Form.Item>
																	</Col>
																</Row>
															</Card>
														))}

														{ (null && (fields.length < null)) ?? true ? <Col span={24}><Form.Item className="mt-2">
															<Button type="dashed" onClick={ () => add() } block>
																Dodaj pozycję
															</Button>
														</Form.Item></Col> : null }
													</React.Fragment>
												)}
											</Form.List>
										</Form.Item>
									</Col>
								</Row>
							],
						}} tabList={[
							{ key: 'general_rg11', tab: 'Dostępne świadczenia' },
							{ key: 'general_rg04_employee', tab: 'Kwotowe limity świadczeń (pracownik)' },
							{ key: 'general_rg04_pensioner', tab: 'Kwotowe limity świadczeń (emeryt)' },
							{ key: 'general_rg05_employee', tab: 'Ilościowe limity świadczeń (pracownik)' },
							{ key: 'general_rg05_pensioner', tab: 'Ilościowe limity świadczeń (emeryt)' },
							{ key: 'general_rg12', tab: 'Dodatkowe dochody' },
							{ key: 'general_rg30', tab: 'Terminy ważności dochodów' },
							{ key: 'general_rg00', tab: 'Okresy rozliczeniowe' },
						]}
						activeTabKey={currentActiveTab_general}
						onTabChange={(key) => { setCurrentActiveTab_general(key) }}
						forceRender={true}
						className="mt-1" />

					<CardCollapse
						title="Wczasy pod gruszą"
						key="pearsCard"
						loading={loading}
						collapsed={isCollapsed_pears}
						onCollapse={(collapsed) => {
							setCollapsed_pears(collapsed);
						}}
						tabContent={{
							pears_rg06: [
								<Row key="pears_rg06_row" gutter={[16,16]}>
									<Col span={24}>
										<Form.Item
											name="reglimitpears"
											initialValue={getInitialValue('reglimitpears')}
										>
											<Form.List
												name="reglimitpears"
											>
												{(fields, { add, remove }) => (
													<React.Fragment key="pears_rg06_fields">
														{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
															<Card
																key={key}
																style={idx ? {marginTop: 20} : {}}
																extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
															>
																<Row gutter={[16,16]}>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Średnie dochody od"
																			name={[name, "income_from"]}
																			fieldKey={[fieldKey, 'income_from']}
																			rules={[{ required: true, message: '[Wczasy pod gruszą] [Progi dochodowe] Proszę uzupełnić średnie dochody' }]}
																		>
																			<InputNumber
																				style={{ width: '100%' }}
																				precision={2}
																				decimalSeparator="."
																			/>
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Średnie dochody do"
																			name={[name, "income_to"]}
																			fieldKey={[fieldKey, 'income_to']}
																			rules={[{ required: true, message: '[Wczasy pod gruszą] [Progi dochodowe] Proszę uzupełnić średnie dochody' }]}
																		>
																			<InputNumber
																				style={{ width: '100%' }}
																				precision={2}
																				decimalSeparator="."
																			/>
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Maksymalne dofinansowanie"
																			name={[name, "max_subsidy"]}
																			fieldKey={[fieldKey, 'max_subsidy']}
																			rules={[{ required: true, message: '[Wczasy pod gruszą] [Progi dochodowe] Proszę uzupełnić maksymalne dofinansowanie' }]}
																		>
																			<InputNumber
																				style={{ width: '100%' }}
																				precision={2}
																				decimalSeparator="."
																			/>
																		</Form.Item>
																	</Col>
																</Row>
															</Card>
														))}

														{ (null && (fields.length < null)) ?? true ? <Col span={24}><Form.Item className="mt-2">
															<Button type="dashed" onClick={ () => add() } block>
																Dodaj pozycję
															</Button>
														</Form.Item></Col> : null }
													</React.Fragment>
												)}
											</Form.List>
										</Form.Item>
									</Col>
								</Row>
							],
						}}
						tabList={[
							{ key: 'pears_rg06', tab: 'Progi dochodowe' },
						]}
						activeTabKey={currentActiveTab_pears}
						onTabChange={(key) => {
							setCurrentActiveTab_pears(key)
						}}
						forceRender={true}
						className="mt-1" />

						<CardCollapse
						title="Wycieczki i wczasy z dojazdem własnym"
						key="tripsCard"
						loading={loading}
						collapsed={isCollapsed_trips}
						onCollapse={(collapsed) => {
							setCollapsed_trips(collapsed);
						}}
						tabContent={{
							trips_rg07: [
								<Row key="trips_rg07_row" gutter={[16,16]}>
									<Col span={24}>
										<Form.Item
											name="reglimittrips"
											initialValue={getInitialValue('reglimittrips')}
										>
											<Form.List
												name="reglimittrips"
											>
												{(fields, { add, remove }) => (
													<React.Fragment key="trips_rg07_fields">
														{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
															<Card
																key={key}
																style={idx ? {marginTop: 20} : {}}
																extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
															>
																<Row gutter={[16,16]}>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Średnie dochody od"
																			name={[name, "income_from"]}
																			fieldKey={[fieldKey, 'income_from']}
																			rules={[{ required: true, message: '[Wycieczki i wczasy z dojazdem własnym] [Progi dochodowe] Proszę uzupełnić średnie dochody' }]}
																		>
																			<InputNumber
																				style={{ width: '100%' }}
																				precision={2}
																				decimalSeparator="."
																			/>
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Średnie dochody do"
																			name={[name, "income_to"]}
																			fieldKey={[fieldKey, 'income_to']}
																			rules={[{ required: true, message: '[Wycieczki i wczasy z dojazdem własnym] [Progi dochodowe] Proszę uzupełnić średnie dochody' }]}
																		>
																			<InputNumber
																				style={{ width: '100%' }}
																				precision={2}
																				decimalSeparator="."
																			/>
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Jednodniowa"
																			name={[name, "is_one_day"]}
																			fieldKey={[fieldKey, 'is_one_day']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Więcej, niż jeden dzień"
																			name={[name, "is_more_days"]}
																			fieldKey={[fieldKey, 'is_more_days']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Ilość dni od"
																			name={[name, "many_days_from"]}
																			fieldKey={[fieldKey, 'many_days_from']}
																		>
																			<InputNumber
																				style={{ width: '100%' }}
																				precision={0}
																			/>
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Ilość dni do"
																			name={[name, "many_days_to"]}
																			fieldKey={[fieldKey, 'many_days_to']}
																		>
																			<InputNumber
																				style={{ width: '100%' }}
																				precision={0}
																			/>
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Krajowa"
																			name={[name, "is_country"]}
																			fieldKey={[fieldKey, 'is_country']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Za granicą"
																			name={[name, "is_abroad"]}
																			fieldKey={[fieldKey, 'is_abroad']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Rajd"
																			name={[name, "is_ride"]}
																			fieldKey={[fieldKey, 'is_ride']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="% dofinansowania"
																			name={[name, "subsidy_percent"]}
																			fieldKey={[fieldKey, 'subsidy_percent']}
																		>
																			<InputNumber
																				style={{ width: '100%' }}
																				precision={2}
																				decimalSeparator="."
																			/>
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Dojazd własny"
																			name={[name, "is_own_transport"]}
																			fieldKey={[fieldKey, 'is_own_transport']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Pobyt"
																			name={[name, "is_stay"]}
																			fieldKey={[fieldKey, 'is_stay']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col span={24}>
																		<Collapse>
																			<Collapse.Panel header="Pracownik">
																				<Row gutter={[16,16]}>
																					<Col lg={24} xl={12}>
																						<Form.Item
																							{...restField}
																							label="Za dzień"
																							name={[name, "employee_person_day"]}
																							fieldKey={[fieldKey, 'employee_person_day']}
																						>
																							<InputNumber
																								style={{ width: '100%' }}
																								precision={2}
																								decimalSeparator="."
																							/>
																						</Form.Item>
																					</Col>
																					<Col lg={24} xl={12}>
																						<Form.Item
																							{...restField}
																							label="Ilość dni dofinansowania"
																							name={[name, "employee_days"]}
																							fieldKey={[fieldKey, 'employee_days']}
																						>
																							<InputNumber
																								style={{ width: '100%' }}
																								precision={0}
																								max={127}
																							/>
																						</Form.Item>
																					</Col>
																					<Col lg={24} xl={12}>
																						<Form.Item
																							{...restField}
																							label="Za nocleg"
																							name={[name, "employee_accomodation"]}
																							fieldKey={[fieldKey, 'employee_accomodation']}
																						>
																							<InputNumber
																								style={{ width: '100%' }}
																								precision={2}
																								decimalSeparator="."
																							/>
																						</Form.Item>
																					</Col>
																					<Col lg={24} xl={12}>
																						<Form.Item
																							{...restField}
																							label="Maksymalne dofinansowanie (pracownik)"
																							name={[name, "employee_max_subsidy_adult"]}
																							fieldKey={[fieldKey, 'employee_max_subsidy_adult']}
																						>
																							<InputNumber
																								style={{ width: '100%' }}
																								precision={2}
																								decimalSeparator="."
																							/>
																						</Form.Item>
																					</Col>
																					<Col lg={24} xl={12}>
																						<Form.Item
																							{...restField}
																							label="Maksymalne dofinansowanie (dziecko)"
																							name={[name, "employee_max_subsidy_child"]}
																							fieldKey={[fieldKey, 'employee_max_subsidy_child']}
																						>
																							<InputNumber
																								style={{ width: '100%' }}
																								precision={2}
																								decimalSeparator="."
																							/>
																						</Form.Item>
																					</Col>
																					<Col lg={24} xl={12}>
																						<Form.Item
																							{...restField}
																							label="Maksymalna liczba rajdów"
																							name={[name, "employee_max_rides"]}
																							fieldKey={[fieldKey, 'employee_max_rides']}
																						>
																							<InputNumber
																								style={{ width: '100%' }}
																								precision={0}
																							/>
																						</Form.Item>
																					</Col>
																				</Row>
																			</Collapse.Panel>
																			<Collapse.Panel header="Emeryt">
																				<Row gutter={[16,16]}>
																					<Col lg={24} xl={12}>
																						<Form.Item
																							{...restField}
																							label="Za dzień"
																							name={[name, "pensioner_person_day"]}
																							fieldKey={[fieldKey, 'pensioner_person_day']}
																						>
																							<InputNumber
																								style={{ width: '100%' }}
																								precision={2}
																								decimalSeparator="."
																							/>
																						</Form.Item>
																					</Col>
																					<Col lg={24} xl={12}>
																						<Form.Item
																							{...restField}
																							label="Ilość dni dofinansowania"
																							name={[name, "pensioner_days"]}
																							fieldKey={[fieldKey, 'pensioner_days']}
																						>
																							<InputNumber
																								style={{ width: '100%' }}
																								precision={0}
																								max={127}
																							/>
																						</Form.Item>
																					</Col>
																					<Col lg={24} xl={12}>
																						<Form.Item
																							{...restField}
																							label="Za nocleg"
																							name={[name, "pensioner_accomodation"]}
																							fieldKey={[fieldKey, 'pensioner_accomodation']}
																						>
																							<InputNumber
																								style={{ width: '100%' }}
																								precision={2}
																								decimalSeparator="."
																							/>
																						</Form.Item>
																					</Col>
																					<Col lg={24} xl={12}>
																						<Form.Item
																							{...restField}
																							label="Maksymalne dofinansowanie (emeryt)"
																							name={[name, "pensioner_max_subsidy_adult"]}
																							fieldKey={[fieldKey, 'pensioner_max_subsidy_adult']}
																						>
																							<InputNumber
																								style={{ width: '100%' }}
																								precision={2}
																								decimalSeparator="."
																							/>
																						</Form.Item>
																					</Col>
																					<Col lg={24} xl={12}>
																						<Form.Item
																							{...restField}
																							label="Maksymalne dofinansowanie (dziecko)"
																							name={[name, "pensioner_max_subsidy_child"]}
																							fieldKey={[fieldKey, 'pensioner_max_subsidy_child']}
																						>
																							<InputNumber
																								style={{ width: '100%' }}
																								precision={2}
																								decimalSeparator="."
																							/>
																						</Form.Item>
																					</Col>
																					<Col lg={24} xl={12}>
																						<Form.Item
																							{...restField}
																							label="Maksymalna liczba rajdów"
																							name={[name, "pensioner_max_rides"]}
																							fieldKey={[fieldKey, 'pensioner_max_rides']}
																						>
																							<InputNumber
																								style={{ width: '100%' }}
																								precision={0}
																							/>
																						</Form.Item>
																					</Col>
																				</Row>
																			</Collapse.Panel>
																		</Collapse>
																	</Col>
																</Row>
															</Card>
														))}

														{ (null && (fields.length < null)) ?? true ? <Col span={24}><Form.Item className="mt-2">
															<Button type="dashed" onClick={ () => add() } block>
																Dodaj pozycję
															</Button>
														</Form.Item></Col> : null }
													</React.Fragment>
												)}
											</Form.List>
										</Form.Item>
									</Col>
								</Row>
							],
							trips_rg16: [
								<Row key="trips_rg16_row" gutter={[16,16]}>
									<Col span={24}>
										<Form.Item
											name="regparamtrips"
											initialValue={getInitialValue('regparamtrips')}
										>
											<Form.List
												name="regparamtrips"
											>
												{(fields, { add, remove }) => (
													<React.Fragment key="trips_rg16_fields">
														{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
															<Card
																key={key}
																style={idx ? {marginTop: 20} : {}}
																extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
															>
																<Row gutter={[16,16]}>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Możliwy kolejny turnus tego samego dnia"
																			name={[name, "is_same_day"]}
																			fieldKey={[fieldKey, 'is_same_day']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Pracownik może być uczestnikiem"
																			name={[name, "is_employee_member"]}
																			fieldKey={[fieldKey, 'is_employee_member']}
																			valuePropName="checked"
																		>
																			<Switch />
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Maksymalna liczba wycieczek zagranicznych"
																			name={[name, "max_abroad_trips"]}
																			fieldKey={[fieldKey, 'max_abroad_trips']}
																		>
																			<InputNumber
																				style={{ width: '100%' }}
																				precision={0}
																			/>
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Maksymalna liczba wycieczek krajowych"
																			name={[name, "max_country_trips"]}
																			fieldKey={[fieldKey, 'max_country_trips']}
																		>
																			<InputNumber
																				style={{ width: '100%' }}
																				precision={0}
																			/>
																		</Form.Item>
																	</Col>
																</Row>
															</Card>
														))}

														{ (null && (fields.length < null)) ?? true ? <Col span={24}><Form.Item className="mt-2">
															<Button type="dashed" onClick={ () => add() } block>
																Dodaj pozycję
															</Button>
														</Form.Item></Col> : null }
													</React.Fragment>
												)}
											</Form.List>
										</Form.Item>
									</Col>
								</Row>
							],
						}}
						tabList={[
							{ key: 'trips_rg07', tab: 'Progi dochodowe' },
							{ key: 'trips_rg16', tab: 'Ograniczenia' },
						]}
						activeTabKey={currentActiveTab_trips}
						onTabChange={(key) => {
							setCurrentActiveTab_trips(key)
						}}
						forceRender={true}
						className="mt-1" />

						<CardCollapse
						title="Bilety i karnety"
						key="ticketsCard"
						loading={loading}
						collapsed={isCollapsed_tickets}
						onCollapse={(collapsed) => {
							setCollapsed_tickets(collapsed);
						}}
						tabContent={{
							tickets_rg08: [
								<Row key="tickets_rg08_row" gutter={[16,16]}>
									<Col span={24}>
										<Form.Item
											name="reglimitikotickets"
											initialValue={getInitialValue('reglimitikotickets')}
										>
											<Form.List
												name="reglimitikotickets"
											>
												{(fields, { add, remove }) => (
													<React.Fragment key="tickets_rg08_fields">
														{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
															<Card
																key={key}
																style={idx ? {marginTop: 20} : {}}
																extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
															>
																<Row gutter={[16,16]}>
																	<Col span={24}>
																		<Form.Item
																			{...restField}
																			label="Rodzaj imprezy"
																			name={[name, "dctikotype_id"]}
																			fieldKey={[fieldKey, 'dctikotype_id']}
																		>
																			<Select>
																				{prepareOptionsFromRelationData('dctikotypes')}
																			</Select>
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Średnie dochody od"
																			name={[name, "income_from"]}
																			fieldKey={[fieldKey, 'income_from']}
																			rules={[{ required: true, message: '[Bilety i karnety] [Progi dochodowe IKO] Proszę uzupełnić średnie dochody' }]}
																		>
																			<InputNumber
																				style={{ width: '100%' }}
																				precision={2}
																				decimalSeparator="."
																			/>
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Średnie dochody do"
																			name={[name, "income_to"]}
																			fieldKey={[fieldKey, 'income_to']}
																			rules={[{ required: true, message: '[Bilety i karnety] [Progi dochodowe IKO] Proszę uzupełnić średnie dochody' }]}
																		>
																			<InputNumber
																				style={{ width: '100%' }}
																				precision={2}
																				decimalSeparator="."
																			/>
																		</Form.Item>
																	</Col>
																	<Col span={24}>
																		<Collapse>
																			<Collapse.Panel header="Pracownik">
																				<Row gutter={[16,16]}>
																					<Col lg={24} xl={12}>
																						<Form.Item
																							{...restField}
																							label="% dofinansowania dla pracownika"
																							name={[name, "employee_percent"]}
																							fieldKey={[fieldKey, 'employee_percent']}
																						>
																							<InputNumber
																								style={{ width: '100%' }}
																								precision={2}
																								decimalSeparator="."
																							/>
																						</Form.Item>
																					</Col>
																					<Col lg={24} xl={12}>
																						<Form.Item
																							{...restField}
																							label="Kwota dofinansowania dla pracownika"
																							name={[name, "employee_quote"]}
																							fieldKey={[fieldKey, 'employee_quote']}
																						>
																							<InputNumber
																								style={{ width: '100%' }}
																								precision={2}
																								decimalSeparator="."
																							/>
																						</Form.Item>
																					</Col>
																				</Row>
																			</Collapse.Panel>
																			<Collapse.Panel header="Emeryt">
																				<Row gutter={[16,16]}>
																					<Col lg={24} xl={12}>
																						<Form.Item
																							{...restField}
																							label="% dofinansowania dla emeryta"
																							name={[name, "pensioner_percent"]}
																							fieldKey={[fieldKey, 'pensioner_percent']}
																						>
																							<InputNumber
																								style={{ width: '100%' }}
																								precision={2}
																								decimalSeparator="."
																							/>
																						</Form.Item>
																					</Col>
																					<Col lg={24} xl={12}>
																						<Form.Item
																							{...restField}
																							label="Kwota dofinansowania dla emeryta"
																							name={[name, "pensioner_quote"]}
																							fieldKey={[fieldKey, 'pensioner_quote']}
																						>
																							<InputNumber
																								style={{ width: '100%' }}
																								precision={2}
																								decimalSeparator="."
																							/>
																						</Form.Item>
																					</Col>
																				</Row>
																			</Collapse.Panel>
																		</Collapse>
																	</Col>
																</Row>
															</Card>
														))}

														{ (null && (fields.length < null)) ?? true ? <Col span={24}><Form.Item className="mt-2">
															<Button type="dashed" onClick={ () => add() } block>
																Dodaj pozycję
															</Button>
														</Form.Item></Col> : null }
													</React.Fragment>
												)}
											</Form.List>
										</Form.Item>
									</Col>
								</Row>
							],
							tickets_rg09: [
								<Row key="tickets_rg09_row" gutter={[16,16]}>
									<Col span={24}>
										<Form.Item
											name="reglimitirotickets"
											initialValue={getInitialValue('reglimitirotickets')}
										>
											<Form.List
												name="reglimitirotickets"
											>
												{(fields, { add, remove }) => (
													<React.Fragment key="tickets_rg09_fields">
														{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
															<Card
																key={key}
																style={idx ? {marginTop: 20} : {}}
																extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
															>
																<Row gutter={[16,16]}>
																	<Col span={24}>
																		<Form.Item
																			{...restField}
																			label="Rodzaj imprezy"
																			name={[name, "dctirotype_id"]}
																			fieldKey={[fieldKey, 'dctirotype_id']}
																		>
																			<Select>
																				{prepareOptionsFromRelationData('dctirotypes')}
																			</Select>
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Średnie dochody od"
																			name={[name, "income_from"]}
																			fieldKey={[fieldKey, 'income_from']}
																			rules={[{ required: true, message: '[Bilety i karnety] [Progi dochodowe IRO] Proszę uzupełnić średnie dochody' }]}
																		>
																			<InputNumber
																				style={{ width: '100%' }}
																				precision={2}
																				decimalSeparator="."
																			/>
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Średnie dochody do"
																			name={[name, "income_to"]}
																			fieldKey={[fieldKey, 'income_to']}
																			rules={[{ required: true, message: '[Bilety i karnety] [Progi dochodowe IRO] Proszę uzupełnić średnie dochody' }]}
																		>
																			<InputNumber
																				style={{ width: '100%' }}
																				precision={2}
																				decimalSeparator="."
																			/>
																		</Form.Item>
																	</Col>
																	<Col span={24}>
																		<Collapse>
																			<Collapse.Panel header="Pracownik">
																				<Row gutter={[16,16]}>
																					<Col lg={24} xl={12}>
																						<Form.Item
																							{...restField}
																							label="% dofinansowania dla pracownika"
																							name={[name, "employee_percent"]}
																							fieldKey={[fieldKey, 'employee_percent']}
																						>
																							<InputNumber
																								style={{ width: '100%' }}
																								precision={2}
																								decimalSeparator="."
																							/>
																						</Form.Item>
																					</Col>
																					<Col lg={24} xl={12}>
																						<Form.Item
																							{...restField}
																							label="Kwota dofinansowania dla pracownika"
																							name={[name, "employee_quote"]}
																							fieldKey={[fieldKey, 'employee_quote']}
																						>
																							<InputNumber
																								style={{ width: '100%' }}
																								precision={2}
																								decimalSeparator="."
																							/>
																						</Form.Item>
																					</Col>
																				</Row>
																			</Collapse.Panel>
																			<Collapse.Panel header="Emeryt">
																				<Row gutter={[16,16]}>
																					<Col lg={24} xl={12}>
																						<Form.Item
																							{...restField}
																							label="% dofinansowania dla emeryta"
																							name={[name, "pensioner_percent"]}
																							fieldKey={[fieldKey, 'pensioner_percent']}
																						>
																							<InputNumber
																								style={{ width: '100%' }}
																								precision={2}
																								decimalSeparator="."
																							/>
																						</Form.Item>
																					</Col>
																					<Col lg={24} xl={12}>
																						<Form.Item
																							{...restField}
																							label="Kwota dofinansowania dla emeryta"
																							name={[name, "pensioner_quote"]}
																							fieldKey={[fieldKey, 'pensioner_quote']}
																						>
																							<InputNumber
																								style={{ width: '100%' }}
																								precision={2}
																								decimalSeparator="."
																							/>
																						</Form.Item>
																					</Col>
																				</Row>
																			</Collapse.Panel>
																		</Collapse>
																	</Col>
																</Row>
															</Card>
														))}

														{ (null && (fields.length < null)) ?? true ? <Col span={24}><Form.Item className="mt-2">
															<Button type="dashed" onClick={ () => add() } block>
																Dodaj pozycję
															</Button>
														</Form.Item></Col> : null }
													</React.Fragment>
												)}
											</Form.List>
										</Form.Item>
									</Col>
								</Row>
							],
							tickets_rg18: [
								<Row key="tickets_rg18_row" gutter={[16,16]}>
									<Col span={24}>
										<Form.Item
											name="regticketdays"
											initialValue={getInitialValue('regticketdays')}
										>
											<Form.List
												name="regticketdays"
											>
												{(fields, { add, remove }) => (
													<React.Fragment key="tickets_rg18_fields">
														{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
															<Card
																key={key}
																style={idx ? {marginTop: 20} : {}}
																extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
															>
																<Row gutter={[16,16]}>
																	<Col span={24}>
																		<Form.Item
																			{...restField}
																			label="Typ wniosku"
																			name={[name, "type"]}
																			fieldKey={[fieldKey, 'type']}
																		>
																			<Select>
																				<Select.Option value="K">IKO</Select.Option>
																				<Select.Option value="R">IRO</Select.Option>
																			</Select>
																		</Form.Item>
																	</Col>
																	<Col lg={24} xl={12}>
																		<Form.Item
																			{...restField}
																			label="Ważność wniosku w dniach"
																			name={[name, "days"]}
																			fieldKey={[fieldKey, 'days']}
																		>
																			<InputNumber
																				style={{ width: '100%' }}
																				precision={0}
																			/>
																		</Form.Item>
																	</Col>
																</Row>
															</Card>
														))}

														{ (2 && (fields.length < 2)) ?? true ? <Col span={24}><Form.Item className="mt-2">
															<Button type="dashed" onClick={ () => add() } block>
																Dodaj pozycję
															</Button>
														</Form.Item></Col> : null }
													</React.Fragment>
												)}
											</Form.List>
										</Form.Item>
									</Col>
								</Row>
							],
						}}
						tabList={[
							{ key: 'tickets_rg08', tab: 'Progi dochodowe IKO' },
							{ key: 'tickets_rg09', tab: 'Progi dochodowe IRO' },
							{ key: 'tickets_rg18', tab: 'Ograniczenia' },
						]}
						activeTabKey={currentActiveTab_tickets}
						onTabChange={(key) => {
							setCurrentActiveTab_tickets(key)
						}}
						forceRender={true}
						className="mt-1" />

						<CardCollapse
							title="Wypoczynek dzieci i młodzieży"
							key="campsCard"
							loading={loading}
							collapsed={isCollapsed_camps}
							onCollapse={(collapsed) => {
								setCollapsed_camps(collapsed);
							}}
							tabContent={{
								camps_rg17_regular: [
									<Row key="camps_rg17_regular_row" gutter={[16,16]}>
										<Col span={24}>
											<Form.Item
												name="reglimitregularcamps"
												initialValue={getInitialValue('reglimitregularcamps', 'campsData')}
											>
												<Form.List
													name="reglimitregularcamps"
												>
													{(fields, { add, remove }) => (
														<React.Fragment key="camps_rg17_regular_fields">
															{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
																<Card
																	key={key}
																	style={idx ? {marginTop: 20} : {}}
																	extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
																>
																	<Row gutter={[16,16]}>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Stopnie edukacji"
																				name={[name, "dcteducation_ids"]}
																				fieldKey={[fieldKey, 'dcteducation_ids']}
																			>
																				<Select mode="multiple">
																					{prepareOptionsFromRelationData('dcteducations')}
																				</Select>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Stopnie niepełnosprawności"
																				name={[name, "dctdisability_ids"]}
																				fieldKey={[fieldKey, 'dctdisability_ids']}
																			>
																				<Select mode="multiple">
																					{prepareOptionsFromRelationData('dctdisabilities')}
																				</Select>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Średnie dochody od"
																				name={[name, "income_from"]}
																				fieldKey={[fieldKey, 'income_from']}
																				rules={[{ required: true, message: '[Wypoczynek dzieci i młodzieży] [Obozy kolonie - Ograniczenia] Proszę uzupełnić średnie dochody' }]}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Średnie dochody do"
																				name={[name, "income_to"]}
																				fieldKey={[fieldKey, 'income_to']}
																				rules={[{ required: true, message: '[Wypoczynek dzieci i młodzieży] [Obozy kolonie - Ograniczenia] Proszę uzupełnić średnie dochody' }]}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Kwota dopłaty do dnia/noclegu"
																				name={[name, "subsidy_quote_per_day"]}
																				fieldKey={[fieldKey, 'subsidy_quote_per_day']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Maksymalna ilość obozów zagranicznych"
																				name={[name, "max_camps_abroad"]}
																				fieldKey={[fieldKey, 'max_camps_abroad']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={0}
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Maks. ilość dni dof. dla dzieci pełnosprawnych"
																				name={[name, "max_days_ability"]}
																				fieldKey={[fieldKey, 'max_days_ability']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={0}
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Maks. ilość dni dof. dla dzieci niepełnosprawnych"
																				name={[name, "max_days_disability"]}
																				fieldKey={[fieldKey, 'max_days_disability']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={0}
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Maks. ilość dni dof. dla dziecka niepełnosprawnego stopień znaczny"
																				name={[name, "max_days_disability_high"]}
																				fieldKey={[fieldKey, 'max_days_disability_high']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={0}
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Minimalny % odpłatności dla dzieci pełnosprawnych"
																				name={[name, "payment_percent_ability"]}
																				fieldKey={[fieldKey, 'payment_percent_ability']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Minimalny % odpłatności dla dzieci niepełnosprawnych"
																				name={[name, "payment_percent_disability"]}
																				fieldKey={[fieldKey, 'payment_percent_disability']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Minimalny % odpłatności dla dziecka niepełnosprawnego stopień znaczny"
																				name={[name, "payment_percent_disability_high"]}
																				fieldKey={[fieldKey, 'payment_percent_disability_high']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="% odpłatności w przypadku śmierci rodzica"
																				name={[name, "payment_percent_died"]}
																				fieldKey={[fieldKey, 'payment_percent_died']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Maksymalny wiek dziecka pełnosprawnego"
																				name={[name, "max_age_ability"]}
																				fieldKey={[fieldKey, 'max_age_ability']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={0}
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Maksymalny wiek dziecka niepełnosprawnego"
																				name={[name, "max_age_disability"]}
																				fieldKey={[fieldKey, 'max_age_disability']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={0}
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Maksymalny wiek dziecka niepełnosprawnego stopień znaczny"
																				name={[name, "max_age_disability_high"]}
																				fieldKey={[fieldKey, 'max_age_disability_high']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={0}
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Czy dziecko się uczy (dot. dzieci pełnosprawnych)"
																				name={[name, "is_education_ability"]}
																				fieldKey={[fieldKey, 'is_education_ability']}
																				valuePropName="checked"
																			>
																				<Switch />
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Czy dziecko się uczy (dot. dzieci niepełnosprawnych)"
																				name={[name, "is_education_disability"]}
																				fieldKey={[fieldKey, 'is_education_disability']}
																				valuePropName="checked"
																			>
																				<Switch />
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Czy dziecko się uczy (dot. dziecka niepełnosprawnego stopień znaczny)"
																				name={[name, "is_education_disability_high"]}
																				fieldKey={[fieldKey, 'is_education_disability_high']}
																				valuePropName="checked"
																			>
																				<Switch />
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Rozliczenie w"
																				name={[name, "is_days_counter"]}
																				fieldKey={[fieldKey, 'is_days_counter']}
																				valuePropName="checked"
																			>
																				<Switch unCheckedChildren="W noclegach" checkedChildren="W dniach" />
																			</Form.Item>
																		</Col>
																	</Row>
																</Card>
															))}

															{ (null && (fields.length < null)) ?? true ? <Col span={24}><Form.Item className="mt-2">
																<Button type="dashed" onClick={ () => add() } block>
																	Dodaj pozycję
																</Button>
															</Form.Item></Col> : null }
														</React.Fragment>
													)}
												</Form.List>
											</Form.Item>
										</Col>
									</Row>
								],
								camps_rg17_green: [
									<Row key="camps_rg17_green_row" gutter={[16,16]}>
										<Col span={24}>
											<Form.Item
												name="reglimitgreencamps"
												initialValue={getInitialValue('reglimitgreencamps', 'campsData')}
											>
												<Form.List
													name="reglimitgreencamps"
												>
													{(fields, { add, remove }) => (
														<React.Fragment key="camps_rg17_green_fields">
															{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
																<Card
																	key={key}
																	style={idx ? {marginTop: 20} : {}}
																	extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
																>
																	<Row gutter={[16,16]}>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Stopnie edukacji"
																				name={[name, "dcteducation_ids"]}
																				fieldKey={[fieldKey, 'dcteducation_ids']}
																			>
																				<Select mode="multiple">
																					{prepareOptionsFromRelationData('dcteducations')}
																				</Select>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Stopnie niepełnosprawności"
																				name={[name, "dctdisability_ids"]}
																				fieldKey={[fieldKey, 'dctdisability_ids']}
																			>
																				<Select mode="multiple">
																					{prepareOptionsFromRelationData('dctdisabilities')}
																				</Select>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Średnie dochody od"
																				name={[name, "income_from"]}
																				fieldKey={[fieldKey, 'income_from']}
																				rules={[{ required: true, message: '[Wypoczynek dzieci i młodzieży] [Zielona szkoła - Ograniczenia] Proszę uzupełnić średnie dochody' }]}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Średnie dochody do"
																				name={[name, "income_to"]}
																				fieldKey={[fieldKey, 'income_to']}
																				rules={[{ required: true, message: '[Wypoczynek dzieci i młodzieży] [Zielona szkoła - Ograniczenia] Proszę uzupełnić średnie dochody' }]}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Kwota dopłaty do dnia/noclegu"
																				name={[name, "subsidy_quote_per_day"]}
																				fieldKey={[fieldKey, 'subsidy_quote_per_day']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Maksymalna ilość obozów zagranicznych"
																				name={[name, "max_camps_abroad"]}
																				fieldKey={[fieldKey, 'max_camps_abroad']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={0}
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Maks. ilość dni dof. dla dzieci pełnosprawnych"
																				name={[name, "max_days_ability"]}
																				fieldKey={[fieldKey, 'max_days_ability']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={0}
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Maks. ilość dni dof. dla dzieci niepełnosprawnych"
																				name={[name, "max_days_disability"]}
																				fieldKey={[fieldKey, 'max_days_disability']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={0}
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Maks. ilość dni dof. dla dziecka niepełnosprawnego stopień znaczny"
																				name={[name, "max_days_disability_high"]}
																				fieldKey={[fieldKey, 'max_days_disability_high']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={0}
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Minimalny % odpłatności dla dzieci pełnosprawnych"
																				name={[name, "payment_percent_ability"]}
																				fieldKey={[fieldKey, 'payment_percent_ability']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Minimalny % odpłatności dla dzieci niepełnosprawnych"
																				name={[name, "payment_percent_disability"]}
																				fieldKey={[fieldKey, 'payment_percent_disability']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Minimalny % odpłatności dla dziecka niepełnosprawnego stopień znaczny"
																				name={[name, "payment_percent_disability_high"]}
																				fieldKey={[fieldKey, 'payment_percent_disability_high']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="% odpłatności w przypadku śmierci rodzica"
																				name={[name, "payment_percent_died"]}
																				fieldKey={[fieldKey, 'payment_percent_died']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Maksymalny wiek dziecka pełnosprawnego"
																				name={[name, "max_age_ability"]}
																				fieldKey={[fieldKey, 'max_age_ability']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={0}
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Maksymalny wiek dziecka niepełnosprawnego"
																				name={[name, "max_age_disability"]}
																				fieldKey={[fieldKey, 'max_age_disability']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={0}
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Maksymalny wiek dziecka niepełnosprawnego stopień znaczny"
																				name={[name, "max_age_disability_high"]}
																				fieldKey={[fieldKey, 'max_age_disability_high']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={0}
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Czy dziecko się uczy (dot. dzieci pełnosprawnych)"
																				name={[name, "is_education_ability"]}
																				fieldKey={[fieldKey, 'is_education_ability']}
																				valuePropName="checked"
																			>
																				<Switch />
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Czy dziecko się uczy (dot. dzieci niepełnosprawnych)"
																				name={[name, "is_education_disability"]}
																				fieldKey={[fieldKey, 'is_education_disability']}
																				valuePropName="checked"
																			>
																				<Switch />
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Czy dziecko się uczy (dot. dziecka niepełnosprawnego stopień znaczny)"
																				name={[name, "is_education_disability_high"]}
																				fieldKey={[fieldKey, 'is_education_disability_high']}
																				valuePropName="checked"
																			>
																				<Switch />
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Rozliczenie w"
																				name={[name, "is_days_counter"]}
																				fieldKey={[fieldKey, 'is_days_counter']}
																				valuePropName="checked"
																			>
																				<Switch unCheckedChildren="W noclegach" checkedChildren="W dniach" />
																			</Form.Item>
																		</Col>
																	</Row>
																</Card>
															))}

															{ (null && (fields.length < null)) ?? true ? <Col span={24}><Form.Item className="mt-2">
																<Button type="dashed" onClick={ () => add() } block>
																	Dodaj pozycję
																</Button>
															</Form.Item></Col> : null }
														</React.Fragment>
													)}
												</Form.List>
											</Form.Item>
										</Col>
									</Row>
								],
								camps_rg17_special: [
									<Row key="camps_rg17_special_row" gutter={[16,16]}>
										<Col span={24}>
											<Form.Item
												name="reglimitspecialcamps"
												initialValue={getInitialValue('reglimitspecialcamps', 'campsData')}
											>
												<Form.List
													name="reglimitspecialcamps"
												>
													{(fields, { add, remove }) => (
														<React.Fragment key="camps_rg17_special_fields">
															{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
																<Card
																	key={key}
																	style={idx ? {marginTop: 20} : {}}
																	extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
																>
																	<Row gutter={[16,16]}>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Stopnie edukacji"
																				name={[name, "dcteducation_ids"]}
																				fieldKey={[fieldKey, 'dcteducation_ids']}
																			>
																				<Select mode="multiple">
																					{prepareOptionsFromRelationData('dcteducations')}
																				</Select>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Stopnie niepełnosprawności"
																				name={[name, "dctdisability_ids"]}
																				fieldKey={[fieldKey, 'dctdisability_ids']}
																			>
																				<Select mode="multiple">
																					{prepareOptionsFromRelationData('dctdisabilities')}
																				</Select>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Średnie dochody od"
																				name={[name, "income_from"]}
																				fieldKey={[fieldKey, 'income_from']}
																				rules={[{ required: true, message: '[Wypoczynek dzieci i młodzieży] [Wypoczynek specjalistyczny - Ograniczenia] Proszę uzupełnić średnie dochody' }]}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Średnie dochody do"
																				name={[name, "income_to"]}
																				fieldKey={[fieldKey, 'income_to']}
																				rules={[{ required: true, message: '[Wypoczynek dzieci i młodzieży] [Wypoczynek specjalistyczny - Ograniczenia] Proszę uzupełnić średnie dochody' }]}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Kwota dopłaty do dnia/noclegu"
																				name={[name, "subsidy_quote_per_day"]}
																				fieldKey={[fieldKey, 'subsidy_quote_per_day']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Maksymalna ilość obozów zagranicznych"
																				name={[name, "max_camps_abroad"]}
																				fieldKey={[fieldKey, 'max_camps_abroad']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={0}
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Maks. ilość dni dof. dla dzieci pełnosprawnych / opiekunów dzieci"
																				name={[name, "max_days_ability"]}
																				fieldKey={[fieldKey, 'max_days_ability']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={0}
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Maks. ilość dni dof. dla dzieci niepełnosprawnych"
																				name={[name, "max_days_disability"]}
																				fieldKey={[fieldKey, 'max_days_disability']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={0}
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Maks. ilość dni dof. dla dziecka niepełnosprawnego stopień znaczny"
																				name={[name, "max_days_disability_high"]}
																				fieldKey={[fieldKey, 'max_days_disability_high']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={0}
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Minimalny % odpłatności dla dzieci pełnosprawnych / opiekunów dzieci"
																				name={[name, "payment_percent_ability"]}
																				fieldKey={[fieldKey, 'payment_percent_ability']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Minimalny % odpłatności dla dzieci niepełnosprawnych"
																				name={[name, "payment_percent_disability"]}
																				fieldKey={[fieldKey, 'payment_percent_disability']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Minimalny % odpłatności dla dziecka niepełnosprawnego stopień znaczny"
																				name={[name, "payment_percent_disability_high"]}
																				fieldKey={[fieldKey, 'payment_percent_disability_high']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="% odpłatności w przypadku śmierci rodzica"
																				name={[name, "payment_percent_died"]}
																				fieldKey={[fieldKey, 'payment_percent_died']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Maksymalny wiek dziecka pełnosprawnego"
																				name={[name, "max_age_ability"]}
																				fieldKey={[fieldKey, 'max_age_ability']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={0}
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Maksymalny wiek dziecka niepełnosprawnego"
																				name={[name, "max_age_disability"]}
																				fieldKey={[fieldKey, 'max_age_disability']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={0}
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Maksymalny wiek dziecka niepełnosprawnego stopień znaczny"
																				name={[name, "max_age_disability_high"]}
																				fieldKey={[fieldKey, 'max_age_disability_high']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={0}
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Czy dziecko się uczy (dot. dzieci pełnosprawnych)"
																				name={[name, "is_education_ability"]}
																				fieldKey={[fieldKey, 'is_education_ability']}
																				valuePropName="checked"
																			>
																				<Switch />
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Czy dziecko się uczy (dot. dzieci niepełnosprawnych)"
																				name={[name, "is_education_disability"]}
																				fieldKey={[fieldKey, 'is_education_disability']}
																				valuePropName="checked"
																			>
																				<Switch />
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Czy dziecko się uczy (dot. dziecka niepełnosprawnego stopień znaczny)"
																				name={[name, "is_education_disability_high"]}
																				fieldKey={[fieldKey, 'is_education_disability_high']}
																				valuePropName="checked"
																			>
																				<Switch />
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Rozliczenie w"
																				name={[name, "is_days_counter"]}
																				fieldKey={[fieldKey, 'is_days_counter']}
																				valuePropName="checked"
																			>
																				<Switch unCheckedChildren="W noclegach" checkedChildren="W dniach" />
																			</Form.Item>
																		</Col>
																	</Row>
																</Card>
															))}

															{ (null && (fields.length < null)) ?? true ? <Col span={24}><Form.Item className="mt-2">
																<Button type="dashed" onClick={ () => add() } block>
																	Dodaj pozycję
																</Button>
															</Form.Item></Col> : null }
														</React.Fragment>
													)}
												</Form.List>
											</Form.Item>
										</Col>
									</Row>
								],
							}}
							tabList={[
								{ key: 'camps_rg17_regular', tab: 'Obozy kolonie - Ograniczenia' },
								{ key: 'camps_rg17_green', tab: 'Zielona szkoła - Ograniczenia' },
								{ key: 'camps_rg17_special', tab: 'Wypoczynek specjalistyczny - Ograniczenia' },
							]}
							activeTabKey={currentActiveTab_camps}
							onTabChange={(key) => {
								setCurrentActiveTab_camps(key)
							}}
							forceRender={true}
							className="mt-1" />

						<CardCollapse
							title="Karty Sportowe"
							key="multisCard"
							loading={loading}
							collapsed={isCollapsed_multis}
							onCollapse={(collapsed) => {
								setCollapsed_multis(collapsed);
							}}
							tabContent={{
								multis_rg10: [
									<Row key="multis_rg10_row" gutter={[16,16]}>
										<Col span={24}>
											<Form.Item
												name="reglimitmultis"
												initialValue={getInitialValue('reglimitmultis')}
											>
												<Form.List
													name="reglimitmultis"
												>
													{(fields, { add, remove }) => (
														<React.Fragment key="multis_rg10_fields">
															{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
																<Card
																	key={key}
																	style={idx ? {marginTop: 20} : {}}
																	extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
																>
																	<Row gutter={[16,16]}>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Średnie dochody od"
																				name={[name, "income_from"]}
																				fieldKey={[fieldKey, 'income_from']}
																				rules={[{ required: true, message: '[Karty Sportowe] [Progi dochodowe] Proszę uzupełnić średnie dochody' }]}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Średnie dochody do"
																				name={[name, "income_to"]}
																				fieldKey={[fieldKey, 'income_to']}
																				rules={[{ required: true, message: '[Karty Sportowe] [Progi dochodowe] Proszę uzupełnić średnie dochody' }]}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col span={24}>
																			<Collapse>
																				<Collapse.Panel header="Pracownik">
																					<Row gutter={[16,16]}>
																						<Col lg={24} xl={12}>
																							<Form.Item
																								{...restField}
																								label="% dofinansowania dla pracownika"
																								name={[name, "employee_percent"]}
																								fieldKey={[fieldKey, 'employee_percent']}
																							>
																								<InputNumber
																									style={{ width: '100%' }}
																									precision={2}
																									decimalSeparator="."
																								/>
																							</Form.Item>
																						</Col>
																						<Col lg={24} xl={12}>
																							<Form.Item
																								{...restField}
																								label="Kwota dofinansowania dla pracownika"
																								name={[name, "employee_quote"]}
																								fieldKey={[fieldKey, 'employee_quote']}
																							>
																								<InputNumber
																									style={{ width: '100%' }}
																									precision={2}
																									decimalSeparator="."
																								/>
																							</Form.Item>
																						</Col>
																					</Row>
																				</Collapse.Panel>
																				<Collapse.Panel header="Emeryt">
																					<Row gutter={[16,16]}>
																						<Col lg={24} xl={12}>
																							<Form.Item
																								{...restField}
																								label="% dofinansowania dla emeryta"
																								name={[name, "pensioner_percent"]}
																								fieldKey={[fieldKey, 'pensioner_percent']}
																							>
																								<InputNumber
																									style={{ width: '100%' }}
																									precision={2}
																									decimalSeparator="."
																								/>
																							</Form.Item>
																						</Col>
																						<Col lg={24} xl={12}>
																							<Form.Item
																								{...restField}
																								label="Kwota dofinansowania dla emeryta"
																								name={[name, "pensioner_quote"]}
																								fieldKey={[fieldKey, 'pensioner_quote']}
																							>
																								<InputNumber
																									style={{ width: '100%' }}
																									precision={2}
																									decimalSeparator="."
																								/>
																							</Form.Item>
																						</Col>
																					</Row>
																				</Collapse.Panel>
																			</Collapse>
																		</Col>
																	</Row>
																</Card>
															))}

															{ (null && (fields.length < null)) ?? true ? <Col span={24}><Form.Item className="mt-2">
																<Button type="dashed" onClick={ () => add() } block>
																	Dodaj pozycję
																</Button>
															</Form.Item></Col> : null }
														</React.Fragment>
													)}
												</Form.List>
											</Form.Item>
										</Col>
									</Row>
								],
								multis_rg14: [
									<Row key="multis_rg14_row" gutter={[16,16]}>
										<Col span={24}>
											<Form.Item
												name="regperiodmultis"
												initialValue={getInitialValue('regperiodmultis')}
											>
												<Form.List
													name="regperiodmultis"
												>
													{(fields, { add, remove }) => (
														<React.Fragment key="multis_rg14_fields">
															{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
																<Card
																	key={key}
																	style={idx ? {marginTop: 20} : {}}
																	extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
																>
																	<Row gutter={[16,16]}>
																		<Col span={24}>
																			<Form.Item
																				{...restField}
																				label="Metoda płatności"
																				name={[name, 'dctpaymethod_id']}
																				fieldKey={[fieldKey, 'dctpaymethod_id']}
																			>
																				<Select>
																					{prepareOptionsFromRelationData('dctpaymethods')}
																				</Select>
																			</Form.Item>
																		</Col>
																		{['styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 'lipiec', 'sierpień', 'wrzesień', 'październik', 'listopad', 'grudzień'].map((month, idx) => (
																			<Col key={['multis_rg14_col', idx]} lg={24} xl={12}>
																				<Form.Item
																					{...restField}
																					label={`Ostatni dzień terminu (${month})`}
																					name={[name, `month_${idx + 1}`]}
																					fieldKey={[fieldKey, `month_${idx + 1}`]}
																				>
																					<InputNumber
																						style={{ width: '100%' }}
																						precision={0}
																					/>
																				</Form.Item>
																			</Col>
																		))}
																	</Row>
																</Card>
															))}

															{ (null && (fields.length < null)) ?? true ? <Col span={24}><Form.Item className="mt-2">
																<Button type="dashed" onClick={ () => add() } block>
																	Dodaj pozycję
																</Button>
															</Form.Item></Col> : null }
														</React.Fragment>
													)}
												</Form.List>
											</Form.Item>
										</Col>
									</Row>
								],
								multis_rg141: [
									<Row key="multis_rg141_row" gutter={[16,16]}>
										<Col span={24}>
											<Form.Item
												name="regparammultis"
												initialValue={getInitialValue('regparammultis')}
											>
												<Form.List
													name="regparammultis"
												>
													{(fields, { add, remove }) => (
														<React.Fragment key="multis_rg141_fields">
															{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
																<Card
																	key={key}
																	style={idx ? {marginTop: 20} : {}}
																	extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
																>
																	<Row gutter={[16,16]}>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Maksymalna ilość dorosłych na wniosek"
																				name={[name, "max_adults"]}
																				fieldKey={[fieldKey, 'max_adults']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={0}
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Maksymalna ilość dzieci na wniosek"
																				name={[name, "max_children"]}
																				fieldKey={[fieldKey, 'max_children']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={0}
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={24}>
																			<Form.Item
																				{...restField}
																				label="Operator karty"
																				name={[name, "dctmultioperator_id"]}
																				fieldKey={[fieldKey, 'dctmultioperator_id']}
																				rules={[{ required: false }, ({ getFieldValue }) => ({
																					validator(rule, value) {
																						const dctmultioperatorIds = new Set();
																						if (value && getFieldValue('regparammultis')) {
																							for (const ob of getFieldValue('regparammultis')) {
																								if(ob !== null && typeof ob !== 'undefined') {
																									if (dctmultioperatorIds.has(ob.dctmultioperator_id)) {
																										return Promise.reject('Operator karty nie może się powtarzać!');
																									}
																									dctmultioperatorIds.add(ob.dctmultioperator_id);
																								}
																						  	}
																							return Promise.resolve();
																						}
																					}
																				})]}
																			>
																				<Select>
																					{prepareOptionsFromRelationData('dctmultioperators')}
																				</Select>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Pracownik jest uczestnikiem"
																				name={[name, "is_employee_member"]}
																				fieldKey={[fieldKey, 'is_employee_member']}
																				valuePropName="checked"
																			>
																				<Switch />
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Jeden rodzaj kart w jednym okresie rozliczeniowym"
																				name={[name, "is_same_cards"]}
																				fieldKey={[fieldKey, 'is_same_cards']}
																				valuePropName="checked"
																			>
																				<Switch />
																			</Form.Item>
																		</Col>
																	</Row>
																</Card>
															))}

															{ (null && (fields.length < null)) ?? true ? <Col span={24}><Form.Item className="mt-2">
																<Button type="dashed" onClick={ () => add() } block>
																	Dodaj pozycję
																</Button>
															</Form.Item></Col> : null }
														</React.Fragment>
													)}
												</Form.List>
											</Form.Item>
										</Col>
									</Row>
								],
								multis_rg00: [
									<Row key="multis_rg00_row" gutter={[16,16]}>
										<Col span={24}>
											<Form.Item
												name="regdctmultis"
												initialValue={getInitialValue('regdctmultis')}
											>
												<Form.List
													name="regdctmultis"
												>
													{(fields, { add, remove }) => (
														<React.Fragment key="multis_rg00_fields">
															{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
																<Card
																	key={key}
																	style={idx ? {marginTop: 20} : {}}
																	extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
																>
																	<Row gutter={[16,16]}>
																		<Col lg={24} xl={9}>
																			<Form.Item
																				{...restField}
																				label="Rodzaj karty"
																				name={[name, "dctmultitype_id"]}
																				fieldKey={[fieldKey, 'dctmultitype_id']}
																			>
																				<Select>
																					{prepareOptionsFromRelationData('dctmultitypes')}
																				</Select>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={9}>
																			<Form.Item
																				{...restField}
																				label="Operator karty"
																				name={[name, "dctmultioperator_id"]}
																				fieldKey={[fieldKey, 'dctmultioperator_id']}
																			>
																				<Select>
																					{prepareOptionsFromRelationData('dctmultioperators')}
																				</Select>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={6}>
																			<Form.Item
																				{...restField}
																				label="Cena karty"
																				name={[name, "card_price"]}
																				fieldKey={[fieldKey, 'card_price']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																	</Row>
																</Card>
															))}

															{ (null && (fields.length < null)) ?? true ? <Col span={24}><Form.Item className="mt-2">
																<Button type="dashed" onClick={ () => add() } block>
																	Dodaj pozycję
																</Button>
															</Form.Item></Col> : null }
														</React.Fragment>
													)}
												</Form.List>
											</Form.Item>
										</Col>
									</Row>
								],
							}}
							tabList={[
								{ key: 'multis_rg10', tab: 'Progi dochodowe' },
								{ key: 'multis_rg14', tab: 'Definicje okresów' },
								{ key: 'multis_rg141', tab: 'Ograniczenia' },
								{ key: 'multis_rg00', tab: 'Słownik' },
							]}
							activeTabKey={currentActiveTab_multis}
							onTabChange={(key) => {
								setCurrentActiveTab_multis(key)
							}}
							forceRender={true}
							className="mt-1" />

						<CardCollapse
							title="Pożyczki"
							key="loansCard"
							loading={loading}
							collapsed={isCollapsed_loans}
							onCollapse={(collapsed) => {
								setCollapsed_loans(collapsed);
							}}
							tabContent={{
								loans_rg29: [
									<Row key="loans_rg29_row" gutter={[16,16]}>
										<Col span={24}>
											<Form.Item
												name="regperiodloans"
												initialValue={getInitialValue('regperiodloans')}
											>
												<Form.List
													name="regperiodloans"
												>
													{(fields, { add, remove }) => (
														<React.Fragment key="loans_rg29_fields">
															{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
																<Card
																	key={key}
																	style={idx ? {marginTop: 20} : {}}
																	extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
																>
																	<Row gutter={[16,16]}>
																		{['styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 'lipiec', 'sierpień', 'wrzesień', 'październik', 'listopad', 'grudzień'].map((month, idx) => (
																			<Col key={['loans_rg29_col', idx]} lg={24} xl={12}>
																				<Form.Item
																					{...restField}
																					label={`Ostatni dzień terminu (${month})`}
																					name={[name, `month_${idx + 1}`]}
																					fieldKey={[fieldKey, `month_${idx + 1}`]}
																				>
																					<InputNumber
																						style={{ width: '100%' }}
																						precision={0}
																					/>
																				</Form.Item>
																			</Col>
																		))}
																	</Row>
																</Card>
															))}

															{ (null && (fields.length < null)) ?? true ? <Col span={24}><Form.Item className="mt-2">
																<Button type="dashed" onClick={ () => add() } block>
																	Dodaj pozycję
																</Button>
															</Form.Item></Col> : null }
														</React.Fragment>
													)}
												</Form.List>
											</Form.Item>
										</Col>
									</Row>
								],
								loans_rg00: [
									<Row key="loans_rg00_row" gutter={[16,16]}>
										<Col span={24}>
											<Form.Item
												name="reglimitloans"
												initialValue={getInitialValue('reglimitloans')}
											>
												<Form.List
													name="reglimitloans"
												>
													{(fields, { add, remove }) => (
														<React.Fragment key="loans_rg00_fields">
															{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
																<Card
																	key={key}
																	style={idx ? {marginTop: 20} : {}}
																	extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
																>
																	<Row gutter={[16,16]}>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Średnie dochody od"
																				name={[name, "income_from"]}
																				fieldKey={[fieldKey, 'income_from']}
																				rules={[{ required: true, message: '[Pożyczki] [Progi dochodowe] Proszę uzupełnić średnie dochody' }]}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Średnie dochody do"
																				name={[name, "income_to"]}
																				fieldKey={[fieldKey, 'income_to']}
																				rules={[{ required: true, message: '[Pożyczki] [Progi dochodowe] Proszę uzupełnić średnie dochody' }]}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Cel pożyczki"
																				name={[name, "dctloanpurpose_id"]}
																				fieldKey={[fieldKey, 'dctloanpurpose_id']}
																			>
																				<Select>
																					{prepareOptionsFromRelationData('dctloanpurposes')}
																				</Select>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Oprocentowanie"
																				name={[name, "percentage"]}
																				fieldKey={[fieldKey, 'percentage']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																	</Row>
																</Card>
															))}

															{ (null && (fields.length < null)) ?? true ? <Col span={24}><Form.Item className="mt-2">
																<Button type="dashed" onClick={ () => add() } block>
																	Dodaj pozycję
																</Button>
															</Form.Item></Col> : null }
														</React.Fragment>
													)}
												</Form.List>
											</Form.Item>
										</Col>
									</Row>
								],
								loans_rg03: [
									<Row key="loans_rg03_row" gutter={[16,16]}>
										<Col span={24}>
											<Form.Item
												name="regdctloans"
												initialValue={getInitialValue('regdctloans')}
											>
												<Form.List
													name="regdctloans"
												>
													{(fields, { add, remove }) => (
														<React.Fragment key="loans_rg03_fields">
															{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
																<Card
																	key={key}
																	style={idx ? {marginTop: 20} : {}}
																	extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
																>
																	<Row gutter={[16,16]}>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Cel pożyczki"
																				name={[name, "dctloanpurpose_id"]}
																				fieldKey={[fieldKey, 'dctloanpurpose_id']}
																			>
																				<Select>
																					{prepareOptionsFromRelationData('dctloanpurposes')}
																				</Select>
																			</Form.Item>
																		</Col>
																	</Row>
																</Card>
															))}

															{ (null && (fields.length < null)) ?? true ? <Col span={24}><Form.Item className="mt-2">
																<Button type="dashed" onClick={ () => add() } block>
																	Dodaj pozycję
																</Button>
															</Form.Item></Col> : null }
														</React.Fragment>
													)}
												</Form.List>
											</Form.Item>
										</Col>
									</Row>
								],
							}}
							tabList={[
								{ key: 'loans_rg29', tab: 'Definicje okresów' },
								{ key: 'loans_rg00', tab: 'Progi dochodowe' },
								{ key: 'loans_rg03', tab: 'Słownik' }
							]}
							activeTabKey={currentActiveTab_loans}
							onTabChange={(key) => {
								setCurrentActiveTab_loans(key)
							}}
							forceRender={true}
							className="mt-1" />

						<CardCollapse
							title="Przedszkola i żłobki"
							key="infantsCard"
							loading={loading}
							collapsed={isCollapsed_infants}
							onCollapse={(collapsed) => {
								setCollapsed_infants(collapsed);
							}}
							tabContent={{
								infants_rg01: [
									<Row key="infants_rg01_row" gutter={[16,16]}>
										<Col span={24}>
											<Form.Item
												name="regperiodinfants"
												initialValue={getInitialValue('regperiodinfants')}
											>
												<Form.List
													name="regperiodinfants"
												>
													{(fields, { add, remove }) => (
														<React.Fragment key="infants_rg01_fields">
															{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
																<Card
																	key={key}
																	style={idx ? {marginTop: 20} : {}}
																	extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
																>
																	<Row gutter={[16,16]}>
																		{['styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 'lipiec', 'sierpień', 'wrzesień', 'październik', 'listopad', 'grudzień'].map((month, idx) => (
																			<Col key={['infants_rg01_col', idx]} lg={24} xl={12}>
																				<Form.Item
																					{...restField}
																					label={`Ostatni dzień terminu (${month})`}
																					name={[name, `month_${idx + 1}`]}
																					fieldKey={[fieldKey, `month_${idx + 1}`]}
																				>
																					<InputNumber
																						style={{ width: '100%' }}
																						precision={0}
																					/>
																				</Form.Item>
																			</Col>
																		))}
																	</Row>
																</Card>
															))}

															{ (null && (fields.length < null)) ?? true ? <Col span={24}><Form.Item className="mt-2">
																<Button type="dashed" onClick={ () => add() } block>
																	Dodaj pozycję
																</Button>
															</Form.Item></Col> : null }
														</React.Fragment>
													)}
												</Form.List>
											</Form.Item>
										</Col>
									</Row>
								],
								infants_rg02: [
									<Row key="infants_rg02_row" gutter={[16,16]}>
										<Col span={24}>
											<Form.Item
												name="reglimitinfants"
												initialValue={getInitialValue('reglimitinfants', 'infantsData')}
											>
												<Form.List
													name="reglimitinfants"
												>
													{(fields, { add, remove }) => (
														<React.Fragment key="infants_rg02_fields">
															{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
																<Card
																	key={key}
																	style={idx ? {marginTop: 20} : {}}
																	extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
																>
																	<Row gutter={[16,16]}>
																		<Col span={24}>
																			<Form.Item
																				{...restField}
																				label="Rodzaj obiektu"
																				name={[name, "infant_levels"]}
																				fieldKey={[fieldKey, 'infant_levels']}
																			>
																				<Select mode="multiple">
																					<Select.Option value="P">Przedszkole</Select.Option>
																					<Select.Option value="Z">Żłobek</Select.Option>
																				</Select>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Średnie dochody od"
																				name={[name, "income_from"]}
																				fieldKey={[fieldKey, 'income_from']}
																				rules={[{ required: true, message: '[Przedszkola i żłobki] [Ograniczenia] Proszę uzupełnić średnie dochody' }]}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Średnie dochody do"
																				name={[name, "income_to"]}
																				fieldKey={[fieldKey, 'income_to']}
																				rules={[{ required: true, message: '[Przedszkola i żłobki] [Ograniczenia] Proszę uzupełnić średnie dochody' }]}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Kwota dofinansowania"
																				name={[name, "subsidy_quote"]}
																				fieldKey={[fieldKey, 'subsidy_quote']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Maksymalne dofinansowanie procentowe"
																				name={[name, "threshold_percent"]}
																				fieldKey={[fieldKey, 'threshold_percent']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																	</Row>
																</Card>
															))}

															{ (null && (fields.length < null)) ?? true ? <Col span={24}><Form.Item className="mt-2">
																<Button type="dashed" onClick={ () => add() } block>
																	Dodaj pozycję
																</Button>
															</Form.Item></Col> : null }
														</React.Fragment>
													)}
												</Form.List>
											</Form.Item>
										</Col>
									</Row>
								],
							}}
							tabList={[
								{ key: 'infants_rg01', tab: 'Definicje okresów' },
								{ key: 'infants_rg02', tab: 'Ograniczenia' },
							]}
							activeTabKey={currentActiveTab_infants}
							onTabChange={(key) => {
								setCurrentActiveTab_infants(key)
							}}
							forceRender={true}
							className="mt-1" />

						<CardCollapse
							title="Świadczenia turystyczno-rekreacyjne"
							key="touristsCard"
							loading={loading}
							collapsed={isCollapsed_tourists}
							onCollapse={(collapsed) => {
								setCollapsed_tourists(collapsed);
							}}
							tabContent={{
								tourists_rg01: [
									<Row key="tourists_rg02_row" gutter={[16,16]}>
										<Col span={24}>
											<Form.Item
												name="reglimittourists"
												initialValue={getInitialValue('reglimittourists')}
											>
												<Form.List
													name="reglimittourists"
												>
													{(fields, { add, remove }) => (
														<React.Fragment key="tourists_rg01_fields">
															{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
																<Card
																	key={key}
																	style={idx ? {marginTop: 20} : {}}
																	extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
																>
																	<Row gutter={[16,16]}>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Średnie dochody od"
																				name={[name, "income_from"]}
																				fieldKey={[fieldKey, 'income_from']}
																				rules={[{ required: true, message: '[Świadczenia tur-rek] [Progi dochodowe] Proszę uzupełnić średnie dochody' }]}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Średnie dochody do"
																				name={[name, "income_to"]}
																				fieldKey={[fieldKey, 'income_to']}
																				rules={[{ required: true, message: '[Świadczenia tur-rek] [Progi dochodowe] Proszę uzupełnić średnie dochody' }]}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>

																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Kwota dofinansowania dla jednego dnia"
																				name={[name, "tourist_day_subsidy"]}
																				fieldKey={[fieldKey, 'tourist_day_subsidy']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Ilość dni/noclegów świadczenia turystycznego"
																				name={[name, "tourist_days"]}
																				fieldKey={[fieldKey, 'tourist_days']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={0}
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Ilość świadczeń turystycznych"
																				name={[name, "tourist_numbers"]}
																				fieldKey={[fieldKey, 'tourist_numbers']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={0}
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Minimalny procent odpłatności"
																				name={[name, "min_payment_percent"]}
																				fieldKey={[fieldKey, 'min_payment_percent']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Rozliczenie w"
																				name={[name, "is_days_counter"]}
																				fieldKey={[fieldKey, 'is_days_counter']}
																				valuePropName="checked"
																			>
																				<Switch unCheckedChildren="W noclegach" checkedChildren="W dniach" />
																			</Form.Item>
																		</Col>

																		<Col span={24}>
																			<Collapse>
																				<Collapse.Panel header="Pracownik">
																					<Row gutter={[16,16]}>
																						<Col lg={24} xl={12}>
																							<Form.Item
																								{...restField}
																								label="% dofinansowania do warunków szczególnych (12 LAT/48 M-CY/ niepełn. st. umiarkowany)"
																								name={[name, "special_percent_employee"]}
																								fieldKey={[fieldKey, 'special_percent_employee']}
																							>
																								<InputNumber
																									style={{ width: '100%' }}
																									precision={2}
																									decimalSeparator="."
																								/>
																							</Form.Item>
																						</Col>
																						<Col lg={24} xl={12}>
																							<Form.Item
																								{...restField}
																								label="% dofinansowania do niepełnosprawności (POWYŻEJ 50 LAT/ niepełn. st. umiarkowany)"
																								name={[name, "disability_percent_employee"]}
																								fieldKey={[fieldKey, 'disability_percent_employee']}
																							>
																								<InputNumber
																									style={{ width: '100%' }}
																									precision={2}
																									decimalSeparator="."
																								/>
																							</Form.Item>
																						</Col>
																					</Row>
																				</Collapse.Panel>
																				<Collapse.Panel header="Emeryt">
																					<Row gutter={[16,16]}>
																						<Col lg={24} xl={12}>
																							<Form.Item
																								{...restField}
																								label="% dofinansowania do warunków szczególnych (12 LAT/48 M-CY/ niepełn. st. umiarkowany)"
																								name={[name, "special_percent_pensioner"]}
																								fieldKey={[fieldKey, 'special_percent_pensioner']}
																							>
																								<InputNumber
																									style={{ width: '100%' }}
																									precision={2}
																									decimalSeparator="."
																								/>
																							</Form.Item>
																						</Col>
																						<Col lg={24} xl={12}>
																							<Form.Item
																								{...restField}
																								label="% dofinansowania do niepełnosprawności (POWYŻEJ 50 LAT/ niepełn. st. umiarkowany)"
																								name={[name, "disability_percent_pensioner"]}
																								fieldKey={[fieldKey, 'disability_percent_pensioner']}
																							>
																								<InputNumber
																									style={{ width: '100%' }}
																									precision={2}
																									decimalSeparator="."
																								/>
																							</Form.Item>
																						</Col>
																					</Row>
																				</Collapse.Panel>
																			</Collapse>
																		</Col>
																	</Row>
																</Card>
															))}

															{ (null && (fields.length < null)) ?? true ? <Col span={24}><Form.Item className="mt-2">
																<Button type="dashed" onClick={ () => add() } block>
																	Dodaj pozycję
																</Button>
															</Form.Item></Col> : null }
														</React.Fragment>
													)}
												</Form.List>
											</Form.Item>
										</Col>
									</Row>
								],
							}}
							tabList={[
								{ key: 'tourists_rg01', tab: 'Progi dochodowe' },
							]}
							activeTabKey={currentActiveTab_tourists}
							onTabChange={(key) => {
								setCurrentActiveTab_tourists(key)
							}}
							forceRender={true}
							className="mt-1" />

						<CardCollapse
							title="Zajęcia rekreacyjne"
							key="rehabsCard"
							loading={loading}
							collapsed={isCollapsed_rehabs}
							onCollapse={(collapsed) => {
								setCollapsed_rehabs(collapsed);
							}}
							tabContent={{
								rehabs_rg01: [
									<Row key="rehabs_rg01_row" gutter={[16,16]}>
										<Col span={24}>
											<Form.Item
												name="reglimitrehabs"
												initialValue={getInitialValue('reglimitrehabs')}
											>
												<Form.List
													name="reglimitrehabs"
												>
													{(fields, { add, remove }) => (
														<React.Fragment key="rehabs_rg01_fields">
															{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
																<Card
																	key={key}
																	style={idx ? {marginTop: 20} : {}}
																	extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
																>
																	<Row gutter={[16,16]}>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Średnie dochody od"
																				name={[name, "income_from"]}
																				fieldKey={[fieldKey, 'income_from']}
																				rules={[{ required: true, message: '[Zajęcia rekreacyjne] [Progi dochodowe] Proszę uzupełnić średnie dochody' }]}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Średnie dochody do"
																				name={[name, "income_to"]}
																				fieldKey={[fieldKey, 'income_to']}
																				rules={[{ required: true, message: '[Zajęcia rekreacyjne] [Progi dochodowe] Proszę uzupełnić średnie dochody' }]}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>

																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Ilość zajęć rekreacyjnych"
																				name={[name, "rehab_numbers"]}
																				fieldKey={[fieldKey, 'rehab_numbers']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={0}
																				/>
																			</Form.Item>
																		</Col>

																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Kwota do dofinansowania"
																				name={[name, "rehab_subsidy"]}
																				fieldKey={[fieldKey, 'rehab_subsidy']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>

																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Minimalny procent odpłatności"
																				name={[name, "min_payment_percent"]}
																				fieldKey={[fieldKey, 'min_payment_percent']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>

																		<Col span={24}>
																			<Collapse>
																				<Collapse.Panel header="Pracownik">
																					<Row gutter={[16,16]}>
																						<Col lg={24} xl={12}>
																							<Form.Item
																								{...restField}
																								label="% dofinansowania do warunków szczególnych (12 LAT/48 M-CY/ niepełn. st. umiarkowany)"
																								name={[name, "special_percent_employee"]}
																								fieldKey={[fieldKey, 'special_percent_employee']}
																							>
																								<InputNumber
																									style={{ width: '100%' }}
																									precision={2}
																									decimalSeparator="."
																								/>
																							</Form.Item>
																						</Col>
																						<Col lg={24} xl={12}>
																							<Form.Item
																								{...restField}
																								label="% dofinansowania do niepełnosprawności (POWYŻEJ 50 LAT/ niepełn. st. umiarkowany)"
																								name={[name, "disability_percent_employee"]}
																								fieldKey={[fieldKey, 'disability_percent_employee']}
																							>
																								<InputNumber
																									style={{ width: '100%' }}
																									precision={2}
																									decimalSeparator="."
																								/>
																							</Form.Item>
																						</Col>
																					</Row>
																				</Collapse.Panel>
																				<Collapse.Panel header="Emeryt">
																					<Row gutter={[16,16]}>
																						<Col lg={24} xl={12}>
																							<Form.Item
																								{...restField}
																								label="% dofinansowania do warunków szczególnych (12 LAT/48 M-CY/ niepełn. st. umiarkowany)"
																								name={[name, "special_percent_pensioner"]}
																								fieldKey={[fieldKey, 'special_percent_pensioner']}
																							>
																								<InputNumber
																									style={{ width: '100%' }}
																									precision={2}
																									decimalSeparator="."
																								/>
																							</Form.Item>
																						</Col>
																						<Col lg={24} xl={12}>
																							<Form.Item
																								{...restField}
																								label="% dofinansowania do niepełnosprawności (POWYŻEJ 50 LAT/ niepełn. st. umiarkowany)"
																								name={[name, "disability_percent_pensioner"]}
																								fieldKey={[fieldKey, 'disability_percent_pensioner']}
																							>
																								<InputNumber
																									style={{ width: '100%' }}
																									precision={2}
																									decimalSeparator="."
																								/>
																							</Form.Item>
																						</Col>
																					</Row>
																				</Collapse.Panel>
																			</Collapse>
																		</Col>
																	</Row>
																</Card>
															))}

															{ (null && (fields.length < null)) ?? true ? <Col span={24}><Form.Item className="mt-2">
																<Button type="dashed" onClick={ () => add() } block>
																	Dodaj pozycję
																</Button>
															</Form.Item></Col> : null }
														</React.Fragment>
													)}
												</Form.List>
											</Form.Item>
										</Col>
									</Row>
								],
							}}
							tabList={[
								{ key: 'rehabs_rg01', tab: 'Progi dochodowe' },
							]}
							activeTabKey={currentActiveTab_rehabs}
							onTabChange={(key) => {
								setCurrentActiveTab_rehabs(key)
							}}
							forceRender={true}
							className="mt-1" />

						<CardCollapse
							title="Zapomogi"
							key="grantsCard"
							loading={loading}
							collapsed={isCollapsed_grants}
							onCollapse={(collapsed) => {
								setCollapsed_grants(collapsed);
							}}
							tabContent={{
								grants_rg02: [
									<Row key="grant_rg02_row" gutter={[16,16]}>
										<Col span={24}>
											<Form.Item
												name="regperiodgrants"
												initialValue={getInitialValue('regperiodgrants')}
											>
												<Form.List
													name="regperiodgrants"
												>
													{(fields, { add, remove }) => (
														<React.Fragment key="grant_rg02_fields">
															{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
																<Card
																	key={key}
																	style={idx ? {marginTop: 20} : {}}
																	extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
																>
																	<Row gutter={[16,16]}>
																		{['styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 'lipiec', 'sierpień', 'wrzesień', 'październik', 'listopad', 'grudzień'].map((month, idx) => (
																			<Col key={['grants_rg02_col', idx]} lg={24} xl={12}>
																				<Form.Item
																					{...restField}
																					label={`Ostatni dzień terminu (${month})`}
																					name={[name, `month_${idx + 1}`]}
																					fieldKey={[fieldKey, `month_${idx + 1}`]}
																				>
																					<InputNumber
																						style={{ width: '100%' }}
																						precision={0}
																					/>
																				</Form.Item>
																			</Col>
																		))}
																	</Row>
																</Card>
															))}

															{ (1 && (fields.length < 1)) ?? true ? <Col span={24}><Form.Item className="mt-2">
																<Button type="dashed" onClick={ () => add() } block>
																	Dodaj pozycję
																</Button>
															</Form.Item></Col> : null }
														</React.Fragment>
													)}
												</Form.List>
											</Form.Item>
										</Col>
									</Row>
								],
								grants_rg03: [
									<Row key="grants_rg03_row" gutter={[16,16]}>
										<Col span={24}>
											<Form.Item
												name="regdctgrants"
												initialValue={getInitialValue('regdctgrants')}
											>
												<Form.List
													name="regdctgrants"
												>
													{(fields, { add, remove }) => (
														<React.Fragment key="grants_rg03_fields">
															{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
																<Card
																	key={key}
																	style={idx ? {marginTop: 20} : {}}
																	extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
																>
																	<Row gutter={[16,16]}>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Rodzaj zapomogi"
																				name={[name, "dctgranttype_id"]}
																				fieldKey={[fieldKey, 'dctgranttype_id']}
																			>
																				<Select>
																					{prepareOptionsFromRelationData('dctgranttypes')}
																				</Select>
																			</Form.Item>
																		</Col>
																	</Row>
																</Card>
															))}

															{ (null && (fields.length < null)) ?? true ? <Col span={24}><Form.Item className="mt-2">
																<Button type="dashed" onClick={ () => add() } block>
																	Dodaj pozycję
																</Button>
															</Form.Item></Col> : null }
														</React.Fragment>
													)}
												</Form.List>
											</Form.Item>
										</Col>
									</Row>
								],
							}}
							tabList={[
								{ key: 'grants_rg02', tab: 'Definicje okresów' },
								{ key: 'grants_rg03', tab: 'Słownik' },
							]}
							activeTabKey={currentActiveTab_grants}
							onTabChange={(key) => {
								setCurrentActiveTab_grants(key)
							}}
							forceRender={true}
							className="mt-1" />

						<CardCollapse
							title="Świadczenia dodatkowe"
							key="extrasCard"
							loading={loading}
							collapsed={isCollapsed_extras}
							onCollapse={(collapsed) => {
								setCollapsed_extras(collapsed);
							}}
							tabContent={{
								extras_rg01: [
									<Row key="extras_rg01_row" gutter={[16,16]}>
										<Col span={24}>
											<Form.Item
												name="reglimitextras"
												initialValue={getInitialValue('reglimitextras')}
											>
												<Form.List
													name="reglimitextras"
												>
													{(fields, { add, remove }) => (
														<React.Fragment key="extras_rg01_fields">
															{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
																<Card
																	key={key}
																	style={idx ? {marginTop: 20} : {}}
																	extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
																>
																	<Row gutter={[16,16]}>
																		<Col span={24}>
																			<Form.Item
																				{...restField}
																				label="Rodzaj świadczenia"
																				name={[name, "dctextratype_id"]}
																				fieldKey={[fieldKey, 'dctextratype_id']}
																			>
																				<Select>
																					{prepareOptionsFromRelationData('dctextratypes')}
																				</Select>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Średnie dochody od"
																				name={[name, "income_from"]}
																				fieldKey={[fieldKey, 'income_from']}
																				rules={[{ required: true, message: '[Świadczenia dodatkowe] [Progi dochodowe] Proszę uzupełnić średnie dochody' }]}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Średnie dochody do"
																				name={[name, "income_to"]}
																				fieldKey={[fieldKey, 'income_to']}
																				rules={[{ required: true, message: '[Świadczenia dodatkowe] [Progi dochodowe] Proszę uzupełnić średnie dochody' }]}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Procentowa wartość dofinansowania"
																				name={[name, "subsidy_percent"]}
																				fieldKey={[fieldKey, 'subsidy_percent']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Kwotowa wartość dofinansowania"
																				name={[name, "subsidy_quote"]}
																				fieldKey={[fieldKey, 'subsidy_quote']}
																			>
																				<InputNumber
																					style={{ width: '100%' }}
																					precision={2}
																					decimalSeparator="."
																				/>
																			</Form.Item>
																		</Col>
																	</Row>
																</Card>
															))}

															{ (null && (fields.length < null)) ?? true ? <Col span={24}><Form.Item className="mt-2">
																<Button type="dashed" onClick={ () => add() } block>
																	Dodaj pozycję
																</Button>
															</Form.Item></Col> : null }
														</React.Fragment>
													)}
												</Form.List>
											</Form.Item>
										</Col>
									</Row>
								],
								extras_rg02: [
									<Row key="extras_rg02_row" gutter={[16,16]}>
										<Col span={24}>
											<Form.Item
												name="regparamextras"
												initialValue={getInitialValue('regparamextras', 'extrasData')}
											>
												<Form.List
													name="regparamextras"
												>
													{(fields, { add, remove }) => (
														<React.Fragment key="extras_rg02_fields">
															{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
																<Card
																	key={key}
																	style={idx ? {marginTop: 20} : {}}
																	extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
																>
																	<Row gutter={[16,16]}>
																		<Col span={24}>
																			<Form.Item
																				{...restField}
																				label="Rodzaj świadczenia"
																				name={[name, "dctextratype_id"]}
																				fieldKey={[fieldKey, 'dctextratype_id']}
																			>
																				<Select>
																					{prepareOptionsFromRelationData('dctextratypes')}
																				</Select>
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Okres od"
																				name={[name, "period_from"]}
																				fieldKey={[fieldKey, 'period_from']}
																			>
																				<DatePicker style={{ width: '100%' }} />
																			</Form.Item>
																		</Col>
																		<Col lg={24} xl={12}>
																			<Form.Item
																				{...restField}
																				label="Okres do"
																				name={[name, "period_to"]}
																				fieldKey={[fieldKey, 'period_to']}
																			>
																				<DatePicker style={{ width: '100%' }} />
																			</Form.Item>
																		</Col>
																	</Row>
																</Card>
															))}

															{ (null && (fields.length < null)) ?? true ? <Col span={24}><Form.Item className="mt-2">
																<Button type="dashed" onClick={ () => add() } block>
																	Dodaj pozycję
																</Button>
															</Form.Item></Col> : null }
														</React.Fragment>
													)}
												</Form.List>
											</Form.Item>
										</Col>
									</Row>
								],
								extras_rg03: [
									<Row key="extras_rg03_row" gutter={[16,16]}>
										<Col span={24}>
											<Form.Item
												name="regdctextras"
												initialValue={getInitialValue('regdctextras')}
											>
												<Form.List
													name="regdctextras"
												>
													{(fields, { add, remove }) => (
														<React.Fragment key="extras_rg03_fields">
															{fields.map(({ key, name, fieldKey, ...restField }, idx) => (
																<Card
																	key={key}
																	style={idx ? {marginTop: 20} : {}}
																	extra={<Button onClick={() => { remove(name); }}>Usuń</Button>}
																>
																	<Row gutter={[16,16]}>
																		<Col span={24}>
																			<Form.Item
																				{...restField}
																				label="Rodzaj świadczenia"
																				name={[name, "dctextratype_id"]}
																				fieldKey={[fieldKey, 'dctextratype_id']}
																			>
																				<Select>
																					{prepareOptionsFromRelationData('dctextratypes')}
																				</Select>
																			</Form.Item>
																		</Col>
																	</Row>
																</Card>
															))}

															{ (null && (fields.length < null)) ?? true ? <Col span={24}><Form.Item className="mt-2">
																<Button type="dashed" onClick={ () => add() } block>
																	Dodaj pozycję
																</Button>
															</Form.Item></Col> : null }
														</React.Fragment>
													)}
												</Form.List>
											</Form.Item>
										</Col>
									</Row>
								],
							}}
							tabList={[
								{ key: 'extras_rg01', tab: 'Progi dochodowe' },
								{ key: 'extras_rg02', tab: 'Definicje okresów' },
								{ key: 'extras_rg03', tab: 'Słownik' },
							]}
							activeTabKey={currentActiveTab_extras}
							onTabChange={(key) => {
								setCurrentActiveTab_extras(key)
							}}
							forceRender={true}
							className="mt-1" />
					</Col>

					<Col sm={24} lg={6}>
						<Card loading={loading}>
							<Row>
								<Col span={24}>
									<Row className="form-group-row" gutter={[16,24]}>
										<Col span={24} className="form-group">
											<label>
												<strong>Data ostatniej aktualizacji</strong>
											</label>
											<span>{getViewValue('updated_at', 'datetime')}</span>
										</Col>

										<Col span={24} className="form-group">
											<label>
												<strong>Osoba aktualizująca</strong>
											</label>
											<span>{getViewValue('updater_full_name')}</span>
										</Col>

										<Col span={24} className="form-group">
											<label>
												<strong>Data utworzenia</strong>
											</label>
											<span>{getViewValue('created_at', 'date')}</span>
										</Col>
									</Row>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
			</div>
		</Form>
		:
		<Row style={{ marginBottom: '15px' }}>
			<Col sm={24}>
				<Alert type="error" message="Brak uprawnień do zasobu" />
			</Col>
		</Row>
		}
	</div>);
};

export default Dashboard_Regulations_General;
