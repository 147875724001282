import React from 'react';
import PropTypes from 'prop-types';

import Record_Field from './Field';

import { Row, Col, Button, Form, Input } from 'antd';

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import _ from 'lodash';

import moment from 'moment';

import { prepareFieldInput, prepareFieldValue } from './utils/Helper';

class RecordFieldTableRelation extends Record_Field {
	static propTypes = Object.assign({}, Record_Field.propTypes, {
		fields: PropTypes.array.isRequired,
		data: PropTypes.func,
		hideRow: PropTypes.func,
		filterRow: PropTypes.func,
		withHeader: PropTypes.bool
	});

	static defaultProps = Object.assign({}, Record_Field.defaultProps, {
		data: () => null,
		hideRow: () => null,
		filterRow: () => null,
		withHeader: false
	});

	static displayName = 'RecordFieldTableRelation';

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	shouldComponentUpdate(newProps, newState) {
		if(this.state.container.dataHash !== newState.container.dataHash) return true;

		return super.shouldComponentUpdate(newProps, newState);
	}

	getRawValue() {
		const {
			fields,
			data
		} = this.props;

		let value = super.getRawValue();

		if(typeof data === 'function') {
			let newData = data((value ?? []), this.data);

			if(newData !== null) {
				value = newData;
			}
		}
		return (value ?? []).map(el => {
			fields.map(_field => {
				if(el && el.hasOwnProperty(_field.name) && _field?.type === 'date') {
					let date = moment(el[_field.name]);
					el[_field.name] = date.isValid() ? date : null;
				}
			});

			return el;
		});
	}

	getValue() {
		const {
			fields,
			hideRow = () => null,
			filterRow = () => null
		} = this.props;

		return (
			<div className="ant-table" key={`tableRelation_${this.key}`}>
				<div className="ant-table-container">
					<div className="ant-table-content">
						<table style={{ tableLayout: 'auto' }}>
							<thead className="ant-table-thead">
								<tr>
									{fields.map((field, idx) => (<React.Fragment key={`theadCol~${idx}`}>
										{!field.isHidden ? <th className="ant-table-cell">{ field.title }</th> : null}
									</React.Fragment>))}
								</tr>
							</thead>
							<tbody className="ant-table-tbody">
								{
									this.getRawValue().map((row, _idx) => {
										if(filterRow(row)) {
											return null;
										}

										return (
											<React.Fragment key={`row~${_idx}`}>
												<tr className="ant-table-row">
													{fields.map((field, idx) => {
														let fieldValue = (row && row.hasOwnProperty(field.name)) ? row[field.name] : null;

														return (<React.Fragment key={`col~${idx}_${_idx}`}>
															{
																!field.isHidden ?
																	<td key={`fieldCol~${idx}_${_idx}`} className="ant-table-cell">{prepareFieldValue(field, fieldValue, this.relationData ?? {}, this.data ?? {}, row)}</td>
																	:
																	null
															}
														</React.Fragment>);
													})}
												</tr>
											</React.Fragment>
										);
									})
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}

	renderEdit() {
		const {
			title,
			name,
			disabled,

			fields,
			hideRow = () => null,
			filterRow = () => null,
			withHeader
		} = this.props;

		return <Form.Item
			{...this.globalItemProps}
		>
			<Form.List
				name={name}
				disabled={disabled}
			>
				{(_fields, { add, remove }) => (
					<React.Fragment key={`fieldsContainer`}>
						{
							withHeader
								? <Row align="middle" gutter={[8,8]}>
									{
										fields.map((field, idx) => (
											<Col key={['tableRelationHead', field.name, idx]} {...field?.span ?? {}}>
												{!field.isHidden ? <strong>{field.title}</strong> : null}
											</Col>)
										)
									}
								</Row>
								: null
						}
						<Row align="middle" gutter={[8,8]}>
							{_fields.map((field, idx) => {
								const _row = (this.getInitialValue()[field.name]) ? this.getInitialValue()[field.name] : {};

								if(filterRow(_row)) {
									return null;
								}

								return (
									<React.Fragment key={['row', field.fieldKey]}>
										<React.Fragment key={['innerRow', field.key]}>
											{fields.map((_field, _idx) => {
												let row = {};
												fields.forEach(el => {
													row[el.name] = (this.getInitialValue()[field.name]) ? this.getInitialValue()[field.name][el.name] ?? null : null;
												});

												let input = prepareFieldInput(_field, [field.name, _field.name], [field.fieldKey, _field.name], this.relationData ?? {}, this.data ?? {}, this.context?.updateRelationData ?? undefined, row, this.form);

												return !_field?.isHidden ?
													<Col {..._field?.span ?? {}}>
														{input}
													</Col>
													:
													input;
											})}
										</React.Fragment>
										{
											field?.isHidden
												? null
												: <React.Fragment key={_.uniqueId('actions_')}>
													<Col xl={4} lg={5} align="right">
														<Button danger style={{ marginLeft: '5px' }} onClick={() => remove(field.name)}><DeleteOutlined /></Button>
													</Col>
												</React.Fragment>
										}
									</React.Fragment>
								);
							})}
						</Row>
						<Form.Item>
							<Button type="dashed" onClick={ () => add() } block icon={<PlusOutlined />}>
								Dodaj pozycję
							</Button>
						</Form.Item>
					</React.Fragment>
				)}
			</Form.List>
		</Form.Item>;
	}
}

export default RecordFieldTableRelation;
