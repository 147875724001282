import React from 'react';

import Moment from 'react-moment';

import Record from '../../../components/Record/index';

import TabbedCard from '../../../components/TabbedCard';

import { useAuth, PERMISSIONS as USER_PERMISSIONS } from '../../../contexts/User';

import { Row, Col, Card, Button } from 'antd';

import moment from 'moment';

import '../../../assets/scss/dashboard.scss';

const Dashboard_Applications_Extras = (props) => {
	const [currentActiveTab, setCurrentActiveTab] = React.useState('general');

	const auth = useAuth();

	return (<div className="content">
		<Record
			controller="appextras"
			relations={[{
				name: 'dctextratypes',
				table: 'regdctextras',
				field: 'dctextratype_id'
			}, 'profiles', 'families', 'contractors', 'dctfiletypes']}
			mode={props?.mode}
			defaultEditing={props?.editing}
			filters={[
				{
					title: 'Kontrahent',
					name: 'contractors.id',
					relation: 'contractors',
					type: 'enum'
				}
			]}
			onCreatedRow={(row, data) => {
				if(data?.is_seen === 0) {
					row.style.background = 'rgba(225, 166, 52, 0.1)';
				}
			}}
			technicalFields={[
				<Record.Field.Input title={'Status wniosku'} name="staapp_name" readonly />
			]}
			customButtons={[
				{ title: 'Akcje', dropdown: [
					{ title: 'Przekazanie wniosku do akceptacji', endpoint: '/appextras/status/toaccept', predicate: (el, data, relationData) => [1,4].includes(data?.staapp_id), modal: { title: 'Potwierdź akcję', content: 'Czy na pewno chcesz przekazać wniosek do akceptacji?' } },
					{ title: 'Przekazanie wniosku do komisji', endpoint: '/appextras/status/tocommission', predicate: (el, data, relationData) => [2].includes(data?.staapp_id), modal: { title: 'Potwierdź akcję', content: 'Czy na pewno chcesz przekazać wniosek do komisji socjalnej?', fields: ['to_commission'] } },
					{ title: 'Zwrot wniosku do korekty', endpoint: '/appextras/status/tocorrect', predicate: (el, data, relationData) => [2,6].includes(data?.staapp_id), modal: { title: 'Potwierdź akcję', content: 'Czy na pewno chcesz przekazać wniosek do korekty?', fields: ['to_correct'] } },
					{ title: 'Akceptuj wniosek', endpoint: '/appextras/status/accepted', predicate: (el, data, relationData) => [2,6].includes(data?.staapp_id) },
					{ title: 'Odrzuć wniosek', endpoint: '/appextras/status/refused', predicate: (el, data, relationData) => [2,6].includes(data?.staapp_id), modal: { title: 'Potwierdź akcję', content: 'Czy na pewno chcesz odrzucić wniosek?', fields: ['remarks'] } },
					{ title: 'Anuluj wniosek', endpoint: '/appextras/status/canceled', predicate: (el, data, relationData) => [1,2,3].includes(data?.staapp_id), modal: { title: 'Potwierdź akcję', content: 'Czy na pewno chcesz anulować wniosek?', fields: ['remarks'] } },
					{ title: 'Cofnij wniosek do akceptacji', endpoint: '/appextras/status/undoaccept', predicate: (_el, data) => auth.can(USER_PERMISSIONS.ADMIN) && [3,5,7].includes(data?.staapp_id), modal: { title: 'Potwierdź akcję', content: 'Czy na pewno chcesz cofnąć wniosek do akceptacji?', fields: ['remarks'] } },
				] },
				{
					title: 'Nadaj numer',
					endpoint: '/appextras/number/{#id}',
					requestMethod: 'put',
					predicate: (el, data, relationData) => (!data?.number) && data?.staapp_id === 2,
					successText: 'Numer wniosku został nadany',
					errorText: 'Wystąpił błąd podczas nadawania numeru'
				},
				{
					title: 'Oblicz dofinansowanie',
					endpoint: '/appextras/count/{#id}',
					requestMethod: 'get',
					successText: 'Obliczenie zostało wykonane',
					errorText: 'Wystąpił błąd podczas obliczania'
				},
				{
					title: 'Usuń',
					endpoint: '/appextras/{#id}',
					requestMethod: 'delete',
					predicate: (el, data, relationData) => [1].includes(data?.staapp_id) && auth.can(USER_PERMISSIONS.ADMIN),
					successText: 'Wniosek został usunięty',
					errorText: 'Wystąpił błąd podczas usuwania wniosku',
					onSuccess: () => {
						window.location.href = '/appextras/';
					},
					modal: {
						title: 'Potwierdź akcję',
						content: 'Czy na pewno chcesz usunąć wniosek?',
						okText: 'Tak, usuń'
					},
					buttonType: 'danger'
				}
			]}
			columns={[
				{
					name: 'id',
					title: '#',
					type: 'number'
				},
				{
					name: 'full_name',
					title: 'Wnioskujący',
					searchable: true,
					searchname: 'users.full_name'
				},
				{
					name: 'dctextratype_name',
					title: 'Rodzaj świadczenia',
					sortname: 'dctadditionals.name'
				},
				{
					name: 'application_date',
					title: 'Data złożenia wniosku'
				},
				{
					name: 'payment_date',
					title: 'Data płatności'
				},
				{
					name: 'staapp_name',
					title: 'Status',
					sortname: 'staapps.name'
				},
				{
					name: 'actions',
					data: null,
					sortable: false,
					title: '',
					render: function(data, type, row) {
						return `<a class="btn btn-table" href="/appextras/${data.id}">Podgląd</a><a class="btn btn-table" href="/appextras/${data.id}/edit">Edytuj</a>`;
					},
					className: 'text-right'
				}
			]}
			onValuesChange={(field, allFields, updateRelationData, updateDependency) => {
				if(field.name === 'profile_id') {
					updateRelationData(field.value);
					updateDependency('families');
				}
			}}
			viewTitle="[{#number}] Świadczenia dodatkowe - PODGLĄD"
			listTitle="Świadczenia dodatkowe"
			editTitle="[{#number}] Świadczenia dodatkowe - EDYCJA"
			createTitle="Świadczenia dodatkowe - PODGLĄD"
		>
			<Record.Consumer>
				{({ isLoading, mode }) => (
					<TabbedCard key="generalCard" loading={isLoading} tabContent={{
						general: [
							<Record.Field.Relation title="Wnioskujący" relation="profiles" name="profile_id" labelFormat="[{#registration_number}] {#full_name} - {#contractor_symbol}" link filter={ (data, record) => {
								return data.filter((el, idx) => {
									return el.staprofile_id === 2;
								});
							} } allowSearch={true} rules={[{ required: true, message: 'Proszę uzupełnić wnioskującego' }]} />,
							<Record.Field.Date title="Data złożenia wniosku" name="application_date" defaultValue={moment()} />,
							<Record.Field.Relation title="Rodzaj świadczenia" name="dctextratype_id" relation="dctextratypes" rules={[{ required: true, message: 'Proszę wybrać rodzaj świadczenia' }]} />,
							<Record.Field.Empty />,
							<Record.Field.Number title="Kwota świadczenia" name="subsidy_total" precision={2} decimalSeparator="." rules={[({ getFieldValue }) => ({
								validator(_, value) {
									if(isNaN(parseFloat(getFieldValue('counted_subsidy_total'))) || parseFloat(getFieldValue('counted_subsidy_total')) >= parseFloat(value)) {
										return Promise.resolve();
									}
									return Promise.reject('Wartość nie może przekraczać wyliczonej kwoty dofinansowania ('+getFieldValue('counted_subsidy_total')+')');
								},
							})]} />,
							<Record.Field.Input title="Wyliczona wysokość dofinansowania" name="counted_subsidy_total" hidePredicate={() => true} />,
							<Record.Field.Date title="Data wypłaty dofinansowania" name="payment_date" />,
							<Record.Field.Switch title="Widoczny dla komisji" name="is_visible" />,
							<Record.Field.Textarea title="Wynik ostatniego obliczenia" name="control" span={{ span: 24 }} />,
							<Record.Field.Textarea title="Uwagi (do korekty)" name="to_correct" span={{ span: 24 }} />,
							<Record.Field.Textarea title="Uwagi (do komisji)" name="to_commission" span={{ span: 24 }} />,
							<Record.Field.Textarea title="Uwagi" name="remarks" span={{ span: 24 }} />

						],
						clientdata: [
							<Record.Field.Input title="Imię i nazwisko" name="full_name" readonly overrideValue={(record, relationData) => {
								if(record.profile_id) {
									let profile = relationData?.profiles?.find(el => el.id === record.profile_id);
									return profile?.full_name;
								}

								return null;
							}} />,
							<Record.Field.Input title="Data urodzenia" name="birth_date" readonly overrideValue={(record, relationData) => {
								if(record.profile_id) {
									let profile = relationData?.profiles?.find(el => el.id === record.profile_id);
									return profile?.birth_date;
								}

								return null;
							}} />,
							<Record.Field.Input title="Miejsce zamieszkania" name="address" readonly overrideValue={(record, relationData) => {
								if(record.profile_id) {
									let profile = relationData?.profiles?.find(el => el.id === record.profile_id);
									return profile?.address;
								}

								return null;
							}} />,
							<Record.Field.Input title="Numer kontaktowy" name="phone" readonly overrideValue={(record, relationData) => {
								if(record.profile_id) {
									let profile = relationData?.profiles?.find(el => el.id === record.profile_id);
									return profile?.phone;
								}

								return null;
							}} />,

							<Record.Field.Input title="Kontrahent" name="contractor_name" readonly overrideValue={(record, relationData) => {
								if(record.profile_id) {
									let profile = relationData?.profiles?.find(el => el.id === record.profile_id);
									return profile?.contractor_name;
								}

								return null;
							}} />,
							<Record.Field.Input title="Numer NIP kontrahenta" name="contractor_nip" readonly overrideValue={(record, relationData) => {
								if(record.profile_id) {
									let profile = relationData?.profiles?.find(el => el.id === record.profile_id);
									return profile?.contractor_nip;
								}

								return null;
							}} />,
							<Record.Field.Input title="Adres kontrahenta" name="branch_address" readonly overrideValue={(record, relationData) => {
								if(record.profile_id) {
									let profile = relationData?.profiles?.find(el => el.id === record.profile_id);
									return profile?.branch_address;
								}

								return null;
							}} />,
							<Record.Field.Input title="Średnie dochody" name="average_incomes" readonly overrideValue={(record, relationData) => {
								if(record.profile_id) {
									let profile = relationData?.profiles?.find(el => (el?.id ?? el?.value) === record.profile_id);
									return profile?.average_incomes;
								}

								return null;
							}} />,

							<Record.Field.TableRelation readonly title={'Rodzina'} name="families" span={{ span: 24 }} fields={[
								{ name: 'first_name', title: 'Imię' },
								{ name: 'last_name', title: 'Nazwisko' },
								{ name: 'birth_date', title: 'Data urodzenia' },
								{ name: 'pesel', title: 'PESEL' },
								{ name: 'dctrelationship_name', title: 'Rodzaj pokrewieństwa' }
							]} />
						],
						attachments: [
							<Record.Field.Attachments title={'Załączniki'} name="attachments" fileCategory="Ogólny" relation="dctfiletypes" />
						],
						history: [
							<Record.Field.TableRelation readonly title={'Historia statusów'} showTitle={false} name="workflow" span={{ span: 24 }} fields={[
								{ name: 'from_status', title: 'Poprzedni status' },
								{ name: 'to_status', title: 'Nowy status' },
								{ name: 'updater', title: 'Zaktualizowane przez' },
								{ name: 'change_date', title: 'Data', type: 'date' }
							]} />
						]
					}} tabList={mode.value === 'create' ? [
						{ key: 'general', tab: 'Dane podstawowe' },
						{ key: 'clientdata', tab: 'Dane klienta' },
						{ key: 'attachments', tab: 'Załączniki' }
					] : [
						{ key: 'general', tab: 'Dane podstawowe' },
						{ key: 'clientdata', tab: 'Dane klienta' },
						{ key: 'attachments', tab: 'Załączniki' },
						{ key: 'history', tab: 'Historia statusów' }
					]} activeTabKey={currentActiveTab} onTabChange={(key) => { setCurrentActiveTab(key) }} forceRender={true} />
				)}
			</Record.Consumer>
		</Record>
	</div>);
};

export default Dashboard_Applications_Extras;
