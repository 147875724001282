import React from 'react';

import Moment from 'react-moment';

import Record from '../../components/Record/index';

import TabbedCard from '../../components/TabbedCard';

import { Row, Col, Card, Button } from 'antd';

import '../../assets/scss/dashboard.scss';

const Dashboard_Notices = (props) => {
	const [currentActiveTab, setCurrentActiveTab] = React.useState('general');
	const [requiredType, setRequiredType] = React.useState(null)

    const onChange = (field) => {
		if (field.name === 'type') {
			setRequiredType(field.value)
		}
    };

	return (<div className="content">
		<Record
			controller="notices"
			relations={[ 'contractors' ]}
			mode={props?.mode}
			defaultEditing={props?.editing}
			columns={[
				{
					name: 'id',
					title: '#',
					type: 'number',
				},
				{
					name: 'type_name',
					title: 'Typ',
					searchable: false
				},
				{
					name: 'title',
					title: 'Tytuł',
					searchable: true
				},
				{
					name: 'from_date',
					title: 'Wyświetlanie od',
				},
				{
					name: 'to_date',
					title: 'Wyświetlanie do',
				},
				{
					name: 'is_active',
					title: 'Aktywny',
					datatype: 'bool',
					searchable: false
				},
				{
					name: 'actions',
					data: null,
					sortable: false,
					title: '',
					render: function(data, type, row) {
						return `<a class="btn btn-table" href="/notices/${data.id}">Podgląd</a><a class="btn btn-table" href="/notices/${data.id}/edit">Edytuj</a>`;
					},
					className: 'text-right'
				}
			]}
			viewTitle="Zawiadomienia - PODGLĄD"
			listTitle="Zawiadomienia"
			createTitle="Zawiadomienia - TWORZENIE"
			editTitle="Zawiadomienia - EDYCJA"
			createButtonText="Dodaj zawiadomienie"
			onValuesChange={onChange}
		>
			<Record.Consumer>
				{({ isLoading, form }) => (
					<TabbedCard key="generalCard" loading={isLoading} form={form} tabContent={{
						general: [
							<Record.Field.Select key={['primaryCard', 'type']} title="Typ" name="type" span={{ span: 24 }} options={[
								{ label: 'Ekran logowania', value: 'LOGIN' },
								{ label: 'Po zalogowaniu', value: 'SYSTEM' },
							]} rules={[{ required: true, message: 'Proszę uzupełnić typ' }]} />,
							<Record.Field.Relation key={['primaryCard', 'contractor_ids']} title={'Kontrahent'} linkRelation={true} mode="multiple" relation="contractors" name="contractor_ids" span={{ span: 24 }} castToString
							hidePredicate={(value, data, mode) => {
								return data?.type !== 'SYSTEM'
							}}
							rules={[{ required: requiredType === 'LOGIN' || (form?.current?.getFieldValue?.('type') === 'LOGIN') ? false : true, message: 'Proszę uzupełnić kontrahenta' }]}
							/>,
							<Record.Field.Input key={['primaryCard', 'title']} title={'Tytuł'} name="title" span={{ span: 24 }} rules={[ { required: true, message: 'Proszę uzupełnić tytuł' } ]} />,
							<Record.Field.Textarea key={['primaryCard', 'content']} title={'Treść'} name="content" span={{ span: 24 }} rules={[ { required: true, message: 'Proszę uzupełnić treść' } ]} />,
							<Record.Field.Date key={['primaryCard', 'from_date']} title={'Wyświetlanie od'} name="from_date" rules={[ { required: true, message: 'Proszę uzupełnić datę rozpoczęcia wyświetlania' } ]} />,
							<Record.Field.Date key={['primaryCard', 'to_date']} title={'Wyświetlanie do'} name="to_date" rules={[ { required: true, message: 'Proszę uzupełnić datę zakończenia wyświetlania' } ]} />,
							<Record.Field.Switch key={['primaryCard', 'is_active']} title={'Wyświetlanie aktywne'} name="is_active" initialValue={0} rules={[ { required: false, message: 'Proszę uzupełnić aktywność wyświetlania' } ]} />
						],
					}} tabList={[
						{ key: 'general', tab: 'Dane podstawowe' },
					]} activeTabKey={currentActiveTab} onTabChange={(key) => { setCurrentActiveTab(key) }} forceRender={true} />
				)}
			</Record.Consumer>
		</Record>
	</div>);
};

export default Dashboard_Notices;
