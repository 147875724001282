import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Record_Field from './Field';

import { Form, Select } from 'antd';

import _ from 'lodash';

import {assignDataToString, getValueFromArray} from '../../../libs/Helpers.js';

import WithSeparator from '../../../components/WithSeparator';
import SearchSelect from "../../SearchSelect";
import {APIBackend as API} from "../../../api";
import Observer from "../../Observer";
import {FILTER_RELATIONS} from "../../../config/constants";
import objectHash from "object-hash";
import {relationParams, relationQueries} from "./utils/relations";

const { Option } = Select;

class RecordFieldRelation extends Record_Field {
	static propTypes = Object.assign({}, Record_Field.propTypes, {
		relation: PropTypes.string.isRequired,
		prefix: PropTypes.string,
		idKey: PropTypes.string,
		relatedKey: PropTypes.string,
		labelFormat: PropTypes.string,
		mode: PropTypes.string,
		link: PropTypes.bool,
		linkReferrer: PropTypes.string,
		separator: PropTypes.string,
		filter: PropTypes.func,
		allowClear: PropTypes.bool
	});

	static defaultProps = Object.assign({}, Record_Field.defaultProps, {
		placeholder: 'Wybierz...',
		prefix: null,
		idKey: 'id',
		relatedKey: 'id',
		labelFormat: '{#name}',
		mode: null,
		link: false,
		separator: ', ',
		allowClear: false
	});

	static displayName = 'RecordFieldRelation';

	get relation() {
		const {
			relation
		} = this.props;

		if(this.relationData && relation && this.relationData.hasOwnProperty(relation)) {
			return this.relationData[relation] ?? [];
		}

		return [];
	}

	get dependency() {
		const {
			relation
		} = this.props;

		if(this.dependencies && relation && this.dependencies.hasOwnProperty(relation)) {
			return this.dependencies[relation] ?? [];
		}

		return [];
	}

	constructor(props) {
		super(props);

		this.fetchFilterOptions = this.fetchFilterOptions.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	getInitialValue() {
		const {
			relatedKey,
			idKey,
			mode
		} = this.props;

		if(mode === 'multiple') {
			return (this.getRawValue() && Array.isArray(this.getRawValue())) ? this.getRawValue().map(el => el ? (el[relatedKey] ?? el[idKey] ?? el.id) : null).filter(el => el !== null) : [];
		} else {
			return this.getRawValue();
		}
	}

	getValue() {
		const {
			idKey,
			labelFormat,
			mode,
			link,
			linkReferrer,
			relation,
			separator,
			relatedKey,
			showFromData = false
		} = this.props;

		let value = null;

		if(showFromData && this.mode === 'view') {
			if(this.data ?? null) {
				if(Array.isArray(this.name)) {
					value = getValueFromArray(this.data, showFromData);
				} else {
					if(this.data.hasOwnProperty(showFromData)) {
						value = this.data[showFromData];
					}
				}
			}

			return value ?? '(brak)';
		}

		if(mode === 'multiple') {
			value = Array.isArray(this.getRawValue()) && this.getRawValue().map((el, idx) => {
				let opt = this.relation.find(o => (o[relatedKey] ?? o[idKey] ?? o.id) === (el[relatedKey] ?? el[idKey] ?? el?.id ?? el));

				if(opt) {
					let tmpValue = assignDataToString(labelFormat, opt, '(brak)');
					return link ?
						<Link
							key={['relationLink', idx]}
							to={`/${linkReferrer ?? relation}/${opt[idKey] ?? opt.id}`}
						>
							{tmpValue}
						</Link>
						:
						tmpValue;
				} else {
					return el.name;
				}
			});

			value = value ? <WithSeparator separator={separator}>{value}</WithSeparator> : null;
		} else {
			if(FILTER_RELATIONS.includes(relation)) {
				let opt = this.dependency.find(el => (el.value ?? el) === this.getRawValue());

				if(opt) {
					value = opt.label;
				}
			} else {
				let opt = this.relation.find(el => (el[relatedKey] ?? el[idKey] ?? el?.id ?? el) === this.getRawValue());

				value = opt ? assignDataToString(labelFormat, opt, '(brak)') : null;

				if (link && value) {
					value = <Link
						to={`/${linkReferrer ?? relation}/${opt[idKey] ?? opt.id}`}
					>
						{value}
					</Link>;
				}
			}
		}

		return value ? value : '(brak)';
	}

	onDependencyDataUpdate() {
		super.onDependencyDataUpdate();

		this.setState({
			hash: objectHash(this.dependency)
		});
	}

	fetchFilterOptions(value, relation, relationKey, relationName) {
		if(!relation) {
			return Promise.reject();
		}

		const paramName = relationParams.hasOwnProperty(relation) ? relationParams[relation] : relationParams.DEFAULT;
		const params = paramName && (this.data?.[paramName] ?? null);
		let relationData;

		if(relationQueries.hasOwnProperty(relation)) {
			relationData = relationQueries[relation](params);
		} else {
			relationData = relationQueries.DEFAULT(relation);
		}

		return new Promise((resolve, reject) => {
			API.datacustom([Object.assign({
				name: relation
			}, relationData, {
				query: value
			})]).then(response => {
				const currentOptions = this.dependencies;
				let newOptions = {...currentOptions};

				if(response.hasOwnProperty(relation)) {
					resolve(response[relation]);

					newOptions = {...newOptions, [relation]: response[relation]};
				} else {
					resolve([]);
					newOptions = {...newOptions, [relation]: []};
				}

				this.dependencies = newOptions;
			}).catch(error => {
				reject(error);
			});
		});
	}

	renderEdit() {
		const {
			title,
			name,
			disabled = false,

			allowSearch,
			placeholder,
			relation,
			relatedKey,
			relatedName = 'name',
			idKey,
			labelFormat,
			mode,
			filter,
			disablePredicate,
			allowClear
		} = this.props;

		let _disabled;

		let _data = this.relation;

		if(typeof filter === 'function') {
			_data = filter(_data, this.data, this.relationData);
		}

		if(disablePredicate && typeof disablePredicate === 'function') {
			_disabled = disablePredicate(this.getRawValue(), this.data, this.relationData);
		}

		_data = _data.sort((a, b) => a[relatedKey] ?? a[idKey] - b[relatedKey] ?? b[idKey]);

		return <Form.Item
			{...this.globalItemProps}
		>
			{
				FILTER_RELATIONS.includes(relation)
					? <SearchSelect
						fetch={this.fetchFilterOptions}
						relation={relation}
						relationKey={relatedKey}
						relationName={relatedName}
						mode={mode}
						disabled={_disabled ?? disabled}
						autoComplete="nope"
						initialOptions={this.dependency ?? []}
					/>
					: <Select
						disabled={_disabled ?? disabled}
						autoComplete="nope"
						optionFilterProp="children"
						showSearch={allowSearch}
						placeholder={placeholder}
						mode={mode}
						allowClear={allowClear}
					>
						{_data.map(el => (
							<Option key={_.uniqueId('option_')} value={el[relatedKey] ?? el[idKey] ?? el.id}>{assignDataToString(labelFormat, el)}</Option>
						))}
					</Select>
			}
		</Form.Item>;
	}
}

export default RecordFieldRelation;
