import React from "react";
import { useHistory, useParams, Link } from "react-router-dom";

import Moment from 'react-moment';
import moment from 'moment';

import DataTable from '../components/DataTable';
import DraggerUpload from '../components/DraggerUpload';
import TabbedCard from '../components/TabbedCard';
import CardCollapse from '../components/CardCollapse';
import CreatableSelect from '../components/CreatableSelect';
import WithSeparator from '../components/WithSeparator';
import ZfssPicker from '../components/DatePicker';

import axios from 'axios';
import objectHash from 'object-hash';

import { backendRoot, backendApi, csrfCookie, apiData } from '../config/paths';

import {
	Row,
	Col,
	Card,
	Alert,
	Button,
	Form,
	Input,
	Checkbox,
	Select,
	Switch,
	DatePicker,
	Menu,
	Dropdown,
	Space,
	Tooltip,
	Tag,
	Modal,
	message,
	Collapse,
	InputNumber
} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

import { DeleteOutlined, PlusOutlined, InboxOutlined, DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { filterObject, objectToFormData, getValueFromArray, objectMap } from '../libs/Helpers.js';
import prepareErrorMessage from '../libs/ErrorHelper.js';

import '../assets/scss/dashboard.scss';

const { Option } = Select;

const Record = ({ readonly, children, onModeChange, ...props }) => {
	/**
	 * ID of record given in URL
	 * @type {String}
	 */
	const { id } = useParams();

	/**
	 * Data of record
	 * @type {Object}
	 */
	const [data, setData] = React.useState(null);

	/**
	 * Changed data of record
	 * @type {Object}
	 */
	const [changes, setChanges] = React.useState(null);

	/**
	 * Data of related records
	 * @type {Object}
	 */
	const [relationData, setRelationData] = React.useState(null);

	/**
	 * State of view - is record being saved at this moment?
	 * @type {Boolean}
	 */
	const [isSaving, setSaving] = React.useState(false);

	/**
	 * State of view - is record being edited at this moment?
	 * @type {Boolean}
	 */
	const [isEditing, setEditing] = React.useState(props.defaultEditing);

	/**
	 * State of process - do error has occurred?
	 * @type {Boolean}
	 */
	const [isError, setError] = React.useState(false);

	/**
	 * State of filters visibility
	 * @type {Boolean}
	 */
	const [filtersVisibility, setFiltersVisibility] = React.useState(false);

	/**
	 * Active mode of view [list, view, edit, create]
	 * @type {String}
	 */
	const [mode, setMode] = React.useState(props.mode ?? 'list');

	/**
	 * Key of active tab if any
	 * @type {String}
	 */
	const [activeTab, setActiveTab] = React.useState(props.tabs?.length ? props.tabs[0].key ?? null : null);

	/**
	 * State of any modal visibility
	 * @type {String}
	 */
	const [isModalVisible, setIsModalVisible] = React.useState(false);

	/**
	 * Instance of Ant Design Form
	 * @type {Form.Instance}
	 */
	const [form] = Form.useForm();

	/**
	 * react-router-dom History object
	 * @type {History}
	 */
	const history = useHistory();
	/**
	 * Referrer
	 * @type {String}
	 */
	const referer = props?.referer ?? props.controller;

	/**
	 * Array of fields and their types
	 * @type {Array}
	 */
	const fields = [];

	/**
	 * Call onModeChange callback if mode has been changed
	 *
	 */
	React.useEffect(() => {
		if(typeof onModeChange === 'function') onModeChange(mode);
	}, [mode, setMode]);

	/**
	 * Assigning fields and their types to array
	 *
	 */
	React.Children.forEach(children, (child) => {
		if(child && ((child.props?.editable ?? true) || (child.props?.createable ?? true))) {
			fields.push({
				name: child.props.field,
				type: child.type,
				props: child.props
			});
		}
	});

	/**
	 * Getting record data and data of related records for view and edit mode.
	 *
	 */
	React.useEffect(() => {
		if(mode !== 'clone') {
			getRecordData();

			getRelationData();
		}
	}, []);

	const getRecordData = () => {
		if(mode === 'view' || mode === 'edit') {
			// Getting CSRF key
			axios.get(backendRoot + csrfCookie).then(() => {
				// Getting record data
				axios.get(`${backendApi}/${props.controller}/${id}`).then((response) => {
					// Assigning record data to state
					setData(response.data);
				}).catch((err) => {
					setData(false);
					if(err.response) {
						if(err.response.data.errcode) {
							setError(err.response.data.errcode);
						} else {
							setError('ERR-UNDEFINED');
						}
					} else {
						setError('ERR-UNDEFINED');
					}
				});
			}).catch((err) => {
				setData(false);
				if(err.response) {
					if(err.response.data.errcode) {
						setError(err.response.data.errcode);
					} else {
						setError('ERR-UNDEFINED');
					}
				} else {
					setError('ERR-UNDEFINED');
				}
			});
		} else {
			// If it isn't a preview or edit mode, then set empty data.
			setData({});
		}
	};

	const getRelationData = () => {
		// If view should get data of related records then get it
		if(props.relations?.length) {
			// Getting CSRF key
			axios.get(backendRoot + csrfCookie).then(() => {
				// Getting data of related records
				axios.get(`${backendApi}${apiData}?tables=${props.relations.join(',')}`).then((response) => {
					// Assigning data of related records to state
					setRelationData(response.data);
				}).catch(() => {
					setRelationData(null);
				});
			}).catch(() => {
				setRelationData(null);
			});
		}
	};

	/**
	 * Options for axios, provided while saving or creating record.
	 * @type {Object}
	 */
	const axiosOptions = {
		transformRequest: function(data, headers) {
			// Browser will detect proper Content-Type
			headers['Content-Type'] = undefined;

			return data;
		}
	};

	/**
	 * Prepare data of record and send a proper save request
	 * @param  {Object} values Form values
	 */
	const onFinish = (values) => {
		const _data = new FormData();
		const _debugData = {}; // NOTE: DEBUG VARIABLE

		// console.log(values);

		/**
		 * Iterate editable and createable fields to prepare a FormData object
		 *
		 */
		fields.map((field) => {
			// Value of field
			let fieldValue = Array.isArray(field.name) ? getValueFromArray(values, field.name) : (values[field.name] ?? null),
				fieldName = Array.isArray(field.name) ? field.name.reduce((acc, v) => `${acc}[${v}]`) : field.name,
				tmpValue;

			// Make an exceptions
			if(fieldValue === null && !['RecordFieldAttachments', 'RecordFieldGroup'].includes(field.type.displayName)) return;

			// Check a type of field and prepare value
			switch(field.type.displayName) {
				case 'RecordFieldDate':
					_data.append(fieldName, fieldValue.format('YYYY-MM-DD'));
					break;
				case 'RecordFieldFields':
				case 'RecordFieldTableRelation':
					// Prepare value of field
					if(fieldValue && Array.isArray(fieldValue)) {
						tmpValue = fieldValue.map((el) => {
							for(let _k in el) {
								let subfield = field.props.fields.find((sf => sf.name === _k));

								let _v = el[_k];

								switch(subfield?.type) {
									case 'date':
										_v = el[_k] ? el[_k].format('YYYY-MM-DD') : null;
										break;
									case 'checkbox':
										_v = el[_k] ? 1 : 0;
										break;
									default:
										break;
								}

								el[_k] = _v;

								if(subfield) {
									// If field is empty and it's not allowed to be empty unset it
									if(subfield.removeEmpty && (_v === '' || _v === null)) {
										delete el[_k];
									}
								} else {
									// Unset field if it doesn't exists
									delete el[_k];
								}
							}

							// Check if whole row is empty
							let emptyRow = Object.keys(el).every(i => {
								return el[i] === '' || el[i] === null || el[i] === 0;
							});

							// If row isn't empty, then add it to data
							if(!emptyRow) return el;
						}).filter(_v => (_v && Object.keys(_v).length)); // Remove empty entries
					}

					_data.append(fieldName, JSON.stringify(tmpValue ?? []));
					break;
				case 'RecordFieldRecord':
					tmpValue = fieldValue;

					tmpValue.forEach((_tmp, idx) => {
						field.props.fields.forEach(_f => {
							let _fName = _f.props.field,
								_fType = _f.type.displayName;

							if(_fType === 'RecordFieldRelation' && _f.props.castToString) {
								if(tmpValue[idx][_fName]) {
									tmpValue[idx][_fName] = tmpValue[idx][_fName].join(',');
								}
							}
						});
					});

					_data.append(fieldName, JSON.stringify(tmpValue));
					break;
				case 'RecordFieldAttachments':
					fieldValue = values[`${fieldName}~${field.props?.fileCategory}`] ?? null;

					fieldValue.add.forEach(el => {
						if(el) _data.append(`files[${el.category}/${el.name}]`, el.file);
					});

					fieldValue.del.forEach(el => {
						if(el) _data.append(`delete[${el.category}/${el.name}]`, el.uid);
					});
					break;
				case 'RecordFieldGroup':
					field.props.fields.forEach(el => {
						if(values[el.name] ?? null !== null) {
							_data.append(el.name, values[el.name] ?? '');
						}
					});
					break;
				case 'RecordFieldSwitch':
				case 'RecordFieldCheckbox':
					_data.append(fieldName, fieldValue ? 1 : 0);
					break;
				case 'RecordFieldRelation':
					if(field.props?.transformDataKey) {
						_data.append(field.props.transformDataKey, fieldValue);
					} else {
						_data.append(fieldName, fieldValue);
					}
					break;
				case 'RecordFieldTableCheck':
					if(field.props?.transformDataKey) {
						_data.append(field.props.transformDataKey, fieldValue.map(el => el.checked ? el.id : null).filter(el => el ?? null !== null));
					} else {
						_data.append(fieldName, fieldValue.map(el => el.checked ? el.id : null).filter(el => el ?? null !== null));
					}
					break;
				case 'RecordFieldTextarea':
				case 'RecordFieldSelect':
				case 'RecordField':
					_data.append(fieldName, fieldValue);
					break;
				default:
					_data.append(fieldName, fieldValue);
					break;
			}
		});

		// NOTE: DEBUG SECTION
		for (var pair of _data.entries()) {
			_debugData[pair[0]] = pair[1];
		}

		// console.log('DEBUG:', mode, _debugData);
		// return;
		// NOTE: END OF DEBUG SECTION

		if(mode === 'edit') {
			// Make an update request
			axios.get(backendRoot + csrfCookie).then(() => {
				axios.post(backendApi + '/' + props.controller + '/' + id, _data, axiosOptions).then((response) => {
					setMode('view');
					setEditing(false);
					setSaving(false);
					setData(response.data);
					setError(false);
				}).catch((err) => {
					if(err.response) {
						if(err.response.data.errcode) {
							setError(err.response.data.errcode);
						} else {
							setError('ERR-UNDEFINED');
						}
						setSaving(false);
					} else {
						if(err.response) {
							if(err.response.data.errcode) {
								setError(err.response.data.errcode);
							} else {
								setError('ERR-UNDEFINED');
							}
						} else {
							setError('ERR-UNDEFINED');
						}
						setSaving(false);
					}
				});
			}).catch((err) => {
				if(err.response) {
					if(err.response.data.errcode) {
						setError(err.response.data.errcode);
					} else {
						setError('ERR-UNDEFINED');
					}
				} else {
					setError('ERR-UNDEFINED');
				}
				setSaving(false);
			});
		} else {
			// Make a create request
			axios.get(backendRoot + csrfCookie).then(() => {
				axios.post(backendApi + '/' + props.controller, _data, axiosOptions).then((response) => {
					setSaving(false);
					setError(false);
					history.push('/'+referer+'/'+response.data.id);
				}).catch((err) => {
					setSaving(false);
					if(err.response) {
						if(err.response.data.errcode) {
							setError(err.response.data.errcode);
						} else {
							setError('ERR-UNDEFINED');
						}
					} else {
						setError('ERR-UNDEFINED');
					}
				});
			}).catch((err) => {
				setSaving(false);
				if(err.response) {
					if(err.response.data.errcode) {
						setError(err.response.data.errcode);
					} else {
						setError('ERR-UNDEFINED');
					}
				} else {
					setError('ERR-UNDEFINED');
				}
			});
		}
	};

	let title, buttons, view;

	switch(mode) {
		case 'clone':
			axios.get(backendRoot + csrfCookie).then(() => {
				if(!isSaving) {
					setSaving(true);
					axios.get(backendApi + '/' + props.controller + '/clone/' + id).then((response) => {
						message.success('Rekord został skopiowany');
						history.push('/'+referer+'/'+response.data.id);
					}).catch((err) => {
						message.error('Wystąpił błąd podczas kopiowania rekordu');
						if(err.response) {
							if(err.response.data.errcode) {
								setError(err.response.data.errcode);
							} else {
								setError('ERR-UNDEFINED');
							}
						} else {
							setError('ERR-UNDEFINED');
						}
					});
				}
			}).catch((err) => {
				message.error('Wystąpił błąd podczas kopiowania rekordu');
				if(err.response) {
					if(err.response.data.errcode) {
						setError(err.response.data.errcode);
					} else {
						setError('ERR-UNDEFINED');
					}
				} else {
					setError('ERR-UNDEFINED');
				}
			});

			view = <Card>
				Kopiowanie rekordu...
				{ isError && <div className="mt-2"><Link to={`/${referer}`}>Wróć do listy</Link></div> }
			</Card>;
			break;
		case 'create':
			// If view is read-only then change mode to list
			if(readonly) setMode('list');

			// Assign proper page title
			title = props.createTitle;
			// Assign proper buttons
			buttons = (<>
				<Button type="danger" onClick={ () => { setMode('list'); history.push('/'+referer); } } disabled={ isSaving }>Anuluj</Button>
				<Button type="primary" onClick={ () => { form.submit(); } } loading={ isSaving }>Utwórz</Button>
			</>);
			// Assign proper view
			view = (<Form form={ form } scrollToFirstError={true} onFinish={ onFinish } onValuesChange={ (val, allVals) => { setChanges(allVals); } } layout="vertical" className="record" autoComplete="nope">
				<RecordView_View form={ form } error={ isError } changes={ changes } relationData={ relationData } activeTab={ activeTab } setActiveTab={ setActiveTab } editing={ true } updateRelationData={getRelationData} {...props}>
					{ children }
				</RecordView_View>
			</Form>);
			break;
		case 'edit':
		case 'view':
			/**
			 * Custom buttons on preview view
			 * @type {Array}
			 */
			let customButtons = [];

			if(props?.customButtons) {
				props.customButtons.forEach((el, _) => {
					let predicateResult = true;
					if(typeof el.predicate === 'function') {
						predicateResult = el.predicate(el, data, relationData);
					}

					if(!predicateResult) return;

					const prepareEndpoint = (endpoint) => {
						return endpoint.replace(/{#([^}]*)}/g, (fullmatch, match) => {
							if(data && data?.hasOwnProperty(match)) {
								return data[match];
							}

							return null;
						});
					};

					// Add dropdown if needed
					if(el?.dropdown) {
						let menu = [];

						el.dropdown.forEach((_el, _) => {
							let subpredicateResult = true;
							if(typeof _el.predicate === 'function') {
								subpredicateResult = _el.predicate(_el, data, relationData);
							}

							if(!subpredicateResult) return;

							menu.push((<Menu.Item key={`${el.title}~${_}`} onClick={() => {
								if(data && _el.endpoint) {
									const formData = new FormData();
									formData.append('id', data.id);
									if(_el.modal) {
										Modal.confirm({
											title: _el.modal.title,
											icon: <ExclamationCircleOutlined />,
											okText: 'Wyślij',
											cancelText: 'Anuluj',
											content: <>
												{ _el.modal.content }

												{ (_el.modal?.fields ?? []).map((modalField, midx) => {
													let _modalField = fields.find(f => f.name === modalField)
													if(_modalField) {
														const modalValue = Object.assign(data, changes)[modalField];

														if(!_el.modal.values) {
															_el.modal.values = {};
														}

														return <Form.Item label={<strong>{_modalField.props.title}</strong>} labelCol={{span: 24}} name={['modal', modalField]} fieldKey={['modal', midx, modalField]} onChange={(e) => { _el.modal.values[modalField] = e?.target?.value; }}>
															<Input.TextArea defaultValue={modalValue} showCount />
														</Form.Item>;
													}
												}) }
											</>,
											onOk(e) {
												for(let key in _el.modal.values) {
													formData.append(key, _el.modal.values[key]);
												}
												return axios.post(`${backendApi}${_el.endpoint}`, formData).then(() => {
													message.success('Status wniosku został zmieniony');
													getRecordData();
												}).catch(err => {
													message.error('Wystąpił błąd podczas zmiany wniosku');
													if(err.response) {
														setError(err.response.data.errcode);
													} else {
														setError('ERR-UNDEFINED');
													}
												});
											},
											onCancel() {},
										});
									} else {
										axios.post(`${backendApi}${_el.endpoint}`, formData).then(() => {
											message.success('Status wniosku został zmieniony');
											getRecordData();
										}).catch(err => {
											message.error('Wystąpił błąd podczas zmiany wniosku');
											if(err.response) {
												setError(err.response.data.errcode);
											} else {
												setError('ERR-UNDEFINED');
											}
										});
									}
								}
							}}>
								{ _el.title }
							</Menu.Item>));
						});

						customButtons.push((<Dropdown key={`dropdown~${_}`} overlay={(<Menu>{ menu }</Menu>)}>
							<Button type="primary">{ el.title } <DownOutlined /></Button>
						</Dropdown>));
					} else {
						// If there is no dropdown

						customButtons.push((<Button type="primary" onClick={() => {
							if(data && el.endpoint) {
								const formData = new FormData();

								if(el?.formDataFromRecord) {
									el.formDataFromRecord.forEach((dataKey) => {
										if(data && data[dataKey]) {
											formData.append(dataKey, data[dataKey]);
										}
									});
								}

								if(el.modal) {
									Modal.confirm({
										title: el.modal.title,
										icon: <ExclamationCircleOutlined />,
										okText: 'Wyślij',
										cancelText: 'Anuluj',
										content: <>
											{ el.modal.content }

											{ (el.modal?.fields ?? []).map((modalField, midx) => {
												let _modalField = fields.find(f => f.name === modalField)
												if(_modalField) {
													const modalValue = Object.assign(data, changes)[modalField];

													if(!el.modal.values) {
														el.modal.values = {};
													}

													return <Form.Item label={<strong>{_modalField.props.title}</strong>} labelCol={{span: 24}} name={['modal', modalField]} fieldKey={['modal', midx, modalField]} onChange={(e) => { el.modal.values[modalField] = e?.target?.value; }}>
														<Input.TextArea defaultValue={modalValue} showCount />
													</Form.Item>;
												}
											}) }
										</>,
										onOk(e) {
											for(let key in el.modal.values) {
												formData.append(key, el.modal.values[key]);
											}
											return axios.post(`${backendApi}${prepareEndpoint(el.endpoint, data)}`, formData).then(() => {
												message.success(el.successText);
												getRecordData();
											}).catch(err => {
												message.error(el.errorText);
												if(err.response) {
													setError(err.response.data.errcode);
												} else {
													setError('ERR-UNDEFINED');
												}
											});
										},
										onCancel() {},
									});
								} else {
									axios.request({
										method: el?.requestMethod ?? 'post',
										url: `${backendApi}${prepareEndpoint(el.endpoint, data)}`,
										data: formData
									}).then(() => {
										message.success(el.successText);
										getRecordData();
									}).catch(err => {
										message.error(el.errorText);
										if(err.response) {
											setError(err.response.data.errcode);
										} else {
											setError('ERR-UNDEFINED');
										}
									});
								}
							}
						}}>{ el.title }</Button>));
					}
				});
			}

			// Assign proper page title
			title = mode === 'view' ? props.viewTitle : props.editTitle;
			// Assign proper buttons
			buttons = (<>
				{ (!isEditing || readonly) ?
					<>
						{ !readonly ? <Button type="primary" onClick={ () => { setEditing(true); setMode('edit'); } }>Edytuj</Button> : null }

						{ customButtons }

						<Link to={'/'+referer+'/'} className="btn-link">Wróć do listy</Link>
					</>
					:
					<>
						<Button type="danger" onClick={ () => { setEditing(false); setMode('view'); } } disabled={ isSaving }>Anuluj</Button>
						<Button type="primary" onClick={ () => { form.submit(); } } loading={ isSaving }>Zapisz</Button>
					</>
				}
			</>);
			// Assign proper view
			view = ((isEditing && !readonly) ? <Form form={ form } scrollToFirstError={true} onFinish={ onFinish } onValuesChange={ (val, allVals) => { setChanges(allVals); } } layout="vertical" className="record" autoComplete="nope">
				<RecordView_View form={ form } error={ isError } editing={ isEditing } data={ data } changes={ changes } relationData={ relationData } activeTab={ activeTab } setActiveTab={ setActiveTab } updateRelationData={getRelationData} {...props}>
					{ children }
				</RecordView_View>
			</Form>
			:
			<RecordView_View error={ isError } editing={ isEditing } data={ data } relationData={ relationData } activeTab={ activeTab } setActiveTab={ setActiveTab } {...props}>
				{ children }
			</RecordView_View>);
			break;
		case 'list':
		default:
			// Assign proper page title
			title = props.listTitle;
			// Assign proper buttons
			buttons = (<>
				<Button type="primary" onClick={ () => { setFiltersVisibility(!filtersVisibility) } }>
					<FontAwesomeIcon icon={faFilter} />
				</Button>
				<Button type="primary">Eksportuj</Button>
				{ !readonly ? <Button type="primary" onClick={ () => { setMode('create'); history.push('/'+referer+'/create'); } }>{ props.createButtonText }</Button> : null }
			</>);
			// Assign proper view
			view = (<RecordView_List filtersVisibility={ filtersVisibility } relationData={ relationData } error={ isError } {...props} />);
			break;
	};

	/**
	 * Rendering proper view
	 *
	 */
	return (<>
		{!props?.withoutHeader ? <div className="subheader">
			<div className="d-flex">
				<h1 className="title">{ title }</h1>
				<span className="subtitle">
					Dzisiaj jest <Moment locale="pl" format="dddd, D MMMM YYYY" />
				</span>
			</div>

			<div className="subheader_actions">
				{ buttons }
			</div>
		</div> : null}

		{ props?.alerts?.length ? <Row style={{ marginBottom: '15px' }}>
			<Col sm={24} md={16}>
				{ props.alerts.map((alert, idx) => {
					if(!alert.modes.includes(mode)) return;

					return (alert?.condition && typeof alert.condition === 'function' && alert.condition(data)) ? <Alert type={ alert.type ?? 'info' } message={ alert.message } /> : (!alert?.condition) ? <Alert type={ alert.type ?? 'info' } message={ alert.message } /> : null;
				}) }
			</Col>
		</Row> : '' }

		{ isError ? <Row style={{ marginBottom: '15px' }}>
			<Col sm={24} md={16}>
				<Alert type="error" message={ prepareErrorMessage(isError) } />
			</Col>
		</Row> : '' }

		{ view }

		<div className="toolbar" style={{ marginTop: '25px' }}>
			<div className="toolbar_actions">
				{ buttons }
			</div>
		</div>
	</>);
};

/**
 * Renderer of list view
 */
const RecordView_List = ({ mode, filtersVisibility, ...props }) => {
	const [draw, setDraw] = React.useState(0);
	const [filters, setFilters] = React.useState([...props?.filters]);
	const [isError, setError] = React.useState(false);

	const onFiltersChange = (field, value) => {
		let alreadyDefined = filters.findIndex(el => el.field === field);

		if(alreadyDefined >= 0) {
			filters[alreadyDefined].value = value;
		} else {
			filters.push({
				field: field,
				value: value
			});
		}

		filters.filter((el) => (el.value ?? false) ? true : false);

		setFilters(filters);
		setDraw(draw + 1);
	};

	return (<div>
		{ isError ? <Row style={{ marginBottom: '15px' }}>
			<Col sm={24} md={16}>
				<Alert type="error" message={ prepareErrorMessage(isError) } />
			</Col>
		</Row> : '' }

		<Card bodyStyle={ mode === 'list' ? { padding: 0 } : {} }>
			<div id="tableFilters" className={filtersVisibility ? 'shown' : ''}>
				<Row gutter={[16,20]}>
					{ props?.filtering ? props.filtering.map(filter => {
						let input;

						switch(filter?.type) {
							case 'enum':
								let options = [];

								if(props?.relationData && props?.relationData[filter?.relation]) {
									props.relationData[filter.relation].forEach((el, idx) => {
										options.push(<Option value={el[filter?.relationKey ?? 'id']}>{ el[filter?.relationName ?? 'name'] }</Option>);
									});
								}

								input = <Select allowClear showSearch optionFilterProp="children" style={{ minWidth: 200 }} defaultValue={ null } placeholder="Wybierz..." onChange={(value) => {
									onFiltersChange(filter.name, value);
								}}>
									{ options }
								</Select>;
								break;
							case 'preenum':
								let enumOptions = [];

								if(filter?.enum && Array.isArray(filter?.enum)) {
									enumOptions = filter.enum.map((el, idx) => {
										return (<Option key={`preenumOption~${idx}`} value={el?.value}>
											{ el.label }
										</Option>);
									});
								}

								input = <Select allowClear showSearch optionFilterProp="children" style={{ minWidth: 200 }} defaultValue={ null } placeholder="Wybierz..." onChange={(value) => {
									onFiltersChange(filter.name, value);
								}}>
									{ enumOptions }
								</Select>;
								break;
							case 'text':
							default:
								input = <Input allowClear defaultValue={ null } placeholder="Wyszukaj..." onChange={(e) => {
									onFiltersChange(filter.name, e.target.value);
								}} />
								break;
						}

						return (<Col key={`filter~${filter.name}`} className="form-group-row">
							<div className="form-group">
								<label>{ filter.title }</label>
								<span>
									{ input }
								</span>
							</div>
						</Col>);
					}) : null }
				</Row>
			</div>
			<div key={`dataTable~draw~${draw}`}>
				<DataTable controller={ props.controller } columns={ props.columns } perPage={ props.perPage } info={ props.info } pagination={ props.pagination } filters={ filters } transformData={ props?.transformData } onError={setError} />
			</div>
		</Card>
	</div>);
};

/**
 * Renderer of preview, edit and create view
 */
const RecordView_View = ({ controller, mode, data, changes, activeTab, setActiveTab, relationData, ...props }) => {
	// State of active tab passed from Record
	const [currentActiveTab, setCurrentActiveTab] = React.useState(activeTab ?? null);

	/**
	 * Catch change of active tab
	 *
	 */
	React.useEffect(() => {
		// Pass new key to parent (Record)
		setActiveTab(currentActiveTab);
	}, [currentActiveTab]);

	/**
	 * Container of childrens, assigned to proper tabs
	 * @type {Object}
	 */
	let childrens = {
		general: []
	};

	/**
	 * Container of technical fields
	 * @type {Array}
	 */
	let technicalFields = [

	];

	/**
	 * Container of history fields
	 * @type {Array}
	 */
	let historyFields = [

	];

	/**
	 * Index of children
	 * @type {Number}
	 */
	let childIdx = 0;

	React.Children.forEach(props.children, child => {
		if(React.isValidElement(child)) {
			// If field name is not assigned then skip
			// if(!child.props.field) return;

			// Value for record field
			let value = null,
				changedValue = null;

			if(Array.isArray(child.props.field)) {
				value = getValueFromArray(data, child.props.field);
				changedValue = getValueFromArray(changes, child.props.field);
			} else {
				value = data ? data[child.props.field] ?? null : null;
				changedValue = changes ? (changes[child.props.field] ?? null) : (data ? data[child.props.field] ?? null : null);
			}

			// Props for record field
			let _props = {
				editing: props.editing,
				value: value,
				key: controller+'_'+child.props.field,
				mode: mode,
				rawData: data,
				recordData: Object.assign({}, data, changes),
				form: props.form,
				updateRelationData: props.updateRelationData
			};

			// Assigning data of related record
			if(child?.props?.relations) {
				if(relationData) {
					let fieldRelations = [];
					if(!Array.isArray(child.props.relations)) {
						fieldRelations = [child.props.relations];
					} else {
						fieldRelations = child.props.relations;
					}

					let _relData = {};

					fieldRelations.forEach(rel => {
						if(typeof relationData[rel] !== 'undefined') {
							_relData[rel] = relationData[rel];
						}
					});

					_props['relationData'] = _relData;
				}
			} else if(child?.props?.relation) {
				if(relationData) {
					let fieldRelations = [];
					if(!Array.isArray(child.props.relation)) {
						fieldRelations = [child.props.relation];
					} else {
						fieldRelations = child.props.relation;
					}

					let _relData = {};

					fieldRelations.forEach(rel => {
						if(typeof relationData[rel] !== 'undefined') {
							_relData[rel] = relationData[rel];
						}
					});

					_props['relationData'] = _relData;
				}
			}

			// Assigning dependency value
			if(child?.props?.dependency) {
				let changedData = changes ?? data;

				_props['dependency'] = {
					field: child.props.dependency,
					value: changedData ? changedData[child.props.dependency] ?? null : null
				};
			}

			// Predicate of disabled and hide
			if(typeof child.props?.disablePredicate === 'function') {
				// If condition is met, then disable the input
				if(child.props.disablePredicate(changedValue, Object.assign({}, data, changes))) {
					_props.disabled = true;
				} else {
					_props.disabled = false;
				}
			}

			if(typeof child.props?.hidePredicate === 'function') {
				// If condition is met, then skip this field
				if(child.props.hidePredicate(changedValue, Object.assign({}, data, changes))) {
					return;
				}
			}

			// Prepare new record field with proper props
			let _child = React.cloneElement(child, _props);

			// If field should be located in specific tab then assign it to that tab
			if(!childrens[_child.props.tab]) childrens[_child.props.tab] = [];

			// If field is technical then append it to rest of technical fields. Otherwise assign it to proper tab
			if(_child?.props?.type && _child?.props?.type === 'technical') {
				technicalFields.push((<Col key={`fieldContainer~${controller+'_'+child.props.field}~${childIdx}`} className="form-group" {..._child.props.span}>{ _child }</Col>));
			} else if(_child?.props?.type && _child?.props?.type === 'history') {
				historyFields.push((<Col key={`fieldContainer~${controller+'_'+child.props.field}~${childIdx}`} className="form-group" {..._child.props.span}>{ _child }</Col>));
			} else {
				// Check if field should be hidden
				if(!(typeof _child.props?.hideCondition === 'function' ? _child.props.hideCondition(_props.value ?? null, data, mode) : false)) {
					// Field isn't hidden
					childrens[_child.props.tab].push((<Col key={`fieldContainer~${controller+'_'+child.props.field}~${childIdx}`} className="form-group" {..._child.props.span}>{ _child }</Col>));
				} else {
					// Field is hidden, pass it and hide it with Form.Item in RecordField
					childrens[_child.props.tab].push(_child);
				}
			}
		}

		childIdx++;
	});

	return (<div key={ controller+'_view' }>
		<Row justify="space-between">
			<Col sm={24} lg={16}>
				{
					props?.cards?.length ? props.cards.map((card, idx) => {
						const _CardComponent = card?.cardType === 'collapse' ? CardCollapse : TabbedCard;
						return (<div className={idx ? 'mt-1' : ''} key={`card~${idx}`}>
							{ card?.tabs?.length ? <_CardComponent forceRender={true} title={card?.label ?? null} loading={ props.relations?.length ? (data === null || relationData === null) : data === null } tabList={card.tabs} tabContent={ objectMap(childrens, (v) => card?.cardType === 'collapse' ? (<Row>
								<Col span={24}>
									<Row className="form-group-row" gutter={[16,24]}>
										{v}
									</Row>
								</Col>
							</Row>) : v) } /> : <Card loading={ props.relations?.length ? (data === null || relationData === null) : data === null }>
								<Row>
									<Col span={24}>
										<Row className="form-group-row" gutter={[16,24]}>
											{ childrens.general }
										</Row>
									</Col>
								</Row>
							</Card> }
						</div>);
					}) : (props.tabs.length ?
						<TabbedCard loading={ props.relations?.length ? (data === null || relationData === null) : data === null } tabContent={ childrens } tabList={ props.tabs } activeTabKey={ currentActiveTab ?? null } onTabChange={ (key) => { setCurrentActiveTab(key) } } forceRender={ true } />
						:
						<Card loading={ props.relations?.length ? (data === null || relationData === null) : data === null }>
							<Row>
								<Col span={24}>
									<Row className="form-group-row" gutter={[16,24]}>
										{ childrens.general }
									</Row>
								</Col>
							</Row>
						</Card>)
				}
			</Col>

			<Col sm={24} lg={6}>
				<Card loading={ props.relations?.length ? (data === null || relationData === null) : data === null }>
					<Row>
						<Col span={24}>
							<Row className="form-group-row" gutter={[16,24]}>
								<Col className="form-group" span={24}>
									<RecordField.Date title={'Data ostatniej aktualizacji'} field="updated_at" editable={ false } value={ data ? data.updated_at : null } key={ controller+'_updated_at' } mode={ mode } />
								</Col>
								<Col className="form-group" span={24}>
									<RecordField title={'Osoba aktualizująca'} field="updater_full_name" editable={ false } value={ data ? data.updater_full_name : null } key={ controller+'_updated_at' } mode={ mode } />
								</Col>
								<Col className="form-group" span={24}>
									<RecordField.Date title={'Data utworzenia'} field="created_at" editable={ false } value={ data ? data.created_at : null } key={ controller+'_updated_at' } mode={ mode } />
								</Col>

								{ technicalFields }
							</Row>
						</Col>
					</Row>
				</Card>

				{ historyFields.length ? <Collapse accordion className="mt-1">
					<Collapse.Panel header="Historia statusów">
						<Row>
							<Col span={24}>
								<Row className="form-group-row" gutter={[16,24]}>
									{ historyFields }
								</Row>
							</Col>
						</Row>
					</Collapse.Panel>
				</Collapse>
				: null }
			</Col>
		</Row>
	</div>);
};

/**
 * Renderer of record fields
 */
const RecordField = ({ withFormItem = true, disabled, ...props }) => {
	const value = props.value ?? props.defaultValue;
	const initial = props.rawValue ?? value;

	let item = props?.fromRecordField ?
		props?.children ?? <Input disabled={disabled ? true : false} autoComplete="nope" />
		:
		<Form.Item initialValue={ React.isValidElement(initial) ? null : initial } name={ disabled ? `__${props.field}` : props.field } label={ props.showTitle ?? true === true ? props.title : null } rules={props.rules || []} getValueFromEvent={ props?.getValueFromEvent ?? null } valuePropName={ props?.valuePropName ?? 'value' } normalize={ props?.normalize ?? null } dependencies={ props?.dependencies ?? [] } hidden={(typeof props?.hideCondition === 'function' ? props.hideCondition(props.value ?? null, props.rawData, props.recordData, props.mode) : false)}>
			{ props?.children ?? <Input disabled={disabled ? true : false} autoComplete="nope" /> }
		</Form.Item>;

	if(!withFormItem) {
		item = props?.children ?? <Input disabled={disabled ? true : false} autoComplete="nope" />;
	}

	return (<>
		<div className="form-group">
			{ (props.editing && (props.editable)) ?
				item
				:
				<>
					{ (props.showTitle ?? true) === true ? <label>
						<strong>{ props.title }</strong>
					</label> : null }
					<span className={ props.editable ? 'editable' : '' }>{ value ?? '(brak)' }</span>
				</>
			}
		</div>
	</>);
};

const RecordFieldTextarea = ({ value: _value, disabled, ...props }) => {
	let value = _value ?? props.defaultValue;

	return (<RecordField key={`field~${props.field}`} value={ value ?? null } disabled={disabled} {...props}>
		<Input.TextArea
		          autoSize={{ minRows: 3, maxRows: 6 }}
				  showCount={ (props.maxLength ?? null) >= 0 ? true : false }
				  maxLength={ props.maxLength ?? null }
				  disabled={disabled ? true : false}
				  autoComplete="nope" />
	</RecordField>);
};

const RecordFieldSwitch = ({ value: _value, disabled, ...props }) => {
	let value = _value ?? props.defaultValue;
	const rawValue = value;
	value = value ? 'Tak' : 'Nie';

	return (props?.fromRecordField ? <Switch style={{ width: '55px' }} checkedChildren="Tak" unCheckedChildren="Nie" defaultChecked={ rawValue ? true : false } disabled={disabled ? true : false} /> : <RecordField key={`field~${props.field}`} value={ value ?? null } rawValue={ rawValue ?? false } disabled={disabled} valuePropName="checked" {...props}>
		<Switch style={{ width: '55px' }} checkedChildren="Tak" unCheckedChildren="Nie" defaultChecked={ rawValue ? true : false } disabled={disabled ? true : false} />
	</RecordField>);
};

const RecordFieldCheckbox = ({ value: _value, disabled, ...props }) => {
	let value = _value ?? props.defaultValue;
	const rawValue = value;
	value = value ? 'Tak' : 'Nie';

	return (<RecordField key={`field~${props.field}`} value={ value ?? null } rawValue={ rawValue ?? null } disabled={disabled} {...props}>
		<Checkbox defaultChecked={ rawValue ? true : false } disabled={disabled ? true : false} />
	</RecordField>);
};

const RecordFieldSelect = ({ value: _value, disabled, ...props }) => {
	let value = _value ?? props.defaultValue;
	const rawValue = value ?? null;

	let options = [];

	if(Object.keys(props.options).length) {
		for(let k in props.options) {
			let el = props.options[k];

			if(k === rawValue) value = el;

			options.push((<Option key={`selectOption~${props.key}~${k}`} value={ k }>{ el }</Option>));
		}
	}

	return (<RecordField key={`field~${props.field}`} value={ value ?? null } rawValue={ rawValue } disabled={disabled} {...props}>
		<Select disabled={disabled ? true : false} autoComplete="nope">
			{ options }
		</Select>
	</RecordField>);
};

const RecordFieldDate = ({ value: _value, disabled, ...props }) => {
	let value = _value ?? props.defaultValue;
	const rawValue = value ?? null;

	return (<RecordField key={`field~${props.field}`} value={ value ?? null } rawValue={ rawValue ? moment(rawValue, ['YYYY-MM-DD', 'YYYY-MM-DD HH:mm:ss']) : null } disabled={disabled} {...props}>
		<DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" disabled={disabled ? true : false} autoComplete="nope" />
	</RecordField>);
};

const RecordFieldRelation = ({ value: _value, disabled, dependency, ...props }) => {
	let value = _value ?? props.defaultValue;
	const rawValue = value ?? null;

	const [options, setOptions] = React.useState([]);

	if(props?.selectMode === 'multiple') {
		value = value ? value.map(el => {
			if(Array.isArray(props.relationValueColumn)) {
				let _tmpValues = [];
				props.relationValueColumn.forEach((_rel) => {
					_tmpValues.push(props?.relationValueColumnPrefix ? el[`${props.relationValueColumnPrefix}${_rel}`] : el[_rel]);
				});

				return _tmpValues.join(' ');
			} else {
				return el[props.relationValueColumn];
			}
		}) : null;
	}

	const [_val, setVal] = React.useState(value);

	React.useEffect(() => {
		if(props?.relationData && props?.relationData[props?.relation]) {
			let _data = props.relationData[props?.relation];
			let _options = [];

			let recordData = props.recordData;
			if(dependency) {
				recordData = Object.assign({}, props.recordData, {[dependency.field]: dependency.value});
			}

			if(typeof props.filterData == 'function') {
				_data = props.filterData(_data, recordData, props?.relationData ?? []);
			}

			_data = _data.sort(function(a, b) {
				return a.id - b.id;
			});

			const values = [];


			// Prepare view value
			props.relationData[props?.relation].forEach((el, _) => {
				if(props?.selectMode === 'multiple') {
					if(rawValue && rawValue.map((_el) => _el[props?.relatedIdColumn ?? 'id']).includes(el.id)) {
						if(props?.labelFormat) {
							let __tmpValue = props.labelFormat.replace(/{#([^}]*)}/g, (fullmatch, match) => {
								if(el && el?.hasOwnProperty(match)) {
									return el[match] ?? '(brak)';
								}

								return '(brak)';
							});

							values.push(
								props?.linkRelation ?
								<Link
									to={`/${(props?.linkReferer ?? props.relation)}/${el[(props?.relatedIdColumn ?? 'id')]}`}
								>
									{__tmpValue}
								</Link>
								:
								__tmpValue
							);
						} else if(Array.isArray(props.relationValueColumn)) {
							let _tmpValues = [];
							props.relationValueColumn.forEach((_rel) => {
								_tmpValues.push(el[_rel]);
							});

							values.push(
								props?.linkRelation ?
								<Link
									to={`/${(props?.linkReferer ?? props.relation)}/${el[(props?.relatedIdColumn ?? 'id')]}`}
								>
									{_tmpValues.join(' ')}
								</Link>
								:
								_tmpValues.join(' ')
							);
						} else {
							values.push(
								props?.linkRelation ?
								<Link
									to={`/${(props?.linkReferer ?? props.relation)}/${el[(props?.relatedIdColumn ?? 'id')]}`}
								>
									{el[props.relationValueColumn]}
								</Link>
								:
								el[props.relationValueColumn]
							);
						}
					}
				} else {
					if(+rawValue === el.id) {
						if(props?.labelFormat) {
							let __tmpValue = props.labelFormat.replace(/{#([^}]*)}/g, (fullmatch, match) => {
								if(el && el?.hasOwnProperty(match)) {
									return el[match] ?? '(brak)';
								}

								return '(brak)';
							});

							values.push(
								props?.linkRelation ?
								<Link
									to={`/${(props?.linkReferer ?? props.relation)}/${el[(props?.relatedIdColumn ?? 'id')]}`}
								>
									{__tmpValue}
								</Link>
								:
								__tmpValue
							);
						} else if(Array.isArray(props.relationValueColumn)) {
							let _tmpValues = [];
							props.relationValueColumn.forEach((_rel) => {
								_tmpValues.push(el[_rel]);
							});

							values.push(
								props?.linkRelation ?
								<Link
									to={`/${(props?.linkReferer ?? props.relation)}/${el[(props?.relatedIdColumn ?? 'id')]}`}
								>
									{_tmpValues.join(' ')}
								</Link>
								:
								_tmpValues.join(' ')
							);
						} else {
							values.push(
								props?.linkRelation ?
								<Link
									to={`/${(props?.linkReferer ?? props.relation)}/${el[(props?.relatedIdColumn ?? 'id')]}`}
								>
									{el[props.relationValueColumn]}
								</Link>
								:
								el[props.relationValueColumn]
							);
						}
					}
				}
			});

			// Prepare options for select
			_data.forEach((el, _) => {
				let _viewValue = [];

				if(props?.labelFormat) {
					_viewValue.push(
						props.labelFormat.replace(/{#([^}]*)}/g, (fullmatch, match) => {
							if(el && el?.hasOwnProperty(match)) {
								return el[match] ?? '(brak)';
							}

							return '(brak)';
						})
					);
				} else {
					if(Array.isArray(props.relationValueColumn)) {
						let _tmpValues = [];
						props.relationValueColumn.forEach((_rel) => {
							_tmpValues.push(el[_rel]);
						});

						_viewValue.push(_tmpValues.join(' '));
					} else {
						_viewValue.push(el[props.relationValueColumn]);
					}
				}

				_options.push((<Option key={`selectOption~${props.key}~${_}`} value={ el.id }>{ _viewValue.join(' ') }</Option>));
			});

			setVal(values);

			setOptions(_options);
		}
	}, [dependency, props.relationData]);

	return (props?.fromRecordField ? <Select mode={ props?.selectMode ?? null } placeholder="Wybierz..." disabled={disabled ? true : false} autoComplete="nope" showSearch={props?.allowSearch ? true : false} optionFilterProp="children">
		{ options }
	</Select> : <RecordField key={`field~${props.field}`}
		value={ Array.isArray(_val) ? (_val.length ? (_val.every(el => React.isValidElement(el)) ? <WithSeparator>{_val}</WithSeparator> : _val.join(', ')) : null) : _val }
		rawValue={ props.mode === 'create' ? [] : props?.selectMode === 'multiple' ? (rawValue ? rawValue.map(el => el[props?.relatedIdColumn ?? 'id']) : null) : +rawValue }
		disabled={disabled} field={props?.fromRecordField ? 'id' : props.field} {...props}
		>
		<Select mode={ props?.selectMode ?? null } placeholder="Wybierz..." disabled={disabled ? true : false} autoComplete="nope" showSearch={props?.allowSearch ? true : false} optionFilterProp="children">
			{ options }
		</Select>
	</RecordField>);
};

const RecordFieldTableRelation = ({ value: _value, disabled, ...props }) => {
	const [fields, setFields] = React.useState([]);

	let value = _value ?? props.defaultValue;
	const rawValue = value ?? null;
	let tmpValue = rawValue;

	React.useEffect(() => {
		try {
			if(typeof tmpValue === 'string') {
				tmpValue = JSON.parse(tmpValue);
			}
		} catch(err) {

		}

		// Modify data of field
		if(tmpValue && typeof tmpValue === 'object' && Object.keys(tmpValue).length) {
			if(typeof props?.data === 'function') {
				tmpValue = props.data(tmpValue, props?.recordData, rawValue);
			}
		}

		const values = [];

		if(props.mode === 'view' || props.mode === 'edit') {
			if(tmpValue && typeof tmpValue == 'object' && Object.keys(tmpValue).length) {
				Object.keys(tmpValue).forEach((elKey, idx) => {
					let _fields = [];
					let el = tmpValue[elKey];

					props.fields.forEach((_el, _idx) => {
						_fields.push({
							name: _el.name,
							title: _el.title,
							span: _el.span ?? parseInt((24 / props.fields.length) - (3 / props.fields.length)),
							value: el[_el.name],
							defaultValue: _el.defaultValue ?? null,
							isHidden: _el.isHidden ?? false,
							removeEmpty: _el.removeEmpty ?? false,
							type: _el.type ?? 'text',
							relation: _el.relation ?? null,
							relationValueColumn: _el.relationValueColumn ?? 'name',
							relationIdColumn: _el.relationIdColumn ?? null
						});
					});

					values.push(_fields);
				});
			}
		} else {
			if(props.fields) {
				if(tmpValue && Array.isArray(tmpValue) && tmpValue.length) {
					tmpValue.forEach((el, idx) => {
						let _fs = [];

						props.fields.forEach((_el, _idx) => {
							_fs.push({
								name: _el.name,
								title: _el.title,
								span: _el.span ?? parseInt((24 / props.fields.length) - (3 / props.fields.length)),
								value: el[_el.name],
								defaultValue: _el.defaultValue ?? null,
								isHidden: _el.isHidden ?? false,
								removeEmpty: _el.removeEmpty ?? false,
								type: _el.type ?? 'text',
								relation: _el.relation ?? null,
								relationValueColumn: _el.relationValueColumn ?? 'name',
								relationIdColumn: _el.relationIdColumn ?? null
							});
						});

						values.push(_fs);
					});
				}
			}
		}

		if(!tmpValue || !tmpValue.length) {
			let _fields = [];

			props.fields.forEach((el, idx) => {
				_fields.push({
					name: el.name,
					title: el.title,
					span: el.span || parseInt((24 / props.fields.length) - (3 / props.fields.length)),
					value: el.defaultValue ?? '',
					defaultValue: el.defaultValue ?? null,
					isHidden: el.isHidden ?? false,
					removeEmpty: el.removeEmpty ?? false,
					type: el.type ?? 'text',
					relation: el.relation ?? null,
					relationValueColumn: el.relationValueColumn ?? 'name',
					relationIdColumn: el.relationIdColumn ?? null
				});
			});

			values.push(_fields);
		}

		setFields(values);
	}, [tmpValue]);

	function prepareFieldValueByType(field, val) {
		let value = null;

		switch(field.type) {
			case 'checkbox':
				value = val ? 'Tak' : 'Nie';
				break;
			case 'relation':
				if(props?.relationData && props?.relationData[field?.relation]) {
					let _data = props.relationData[field?.relation];

					value = _data.find((el) => {
						return el.id === val;
					});

					if(value) {
						value = value[field.relationValueColumn] || value.name || null;
					} else {
						value = null;
					}
				}
				break;
			case 'select':
				if(field?.options && Array.isArray(field.options)) {
					value = field.options.find((el) => {
						return el.value === val;
					});

					if(value) {
						value = value?.label || null;
					} else {
						value = null;
					}
				}
				break;
			case 'date':
				if(val instanceof moment) {
					value = val.format('YYYY-MM-DD');
				}
				break;
			case 'string':
			case 'text':
			default:
				value = val;
				break;
		}

		return value ?? '(brak)';
	}

	function prepareFieldInputByType(field, idx, _idx, name) {
		let value = null;
		let options = [];

		switch(field.type) {
			case 'date':
				value = <Form.Item hidden={ field.isHidden ?? false } name={[name, field.name]} fieldKey={[idx, field.name]}><DatePicker format="YYYY-MM-DD" autoComplete="nope" /></Form.Item>;
				break;
			case 'checkbox':
				value = <Form.Item hidden={ field.isHidden ?? false } initialValue={ field.value ? true : false } name={[name, field.name]} valuePropName="checked" fieldKey={[idx, field.name]}><Checkbox /></Form.Item>;
				break;
			case 'relation':
				options = [];

				if(props?.relationData && props?.relationData[field?.relation]) {
					let _data = props.relationData[field?.relation];

					_data = _data.sort(function(a, b) {
						return a.id - b.id;
					});

					_data.forEach((el) => {
						options.push((<Option key={`option~${props.field}~${el.id}`} value={ el.id }>{ el[field.relationValueColumn] || el.name || null }</Option>));
					});
				}

				value = <Form.Item hidden={ field.isHidden ?? false } initialValue={field.value} name={[name, field.name]} fieldKey={[idx, field.name]}>
					<Select autoComplete="nope">
						{ options }
					</Select>
				</Form.Item>;
				break;
			case 'select':
				options = [];

				if(field?.options && Array.isArray(field.options)) {
					field.options.forEach(el => {
						options.push((
							<Option key={`option~${props.field}~${el.value}`} value={ el.value }>
								{ el.label }
							</Option>
						));
					});
				}

				value = <Form.Item hidden={ field.isHidden ?? false } initialValue={field.value} name={[name, field.name]} fieldKey={[idx, field.name]}>
					<Select autoComplete="nope">
						{ options }
					</Select>
				</Form.Item>
				break;
			case 'string':
			case 'text':
			default:
				value = <Form.Item hidden={ field.isHidden ?? false } initialValue={field.value} name={[name, field.name]} fieldKey={[idx, field.name]}><Input placeholder={field.title || ''} autoComplete="nope" /></Form.Item>;
				break;
		}

		if(field?.tooltip) {
			value = <Tooltip title={field.tooltip}>
				{ value }
			</Tooltip>;
		}

		return value;
	}

	value = (<div className="ant-table" key={`tableRelation~preview~${props.field}`}>
		<div className="ant-table-container">
			<div className="ant-table-content">
				<table style={{ tableLayout: 'auto' }}>
					<thead className="ant-table-thead">
						<tr>
							{ props.fields.map((field, idx) => (<React.Fragment key={`theadCol~${idx}`}>
								{ !field.isHidden ? <th className="ant-table-cell">{ field.title }</th> : null }
							</React.Fragment>)) }
						</tr>
					</thead>
					<tbody className="ant-table-tbody">
						{ tmpValue?.length ? tmpValue.map((el, _idx) => (<React.Fragment key={`row~${_idx}`}>
							<tr className="ant-table-row">
								{ props.fields.map((field, idx) => (<React.Fragment key={`col~${idx}_${_idx}`}>
									{ !field.isHidden ? <td key={`fieldCol~${idx}_${_idx}`} className="ant-table-cell">{ prepareFieldValueByType(field, (el[field.name] ?? field.defaultValue) ?? null) }</td> : null }
								</React.Fragment>)) }
							</tr>
						</React.Fragment>)) : '' }
					</tbody>
				</table>
			</div>
		</div>
	</div>);

	return (<RecordField key={`field~${props.field}`} value={ value ?? null } rawValue={ Array.isArray((rawValue ?? [])) ? (rawValue ?? []).map(el => {
		props.fields.map((_field) => {
			if(el.hasOwnProperty(_field.name) && _field?.type === 'date') {
				let _date = moment(el[_field.name]);
				el[_field.name] = _date.isValid() ? _date : null;
			}
		});
		return el;
	}) : (rawValue ?? []) } disabled={disabled} {...props}>
		<Form.List name={`${props.field}`} disabled={disabled ? true : false}>
			{(_fields, { add, remove }) => (
				<>
					<Row align="middle" gutter={[8,8]}>
						{_fields.map((field, idx) => (
							<React.Fragment key={`wrapper~${field.key}`}>
								<React.Fragment key={field.key}>
									{ props.fields.map((_field, _idx) => {
										return !_field.isHidden ?
											<Col {..._field.span}>
												{ prepareFieldInputByType(_field, field.fieldKey, _idx, field.name) }
											</Col>
											:
											prepareFieldInputByType(_field, field.fieldKey, _idx, field.name);
									}) }
								</React.Fragment>
								<React.Fragment key={`actions~${idx}`}>
									<Col xl={4} lg={5}>
										<Button onClick={() => add()}><PlusOutlined /></Button>
										<Button danger style={{ marginLeft: '5px' }} onClick={() => remove(field.name)}><DeleteOutlined /></Button>
									</Col>
								</React.Fragment>
							</React.Fragment>
						))}
					</Row>
					{ _fields.length ? null : <Form.Item>
						<Button type="dashed" onClick={ () => add() } block icon={<PlusOutlined />}>
							Dodaj pozycję
						</Button>
					</Form.Item> }
				</>
			)}
		</Form.List>
		{ /*
		<React.Fragment key={`relationRow~${idx}`}>
			{ _fields.length ?
			<Row align="middle" gutter={[8,8]}>
				{ _fields.map((field, _idx) => (
					<React.Fragment key={`field~${field.name}~${idx}~${_idx}`}>
						{ !field.isHidden ?
						<Col {...field.span}>
							{ prepareFieldInputByType(field, idx, _idx) }
						</Col> : prepareFieldInputByType(field, idx, _idx) }
					</React.Fragment>
				)) }
				<React.Fragment key={`actions~${idx}`}>
					<Col xl={4} lg={5}>
						<Button onClick={() => handleAddFields()}><PlusOutlined /></Button>
						<Button danger style={{ marginLeft: '5px' }} onClick={() => handleRemoveFields(idx)}><DeleteOutlined /></Button>
					</Col>
				</React.Fragment>
			</Row> : null }
		</React.Fragment>
		*/ }
	</RecordField>);
};

const RecordFieldAttachments = ({ value: _value, disabled, field, form, ...props }) => {
	const [attachments, setAttachments] = React.useState({
		list: [],
		add: [],
		del: []
	});

	let value = _value ?? props.defaultValue;
	const rawValue = value ?? null;

	const fileType = (props.relationData && props.relationData[props?.relation]) ? props.relationData[props?.relation].find((el) => {
		return el.name === props.fileCategory;
	}) : null;

	React.useEffect(() => {
		const values = {...attachments};

		if(Array.isArray(_value) && fileType) {
			_value.forEach((el, idx) => {
				if(el.dctfiletype_id === fileType?.id) {
					// Check if file already exists in list
					if(!values.list.find((_el) => _el.uid === el.id))
						values.list.push({
							uid: el.id,
							name: el.file_name,
							file: el,
							category: el.dctfiletype_id
						});
				}
			});
		}

		setAttachments(values);
	}, [props.relationData]);

	const onAttachmentsUpdate = (data) => {
		setAttachments(data);
		if(form) {
			form.setFieldsValue({
				[`${field}~${props.fileCategory}`]: data
			});
		}
	};

	const normFile = e => {
		if (Array.isArray(e)) {
			return e;
		}
		return e && e.fileList;
	};

	value = (<ul className="upload-file-list">
		{ value ? value.filter(file => {
			return file.dctfiletype_id === fileType?.id;
		}).map(file => (
			<li key={`file~${file.id}`}>
				<a href={backendApi + '/storage/app' + file.file_path}>{file.file_name}</a>
			</li>
		)) : null }
	</ul>);

	return (<RecordField key={`field~${field}~${props.fileCategory}`} field={`${field}~${props.fileCategory}`} getValueFromEvent={ normFile } value={ value ?? null } rawValue={ rawValue ?? null } disabled={disabled} {...props}>
		<DraggerUpload id={`${field}~${props.fileCategory}`} data={ attachments } updateAttachments={ onAttachmentsUpdate } fileType={ fileType } disabled={disabled ? true : false}>
			<p className="ant-upload-drag-icon">
				<InboxOutlined />
			</p>

			<p className="ant-upload-text">Kliknij lub przeciągnij plik do tego obszaru, aby dodać załącznik</p>
			<p className="ant-upload-hint">
				TBA
			</p>
		</DraggerUpload>
	</RecordField>);
};

const RecordFieldTable = ({ value: _value, disabled, ...props }) => {
	let value = _value ?? props.defaultValue;

	if(props?.relationData && props.relationData[props?.relation]) {
		let _data = props.relationData[props?.relation];

		if(typeof props.filter == 'function') {
			_data = props.filter(_data, props.recordData);
		}

		value = _data;
	}

	function prepareFieldValueByType(field, val) {
		let value = null;

		switch(field.type) {
			case 'checkbox':
				value = val ? 'Tak' : 'Nie';
				break;
			case 'relation':
				if(props?.relationData?.length) {
					let _data = props.relationData[props?.relation];

					value = _data.find((el) => {
						return el.id === val;
					});

					if(value) {
						value = value[field.relationValueColumn] || value.name || null;
					} else {
						value = null;
					}
				}
				break;
			case 'string':
			case 'text':
			default:
				value = val;
				break;
		}

		return value;
	}

	value = (<div className="ant-table" key={`table~preview~${props.field}`}>
		<div className="ant-table-container">
			<div className="ant-table-content">
				<table style={{ tableLayout: 'auto' }}>
					<thead className="ant-table-thead">
						<tr>
							{ props.fields.map((field, idx) => (<React.Fragment key={`theadCol~${idx}`}>
								{ !field.isHidden ? <th className="ant-table-cell">{ field.title }</th> : null }
							</React.Fragment>)) }
							{ props.showActions ? <th className="ant-table-cell">&nbsp;</th> : null }
						</tr>
					</thead>
					<tbody className="ant-table-tbody">
						{ value?.length ? value.map((el, _idx) => (<React.Fragment key={`row~${_idx}`}>
							<tr className="ant-table-row">
								{ props.fields.map((field, idx) => (<React.Fragment key={`col~${idx}_${_idx}`}>
									{ !field.isHidden ? <td className="ant-table-cell">{ prepareFieldValueByType(field, el[field.name] || field.defaultValue) ?? '(brak)' }</td> : null }
								</React.Fragment>)) }

								{ props.showActions ? <td className="ant-table-cell">
									<Link to={`/${props.relation}/${el.id}`}>Przejdź</Link>
								</td> : null }
							</tr>
						</React.Fragment>)) : '' }
					</tbody>
				</table>
			</div>
		</div>
	</div>);

	return (<RecordField key={`field~${props.field}`} value={ value ?? null } disabled={disabled} {...props} />);
};

const RecordFieldGroup = ({ value: _value, disabled, ...props }) => {
	let value = _value ?? props.defaultValue;
	const rawValue = value ?? null;

	let _values = [];

	props.fields.forEach((field) => {
		if(props?.recordData) {
			if((props.recordData[field.name] ?? null) !== null)
				_values.push(props.recordData[field.name]);
		}
	});

	value = _values.length > 0 ? _values.join(`${props.delimiter}`) : null;

	return (<RecordField key={`field~${props.title}`} value={ value ?? null } rawValue={ rawValue } disabled={disabled} {...props}>
		<Input.Group compact>
			{props.fields.map((field) => {
				return <Form.Item style={{ width: field.width ?? 'auto' }} initialValue={props?.recordData ? (props.recordData[field.name] ?? null) : null} rules={ field.rules ?? null } name={ field.name }>
					<Input placeholder={ field?.title ?? null } disabled={disabled ? true : false} autoComplete="nope" />
				</Form.Item>;
			})}
		</Input.Group>
	</RecordField>);
};

const RecordFieldFields = ({ value: _value, disabled, ...props }) => {
	const [fields, setFields] = React.useState([]);
	const [fieldsCount, setFieldsCount] = React.useState(null);

	let value = _value ?? props.defaultValue;
	const rawValue = value ?? null;

	React.useEffect(() => {
		const values = [];

		if(props.mode === 'view' || props.mode === 'edit') {
			if(rawValue && Array.isArray(rawValue)) {
				rawValue.forEach((el, idx) => {
					let _fields = [];

					props.fields.forEach((_el, _idx) => {
						_fields.push({
							name: _el.name,
							title: _el.title,
							span: _el.span ?? parseInt((24 / props.fields.length) - (3 / props.fields.length)),
							value: el[_el.name],
							defaultValue: _el.defaultValue ?? null,
							isHidden: _el.isHidden ?? false,
							removeEmpty: _el.removeEmpty ?? false,
							type: _el.type ?? 'text',
							relation: _el.relation ?? null,
							relationValueColumn: _el.relationValueColumn ?? 'name',
							relationIdColumn: _el.relationIdColumn ?? null,
							filterData: _el?.filterData ?? null
						});
					});

					values.push(_fields);
				});
			}
		} else {
			if(props.fields) {
				if(rawValue && Array.isArray(rawValue)) {
					rawValue.forEach((el, idx) => {
						let _fs = [];

						props.fields.forEach((_el, _idx) => {
							_fs.push({
								name: _el.name,
								title: _el.title,
								span: _el.span ?? parseInt((24 / props.fields.length) - (3 / props.fields.length)),
								value: el[_el.name],
								defaultValue: _el.defaultValue ?? null,
								isHidden: _el.isHidden ?? false,
								removeEmpty: _el.removeEmpty ?? false,
								type: _el.type ?? 'text',
								relation: _el.relation ?? null,
								relationValueColumn: _el.relationValueColumn ?? 'name',
								relationIdColumn: _el.relationIdColumn ?? null,
								filterData: _el?.filterData ?? null
							});
						});

						values.push(_fs);
					});
				}
			}
		}

		if(!rawValue || !rawValue.length) {
			let _fields = [];

			props.fields.forEach((el, idx) => {
				_fields.push({
					name: el.name,
					title: el.title,
					span: el.span || parseInt((24 / props.fields.length) - (3 / props.fields.length)),
					value: el.defaultValue ?? '',
					defaultValue: el.defaultValue ?? null,
					isHidden: el.isHidden ?? false,
					removeEmpty: el.removeEmpty ?? false,
					type: el.type ?? 'text',
					relation: el.relation ?? null,
					relationValueColumn: el.relationValueColumn ?? 'name',
					relationIdColumn: el.relationIdColumn ?? null,
					filterData: el?.filterData ?? null
				});
			});

			values.push(_fields);
		}

		setFields(values);
	}, [props.relationData]);

	function prepareFieldValueByType(field, val) {
		let value = null;

		switch(field.type) {
			case 'checkbox':
				value = val ? 'Tak' : 'Nie';
				break;
			case 'relation':
				if(props?.relationData && props?.relationData[field?.relation]) {
					let _data = props.relationData[field?.relation];

					if(typeof field?.filterData == 'function') {
						_data = field.filterData(_data, props?.recordData);
					}

					value = _data.find((el) => {
						return el.id === val;
					});

					if(value) {
						value = value[field.relationValueColumn] || value.name || null;
					} else {
						value = null;
					}
				}
				break;
			case 'creatableRelation':
				if(props?.relationData && props?.relationData[field?.relation]) {
					let _data = props.relationData[field?.relation];

					if(typeof field?.filterData == 'function') {
						_data = field.filterData(_data, props?.recordData);
					}

					value = _data.find((el) => {
						return el.id === val;
					});

					if(value) {
						value = value[field.relationValueColumn] || value.name || null;
					} else {
						value = null;
					}
				}
				break;
			case 'string':
			case 'text':
			default:
				value = val;
				break;
		}

		return value ?? '(brak)';
	}

	function prepareFieldInputByType(field, idx, _idx, name) {
		let value = null;
		let options = [];

		switch(field.type) {
			case 'date':
				value = <Form.Item hidden={ field.isHidden ?? false } name={[name, field.name]} fieldKey={[idx, field.name]}><DatePicker format="YYYY-MM-DD" autoComplete="nope" /></Form.Item>;
				break;
			case 'checkbox':
				value = <Form.Item hidden={ field.isHidden ?? false } initialValue={ field.value ? true : false } name={[name, field.name]} valuePropName="checked" fieldKey={[idx, field.name]}><Checkbox /></Form.Item>;
				break;
			case 'relation':
				options = [];

				if(props?.relationData && props?.relationData[field?.relation]) {
					let _data = props.relationData[field?.relation];

					if(typeof field?.filterData == 'function') {
						_data = field.filterData(_data, props?.recordData);
					}

					_data = _data.sort(function(a, b) {
						return a.id - b.id;
					});

					_data.forEach((el) => {
						options.push((<Option key={`option~${props.field}~${el.id}`} value={ el.id }>{ el[field.relationValueColumn] || el.name || null }</Option>));
					});
				}

				value = <Form.Item hidden={ field.isHidden ?? false } initialValue={field.value} name={[name, field.name]} fieldKey={[idx, field.name]}>
					<Select autoComplete="nope">
						{ options }
					</Select>
				</Form.Item>;
				break;
			case 'creatableRelation':
				options = [];

				if(props?.relationData && props?.relationData[field?.relation]) {
					let _data = props.relationData[field?.relation];

					if(typeof field?.filterData == 'function') {
						_data = field.filterData(_data, props?.recordData);
					}

					_data = _data.sort(function(a, b) {
						return a.id - b.id;
					});

					_data.forEach((el) => {
						options.push((<CreatableSelect.Option key={`option~${props.field}~${el.id}`} value={ el.id }>{ el[field.relationValueColumn] || el.name || null }</CreatableSelect.Option>));
					});
				}

				value = <Form.Item hidden={ field.isHidden ?? false } initialValue={field.value} name={[name, field.name]} fieldKey={[idx, field.name]}>
					<CreatableSelect key={`creatableSelect~${objectHash(typeof props.relationData === 'object' ? props.relationData : {})}`} serverside controller={field.controller} field={field.field} fields={field.fields} onCreated={() => { props.updateRelationData(); }} autoComplete="nope">
						{ options }
					</CreatableSelect>
				</Form.Item>;
				break;
			case 'number':
				value = <Form.Item hidden={ field.isHidden ?? false } initialValue={field.value} name={[name, field.name]} fieldKey={[idx, field.name]}><InputNumber decimalSeparator="." formatter={(value) => value.replace(/,/, '.')} parser={(value) => value.replace(/,/, '.')} precision={2} placeholder={field.title || ''} autoComplete="nope" style={{ width: "100%" }} /></Form.Item>;
				break;
			case 'string':
			case 'text':
			default:
				value = <Form.Item hidden={ field.isHidden ?? false } initialValue={field.value} name={[name, field.name]} fieldKey={[idx, field.name]}><Input placeholder={field.title || ''} autoComplete="nope" /></Form.Item>;
				break;
		}

		if(field?.tooltip) {
			value = <Tooltip title={field.tooltip}>
				{ value }
			</Tooltip>;
		}

		return value;
	}

	// Listen for changes (count of rows) in Form.List's rows
	React.useEffect(() => {
		// New value of row count
		const field = props?.form?.getFieldValue(props.field);
		let _fieldsCount = field?.length;

		if(fieldsCount !== null && fieldsCount < _fieldsCount) {
			// Get previous row
			const previousRow = field.slice(-2, -1);

			// New row appeared
			props.fields.forEach(_field => {
				if(_field?.copyValueFromPreviousRow && previousRow.length) {
					// If new row is undefined then set it to object
					if(typeof field[_fieldsCount - 1] !== 'object') {
						field[_fieldsCount - 1] = {};
					}

					// Assign new value
					Object.assign(field[_fieldsCount - 1], { [_field.name]: previousRow[0][_field.name] ?? null });
				}
			});

			// Set new field value
			props.form.setFieldsValue({ [props.field]: field });
		}

		// Set new value of row count
		setFieldsCount(_fieldsCount);
	}, [props?.form?.getFieldValue(props.field)?.length]);

	value = (<div className="ant-table" key={`tableRelation~preview~${props.field}`}>
		<div className="ant-table-container">
			<div className="ant-table-content">
				<table style={{ tableLayout: 'auto' }}>
					<thead className="ant-table-thead">
						<tr>
							{ props.fields.map((field, idx) => (<React.Fragment key={`theadCol~${idx}`}>
								{ !field.isHidden ? <th className="ant-table-cell">{ field.title }</th> : null }
							</React.Fragment>)) }
						</tr>
					</thead>
					<tbody className="ant-table-tbody">
						{ rawValue?.length ? rawValue.map((el, _idx) => (<React.Fragment key={`row~${_idx}`}>
							<tr className="ant-table-row">
								{ props.fields.map((field, idx) => (<React.Fragment key={`col~${idx}_${_idx}`}>
									{ !field.isHidden ? <td key={`fieldCol~${idx}_${_idx}`} className="ant-table-cell">{ prepareFieldValueByType(field, (el[field.name] ?? field.defaultValue) ?? null) }</td> : null }
								</React.Fragment>)) }
							</tr>
						</React.Fragment>)) : '' }
					</tbody>
				</table>
			</div>
		</div>
	</div>);

	return (<RecordField key={`field~${props.field}`} value={ value ?? null } rawValue={ rawValue ?? [] } disabled={disabled} {...props}>
		<Form.List name={`${props.field}`} disabled={disabled ? true : false}>
			{(_fields, { add, remove }) => (
				<>
					<Row align="middle" gutter={[8,8]}>
						{_fields.map((field, idx) => (
							<React.Fragment key={`wrapper~${field.key}`}>
								<React.Fragment key={field.key}>
									{ props.fields.map((_field, _idx) => {
										return !_field.isHidden ?
											<Col {..._field.span}>
												{ prepareFieldInputByType(_field, field.fieldKey, _idx, field.name) }
											</Col>
											:
											prepareFieldInputByType(_field, field.fieldKey, _idx, field.name);
									}) }
								</React.Fragment>
								<React.Fragment key={`actions~${idx}`}>
									<Col xl={4} lg={5}>
										<Button onClick={() => add()}><PlusOutlined /></Button>
										<Button danger style={{ marginLeft: '5px' }} onClick={() => remove(field.name)}><DeleteOutlined /></Button>
									</Col>
								</React.Fragment>
							</React.Fragment>
						))}
					</Row>
					{ _fields.length ? null : <Form.Item>
						<Button type="dashed" onClick={ () => add() } block icon={<PlusOutlined />}>
							Dodaj pozycję
						</Button>
					</Form.Item> }
				</>
			)}
		</Form.List>
	</RecordField>);
};

const RecordFieldTableCheck = ({ value: _value, disabled, ...props }) => {
	let value = _value ?? props.defaultValue;
	const rawValue = value ?? null;

	value = <div className="ant-table" key={`tableRelation~preview~${props.field}`}>
		<div className="ant-table-container">
			<div className="ant-table-content">
				<table style={{ tableLayout: 'auto' }}>
					<thead className="ant-table-thead">
						<tr>
							{ props.fields.map((field, idx) => (<React.Fragment key={`theadCol~${idx}`}>
								<th className="ant-table-cell">{ field.title }</th>
							</React.Fragment>)) }
							<th>Aktywne</th>
						</tr>
					</thead>
					<tbody className="ant-table-tbody">
						{
							props.relationData && props.relationData[props?.relatedTable] ? props.relationData[props?.relatedTable].map((row, idx) => {
								const isChecked = rawValue ? rawValue.find(el => el.id === row.id) ? true : false : false;

								return (<tr key={idx}>
									{ props.fields.map((field, _idx) => {
										return row[field.name] ? <td key={[props.field, idx, _idx]}>{row[field.name]}</td> : null;
									}) }
									<td key={[idx, 'col', 'checkbox']}>
										{isChecked ? <Tag key={['tag', idx]} color="green">TAK</Tag> : <Tag key={['tag', idx]} color="red">NIE</Tag>}
									</td>
								</tr>);
							}) : null
						}
					</tbody>
				</table>
			</div>
		</div>
	</div>;

	return (<RecordField key={`field~${props.field}`} value={ value ?? null } rawValue={ rawValue ?? null } disabled={disabled} withFormItem={false} {...props}>
		<div className="ant-table" key={`tableRelation~preview~${props.field}`}>
			<div className="ant-table-container">
				<div className="ant-table-content">
					<table style={{ tableLayout: 'auto' }}>
						<thead className="ant-table-thead">
							<tr>
								{ props.fields.map((field, idx) => (<React.Fragment key={`theadCol~${idx}`}>
									<th className="ant-table-cell">{ field.title }</th>
								</React.Fragment>)) }
								<th>Aktywne</th>
							</tr>
						</thead>
						<tbody className="ant-table-tbody">
							{
								props.relationData && props.relationData[props?.relatedTable] ? props.relationData[props?.relatedTable].map((row, idx) => {
									const isChecked = rawValue ? rawValue.find(el => el.id === row.id) ? true : false : false;

									return (<tr key={idx}>
										{ props.fields.map((field, _idx) => {
											return row[field.name] ? <td key={[props.field, 'col', idx, _idx]}>{row[field.name]}</td> : null;
										}) }
										<td key={['row', idx]}>
											<Form.Item key={[props.field, idx, 'field', 'checked']} name={[props.field, idx, 'checked']} valuePropName="checked" fieldKey={[props.field, idx, 'checked']} initialValue={isChecked}>
												<Checkbox disabled={disabled ? true : false} />
											</Form.Item>

											<Form.Item key={[props.field, idx, 'field', 'id']} name={[props.field, idx, 'id']} fieldKey={[props.field, idx, 'id']} initialValue={row.id} hidden>
												<Input value={row.id} />
											</Form.Item>
										</td>
									</tr>);
								}) : null
							}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</RecordField>);
};

const RecordFieldAccordion = ({ value: _value, disabled, ...props }) => {
	let value = _value ?? props.defaultValue;
	const rawValue = value ?? null;

	return (<>

	</>);
};

const RecordFieldRecord = ({ value: _value, disabled, ...props }) => {
	let value = _value ?? props.defaultValue;
	const rawValue = value ?? null;

	const _initial = [];

	props.recordData && props.recordData[props.field] && props.recordData[props.field].forEach((row, idx) => {
		let _tmpRow = {};

		React.Children.map(props.fields ?? [], f => {
			let _fValue = null;
			if(Array.isArray(f.props.field)) {
				_fValue = getValueFromArray(props.recordData[props.field] ?? {}, f.props.field);
			} else {
				_fValue = props.recordData ? props.recordData[props.field][idx] ?? null : null;
			}

			switch(f?.type?.displayName) {
				case 'RecordFieldRelation':
					if(f.props?.selectMode === 'multiple') {
						_tmpRow[f.props.field] = _fValue ? (_fValue[f.props.field] ?? []).map(fel => fel.id) : null;
					} else {
						_tmpRow[f.props.field] = _fValue ? _fValue[f.props.field] ?? null : null;
					}
					break;
				case 'RecordFieldAccordion':
					f.props?.accords.forEach(_accord => {
						_accord.fields.forEach(_accordField => {
							_tmpRow[_accordField.props.field] = props.recordData ? (props.recordData[props.field][idx] && props.recordData[props.field][idx][_accordField.props.field]) ?? null : null;
						});
					});
					break;
				default:
					_tmpRow[f.props.field] = _fValue ? _fValue[f.props.field] ?? null : null;
					break;
			}
		});

		_initial.push(_tmpRow);
	});

	value = rawValue?.map((f, idx) => {
		return (<Card style={idx ? {marginTop: 20} : {}}>
			<Row>
				<Col span={24}>
					<Row className="form-group-row" gutter={[16,24]}>
						{ React.Children.map(props.fields ?? [], f => {
							if(React.isValidElement(f)) {
								let _fValue = null;

								if(Array.isArray(f.props.field)) {
									_fValue = getValueFromArray(props.recordData[props.field] ?? {}, f.props.field)[f.props.field];
								} else {
									_fValue = (props.recordData && props.recordData[props.field][idx]) ? props.recordData[props.field][idx][f.props.field] ?? null : null;
								}

								let _props = {};
								let options = [];

								switch(f.type.displayName) {
									case 'RecordFieldSwitch':
										_fValue = _fValue ? 'Tak' : 'Nie';
										// _recordField = <Switch style={{ width: '55px' }} checkedChildren="Tak" unCheckedChildren="Nie" disabled={f.props?.disabled ? true : false} {..._fieldProps} />;
										break;
									case 'RecordFieldRelation':
										let _tmpViewValues = [];
										if(props.relationData && props.relationData[f.props?.relation]) {
											props.relationData[f.props.relation].forEach((el, _) => {
												if(f.props?.selectMode === 'multiple') {
													if(Array.isArray(f.props.relationValueColumn)) {
														let _tmpValues = [];
														f.props.relationValueColumn.forEach((_rel) => {
															_tmpValues.push(el[_rel]);
														});

														if(Array.isArray(_fValue) && _fValue.find(fel => fel.id === el.id)) {
															_tmpViewValues.push(_tmpValues);
														}
													} else {
														if(Array.isArray(_fValue) && _fValue.find(fel => fel.id === el.id)) {
															_tmpViewValues.push(el[f.props.relationValueColumn]);
														}
													}
												} else {
													if(f.props?.labelFormat) {
														let _tmpValues = f.props.labelFormat.replace(/{#([^}]*)}/g, (fullmatch, match) => {
															if(el && el?.hasOwnProperty(match)) {
																return el[match] ?? '(brak)';
															}

															return '(brak)';
														});

														if(Array.isArray(_fValue) && _fValue.find(fel => fel.id === el.id)) {
															_tmpViewValues.push(_tmpValues);
														}
													} else {
														if(Array.isArray(f.props.relationValueColumn)) {
															let _tmpValues = [];
															f.props.relationValueColumn.forEach((_rel) => {
																_tmpValues.push(el[_rel]);
															});

															if(Array.isArray(_fValue) && _fValue.find(fel => fel.id === el.id)) {
																_tmpViewValues.push(_tmpValues);
															}
														} else {
															if(Array.isArray(_fValue) && _fValue.find(fel => fel.id === el.id)) {
																_tmpViewValues.push(el[f.props.relationValueColumn]);
															}
														}
													}
												}
											});
										}

										_fValue = _tmpViewValues.join(', ');
										break;
									case 'RecordFieldSelect':
										if(Object.keys(f.props.options).length) {
											for(let k in f.props.options) {
												let el = f.props.options[k];

												if(k === _fValue) _fValue = el;
											}
										}
										break;
									case 'RecordFieldAccordion':
										_fValue = <Collapse>
											{f.props.accords.map(accord => {
												return <Collapse.Panel header={accord.title} key={[props.field, 'accordion', accord.name]}>
													<Row>
														<Col span={24}>
															<Row className="form-group-row" gutter={[16,24]}>
																{React.Children.map(accord.fields ?? [], _accordField => {
																	let _accordValue = (props.recordData && props.recordData[props.field][idx]) ? props.recordData[props.field][idx][_accordField.props.field] ?? null : null;

																	if(React.isValidElement(_accordField)) {
																		switch(f?.type?.displayName) {
																			case 'RecordFieldRelation':

																				break;
																		}
																	}

																	return (<Col key={`fieldContainer~${props.controller+'_'+_accordField.props.field}~${idx}`} className="form-group" {..._accordField.props.span}>
																		{ (_accordField.props.showTitle ?? true) === true ? <label>
																			<strong>{ _accordField.props.title }</strong>
																		</label> : null }
																		<span className={ props.editable ? 'editable' : '' }>{ _accordValue ?? '(brak)' }</span>
																	</Col>);
																})}
															</Row>
														</Col>
													</Row>
												</Collapse.Panel>;
											})}
										</Collapse>;
										break;
									default:
										break;
								}

								return (<Col key={`fieldContainer~${props.controller+'_'+f.props.field}~${idx}`} className="form-group" {...f.props.span}>
									{ f?.type?.displayName === 'RecordFieldAccordion' ? _fValue : <>
										{ (f.props.showTitle ?? true) === true ? <label>
											<strong>{ f.props.title }</strong>
										</label> : null }
										<span className={ props.editable ? 'editable' : '' }>{ _fValue ?? '(brak)' }</span>
									</> }
								</Col>);
							}

							return f;
						}) }
					</Row>
				</Col>
			</Row>
		</Card>)
	}) ?? null;

	return ((props.editing && (props?.editable ?? true)) ? <Form.Item name={props.field} initialValue={_initial}>
		<Form.List name={props.field} disabled={disabled ? true : false}>
			{(_fields, { add, remove }) => (
				<>
					{_fields.map((field, idx) => (
						<React.Fragment key={`wrapper~${field.key}`}>
							<Card style={idx ? {marginTop: 20} : {}} extra={<Button onClick={() => { remove(field.name) }}>Usuń</Button>}>
								<Row>
									<Col span={24}>
										<Row className="form-group-row" gutter={[16,24]}>
											{ React.Children.map(props.fields ?? [], f => {
												if(React.isValidElement(f)) {
													let _fValue = null;

													if(Array.isArray(f.props.field)) {
														_fValue = getValueFromArray(props.recordData[props.field] ?? {}, f.props.field)[f.props.field];
													} else {
														_fValue = (props.recordData && props.recordData[props.field][field.name]) ? props.recordData[props.field][field.name][f.props.field] ?? null : null;
													}

													let _fieldProps = {};

													if(typeof f.props?.disablePredicate === 'function') {
														// If condition is met, then disable the input
														if(f.props.disablePredicate(props?.recordData[props.field][field.name])) {
															_fieldProps.disabled = true;
														} else {
															_fieldProps.disabled = false;
														}
													}

													let _recordField = <Input autoComplete="nope" {..._fieldProps} />;
													let _props = {};
													let options = [];

													if(f.props?.rules && Array.isArray(f.props.rules)) {
														_props.rules = f.props.rules;
													}

													switch(f.type.displayName) {
														case 'RecordFieldSwitch':
															_props = {
																valuePropName: 'checked'
															};
															_recordField = <Switch style={{ width: '55px' }} checkedChildren="Tak" unCheckedChildren="Nie" disabled={f.props?.disabled ? true : false} {..._fieldProps} />;
															break;
														case 'RecordFieldRelation':
															if(props.relationData && props.relationData[f.props?.relation]) {
																props.relationData[f.props.relation].forEach((el, _) => {
																	if(f.props?.selectMode === 'multiple') {
																		if(Array.isArray(f.props.relationValueColumn)) {
																			let _tmpValues = [];
																			f.props.relationValueColumn.forEach((_rel) => {
																				_tmpValues.push(el[_rel]);
																			});

																			options.push(<Option key={[props.field, field.name, f.props.field, 'option', _]} value={el.id}>{_tmpValues}</Option>);
																		} else {
																			options.push(<Option key={[props.field, field.name, f.props.field, 'option', _]} value={el.id}>{el[f.props.relationValueColumn]}</Option>);
																		}
																	} else {
																		if(f.props?.labelFormat) {
																			let _tmpValues = f.props.labelFormat.replace(/{#([^}]*)}/g, (fullmatch, match) => {
																				if(el && el?.hasOwnProperty(match)) {
																					return el[match] ?? '(brak)';
																				}

																				return '(brak)';
																			});

																			options.push(<Option key={[props.field, field.name, f.props.field, 'option', _]} value={el.id}>{_tmpValues}</Option>);
																		} else {
																			if(Array.isArray(f.props.relationValueColumn)) {
																				let _tmpValues = [];
																				f.props.relationValueColumn.forEach((_rel) => {
																					_tmpValues.push(el[_rel]);
																				});

																				options.push(<Option key={[props.field, field.name, f.props.field, 'option', _]} value={el.id}>{_tmpValues}</Option>);
																			} else {
																				options.push(<Option key={[props.field, field.name, f.props.field, 'option', _]} value={el.id}>{el[f.props.relationValueColumn]}</Option>);
																			}
																		}
																	}
																});
															}

															_recordField = <Select mode={f.props?.selectMode ?? null} placeholder="Wybierz..." disabled={disabled ? true : false} autoComplete="nope" showSearch={f.props?.allowSearch ? true : false} optionFilterProp="children" {..._fieldProps}>
																{ options }
															</Select>;
															break;
														case 'RecordFieldSelect':
															if(Object.keys(f.props.options).length) {
																for(let k in f.props.options) {
																	let el = f.props.options[k];

																	options.push((<Option key={[props.field, field.name, f.props.field, 'option', k]} value={k}>{el}</Option>));
																}
															}

															_recordField = <Select disabled={props?.disabled ? true : false} autoComplete="nope" {..._fieldProps}>
																{options}
															</Select>;
															break;
														case 'RecordFieldAccordion':
															_recordField = <Collapse>
																{f.props.accords.map(accord => {
																	return <Collapse.Panel header={accord.title} key={[props.field, 'accordion', accord.name]}>
																		<Row>
																			<Col span={24}>
																				<Row className="form-group-row" gutter={[16,24]}>
																					{React.Children.map(accord.fields ?? [], _accordField => {
																						let __accordField = null;
																						let _accordProps = {};

																						if(React.isValidElement(_accordField)) {
																							switch(f?.type?.displayName) {
																								case 'RecordFieldRelation':

																									break;
																								default:
																									__accordField = <Input autoComplete="nope" {..._fieldProps} />;
																									break;
																							}
																						}

																						return (<Col key={`fieldContainer~${props.controller+'_'+_accordField.props.field}~${idx}`} className="form-group" {..._accordField.props.span}>
																							<Form.Item {...field} label={_accordField.props.title} name={[field.name, _accordField.props.field]} fieldKey={[field.fieldKey, _accordField.props.field]} {..._accordProps}>
																								{__accordField}
																							</Form.Item>
																						</Col>);
																					})}
																				</Row>
																			</Col>
																		</Row>
																	</Collapse.Panel>;
																})}
															</Collapse>;
															break;
														default:
															break;
													}

													// const _recordField = React.cloneElement(f, _props);

													return (<Col key={`fieldContainer~${props.controller+'_'+f.props.field}~${idx}`} className="form-group" {...f.props.span}>
														{ f?.type?.displayName === 'RecordFieldAccordion' ? _recordField : <Form.Item {...field} label={f.props.title} name={[field.name, f.props.field]} fieldKey={[field.fieldKey, f.props.field]} {..._props}>
															{ _recordField }
														</Form.Item> }
													</Col>);
												}

												return f;
											}) }
										</Row>
									</Col>
								</Row>
							</Card>
						</React.Fragment>
					))}
					{ (props?.rowLimit && (_fields.length < props.rowLimit)) ?? true ? <Form.Item className="mt-2">
						<Button type="dashed" onClick={ () => add() } block icon={<PlusOutlined />}>
							Dodaj pozycję
						</Button>
					</Form.Item> : null }
				</>
			)}
		</Form.List>
	</Form.Item> : value);
};

Record.defaultProps = {
	defaultEditing: false,
	defaultMode: 'list',
	listTitle: 'Lista rekordów',
	createTitle: 'Tworzenie rekordu',
	editTitle: 'Edycja rekordu',
	viewTitle: 'Podgląd rekordu',
	createButtonText: 'Dodaj rekord',
	info: true,
	pagination: true,
	perPage: 20,
	readonly: false,
	defaultValues: {},
	relations: [],
	tabs: [],
	filters: [],
	onModeChange: null,
	disabled: false
};

RecordField.defaultProps = {
	defaultValue: null,
	editable: true,
	createable: true,
	editing: false,
	tab: 'general',
	span: { lg: 24, xl: 12 },
	relationValueColumn: 'name',
	fileCategory: 'Ogólny',
	showTitle: true
};
RecordField.displayName = 'RecordField';

RecordFieldSelect.defaultProps = {
	tab: 'general',
	options: {},
	span: { lg: 24, xl: 12 },
	editable: true,
	createable: true,
};
RecordFieldSelect.displayName = 'RecordFieldSelect';

RecordFieldTextarea.defaultProps = {
	tab: 'general',
	span: { lg: 24, xl: 12 },
	editable: true,
	createable: true
};
RecordFieldTextarea.displayName = 'RecordFieldTextarea';

RecordFieldSwitch.defaultProps = {
	tab: 'general',
	span: { lg: 24, xl: 12 },
	editable: true,
	createable: true,
};
RecordFieldSwitch.displayName = 'RecordFieldSwitch';

RecordFieldCheckbox.defaultProps = {
	tab: 'general',
	span: { lg: 24, xl: 12 },
	editable: true,
	createable: true,
};
RecordFieldCheckbox.displayName = 'RecordFieldCheckbox';

RecordFieldDate.defaultProps = {
	tab: 'general',
	span: { lg: 24, xl: 12 },
	editable: true,
	createable: true,
};
RecordFieldDate.displayName = 'RecordFieldDate';

RecordFieldRelation.defaultProps = {
	tab: 'general',
	span: { lg: 24, xl: 12 },
	relationValueColumn: 'name',
	editable: true,
	createable: true,
};
RecordFieldRelation.displayName = 'RecordFieldRelation';

RecordFieldTableRelation.defaultProps = {
	tab: 'general',
	span: { lg: 24, xl: 12 },
	editable: true,
	createable: true,
	defaultValue: []
};
RecordFieldTableRelation.displayName = 'RecordFieldTableRelation';

RecordFieldAttachments.defaultProps = {
	tab: 'general',
	span: { lg: 24, xl: 12 },
	fileCategory: 'Ogólny',
	editable: true,
	createable: true,
};
RecordFieldAttachments.displayName = 'RecordFieldAttachments';

RecordFieldTable.defaultProps = {
	tab: 'general',
	span: { lg: 24, xl: 12 },
	relationValueColumn: 'name',
	editable: false,
	createable: false,
};
RecordFieldTable.displayName = 'RecordFieldTable';

RecordFieldGroup.defaultProps = {
	tab: 'general',
	span: { lg: 24, xl: 12 },
	editable: true,
	createable: true,
	fields: [],
	delimiter: ', '
};
RecordFieldGroup.displayName = 'RecordFieldGroup';

RecordFieldFields.defaultProps = {};
RecordFieldFields.displayName = 'RecordFieldFields';

RecordFieldTableCheck.defaultProps = {};
RecordFieldTableCheck.displayName = 'RecordFieldTableCheck';

RecordFieldAccordion.defaultProps = {};
RecordFieldAccordion.displayName = 'RecordFieldAccordion';

RecordFieldRecord.defaultProps = {};
RecordFieldRecord.displayName = 'RecordFieldRecord';

RecordField.Textarea = RecordFieldTextarea;
RecordField.Switch = RecordFieldSwitch;
RecordField.Checkbox = RecordFieldCheckbox;
RecordField.Select = RecordFieldSelect;
RecordField.Date = RecordFieldDate;
RecordField.Relation = RecordFieldRelation;
RecordField.TableRelation = RecordFieldTableRelation;
RecordField.Attachments = RecordFieldAttachments;
RecordField.Table = RecordFieldTable;
RecordField.Group = RecordFieldGroup;
RecordField.Fields = RecordFieldFields;
RecordField.TableCheck = RecordFieldTableCheck;
RecordField.Accordion = RecordFieldAccordion;
RecordField.Record = RecordFieldRecord;

Record.Field = RecordField;

export default Record;
