import React from 'react';
import styled from 'styled-components';

import Moment from 'react-moment';

import Record from '../../components/Record/index';

import TabbedCard from '../../components/TabbedCard';

import { Row, Col, Card, Button, Modal, Upload, message, Steps, Tabs, Select, Space, Spin, Table, Alert } from 'antd';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';

import '../../assets/scss/dashboard.scss';

import { APIBackend as API } from '../../api';

const { Step } = Steps;

const { Option } = Select;

const FormItemLabel = styled.label`
	display: block;
	margin-bottom: 5px;
`;

const Scroller = styled.div`
	overflow: hidden;
	overflow-y: auto;
	height: 100%;
`;

const allowedMimeTypes = [
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/vnd.ms-excel'
];

const importErrorColumns = [
	{
		key: 'erroridx',
		title: '#',
		render: (text, row, idx) => {
			return idx + 1;
		}
	},
	{
		dataIndex: 'msg',
		title: 'Treść błędu'
	}
];

const importColumns = [
	{
		dataIndex: 'Wszyscy pracownicy',
		title: 'Wszyscy pracownicy'
	},
	{
		dataIndex: 'Pracownicy nowi',
		title: 'Nowi pracownicy'
	},
	{
		dataIndex: 'Pracownicy istniejący',
		title: 'Pracownicy obecni w systemie'
	},
	{
		dataIndex: 'Pracownicy do zablokowania',
		title: 'Pracownicy do zablokowania'
	},
	{
		dataIndex: 'Pracownicy do usunięcia',
		title: 'Pracownicy do usunięcia'
	},
];

const importPensionerColumns = [
	{
		dataIndex: 'Wszystkie osoby w pliku',
		title: 'Wszystkie osoby w pliku'
	},
	{
		dataIndex: 'Nowi emeryci',
		title: 'Nowi emeryci'
	},
	{
		dataIndex: 'Pracownicy nadal zatrudnieni',
		title: 'Pracownicy nadal zatrudnieni'
	},
	{
		dataIndex: 'Emeryci o zarchiwowanych profilach',
		title: 'Emeryci o zarchiwowanych profilach'
	},
	{
		dataIndex: 'Emeryci już zarejestrowani',
		title: 'Emeryci już zarejestrowani'
	},
	{
		dataIndex: 'Pracownicy nieobecni w systemie',
		title: 'Pracownicy nieobecni w systemie'
	},
	{
		dataIndex: 'Pracownicy, którzy nie założyli profilu',
		title: 'Pracownicy, którzy nie założyli profilu'
	},
];

const Dashboard_Pesels = (props) => {
	const [currentActiveTab, setCurrentActiveTab] = React.useState('general');

	const [currentStep, setCurrentStep] = React.useState(0);
	const [activeTab, setActiveTab] = React.useState('steps_0');
	const [relationData, setRelationData] = React.useState(null);

	const hasNextStep = () => {
		return currentStep + 1 <= 2;
	};
	const hasPrevStep = () => {
		return currentStep - 1 >= 0;
	};

	const nextStep = () => {
		if(hasNextStep()) {
			setCurrentStep(currentStep + 1);
		}
	};
	const prevStep = () => {
		if(hasPrevStep()) {
			setCurrentStep(currentStep - 1);
		}
	};

	React.useEffect(() => {
		setActiveTab(`steps_${currentStep}`);

		if(currentStep === 1) {
			handleUpload();
		}

		if(currentStep === 2) {
			handleImport();
		}
	}, [currentStep, setCurrentStep]);

	React.useEffect(() => {
		API.relations('contractors').then(r => {
			setRelationData(r);
		}).catch(err => {
			setRelationData({});
		});
	}, []);

	const [isModalVisible, setModalVisible] = React.useState(false);
	const [importLoading, setImportLoading] = React.useState(false);
	const [importType, setImportType] = React.useState(null);
	const [contractorId, setContractorId] = React.useState(null);
	const [fileList, setFileList] = React.useState([]);
	const [uploading, setUploading] = React.useState([]);
	const [importResult, setImportResult] = React.useState(null);
	const [importError, setImportError] = React.useState(null);

	const handleImport = () => {
		if(fileList.length && importType && contractorId) {
			setImportLoading(true);
			API.importPesels(importType, fileList[0], contractorId).then(r => {
				console.log(r);
				setImportError(null);
				setImportResult([r]);
			}).catch(err => {
				console.log(err?.response?.data);
				if(err?.response?.data?.errcode ?? 'ERR-UNDEFINED' === 'CHECK-FILE-ERROR') {
					message.error(<>Wystąpił błąd podczas importowania<br/>{err?.response?.data?.message ?? null}</>);
					prevStep();
				} else {
					if(err?.response?.data) {
						const importErrors = [];
						for(let k in err.response.data) {
							if(['debug', 'message'].includes(k)) continue;
							importErrors.push({
								msg: err.response.data[k]
							});
						}
						setImportError(importErrors);
					}
				}
			}).finally(() => {
				setImportLoading(false);
			});
		} else {
			if(!contractorId) {
				message.error('Nie wybrano kontrahenta');
			} else {
				message.error('Nie wybrano żadnego pliku lub rodzaju pliku');
			}

			prevStep();
		}
	};

	const handleUpload = () => {
		if(fileList.length && importType && contractorId) {
			setImportLoading(true);
			API.checkPesels(importType, fileList[0], contractorId).then(r => {
				console.log(r);
				setImportError(null);
				setImportResult([r]);
			}).catch(err => {
				console.log(err?.response?.data);
				if(err?.response?.data?.errcode ?? 'ERR-UNDEFINED' === 'CHECK-FILE-ERROR') {
					message.error(<>Wystąpił błąd podczas importowania<br/>{err?.response?.data?.message ?? null}</>);
					prevStep();
				} else {
					if(err?.response?.data) {
						const importErrors = [];
						for(let k in err.response.data) {
							if(['debug', 'message'].includes(k)) continue;
							importErrors.push({
								msg: err.response.data[k]
							});
						}
						setImportError(importErrors);
					}
				}
			}).finally(() => {
				setImportLoading(false);
			});
		} else {
			if(!contractorId) {
				message.error('Nie wybrano kontrahenta');
			} else {
				message.error('Nie wybrano żadnego pliku lub rodzaju pliku');
			}

			prevStep();
		}
	};

	const uploadProps = {
		maxCount: 1,
		onRemove: file => {
			const index = fileList.indexOf(file);
			const newFileList = fileList.slice();
			newFileList.splice(index, 1);

			setFileList(newFileList);
		},
		beforeUpload: file => {
			if(allowedMimeTypes.includes(file.type)) {
				setFileList([file]);
			} else {
				message.error('Proszę wybrać prawidłowy plik Excel');
			}
			return false;
		},
		fileList
	};

	return (<div className="content">
		<Modal
			title="Importowanie bazy uprawnionych"
			okText="Zamknij"
			onOk={handleUpload}
			cancelText={currentStep === 2 ? 'Zamknij' : 'Anuluj'}
			onCancel={() => {
				setModalVisible(false);
				setCurrentStep(0);
				setContractorId(null);
				setImportType(null);
				setImportResult(null);
				setImportLoading(false);
				setImportError(null);
				setFileList([]);
			}}
			visible={isModalVisible}
			okButtonProps={{
				style: { display: 'none' }
			}}
		>
			<Steps progressDot current={currentStep}>
				<Step title="Wybierz plik" />
				<Step title="Zweryfikuj" />
				<Step title="Wynik" />
			</Steps>

			<Tabs
				size="large"
				forceRender
				renderTabBar={() => null}
				activeKey={activeTab}
				defaultActiveKey="steps_0"
				style={{ marginTop: 25 }}
			>
				<Tabs.TabPane tab="Dane podstawowe" key="steps_0">
					<Scroller>
						<Row gutter={[16,16]}>
							<Col span={24}>
								<FormItemLabel>Rodzaj pliku</FormItemLabel>
								<Select style={{ width: '100%' }} placeholder="Wybierz rodzaj pliku..." value={importType} onChange={(val) => setImportType(val)}>
									<Option value="employees">Pracownicy</Option>
									<Option value="pensioners">Emeryci</Option>
								</Select>
							</Col>

							<Col span={24}>
								<FormItemLabel>Kontrahent</FormItemLabel>
								<Select showSearch optionFilterProp="children" loading={relationData === null} style={{ width: '100%' }} value={contractorId} onChange={(val) => setContractorId(val)} placeholder="Wybierz kontrahenta...">
									{
										relationData && relationData.hasOwnProperty('contractors') && relationData.contractors.map(el => (
											<Option key={['import', 'contractors', el.id]} value={el.id}>
												{el.name}
											</Option>
										))
									}
								</Select>
							</Col>

							<Col span={24}>
								<Upload
									{...uploadProps}
								>
									<Button icon={<UploadOutlined />}>Wybierz plik</Button>
								</Upload>
							</Col>
						</Row>
					</Scroller>
				</Tabs.TabPane>

				<Tabs.TabPane tab="Zweryfikuj" key="steps_1">
					{
						importLoading
							? <Spin />
							: importError
								? <>
									<Alert type="error" message="Wystąpił lub wystąpiły błędy podczas sprawdzania poprawności pliku" style={{ marginBottom: 15 }} />
									<Table columns={importErrorColumns} dataSource={importError} />
								</>
								: <Table columns={importType === 'pensioners' ? importPensionerColumns : importColumns} dataSource={importResult} />
					}
				</Tabs.TabPane>

				<Tabs.TabPane tab="Wynik" key="steps_2">
					{
						importLoading
							? <Spin />
							: importError
								? <>
									<Alert type="error" message="Wystąpił błąd podczas importowania danych" style={{ marginBottom: 15 }} />
								</>
								: <Alert type="success" message="Dane zostały zaimportowane!" style={{ marginBottom: 15 }} />
					}
				</Tabs.TabPane>
			</Tabs>
			{/*<Upload
				{...uploadProps}
			>
				<Button>Wybierz plik</Button>
			</Upload>*/}
			<Row>
				<Col span={24} align="right">
					<Space>
					{
						hasPrevStep()
							? <Button onClick={() => prevStep()}>Wstecz</Button>
							: null
					}
					{
						hasNextStep()
							? <Button type="primary" disabled={importError && currentStep > 0} onClick={() => nextStep()}>Dalej</Button>
							: currentStep !== 2
								? <Button type="primary" disabled={importError} onClick={() => handleUpload()}>Importuj</Button>
								: null
					}
					</Space>
				</Col>
			</Row>
		</Modal>

		<Record
			controller="pesels"
			relations={['contractors']}
			mode={props?.mode}
			defaultEditing={props?.editing}
			extraListActions={<>
				<Button type="primary" onClick={() => {
					setModalVisible(true);
				}}>Importuj</Button>
			</>}
			noCreate
			filters={[
				{
					title: 'Kontrahent',
					name: 'contractor_id',
					relation: 'contractors',
					type: 'enum'
				},
				{
					title: 'Emeryt',
					name: 'is_pension_declaration',
					type: 'preenum',
					enum: [
						{ label: '- wybierz -', value: null },
						{ label: 'TAK', value: 1 },
						{ label: 'NIE', value: 0 }
					]
				}
			]}
			columns={[
				{
					name: 'id',
					title: '#',
					type: 'number'
				},
				{
					name: 'first_name',
					title: 'Imię',
					searchable: true
				},
				{
					name: 'last_name',
					title: 'Nazwisko',
					searchable: true
				},
				{
					name: 'pesel',
					title: 'PESEL',
					searchable: true
				},
				{
					name: 'is_pension_declaration',
					title: 'Emeryt',
					datatype: 'bool',
					orderable: false,
					searchable: false
				},
				{
					name: 'contractor_name',
					title: 'Kontrahent',
					searchable: true,
					sortname: 'contractors.name'
				},
				{
					name: 'nip',
					title: 'NIP',
					searchable: true
				},
				{
					name: 'actions',
					data: null,
					sortable: false,
					title: '',
					render: function(data, type, row) {
						return `<a class="btn btn-table" href="/pesels/${data.id}">Podgląd</a>`;
					},
					className: 'text-right'
				}
			]}
			viewTitle="Podgląd uprawnionego"
			listTitle="Lista uprawnionych"
		>
			<Record.Consumer>
				{({ isLoading }) => (
					<TabbedCard key="generalCard" loading={isLoading} tabContent={{
						general: [
							<Record.Field.Input title={'Imię'} name="first_name" />,
							<Record.Field.Input title={'Nazwisko'} name="last_name" />,
							<Record.Field.Input title={'PESEL'} name="pesel" />,
							<Record.Field.Relation title={'Kontrahent'} linkRelation={true} relation="contractors" name="contractor_id"  />,
							<Record.Field.Input title={'NIP'} name="nip" />
						]
					}} tabList={[
						{ key: 'general', tab: 'Dane podstawowe'}
					]} activeTabKey={currentActiveTab} onTabChange={(key) => { setCurrentActiveTab(key) }} forceRender={true} />
				)}
			</Record.Consumer>
		</Record>
	</div>);
};

export default Dashboard_Pesels;
