import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { useAuth } from '../contexts/User';

const Logout = (props) => {
	const history = useHistory();
	const auth = useAuth();

    useEffect(() => {
		auth.logout().then(() => {
			history.push('/login');
		}).catch(err => console.log(err));
    }, []);

	return (<></>);
};

export default Logout;
