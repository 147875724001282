export const STATUS = {
	DURING_REFILLING: {
		id: 1,
		label: 'W trakcie wprowadzania'
	},
	ACTIVE: {
		id: 2,
		label: 'Aktywny'
	},
	ARCHIVED: {
		id: 3,
		label: 'Archiwalny'
	},
	BACK_TO_CORRECTION: {
		id: 4,
		label: 'Zwrócony do korekty'
	},
	IN_ACCEPTATION: {
		id: 6,
		label: 'W akceptacji'
	},
	IN_COMMISSION: {
		id: 7,
		label: 'Przekazany do komisji'
	},
	CANCELED: {
		id: 8,
		label: 'Anulowany'
	}
};

export const getStatusArray = () => {
	const arr = [];

	for(let k in STATUS) {
		let STA = STATUS[k];

		arr.push({
			name: k,
			id: STA.id,
			label: STA.label
		});
	}

	return arr;
};

export const getStatusById = (id) => {
	for(let k in STATUS) {
		let STA = STATUS[k];

		if(STA.id === id) return STA;
	}

	return null;
};
