import React from 'react';

import { captureException } from '@sentry/react';

import Moment from 'react-moment';

import { Row, Col, Card, Form, Select, Button, Alert, message, DatePicker, Input } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { APIBackend as API } from '../../../api';
import { backendRoot, backendApi, csrfCookie, apiData } from '../../../config/paths';
import axios from 'axios';

import moment from 'moment';

import FileDownload from 'js-file-download';

import '../../../assets/scss/dashboard.scss';

const { Option } = Select;

const MIMES = {
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
	'application/vnd.ms-excel': 'xls',
	'text/csv': 'csv',
	'application/json': 'json',
	'application/rtf': 'rtf',
	'application/xml': 'xml',
	'text/xml': 'xml',
	'text/plain': 'txt'
};

const Dashboard_Reports_Bank = (props) => {
	const [form] = Form.useForm();

	const [relationData, setRelationData] = React.useState(null);
	const [error, setError] = React.useState(false);

	React.useEffect(() => {
		axios.get(backendRoot + csrfCookie).then(() => {
			// Getting data of related records
			axios.get(`${backendApi}${apiData}?tables=${['contractors', 'cnfapps'].join(',')}`).then((response) => {
				// Assigning data of related records to state
				setRelationData(response.data);
				setError(false);
			}).catch((err) => {
				setError({
					errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED',
					message: err?.reponse?.data?.message
				});
			});
		}).catch((err) => {
			setError({
				errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED',
				message: err?.reponse?.data?.message
			});
		});
	}, []);

	const onGenerate = (values) => {
		const _data = new FormData();

		_data.append('contractor_id', values.contractor_id);
		_data.append('app_table', values.app_table);
		_data.append('from', values.from.format('YYYY-MM-DD'));
		_data.append('to', values.to.format('YYYY-MM-DD'));
		_data.append('bank_title', values.bank_title);

		API.getBankReport(_data).then(data => {
			setError(false);
			message.success('Raport został wygenerowany');
			// console.log(data);
			FileDownload(data, `raport-bank-${moment().format('YYYY-MM-DD-hh-mm-ss')}.${MIMES[data.type] ?? 'xlsx'}`);
		}).catch(err => {
			setError({
				errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED',
				message: err?.response?.data?.message ?? null
			});
		});
	};

	return (<div className="content">
		<div className="subheader">
			<div className="d-flex">
				<h1 className="title">Raport przelewów bankowych</h1>
				<span className="subtitle">
					Dzisiaj jest <Moment locale="pl" format="dddd, D MMMM YYYY" />
				</span>
			</div>

			<div className="subheader_actions">
				<Button type="primary" onClick={() => { form.submit(); }}>Wygeneruj</Button>
			</div>
		</div>

		{
			error && <Alert type="error" message={error.message ?? 'Wystąpił nieoczekiwany błąd podczas generowania raportu lub pobierania danych'} style={{ marginBottom: 25 }} />
		}

		<Card loading={relationData === null}>
			<Form
				onFinish={onGenerate}
				layout="vertical"
				form={form}
			>
				<Row gutter={[16,16]}>
					<Col sm={24} md={12}>
						<Form.Item
							label="Kontrahent"
							name="contractor_id"
							rules={[
								{ required: true, message: 'Proszę wybrać kontrahenta' }
							]}
						>
							<Select
								allowSearch
								optionFilterProp="children"
							>
								{relationData?.contractors?.map(el => (
									<Option key={['contractors', el.id]} value={el.id}>
										[{el.symbol}] {el.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>

					<Col sm={24} md={12}>
						<Form.Item
							label="Wniosek"
							name="app_table"
							rules={[
								{ required: true, message: 'Proszę wybrać wniosek' }
							]}
						>
							<Select
								allowSearch
								optionFilterProp="children"
							>
								{relationData?.cnfapps?.map(el => (
									<Option key={['cnfapps', el.id]} value={el.table}>
										{el.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>

					<Col sm={24} md={12}>
						<Form.Item
							label="Od dnia"
							name="from"
							rules={[
								{ required: true, message: 'Proszę wybrać zakres dat' }
							]}
						>
							<DatePicker style={{ width: '100%' }} />
						</Form.Item>
					</Col>

					<Col sm={24} md={12}>
						<Form.Item
							label="Do dnia"
							name="to"
							rules={[
								{ required: true, message: 'Proszę wybrać zakres dat' }
							]}
						>
							<DatePicker style={{ width: '100%' }} />
						</Form.Item>
					</Col>

					<Col sm={24} md={12}>
						<Form.Item
							label="Tytuł przelewu"
							name="bank_title"
							rules={[
								{ required: true, message: 'Proszę uzupełnić tytuł przelewu' }
							]}
						>
							<Input />
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Card>
	</div>);
};

export default Dashboard_Reports_Bank;
