import React from 'react';

class WithSeparator extends React.Component {
	createSeparator(sep, idx) {
		if(typeof sep === 'string') {
			return sep;
		}

		return React.cloneElement(sep, { key: `separator~${idx}` });
	}

	render() {
		const children = React.Children.toArray(this.props.children);

		return children.map((child, idx) => {
			return (<>
				{ idx > 0 && this.createSeparator(this.props.separator, idx) }
				{ child }
			</>);
		});
	}
}

WithSeparator.defaultProps = {
	separator: ', '
};

export default WithSeparator;
