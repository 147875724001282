import React from 'react';

import RecordBase from './base';

class Record extends RecordBase {
	static propTypes = Object.assign({}, RecordBase.propTypes, {

	});

	
}

export default Record;
