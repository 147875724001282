import React from 'react';
import PropTypes from 'prop-types';

import Record_Field from './Field';

import { Form, Input, Card, Row, Col, Button } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import moment from 'moment';

class RecordFieldRecord extends Record_Field {
	static propTypes = Object.assign({}, Record_Field.propTypes, {
		fields: PropTypes.array.isRequired,
		rowLimit: PropTypes.number
	});

	static defaultProps = Object.assign({}, Record_Field.defaultProps, {
		span: { span: 24 }
	});

	static displayName = 'RecordFieldRecord';

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	getRawValue() {
		const {
			fields
		} = this.props;

		let value = super.getRawValue();

		return (value ?? []).map(el => {
			fields.map(_field => {
				if(el && el.hasOwnProperty(_field.props.name)) {
					switch(_field?.type.displayName) {
						case 'RecordFieldDate':
							let date = moment(el[_field.props.name]);
							el[_field.props.name] = date.isValid() ? date : null;
							break;
						case 'RecordFieldRelation':
							if(Array.isArray(el[_field.props.name])) {
								el[_field.props.name] = el[_field.props.name] ? el[_field.props.name].map(__el => {
									if(typeof __el === 'object') {
										return __el ? (__el[_field.props.idKey] ?? __el?.id) : null;
									} else {
										return __el;
									}
								}) : null;
							}
							break;
						default:
							break;
					}
				}
			});

			return el;
		});
	}

	getValue() {
		const {
			name,
			disabled,
			fields,
			rowLimit
		} = this.props;

		return <>
			{this.getRawValue().map((row, idx) => (
				<Card style={idx ? {marginTop: 20} : {}} key={`wrapper~${name}~${idx}`}>
					<Row>
						<Col span={24}>
							<Row className="form-group-row" gutter={[16,24]}>
								{React.Children.map(fields ?? [], f => {
									if(React.isValidElement(f)) {
										let fieldName = [name, idx, f.props.name];

										return React.cloneElement(f, {
											name: fieldName,
											namePrefix: [idx],
											accordFieldPrefix: [name],
											registerField: false
										});
									}
								})}
							</Row>
						</Col>
					</Row>
				</Card>
			))}
		</>;
	}

	renderEdit() {
		const {
			title,
			name,
			disabled,
			rowLimit,
			fields
		} = this.props;

		return <Form.Item
			{...this.globalItemProps}
		>
			<Form.List
				name={name}
				disabled={disabled}
			>
				{(_fields, { add, remove }) => (
					<>
						{_fields.map((field, idx) => (
							<Card style={idx ? {marginTop: 20} : {}} extra={<Button onClick={() => { remove(field.name); }}>Usuń</Button>} key={`wrapper~${name}~${field.key}`}>
								<Row>
									<Col span={24}>
										<Row className="form-group-row" gutter={[16,24]}>
											{React.Children.map(fields ?? [], f => {
												if(React.isValidElement(f)) {
													let fieldName = [field.name, f.props.name];

													return React.cloneElement(f, {
														name: fieldName,
														namePrefix: [field.name],
														key: ['recordField', name, field.key, field.name],
														registerField: false
													});
												}
											})}
										</Row>
									</Col>
								</Row>
							</Card>
						))}
						{ (rowLimit && (_fields.length < rowLimit)) ?? true ? <Form.Item className="mt-2">
							<Button type="dashed" onClick={ () => add() } block icon={<PlusOutlined />}>
								Dodaj pozycję
							</Button>
						</Form.Item> : null }
					</>
				)}
			</Form.List>
		</Form.Item>;
	}
}

export default RecordFieldRecord;
