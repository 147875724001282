import React from 'react';
import PropTypes from 'prop-types';

import Record_Field from './Field';

import { Form, Checkbox } from 'antd';

class RecordFieldCheckbox extends Record_Field {
	static propTypes = Object.assign({}, Record_Field.propTypes, {

	});

	static defaultProps = Object.assign({}, Record_Field.defaultProps, {

	});

	static displayName = 'RecordFieldCheckbox';

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	getValue() {
		return this.getRawValue() ? 'Tak' : 'Nie';
	}

	renderEdit() {
		const {
			title,
			name,
			disabled
		} = this.props;

		return <Form.Item
			{...this.globalItemProps}
		>
			<Checkbox autoComplete="nope" disabled={disabled} />
		</Form.Item>;
	}
}

export default RecordFieldCheckbox;
