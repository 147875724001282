import { Tooltip, Form, Input, DatePicker, Checkbox, Select, InputNumber } from 'antd';

import CreatableSelect from '../../../../components/CreatableSelect';

import moment from 'moment';
import _ from 'lodash';
import { assignDataToString } from '../../../../libs/Helpers.js';

const { Option } = Select;

export const prepareFieldValue = (field, value, relationData = {}, recordData = {}) => {
	let val = null;

	switch(field.type) {
		case 'date':
			if(value instanceof moment) {
				val = value.format(field?.format ?? 'YYYY-MM-DD');
			}
			break;
		case 'checkbox':
			val = value ? 'Tak' : 'Nie';
			break;
		case 'select':
			if(field?.options && Array.isArray(field.options)) {
				val = field.options.find(el => el.value === value);

				val = val ? assignDataToString(field?.labelFormat ?? '{#label}', val) : null;
			}
			break;
		case 'relation':
			if(relationData && field?.relation && relationData.hasOwnProperty(field.relation)) {
				let _data = relationData[field.relation];

				val = _data.find(el => (el[field?.valueKey] ?? el.id) === value);

				val = val ? assignDataToString(field?.labelFormat ?? '{#name}', val) : null;
			}
			break;
		case 'creatableRelation':
			if(relationData && field?.relation && relationData.hasOwnProperty(field.relation)) {
				let _data = relationData[field.relation];

				if(typeof field?.filterData === 'function') {
					_data = field.filterData(_data, recordData, relationData);
				}

				val = _data.find(el => el.id === value);

				if(val) {
					val = val[field.relationValueColumn] || val.name || null;
				} else {
					val = null;
				}
			}
			break;
		default:
			val = value;
			break;
	}

	return val ?? '(brak)';
};

export const prepareFieldInput = (field, name, key, relationData = {}, recordData = {}, updateRelationData = () => {}, row, form) => {
	let input = null;
	let options = [],
		disabled = false;

	switch(field.type) {
		case 'date':
			input = <Form.Item
				name={name}
				fieldKey={key}
				hidden={field?.isHidden ?? false}
				{...(field?.fieldProps ?? {})}
			>
				<DatePicker style={{ width: '100%' }} placeholder={field?.title ?? 'Wybierz datę'} format={field?.format ?? 'YYYY-MM-DD'} autoComplete="nope" defaultValue={null} />
			</Form.Item>;
			break;
		case 'checkbox':
			input = <Form.Item
				name={name}
				fieldKey={key}
				hidden={field?.isHidden ?? false}
				valuePropName="checked"
				onChange={(e) => {
					const _val = e.target.checked;
					if(typeof field?.onChange === 'function') {
						field.onChange(name, _val, recordData, form);
					}
				}}
				{...(field?.fieldProps ?? {})}
			>
				<Checkbox />
			</Form.Item>;
			break;
		case 'select':
			options = [];

			if(field?.options && Array.isArray(field.options)) {
				field.options.forEach(el => {
					options.push(<Option
						key={_.uniqueId('option_')}
						value={el.value}
					>
						{el.label}
					</Option>);
				});
			}

			input = <Form.Item
				name={name}
				fieldKey={key}
				hidden={field?.isHidden ?? false}
				{...(field?.fieldProps ?? {})}
			>
				<Select
					autoComplete="nope"
				>
					{options}
				</Select>
			</Form.Item>;
			break;
		case 'relation':
			options = [];

			if(relationData && field?.relation && relationData.hasOwnProperty(field.relation)) {
				let _data = relationData[field.relation];

				if(typeof field?.filterData === 'function') {
					_data = field.filterData(_data, recordData, relationData);
				}

				_data = _data.sort((a, b) => (a[field?.valueKey] ?? a.id) - (b[field?.valueKey] ?? b.id));

				_data.forEach(el => {
					let optionValue = assignDataToString(field?.labelFormat ?? '{#name}', el),
						optionKey = el[field?.valueKey] ?? el.id;

					options.push(<Option
						key={_.uniqueId('option_')}
						value={optionKey}
					>
						{optionValue}
					</Option>);
				});

				disabled = field?.disablePredicate?.(_data, recordData, relationData, row) ?? false;
			}

			input = <Form.Item
				name={name}
				fieldKey={key}
				hidden={field?.isHidden ?? false}
				{...(field?.fieldProps ?? {})}
			>
				<Select
					autoComplete="nope"
					disabled={disabled}
				>
					{options}
				</Select>
			</Form.Item>;
			break;
		case 'creatableRelation':
			options = [];

			if(relationData && field?.relation && relationData.hasOwnProperty(field.relation)) {
				let _data = relationData[field.relation];

				if(typeof field?.filterData === 'function') {
					_data = field.filterData(_data, recordData, relationData);
				}

				_data = _data.sort((a, b) => a.id - b.id);

				_data.forEach(el => {
					options.push(
						<CreatableSelect.Option key={_.uniqueId('option_')} value={el.id}>
							{el[field.relationValueColumn] || el.name || null}
						</CreatableSelect.Option>
					);
				});
			}

			input = <Form.Item
				name={name}
				fieldKey={key}
				hidden={field.isHidden ?? false}
				{...(field?.fieldProps ?? {})}
			>
				<CreatableSelect serverside controller={field.controller} field={field.field} fields={field.fields} onCreated={() => { updateRelationData() }} autoComplete="nope">
					{options}
				</CreatableSelect>
			</Form.Item>;
			break;
		case 'number':
			input = <Form.Item
				name={name}
				fieldKey={key}
				hidden={field?.isHidden ?? false}
				{...(field?.fieldProps ?? {})}
			>
				<InputNumber precision={2} delimiter="." placeholder={field?.title ?? ''} autoComplete="nope" style={{ width: '100%' }} />
			</Form.Item>;
			break;
		default:
			input = <Form.Item
				name={name}
				fieldKey={key}
				hidden={field?.isHidden ?? false}
				{...(field?.fieldProps ?? {})}
			>
				<Input placeholder={field?.title ?? ''} autoComplete="nope" />
			</Form.Item>;
			break;
	}

	if(field?.tooltip) {
		input = <Tooltip title={field.tooltip}>
			{input}
		</Tooltip>;
	}

	return input;
};
