import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import { Drawer } from 'antd';

import { DownOutlined, RightOutlined } from '@ant-design/icons';

import '../../assets/scss/menu.scss';

class Menu extends React.Component {
	render() {
		return (<>
			<Drawer
				className="drawer-menu"
				title="Menu"
				placement="left"
				onClick={() => this.props?.setVisible(false)}
				onClose={() => this.props?.setVisible(false)}
				visible={this.props.isVisible}
				width={350}
			>
				<ul className="menu-nav mobile">
					{ this.props.children }
				</ul>
			</Drawer>
			<ul className="menu-nav">
				{ this.props.children }
			</ul>
		</>);
	}
}

class MenuSubmenu extends React.Component {
	state = {
		isVisible: false
	};

	constructor(props) {
		super(props);

		this.toggleVisibility = this.toggleVisibility.bind(this);
	}

	toggleVisibility() {
		this.setState({
			isVisible: !this.state.isVisible
		});
	}

	render() {
		const children = React.Children.map(this.props.children, child => {
            if(React.isValidElement(child)) {
                return React.cloneElement(child, { parent: this });
            }

            return child;
        });

		return (<ul className="menu-subnav">
			{ children }
		</ul>);
	}
}

class MenuItem extends React.Component {
	state = {
		isVisible: false
	};

	constructor(props) {
		super(props);

		this.toggleVisibility = this.toggleVisibility.bind(this);
	}

	toggleVisibility() {
		this.setState({
			isVisible: !this.state.isVisible
		});
	}

	render() {
		let self = this;

		const children = React.Children.map(this.props.children, child => {
            if(React.isValidElement(child)) {
                return React.cloneElement(child, { parent: self.props.parent || null, badge: self.props?.badge ?? null });
            }

            return child;
        });

		return (<>
			{ this.props.submenu ? <li className={ 'menu-item menu-item-submenu'+(this.state.isVisible ? ' menu-item-open' : '')+(this.props.parent ? ' menu-submenu-right menu-submenu-noroot' : '') } onMouseEnter={ this.toggleVisibility } onMouseLeave={ this.toggleVisibility }>
			<Link to="#" className="menu-link">
				{ this.props?.icon ? <span className="menu-icon">
					{ this.props.icon }
				</span> : null }
				<span className="menu-text">
					{ this.props.title || '' }
				</span>
				{ this.props.badge ?
				<span className="menu-badge">
					{ this.props?.badge ?? null }
				</span>
				: null }
				<span className="menu-arrow">
					{ this.props.parent ? <RightOutlined /> : <DownOutlined /> }
				</span>
			</Link>

			<div className="menu-submenu">
				{ this.props.children }
			</div>
		</li> : <li className="menu-item">
			<Link to={ this.props.to } className="menu-link">
				{ this.props?.icon ? <span className="menu-icon">
					{ this.props.icon }
				</span> : null }
				<span className="menu-text">
					{ this.props.children }
				</span>
				{ this.props.badge ?
				<span className="menu-badge">
					{ this.props?.badge ?? null }
				</span>
				: null }
			</Link>
		</li> }
		</>);
	}
}

MenuItem.defaultProps = {
	submenu: false,
	parent: null,
	icon: null
};

Menu.Item = MenuItem;
Menu.Submenu = MenuSubmenu;

export default Menu;
