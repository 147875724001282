import React from 'react';
import PropTypes from 'prop-types';

import reactComponentDebounce from 'react-component-debounce';

import Record_Field from './Field';

import { Form, Input } from 'antd';

const DebouncedInput = reactComponentDebounce({
	valuePropName: 'value',
	triggerMs: 250
})(Input);

class RecordFieldInput extends Record_Field {
	static propTypes = Object.assign({}, Record_Field.propTypes, {
		maxLength: PropTypes.number
	});

	static defaultProps = Object.assign({}, Record_Field.defaultProps, {

	});

	static displayName = 'RecordFieldInput';

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	renderEdit() {
		const {
			title,
			name,
			disabled,
			debounced,
			placeholder,
			maxLength = undefined,

		} = this.props;

		return <Form.Item
			{...this.globalItemProps}
		>
			{debounced ? <DebouncedInput autoComplete="nope" disabled={disabled} /> : <Input autoComplete="nope" disabled={disabled} placeholder={placeholder} maxLength={maxLength} />}
		</Form.Item>;
	}
}

export default RecordFieldInput;
