import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import hash from 'object-hash';

import Record_Field from './Field';

import { Form, Input } from 'antd';

const JSONGrid_Container = styled.div`
	overflow: scroll;
	padding: 10px;
`;

const JSONGrid_Table = styled.table`
	border-collapse: separate;
	border-spacing: 0;
	border: solid #b5b5b5 1px;
	border-radius: 3px;
	box-shadow: 0 1px 1px #ccc;
	background: #444;
	color: #fff;
`;

const JSONGrid_Table_Row = styled.tr`

`;

const JSONGrid_Table_Col = styled.td`
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	border: 1px solid #474747;
	font-size: 11px;
	padding: 3px 10px 3px 10px;
	cursor: pointer;
	white-space: nowrap;

	&.name {
		vertical-align: top;
	}

	& > .number, & > .boolean {
		color: #6c99bb;
	}

	& > .string {
		color: #a5c261;
	}

	& > .object {
		color: white;
	}
`;

class RecordFieldTempLog extends Record_Field {
	static propTypes = Object.assign({}, Record_Field.propTypes, {
		autoSize: PropTypes.object,
		showCount: PropTypes.bool,
		maxLength: PropTypes.number
	});

	static defaultProps = Object.assign({}, Record_Field.defaultProps, {
		autoSize: { minRows: 3, maxRows: 6 },
		showCount: true,
		maxLength: null,
		disabled: true,
		readonly: true
	});

	static displayName = 'RecordFieldTempLog';

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	buildTableFromObject(obj) {
		return <JSONGrid_Table>
		{
			Object.keys(obj).map((k, idx) => (
				<JSONGrid_Table_Row key={[hash(obj), 'obj', 'row', idx]}>
					<JSONGrid_Table_Col className="name">
						{k}
					</JSONGrid_Table_Col>

					<JSONGrid_Table_Col className="value">
						<span className={typeof obj[k]}>
							{
								(
									typeof obj[k] === 'object'
										? this.buildTable(obj[k])
										: obj[k]
								)
							}
						</span>
					</JSONGrid_Table_Col>
				</JSONGrid_Table_Row>
			))
		}
		</JSONGrid_Table>;
	}

	buildTableFromArray(arr) {
		return <JSONGrid_Table>
			{
				<>
					{
						arr.every((el) => typeof el === 'object' && !Array.isArray(el))
							? <JSONGrid_Table_Row key={[hash(arr), 'arr', 'header_row']}>
								{
									Object.keys(arr[0]).map((_el, _idx) => (
										<JSONGrid_Table_Col key={[hash(arr), 'arr', 'header_row', _idx]} className="name">
											{_el}
										</JSONGrid_Table_Col>
									))
								}
							</JSONGrid_Table_Row>
							: null
					}

					{
						arr.map((el, idx) => (
							<JSONGrid_Table_Row key={[hash(arr), 'arr', 'row', idx]}>
								{
									Object.values(el).map((_el, _idx) => (
										<JSONGrid_Table_Col key={[hash(arr), 'arr', 'row', idx, _idx]} className="value">
											<span className={typeof _el}>
												{
													(
														typeof _el === 'object'
															? this.buildTable(_el)
															: _el
													)
												}
											</span>
										</JSONGrid_Table_Col>
									))
								}
							</JSONGrid_Table_Row>
						))
					}
				</>
			}
		</JSONGrid_Table>;
	}

	buildTable(data) {
		if(!data) return null;

		if(typeof data === 'object') {
			if(Array.isArray(data)) {
				return this.buildTableFromArray(data);
			} else {
				return this.buildTableFromObject(data);
			}
		}

		return null;
	}

	getValue() {
		const value = this.getRawValue();

		const getObjOrStr = (str) => {
			let _obj;

			try {
				_obj = JSON.parse(value);
			} catch(e) {
				return str;
			}

			return _obj;
		};

		const obj = getObjOrStr(value);

		return obj ? (<JSONGrid_Container>{this.buildTable(obj) ?? '(brak)'}</JSONGrid_Container>) : '(brak)';
	}

	renderEdit() {
		const {
			title,
			name,
			disabled,

			autoSize,
			showCount,
			maxLength
		} = this.props;

		return <Form.Item
			{...this.globalItemProps}
		>
			<Input.TextArea autoComplete="nope" disabled={disabled} autoSize={autoSize} showCount={showCount} maxLength={maxLength} />
		</Form.Item>;
	}
}

export default RecordFieldTempLog;
