/**
 * Max file size in megabytes.
 * Default: 10MB
 * @type {number}
 */
export const MAX_FILE_SIZE = 10;

export const MBtoBytes = (mb) => Math.floor(mb * 1024 * 1024);

export const FILTER_RELATIONS = ['profiles', 'contractors', 'users', 'infants', 'dctviews', 'committees', 'apptripmembers', 'tripprices', 'offtrips', 'families', 'appreftripmembers', 'campprices', 'offcamps', 'refcampprices', 'touristprices', 'offtourists', 'appmultimembers'];
export const RECORD_ID_RELATIONS = ['apptripmembers', 'appreftripmembers', 'appmultimembers'];
