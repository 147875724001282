import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Record_Field from './Field';

import { Form, Select } from 'antd';

import _ from 'lodash';

import { assignDataToString } from '../../../libs/Helpers.js';

import WithSeparator from '../../../components/WithSeparator';

const { Option } = Select;

class RecordFieldRelation extends Record_Field {
	static propTypes = Object.assign({}, Record_Field.propTypes, {
		relation: PropTypes.string.isRequired,
		prefix: PropTypes.string,
		idKey: PropTypes.string,
		relatedKey: PropTypes.string,
		labelFormat: PropTypes.string,
		mode: PropTypes.string,
		link: PropTypes.bool,
		linkReferrer: PropTypes.string,
		separator: PropTypes.string,
		filter: PropTypes.func,
		allowClear: PropTypes.bool
	});

	static defaultProps = Object.assign({}, Record_Field.defaultProps, {
		placeholder: 'Wybierz...',
		prefix: null,
		idKey: 'id',
		relatedKey: 'id',
		labelFormat: '{#name}',
		mode: null,
		link: false,
		separator: ', ',
		allowClear: false
	});

	static displayName = 'RecordFieldRelation';

	get relation() {
		const {
			relation
		} = this.props;

		if(this.relationData && relation && this.relationData.hasOwnProperty(relation)) {
			return this.relationData[relation] ?? [];
		}

		return [];
	}

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	getInitialValue() {
		const {
			relatedKey,
			idKey,
			mode
		} = this.props;

		if(mode === 'multiple') {
			return (this.getRawValue() && Array.isArray(this.getRawValue())) ? this.getRawValue().map(el => el ? (el[relatedKey] ?? el[idKey] ?? el.id) : null).filter(el => el !== null) : [];
		} else {
			return this.getRawValue();
		}
	}

	getValue() {
		const {
			idKey,
			labelFormat,
			mode,
			link,
			linkReferrer,
			relation,
			separator,
			relatedKey
		} = this.props;

		let value = null;

		if(mode === 'multiple') {
			value = Array.isArray(this.getRawValue()) && this.getRawValue().map((el, idx) => {
				let opt = this.relation.find(o => (o[relatedKey] ?? o[idKey] ?? o.id) === (el[relatedKey] ?? el[idKey] ?? el?.id ?? el));

				if(opt) {
					let tmpValue = assignDataToString(labelFormat, opt, '(brak)');
					return link ?
						<Link
							key={['relationLink', idx]}
							to={`/${linkReferrer ?? relation}/${opt[idKey] ?? opt.id}`}
						>
							{tmpValue}
						</Link>
						:
						tmpValue;
				}
			});

			value = value ? <WithSeparator separator={separator}>{value}</WithSeparator> : null;
		} else {
			let opt = this.relation.find(el => (el[relatedKey] ?? el[idKey] ?? el?.id ?? el) === this.getRawValue());

			value = opt ? assignDataToString(labelFormat, opt, '(brak)') : null;

			if(link && value) {
				value = <Link
					to={`/${linkReferrer ?? relation}/${opt[idKey] ?? opt.id}`}
				>
					{value}
				</Link>;
			}
		}

		return value ? value : '(brak)';
	}

	renderEdit() {
		const {
			title,
			name,
			disabled = false,

			allowSearch,
			placeholder,
			relatedKey,
			idKey,
			labelFormat,
			mode,
			filter,
			disablePredicate,
			allowClear
		} = this.props;

		let _disabled;

		let _data = this.relation;

		if(typeof filter === 'function') {
			_data = filter(_data, this.data, this.relationData);
		}

		if(disablePredicate && typeof disablePredicate === 'function') {
			_disabled = disablePredicate(this.getRawValue(), this.data, this.relationData);
		}

		_data = _data.sort((a, b) => a[relatedKey] ?? a[idKey] - b[relatedKey] ?? b[idKey]);

		return <Form.Item
			{...this.globalItemProps}
		>
			<Select
				disabled={_disabled ?? disabled}
				autoComplete="nope"
				optionFilterProp="children"
				showSearch={allowSearch}
				placeholder={placeholder}
				mode={mode}
				allowClear={allowClear}
			>
				{_data.map(el => (
					<Option key={_.uniqueId('option_')} value={el[relatedKey] ?? el[idKey] ?? el.id}>{assignDataToString(labelFormat, el)}</Option>
				))}
			</Select>
		</Form.Item>;
	}
}

export default RecordFieldRelation;
