import React from 'react';
import { useParams } from 'react-router-dom';

import Moment from 'react-moment';

import Record from '../../components/Record/index';

import TabbedCard from '../../components/TabbedCard';

import { Row, Col, Card, Button, Table, message } from 'antd';

import '../../assets/scss/dashboard.scss';

import { useAuth, PERMISSIONS as USER_PERMISSIONS } from '../../contexts/User';

import { APIBackend as API } from '../../api';

const Dashboard_Profiles = (props) => {
	const { id } = useParams();

	const [currentActiveTab, setCurrentActiveTab] = React.useState('general');

	const [profileHistory, setProfileHistory] = React.useState(null);

	const auth = useAuth();

	const isOperator = auth.can(USER_PERMISSIONS.OPERATOR);

	const columns = [
		{
			title: 'Data zmiany',
			key: 'change_date',
			dataIndex: 'change_date'
		},
		{
			title: 'Dochody klienta',
			key: 'client_income',
			dataIndex: 'client_income',
			render: (text, record, index) => {
				return (text && Object.keys(text.toString()).length) ? text : '-';
			}
		},
		{
			title: 'Dochody współmałżonka',
			key: 'spouse_income',
			dataIndex: 'spouse_income',
			render: (text, record, index) => {
				return (text && Object.keys(text.toString()).length) ? text : '-';
			}
		},
		{
			title: 'Dochody inne',
			key: 'other_incomes',
			dataIndex: 'other_incomes',
			render: (text, record, index) => {
				return (text && Object.keys(text.toString()).length) ? text : '-';
			}
		},
		{
			title: 'Dochody średnie',
			key: 'average_income',
			dataIndex: 'average_income',
			render: (text, record, index) => {
				return (text && Object.keys(text.toString()).length) ? text : '-';
			}
		},
		{
			title: 'Pracownik/Emeryt',
			key: 'client_status',
			dataIndex: 'client_status',
			render: (text, record, index) => {
				return (text && Object.keys(text.toString()).length) ? text : '-';
			}
		}
	];

	const getProfileHistory = () => {
		if(id) {
			API.getProfileHistory(id).then(data => {
				setProfileHistory(data);
			}).catch(err => {
				console.log(err);
				message.error('Wystąpił błąd podczas pobierania historii dochodów');
			});
		}
	};

	React.useEffect(() => {
		getProfileHistory();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (<div className="content">
		<Record
			controller="profiles"
			relations={['users', 'contractors', 'branches', 'dctmaritials', 'dctrelationships', 'dctincometypes', 'dctfiletypes', 'staprofiles', 'dctdisabilities', 'dcteducations']}
			mode={props?.mode}
			defaultEditing={props?.editing}
			filter={[
				{ field: 'staprofiles.id', value: isOperator ? 2 : 7 },
				// { field: 'is_seen', value: isOperator ? '___' : '_0_' }
			]}
			filters={[
				{
					title: 'Status profilu',
					name: 'staprofiles.id',
					relation: 'staprofiles',
					type: 'enum'
				},
				{
					title: 'Numer dokumentu',
					name: 'identity_number',
					type: 'text'
				},
				{
					title: 'Kontrahent',
					name: 'contractors.id',
					relation: 'contractors',
					type: 'enum'
				},
				{
					title: 'Emeryt',
					name: 'is_pension_declaration',
					type: 'preenum',
					enum: [
						{ label: '- wybierz -', value: null },
						{ label: 'TAK', value: 1 },
						{ label: 'NIE', value: 0 }
					]
				}
			]}
			customButtons={[
				{ title: 'Akcje', dropdown: [
					{ title: 'Przekaż do akceptacji', endpoint: '/profiles/status/toaccept', predicate: (el, data, relationData) => [1,4].includes(data?.staprofile_id) },
					{ title: 'Aktywuj', endpoint: '/profiles/status/activate', predicate: (el, data, relationData) => [6,7].includes(data?.staprofile_id) },
					{ title: 'Przekaż do komisji', endpoint: '/profiles/status/tocommission', predicate: (el, data, relationData) => [6].includes(data?.staprofile_id), modal: { title: 'Potwierdź akcję', content: 'Czy na pewno chcesz przekazać profil do komisji socjalnej?', fields: ['to_commission'] } },
					{ title: 'Zwróć do korekty', endpoint: '/profiles/status/tocorrect', predicate: (el, data, relationData) => [6,7].includes(data?.staprofile_id), modal: { title: 'Potwierdź akcję', content: 'Czy na pewno chcesz przekazać profil do korekty?', fields: ['to_correct'] } },
					{ title: 'Anuluj wniosek', endpoint: '/profiles/status/canceled', predicate: (el, data, relationData) => [1,4,6].includes(data?.staprofile_id) && auth.can(USER_PERMISSIONS.OPERATOR), modal: { title: 'Potwierdź akcję', content: 'Czy na pewno chcesz anulować wniosek?', fields: ['remarks'] } }
				] },
				{ title: 'Ustaw jako aktywny', endpoint: '/profiles/status/setactive', formDataFromRecord: ['id'], successText: 'Profil został aktywowany', errorText: 'Wystąpił błąd podczas aktywowania profilu', predicate: (el, data, relationData) => [3].includes(data?.staprofile_id) }
			]}
			defaultSort={[[2, 'asc']]}
			columns={[
				{
					name: 'id',
					title: '#',
					type: 'number'
				},
				{
					name: 'first_name',
					title: 'Imię',
					searchable: true,
					searchname: 'users.first_name',
					sortname: 'users.first_name'
				},
				{
					name: 'last_name',
					title: 'Nazwisko',
					searchable: true,
					searchname: 'users.last_name',
					sortname: 'users.last_name'
				},
				{
					name: 'registration_number',
					title: 'Numer ewidencyjny',
					searchable: true
				},
				{
					name: 'contractor_name',
					title: 'Kontrahent',
				},
				{
					name: 'is_pension_declaration',
					title: 'Emeryt',
					datatype: 'bool',
					orderable: false,
					searchable: true,
					searchable: false
				},
				{
					name: 'actions',
					data: null,
					sortable: false,
					title: '',
					render: function(data, type, row) {
						return `<a class="btn btn-table" href="/profiles/${data.id}">Podgląd</a>${row?.staprofile_id === 3 ? `` : `<a class="btn btn-table" href="/profiles/${data.id}/edit">Edytuj</a>`}`;
					},
					className: 'text-right'
				}
			]}
			technicalFields={[
				<Record.Field.Input title={'Status profilu'} name="staprofile_name" readonly span={{ span: 24 }} />,
				<Record.Field.Input title={'Aktywowany przez'} name="activatedBy" readonly span={{ span: 24 }} hidePredicate={(value, data, mode) => ![2,3].includes(data?.staprofile_id)} />,
			]}
			viewTitle="Podgląd klienta"
			listTitle="Lista klientów"
			createTitle="Tworzenie profilu"
			editTitle="Edycja profilu"
			disableEditPredicate={(recordData) => {
				if(recordData?.staprofile_id === 3) return true;

				return false;
			}}
		>
			<Record.Consumer>
				{({ isLoading, mode, data, form }) => {
					return (
						<TabbedCard key="generalCard" loading={isLoading} tabContent={{
							general: [
								<Record.Field.Relation title={'Klient'} showFromData={"full_name"} name="user_id" relation="users" relatedName="last_name" labelFormat="{#first_name} {#last_name}" span={{ span: 24 }} link linkReferer="clients" rules={[ { required: true, message: 'Proszę wybrać powiązanego klienta' } ]} allowSearch={true} filter={ (data, record) => {
									return data.filter((el, idx) => {
										return el.permission_code < 60;
									});
								} } />,

								<Record.Field.Relation title={'Kontrahent'} name="contractor_id" relation="contractors" link rules={[ { required: true, message: 'Proszę wybrać powiązanego kontrahenta' } ]} filter={ (data, record, relationData) => {
									let _user = relationData?.users ? relationData?.users.find(el => el.id === record?.user_id) : null;
									if(!_user) {
										return [];
									}
									return data.filter((el, idx) => {
										return _user?.contractors?.find?.(_el => _el.id === el.id) ? true : false;
									});
								} } overrideValue={(data, relationData, mode) => {
									if(mode !== 'create') return null;

									const user = relationData && relationData.hasOwnProperty('users') && relationData.users.find(el => el.id === data?.user_id);

									const contractors = relationData && relationData.hasOwnProperty('contractors') && relationData.contractors.filter(el => {
										return user?.contractors?.find?.(_el => _el.id === el.id) ? true : false;
									});

									if(contractors && contractors.length === 1) {
										return contractors[0].id;
									}

									return null;
								}} shouldUpdate={(prevValues, currentValues) => {
									return prevValues.user_id !== currentValues.user_id;
								}} />,
								<Record.Field.Relation title={'Oddział'} relation="branches" name="branch_id" shouldUpdate={(prevValues, currentValues) => {
									return prevValues.contractor_id !== currentValues.contractor_id;
								}} filter={ (data, record) => {
									return data.filter((el, idx) => {
										// console.log(record);
										if(record) {
											if(el.contractor_id === parseInt(form?.current?.getFieldValue?.('contractor_id'))) {
												return true;
											} else {
												return false;
											}
										}

										return true;
									});
								} } rules={[ { required: true, message: 'Proszę wybrać oddział kontrahenta' } ]} overrideValue={(data, relationData, mode, form) => {
									if(mode !== 'create') return null;

									const branches = relationData && relationData.hasOwnProperty('branches') && relationData.branches.filter(el => {
										return el.contractor_id === parseInt(form?.current?.getFieldValue?.('contractor_id'));
									});

									if(branches && branches.length === 1) {
										return branches[0].id;
									}

									return null;
								}} />,

								<Record.Field.Relation title={'Stan cywilny'} name="dctmaritial_id" relation="dctmaritials" rules={[ { required: true, message: 'Proszę wybrać stan cywilny klienta' } ]} />,
								<Record.Field.Select title={'Rodzaj'} name="type" options={[
									{ label: 'Zewnętrzny', value: 'Z' },
									{ label: 'Wewnętrzny', value: 'K' }
								]} />,
								<Record.Field.Input title={'PESEL'} name="pesel" overrideValue={(data, relationData, mode) => {
									if(mode !== 'create') return null;

									const user = relationData && relationData.hasOwnProperty('users') && relationData.users.find(el => el.id === data.user_id);

									return user?.pesel ?? null;
								}} />,
								<Record.Field.Date title={'Data urodzenia'} name="birth_date" rules={[ { required: true, message: 'Proszę uzupełnić datę urodzenia klienta' } ]} />,

								// <Record.Field.Select title="Rodzaj dokumentu tożsamości" name="identity_type" options={[
								// 	{ label: 'Dowód osobisty', value: 'D' },
								// 	{ label: 'Paszport', value: 'P' }
								// ]} />,
								// <Record.Field.Input title="Numer dokumentu tożsamości" name="identity_number" rules={[
								// 	{
								// 		required: true,
								// 		message: 'Proszę uzupełnić numer dokumentu tożsamości'
								// 	}
								// ]} />,
								<Record.Field.Input title="Numer ewidencyjny" name="registration_number" />,
								<Record.Field.Input title="Symbol komórki organizacyjnej" name="department_symbol" />,
								<Record.Field.Input title={'Ulica i nr domu/mieszkania'} name="street" span={{ sm: 24, md: 12 }} rules={[ { required: true, message: 'Proszę uzupełnić adres zamieszkania klienta' } ]} />,
								<Record.Field.Input title={'Kod pocztowy'} name="zip" span={{ sm: 24, md: 5 }} rules={[{ pattern: /^\d{2}-\d{3}$/g, max: 6, message: 'Kod pocztowy powinien spełniać odpowiedni format: XX-XXX, gdzie X oznacza cyfrę'}, { required: true, message: 'Proszę uzupełnić adres zamieszkania klienta' }]} />,
								<Record.Field.Input title={'Miasto'} name="town" span={{ sm: 24, md: 7 }} rules={[ { required: true, message: 'Proszę uzupełnić adres zamieszkania klienta' } ]} />,

								<Record.Field.Input title={'Numer rachunku bankowego'} name="bank_account" span={{ span: 24 }} rules={[{ pattern: /^\d{2}[ ]\d{4}[ ]\d{4}[ ]\d{4}[ ]\d{4}[ ]\d{4}[ ]\d{4}$|^\d{26}$/, message: 'Proszę wprowadzić prawidłowy numer rachunku bankowego' }]} />,

								<Record.Field.Input title={'Numer kontaktowy'} name="phone" rules={[{ pattern: /^(?<!\w)(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}(?!\w)$/, message: 'Proszę wprowadzić prawidłowy numer telefonu' }]} overrideValue={(data, relationData, mode) => {
									if(mode !== 'create') return null;

									const user = relationData && relationData.hasOwnProperty('users') && relationData.users.find(el => el.id === data.user_id);

									return user?.phone ?? null;
								}} />,
								<Record.Field.Switch title={'Emeryt'} name="is_pension_declaration" />,

								<Record.Field.Textarea title={'Uwagi (do korekty)'} name="to_correct" span={{ span: 24 }} />,
								<Record.Field.Textarea title={'Uwagi (do komisji)'} name="to_commission" span={{ span: 24 }} />,
								<Record.Field.Textarea title={'Uwagi'} name="remarks" span={{ span: 24 }} />
							],
							family: [
								<Record.Field.Record
									title="Rodzina"
									showTitle={false}
									name="families"
									fields={[
										<Record.Field.Input title="Imię" name="first_name" rules={[{ required: true, message: '[Rodzina] Proszę wprowadzić imię członka rodziny' }]} />,
										<Record.Field.Input title="Nazwisko" name="last_name" rules={[{ required: true, message: '[Rodzina] Proszę wprowadzić nazwisko członka rodziny' }]} />,
										<Record.Field.Input title="Numer PESEL" name="pesel" />,
										<Record.Field.Date title="Data urodzenia" name="birth_date" rules={[{ required: true, message: '[Rodzina] Proszę wprowadzić datę członka rodziny' }]} />,
										<Record.Field.Relation title="Rodzaj pokrewieństwa" name="dctrelationship_id" relation="dctrelationships" rules={[{ required: true, message: '[Rodzina] Proszę wybrać rodzaj pokrewieństwa członka rodziny' }]} />,
										<Record.Field.Switch title="Uprawniony do pobierania świadczeń" name="is_authorized" />,
										<Record.Field.Switch title="Czy rodzic zmarł?" name="is_parent_died" />,
										<Record.Field.Empty />,
										<Record.Field.Switch title="Edukacja" name="is_education" />,
										<Record.Field.Switch title="Niepełnosprawność" name="is_disability" />,
										<Record.Field.Relation title="Stopień edukacji" name="dcteducation_id" relation="dcteducations" />,
										<Record.Field.Relation title="Stopień niepełnosprawności" name="dctdisability_id" relation="dctdisabilities" />
									]}
								/>,
								// <Record.Field.TableRelation title={'Rodzina'} name="families" span={{ span: 24 }} fields={[
								// 	{ name: 'id', title: 'ID', isHidden: true, removeEmpty: true },
								// 	{ name: 'first_name', title: 'Imię', span: { lg: 3, xl: 4 } },
								// 	{ name: 'last_name', title: 'Nazwisko', span: { lg: 3, xl: 4 } },
								// 	{ name: 'pesel', title: 'PESEL', span: { lg: 4, xl: 5 } },
								// 	{ name: 'birth_date', title: 'Data urodzenia', span: { lg: 4, xl: 3 }, type: 'date' },
								// 	{ name: 'dctrelationship_id', title: 'Rodzaj pokrewieństwa', type: 'relation', relation: 'dctrelationships', relationValueColumn: 'name', relationIdColumn: 'dctrelationship_id', span: { lg: 2, xl: 3 } },
								// 	{ name: 'is_authorized', title: 'Autoryzowany', type: 'checkbox', span: { span: 1 }, defaultValue: 0, tooltip: 'Czy członek rodziny jest uprawniony do pobierania świadczeń?' }
								// ]} showTitle={ false } />
							],
							income: [
								<Record.Field.Input title="Dochody klienta" name="client_income" />,
								<Record.Field.Input title="Dochody współmałżonka" name="spouse_income" />,

								<Record.Field.TableRelation title={'Dochody dodatkowe'} name="incomeothers" span={{ span: 24 }} fields={[
									{ name: 'id', title: 'ID', isHidden: true, removeEmpty: true },
									{
										name: 'dctincometype_id',
										title: 'Nazwa',
										span: { lg: 9, xl: 9 },
										type: 'relation',
										relation: 'dctincometypes',
										relationValueColumn: 'name',
										relationIdColumn: 'dctincometype_id',
										removeEmpty: true,
										fieldProps: {
											rules: [
												{
													required: true,
													message: '[Dochody] Proszę uzupełnić rodzaj dochodu dodatkowego'
												}
											]
										}
									},
									{ name: 'quote', title: 'Wartość', span: { lg: 9, xl: 9 } }
								]} />,

								<Record.Field.Number title="Średnie dochody" name="average_incomes" precision={2} disabled={true} />,
								<Record.Field.Number title="Dochody (ręcznie)" name="manual_income" precision={2} />,
								<Record.Field.Date title="Data ostatniej zmiany dochodów" name="income_date" disabled={true} />
							],
							attachments: [
								<Record.Field.Attachments title={'Wnioski'} name="attachments1" valueName="attachments" fileCategory="Wnioski" relation="dctfiletypes" />,
								<Record.Field.Attachments title={'Zaświadczenia'} name="attachments2" valueName="attachments" fileCategory="Zaświadczenia" relation="dctfiletypes" />
							],
							history: [
								<Col span={24}>
									<Table columns={columns} dataSource={profileHistory} />
								</Col>
							]
						}} tabList={
							mode.value === 'create'
								? [
									{ key: 'general', tab: 'Dane podstawowe' },
									{ key: 'family', tab: 'Rodzina' },
									{ key: 'income', tab: 'Dochody' },
									{ key: 'attachments', tab: 'Załączniki' }
								]
								: [
									{ key: 'general', tab: 'Dane podstawowe' },
									{ key: 'family', tab: 'Rodzina' },
									{ key: 'income', tab: 'Dochody' },
									{ key: 'attachments', tab: 'Załączniki' },
									{ key: 'history', tab: 'Historia dochodów' }
								]
						} activeTabKey={currentActiveTab} onTabChange={(key) => { setCurrentActiveTab(key) }} forceRender={true} />
					);
				}}
			</Record.Consumer>
		</Record>
	</div>);
};

export default Dashboard_Profiles;
