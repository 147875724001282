import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import shallowEqual from '../../libs/shallowEqual';
import { openInNewTab } from '../../libs/Helpers';

import { RecordSaveError, RecordCloneError, RecordViewError, ChangeStatusError } from '../../errors';

import { RecordContext } from '../../contexts/Record';
import { Card, Affix, Button, Row, Col, Form, message, Modal, Alert, Menu, Input, Dropdown } from 'antd';
import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import DataTable from '../../components/RecordTable';
import Moment from 'react-moment';

import { backendRoot, backendApi, csrfCookie, apiData } from '../../config/paths';

import { captureException, captureMessage } from '@sentry/react';
import { APIBackend as API, APIBackendInstance as APIInstance } from '../../api';

import { filterObject, objectToFormData, getValueFromArray, objectMap, assignDataToString } from '../../libs/Helpers.js';
import prepareErrorMessage from '../../libs/ErrorHelper.js';

const SubheaderActions = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-bottom: 15px;

	& .ant-btn:not(:first-child) {
		margin-left: 5px;
	}
`;

class RecordBase extends React.Component {
	static propTypes = {
		/**
		 * Object of props passed to the data table component
		 * @type {Object}
		 */
		listProps: PropTypes.object,
		withHeader: PropTypes.bool,
		listTitle: PropTypes.string,
		viewTitle: PropTypes.string,
		editTitle: PropTypes.string,
		createTitle: PropTypes.string,
		createButtonText: PropTypes.string,
	};

	static defaultProps = {
		withHeader: true,
		debugLevel: 0
	};

	static contextType = RecordContext;

	state = {
		isAffixedActions: false
	};

	set changes(value) {
		this.context.hasOwnProperty('setChanges') && this.context.setChanges(value);
	}

	get changes() {
		return this.context?.changes ?? {};
	}

	set mode(value) {
		this.context.hasOwnProperty('setMode') && this.context.setMode(value);
	}

	get mode() {
		return this.context?.mode ?? 'list';
	}

	get referrer() {
		return this.props?.referrer ?? this.props.controller;
	}

	get data() {
		return Object.assign({ id: null }, this.context?.data ?? {}, this.context?.changes ?? {});
	}

	get id() {
		return this.context?.id ?? null;
	}

	get fields() {
		return (this.context && this.context.reducer) ? this.context.reducer.state.fields : [];
	}

	constructor(props, context) {
		super(props, context);

		this.onRecordSave = this.onRecordSave.bind(this);
		this.onRecordError = this.onRecordError.bind(this);
		this.onRecordChange = this.onRecordChange.bind(this);
	}

	shouldComponentUpdate(newProps, newState) {
		return (
			!shallowEqual(this.props, newProps) || !shallowEqual(this.state, newState)
		);
	}

	debug(level, cb = null) {
		const {
			debugLevel
		} = this.props;

		if(debugLevel < level) return;

		if(typeof cb === 'function') {
			cb();
		} else {
			if(arguments.length > 1) {
				let args = Array.prototype.slice.call(arguments).slice(1);
				console.log(...args);
			} else {
				console.trace();
			}
		}
	}

	getFieldByName(name) {
		let field = this.fields.find(el => el.name === name);

		if(field) {
			return {
				name: Array.isArray(field.name) ? field.name.reduce((acc, v) => `${acc}[${v}]`) : field.name,
				tab: field.props.tab,
				scrollTo: field?.scrollTo ?? function() {}
			};
		}

		return false;
	}

	onRecordSave(values) {
		const {
			confirmSave = false,
		} = this.props;

		const self = this;

		if(confirmSave) {
			if(confirmSave.hasOwnProperty('message')) {
				if(
					!confirmSave.hasOwnProperty('predicate')
					|| (typeof confirmSave.predicate === 'function' && confirmSave.predicate(this.data, values))
				) {
					Modal.confirm({
						title: 'Potwierdź akcję',
						content: confirmSave.message,
						okText: 'Dalej',
						cancelText: 'Anuluj',
						onOk() {
							self.preSave(values);
						}
					});
				} else {
					this.preSave(values);
				}
			}
		} else {
			this.preSave(values);
		}
	}

	preSave(values) {
		const _data = new FormData();
		const _debugData = {};

		this.debug(1, 'Saving record[0]', values);

		this.fields.map(field => {
			let fieldValue = Array.isArray(field.name) ? getValueFromArray(values, field.name) : (values[field.name] ?? null),
				fieldName = Array.isArray(field.name) ? field.name.reduce((acc, v) => `${acc}[${v}]`) : field.name,
				tmpValue;

			// if(fieldValue === null && !['RecordFieldAttachments', 'RecordFieldGroup'].includes(field.displayName)) return;

			switch(field.displayName) {
				case 'RecordFieldDate':
					_data.append(fieldName, fieldValue ? fieldValue.format('YYYY-MM-DD') : null);
					break;
				case 'RecordFieldTableRelation':
					// Prepare value of field
					if(fieldValue && Array.isArray(fieldValue)) {
						tmpValue = fieldValue.map((el) => {
							for(let _k in el) {
								let subfield = field.props.fields.find((sf => sf.name === _k));

								let _v = el[_k];

								switch(subfield?.type) {
									case 'date':
										_v = el[_k] ? el[_k].format('YYYY-MM-DD') : null;
										break;
									case 'checkbox':
										_v = el[_k] ? 1 : 0;
										break;
									default:
										break;
								}

								el[_k] = _v;

								if(subfield) {
									// If field is empty and it's not allowed to be empty unset it
									if(subfield.removeEmpty && (_v === '' || _v === null)) {
										delete el[_k];
									}
								} else {
									// Unset field if it doesn't exists
									delete el[_k];
								}
							}

							if(field.props?.defaultValues) {
								for(let _k in field.props.defaultValues) {
									if((el[_k] ?? null) === null) {
										el[_k] = assignDataToString(field.props.defaultValues[_k], this.data);
									}
								}
							}

							// Check if whole row is empty
							let emptyRow = Object.keys(el).every(i => {
								return el[i] === '' || el[i] === null || el[i] === 0;
							});

							// If row isn't empty, then add it to data
							if(!emptyRow) return el;
						}).filter(_v => (_v && Object.keys(_v).length)); // Remove empty entries
					}

					_data.append(field.props?.transformDataKey ?? fieldName, JSON.stringify(tmpValue ?? []));
					break;
				case 'RecordFieldAttachments':
					fieldValue = values[`${fieldName}~${field.props?.fileCategory}`] ?? null;

					if(fieldValue !== null) {
						fieldValue.add.forEach(el => {
							if(el) _data.append(`files[${el.category}/${el.name}]`, el.file);
						});

						fieldValue.del.forEach(el => {
							if(el) _data.append(`delete[${el.category}/${el.name}]`, el.uid);
						});
					}
					break;
				case 'RecordFieldGroup':
					field.props.fields.forEach(el => {
						if(values[el.name] ?? null !== null) {
							_data.append(el.name, values[el.name] ?? '');
						}
					});
					break;
				case 'RecordFieldSwitch':
				case 'RecordFieldCheckbox':
					_data.append(fieldName, fieldValue ? 1 : 0);
					break;
				case 'RecordFieldRelation':
					if(field.props?.transformDataKey) {
						_data.append(field.props.transformDataKey, fieldValue);
					} else {
						_data.append(fieldName, fieldValue);
					}
					break;
				case 'RecordFieldTableCheck':
					if(field.props?.transformDataKey) {
						_data.append(field.props.transformDataKey, fieldValue.map(el => el.checked ? el.id : null).filter(el => el ?? null !== null));
					} else {
						_data.append(fieldName, fieldValue.map(el => el.checked ? el.id : null).filter(el => el ?? null !== null));
					}
					break;
				case 'RecordFieldRecord':
					tmpValue = fieldValue;

					tmpValue.forEach((_tmp, idx) => {
						field.props.fields.forEach(_f => {
							let _fName = _f.props.name,
								_fType = _f.type.displayName;

							if(_fType === 'RecordFieldRelation' && _f.props.castToString) {
								if(tmpValue[idx][_fName]) {
									tmpValue[idx][_fName] = tmpValue[idx][_fName].filter(el => !(el === '' || el === null)).join(',');
								}
							} else if(_fType === 'RecordFieldDate') {
								if(tmpValue[idx][_fName]) {
									tmpValue[idx][_fName] = tmpValue[idx][_fName].format(_f.props.format ?? 'YYYY-MM-DD');
								}
							}
						});
					});

					_data.append(fieldName, JSON.stringify(tmpValue));
					break;
				default:
					_data.append(fieldName, (fieldValue && Object.keys(fieldValue.toString()).length) ? fieldValue : '');
					break;
			}
		});



		// if(this.props.debugLevel) {
			for(let pair of _data.entries()) {
				_debugData[pair[0]] = pair[1];
			}
		// }

		for(let defKey in this.props?.defaultValues ?? {}) {
			const defVal = (this.props?.defaultValues ?? {})[defKey];
			if(!_debugData.hasOwnProperty(defKey)) {
				_data.append(defKey, defVal);
				_debugData[defKey] = defVal;
			}
		}

		this.debug(1, 'Saving record[1]', this.mode, _debugData);
		if(this.props.debugLevel >= 3) return;

		this.save(_data);
	}

	save(data) {
		const {
			controller
		} = this.props;

		const endpoint = (this.mode === 'edit') ? `/api/${controller}/${this.id}` : `/api/${controller}`;

		this.setState({
			saving: true
		});

		// Make a request
		API.csrfCookie().then(() => {
			APIInstance.post(endpoint, data).then((response) => {
				this.setState({
					saving: false,
					error: false
				});
				this.context.updateData(true);
				this.context.changeMode('view', this.id);
			}).catch((err) => {
				this.context.setError({
					errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED',
					message: err?.response?.data?.message
				});

				if(err.response) {
					if(err.response.data.errcode) {
						this.setState({
							saving: false,
							error: {
								errcode: err.response.data.errcode,
								message: err.response.data?.message
							}
						});
					} else {
						this.setState({
							saving: false,
							error: {
								errcode: 'ERR-UNDEFINED',
								message: err.response.data?.message
							}
						});
					}
				} else {
					this.setState({
						saving: false,
						error: {
							errcode: 'ERR-UNDEFINED',
							message: err?.response?.data?.message
						}
					});
				}

				// captureException(new RecordSaveError(`Error occured while saving record`), {
				// 	contexts: {
				// 		record: {
				// 			controller: this.props.controller,
				// 			id: this.id,
				// 			mode: this.mode
				// 		},
				// 		error: {
				// 			message: err?.response?.data?.message,
				// 			details: err?.response?.data?.details,
				// 			errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED'
				// 		}
				// 	}
				// });
			});
		}).catch((err) => {
			this.context.setError({
				errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED',
				message: err?.response?.data?.message
			});

			if(err.response) {
				if(err.response.data.errcode) {
					this.setState({
						saving: false,
						error: {
							errcode: err.response.data.errcode,
							message: err.response.data?.message
						}
					});
				} else {
					this.setState({
						saving: false,
						error: {
							errcode: 'ERR-UNDEFINED',
							message: err.response.data?.message
						}
					});
				}
			} else {
				this.setState({
					saving: false,
					error: {
						errcode: 'ERR-UNDEFINED',
						message: err?.response?.data?.message
					}
				});
			}

			// captureException(new RecordSaveError(`Error occured while saving record`), {
			// 	contexts: {
			// 		record: {
			// 			controller: this.props.controller,
			// 			id: this.id,
			// 			mode: this.mode
			// 		},
			// 		error: {
			// 			message: err?.response?.data?.message,
			// 			details: err?.response?.data?.details,
			// 			errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED'
			// 		}
			// 	}
			// });
		});
	}

	onRecordError(data) {
		const {
			values,
			errorFields,
			outOfDate
		} = data;

		if(errorFields.length) {
			let errorField = errorFields[0];

			errorFields.forEach(el => {
				if(el.errors[0]) {
					message.error(`${el.errors[0]}`);
				}
			});
		}
	}

	onRecordChange(field, allFields) {
		const {
			onValuesChange
		} = this.props;

		this.changes = allFields;

		// if(onValuesChange && typeof onValuesChange === 'function') {
		// 	const field = Object.keys(val).length ? {
		// 		name: Object.keys(val)[0],
		// 		value: Object.values(val)[0]
		// 	} : {};
		// 	onValuesChange(field, allValues, this.getRelationData.bind(this));
		// }
	}

	prepareTitle() {
		const {
			listTitle,
			viewTitle,
			editTitle,
			createTitle
		} = this.props;

		switch(this.mode) {
			case 'list':
				return listTitle ?? 'Lista pozycji';
			case 'view':
				return viewTitle ?? 'Podgląd rekordu';
			case 'edit':
				return editTitle ?? 'Edycja rekordu';
			case 'create':
				return createTitle ?? 'Tworzenie rekordu';
		}
	}

	prepareButtons() {
		const {
			createButtonText,
			extraListActions = null
		} = this.props;

 		const makeRequest = (endpoint, successText, errorText, onSuccess = null, data = null, method = 'post', errMsg = false) => {
 			return APIInstance.request({
 				method: method ?? 'post',
 				url: `${assignDataToString(endpoint, this.data)}`,
 				data: data
 			}).then(() => {
 				message.success(successText ?? 'Akcja została wykonana');
 				this.context.updateData();
				if(onSuccess === null) {
	 				if(this.mode === 'view') {
	 					// window.location.reload();
	 				}
				} else {
					onSuccess();
				}
 			}).catch(err => {
 				message.error(errorText ?? 'Wystąpił błąd podczas wykonywana akcji');
 				if(err.response) {
 					this.setState({ error: {
 						errcode: err.response.data.errcode,
 						message: err.response.data.message
 					} });
 				} else {
 					this.setState({ error: 'ERR-UNDEFINED' });
 				}
 			});
 		};

		const newTabClick = (e) => {
			if(!e.defaultPrevented) {
				if(e.button === 1 || e.metaKey || e.ctrlKey) {
					e.preventDefault();
					return true;
				}
			}

			return false;
		};

		switch(this.mode) {
			case 'list':
				return (<>
					{extraListActions}
					<Button
						key={['actionButtons', 'list', 'create']}
						type="primary"
						onClick={() => {
							this.context.changeMode('create');
						}}
						onMouseUp={(e) => {
							if(newTabClick(e)) {
								openInNewTab(`/${this.referrer}/create`);
							}
						}}
					>
						{createButtonText}
					</Button>
				</>);
				break;
			case 'view':
				/**
				 * Custom buttons on preview/edit mode
				 * @type {Array}
				 */
				let customButtons = [];

				if(this.props?.customButtons) {
					this.props.customButtons.forEach((el, _) => {
						let predicateResult = true;
						if(typeof el.predicate === 'function') {
							predicateResult = el.predicate(el, this.data, this.relationData, this.mode);
						}

						// Skip item due to predicate result
						if(!predicateResult) return;

						if(el?.dropdown) {
							// Item have a dropdown

							/**
							 * Subitems of an item
							 * @type {Array}
							 */
							let menu = [];

							el.dropdown.forEach((_el, _) => {
								let subpredicateResult = true;
								if(typeof _el.predicate === 'function') {
									subpredicateResult = _el.predicate(_el, this.data, this.relationData, this.mode);
								}

								// Skip subitem due to predicate result
								if(!subpredicateResult) return;

								// Push subitem to the item
								menu.push((<Menu.Item key={[el.title, _]} onClick={() => {
									if(this.data && _el.endpoint) {
										const formData = new FormData();
										// Append ID of the current record
										formData.append('id', this.data?.id);

										if(_el.modal) {
											// Subitem have a modal
											Modal.confirm({
												title: _el.modal.title,
												icon: <ExclamationCircleOutlined />,
												okText: _el.modal?.okText ?? 'Wyślij',
												cancelText: 'Anuluj',
												content: <>
													{_el.modal.content}

													{(_el.modal?.fields ?? []).map((modalField, midx) => {
														let _modalField = this.fields.find(f => f.name === modalField);
														if(_modalField) {
															const modalValue = Object.assign(this.data, this.changes)[modalField];

															if(!_el.modal.values) {
																_el.modal.values = {};
															}

															return <Form.Item
																key={['confirm', midx]}
																label={<strong>{_modalField.props.title}</strong>}
																labelCol={{span: 24}} name={['modal', modalField]}
																fieldKey={['modal', midx, modalField]}
																onChange={(e) => { _el.modal.values[modalField] = e?.target?.value; }}
															>
																<Input.TextArea defaultValue={modalValue} showCount />
															</Form.Item>;
														}
													})}
												</>,
												onOk(e) {
													for(let key in _el.modal.values) {
														formData.append(key, _el.modal.values[key]);
													}

													return makeRequest(_el.endpoint, el.successText, el.errorText, el?.onSuccess ?? null, formData, _el?.requestMethod ?? 'post');
												},
												onCancel() {}
											});
										} else {
											// Subitem doesn't have a modal
											makeRequest(_el.endpoint, el.successText, el.errorText, el?.onSuccess ?? null, formData, _el?.requestMethod ?? 'post');
										}
									}
								}}>
									{_el.title}
								</Menu.Item>));
							});

							customButtons.push((<Dropdown key={`dropdown~${_}`} overlay={(<Menu>{menu}</Menu>)}>
								<Button type="primary">{el.title} <DownOutlined /></Button>
							</Dropdown>));
						} else {
							// Item doesn't have a dropdown
							let onClick = () => {};

							if(this.data && el.hasOwnProperty('onClick') && typeof el.onClick === 'function') {
								onClick = (e) => {
									el.onClick(e, this.id, this.data);
								};
							}

							let disablePredicate = () => false;

							if(el.hasOwnProperty('disablePredicate') && typeof el.disablePredicate === 'function') {
								disablePredicate = () => el.disablePredicate(this.data);
							}

							customButtons.push((<Button key={`dropdown~${_}`} disabled={disablePredicate()} type={el?.buttonType ?? 'primary'} onClick={() => {
								onClick();
								if(this.data && el.endpoint) {
									const formData = new FormData();

									if(el?.formDataFromRecord) {
										el.formDataFromRecord.forEach((dataKey) => {
											if(this.data?.hasOwnProperty(dataKey)) {
												formData.append(dataKey, this.data[dataKey]);
											}
										});
									}

									if(el.modal) {
										// Item have a modal

										Modal.confirm({
											title: el.modal.title,
											icon: <ExclamationCircleOutlined />,
											okText: el.modal?.okText ?? 'Wyślij',
											cancelText: 'Anuluj',
											content: <>
												{el.modal.content}

												{(el.modal?.fields ?? []).map((modalField, midx) => {
													let _modalField = this.fields.find(f => f.name === modalField)
													if(_modalField) {
														const modalValue = Object.assign(this.data, this.changes)[modalField];

														if(!el.modal.values) {
															el.modal.values = {};
														}

														return <Form.Item
															label={<strong>{_modalField.props.title}</strong>}
															labelCol={{span: 24}} name={['modal', modalField]}
															fieldKey={['modal', midx, modalField]}
															onChange={(e) => { el.modal.values[modalField] = e?.target?.value; }}
														>
															<Input.TextArea defaultValue={modalValue} showCount />
														</Form.Item>;
													}
												})}
											</>,
											onOk(e) {
												for(let key in el.modal.values) {
													formData.append(key, el.modal.values[key]);
												}

												return makeRequest(el.endpoint, el.successText, el.errorText, el?.onSuccess ?? null, formData, el?.requestMethod ?? 'post');
											},
											onCancel() {}
										});
									} else {
										// Item doesn't have a modal
										makeRequest(el.endpoint, el.successText, el.errorText, el?.onSuccess ?? null, formData, el?.requestMethod ?? 'post');
									}
								}
							}}>
								{el.title}
							</Button>));
						}
					});
				}

				return (<>
					{customButtons}

					<Button
						key={['actionButtons', 'view', 'back']}
						type="link"
						onClick={() => {
							this.context.changeMode('list');
						}}
					>
						Wróć do listy
					</Button>

					<Button
						key={['actionButtons', 'view', 'edit']}
						type="primary"
						onClick={() => {
							this.context.changeMode('edit', this.id);
						}}
					>
						Edytuj
					</Button>
				</>);
			case 'edit':
				return (<>
					<Button
						key={['actionButtons', 'edit', 'cancel']}
						danger
						type="primary"
						onClick={() => {
							this.context.changeMode('view', this.id);
						}}
					>
						Anuluj
					</Button>

					<Button
						key={['actionButtons', 'edit', 'save']}
						type="primary"
						onClick={() => {
							if(this.context.form?.current) {
								this.context.form.current.submit();
							}
						}}
					>
						Zapisz
					</Button>
				</>);
			case 'create':
				return (<>
					<Button
						key={['actionButtons', 'create', 'cancel']}
						danger
						type="primary"
						onClick={() => {
							this.context.changeMode('list');
						}}
					>
						Anuluj
					</Button>

					<Button
						key={['actionButtons', 'create', 'save']}
						type="primary"
						onClick={() => {
							if(this.context.form?.current) {
								this.context.form.current.submit();
							}
						}}
					>
						Utwórz
					</Button>
				</>);
		}

		return null;
	}

	prepareContent() {
		const {
			listProps,
			filter,
			modal,
			technicalFields
		} = this.props;

		switch(this.mode) {
			case 'list':
				return <Card bodyStyle={{ padding: 0 }}>
					<DataTable
						controller={this.props.controller}
						{...listProps}
						filters={filter}
						withPaginationPadding
						inModal={modal ? true : false}
					/>
				</Card>;
			case 'view':
				return (<Row justify="space-between">
					<Col sm={24} lg={16}>
						{this.props.children}
					</Col>

					<Col sm={24} lg={6}>
						<Card loading={this.context.loading}>
							<Row>
								<Col span={24}>
									<Row className="form-group-row" gutter={[16,24]}>
										{technicalFields.map(Field => {
											return React.cloneElement(Field, Object.assign({}, Field.props, {
												registerField: false
											}));
										})}
									</Row>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>);
			case 'create':
			case 'edit':
				return <Row justify="space-between">
					<Col sm={24} lg={16}>
						<Form
							ref={this.context.form}
							scrollToFirstError={true}
							onFinish={this.onRecordSave}
							onFinishFailed={this.onRecordError}
							onValuesChange={this.onRecordChange}
							layout="vertical"
							className="record"
							autoComplete="nope"
						>
							{this.props.children}
						</Form>
					</Col>

					<Col sm={24} lg={6}>
						<Card loading={this.context.loading}>
							<Row>
								<Col span={24}>
									<Row className="form-group-row" gutter={[16,24]}>
										{technicalFields.map(Field => {
											return React.cloneElement(Field, Object.assign({}, Field.props, {
												registerField: false
											}));
										})}
									</Row>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>;
		}

		return null;
	}

	render() {
		const {
			withHeader,
			modal
		} = this.props;

		const buttons = this.prepareButtons();

		return (<div className="content">
			<div className="subheader">
				{
					withHeader
						? <div className="d-flex">
							<h1 className="title">{this.prepareTitle()}</h1>
							<span className="subtitle">
								Dzisiaj jest <Moment locale="pl" format="dddd, D MMMM YYYY" />
							</span>
						</div>
						: null
				}

				{
					modal
						? <SubheaderActions className="subheader_actions">
							{buttons}
						</SubheaderActions>
						: buttons
							?
							<Affix offsetTop={54} onChange={(affixed) => { this.setState({ isAffixedActions: affixed }); }}>
								<div className="subheader_actions">
									{
										(this.state?.isAffixedActions ?? false) ?
										<div className="container">
											<h1 className="title m-0">{this.prepareTitle()}</h1>
											<div className="d-flex">
												{buttons}
											</div>
										</div>
										:
										buttons
									}
								</div>
							</Affix>
							: null
				}
			</div>

			{ this.context.error ? <Row style={{ marginBottom: '15px' }}>
				<Col sm={24} md={16}>
					<Alert type="error" message={ prepareErrorMessage(this.context.error) } />
				</Col>
			</Row> : '' }

			<div>
				{this.context ? this.prepareContent() : null}
			</div>
		</div>);
	}
}

export default RecordBase;
