import React from 'react';

import Moment from 'react-moment';

import Record from '../../../components/Record/index';

import TabbedCard from '../../../components/TabbedCard';

import { useAuth, PERMISSIONS as USER_PERMISSIONS } from '../../../contexts/User';

import { Row, Col, Card, Button } from 'antd';

import moment from 'moment';

import '../../../assets/scss/dashboard.scss';

const Dashboard_Offers_Camps = (props) => {
	const [currentActiveTab, setCurrentActiveTab] = React.useState('general');

	const auth = useAuth();

	return (<div className="content">
		<Record
			controller="offcamps"
			referrer="camps"
			relations={['services', 'dctcamptypes', 'dctunits', 'contractors', 'dctresorts']}
			relationsLimit={-1}
			mode={props?.mode}
			defaultEditing={props?.editing}
			filters={[
				{
					title: 'Kontrahent',
					name: 'contractors.id',
					relation: 'contractors',
					type: 'enum'
				}
			]}
			columns={[
				{
					name: 'id',
					title: '#',
					type: 'number'
				},
				{
					name: 'dctresort_name',
					title: 'Nazwa ośrodka',
					searchable: true
				},
				{
					name: 'dctcamptype_name',
					title: 'Rodzaj wypocznku',
					searchable: true,
					sortname: 'dctcamptypes.name'
				},
				{
					name: 'place',
					title: 'Lokalizacja',
					searchable: true
				},
				{
					name: 'from',
					title: 'Terminy od',
					searchable: true
				},
				{
					name: 'to',
					title: 'Terminy do',
					searchable: true
				},
				{
					name: 'count_nights',
					title: 'Liczba noclegów',
					searchable: true
				},
				{
					name: 'is_active',
					title: 'Aktywna',
					datatype: 'bool'
				},
				{
					name: 'actions',
					data: null,
					sortable: false,
					title: '',
					render: function(data, type, row) {
						return `<a class="btn btn-table" href="/camps/${data.id}">Podgląd</a><a class="btn btn-table" href="/camps/${data.id}/edit">Edytuj</a><a class="btn btn-table" href="/camps/${data.id}/clone">Skopiuj</a>`;
					},
					className: 'text-right'
				}
			]}
			viewTitle="Podgląd oferty wypoczynku dzieci"
			listTitle="Wypoczynki dzieci"
			editTitle="Oferta wypoczynku dzieci - EDYCJA"
			createTitle="Oferta wypoczynku dzieci - TWORZENIE"
		>
			<Record.Consumer>
				{({ isLoading, mode }) => (
					<TabbedCard key="generalCard" loading={isLoading} tabContent={{
						general: [
							<Record.Field.Relation title={'Rodzaj wypoczynku'} name="dctcamptype_id" relation="dctcamptypes" />,
							<Record.Field.Relation title={'Ośrodek wczasowy'} rules={[{ required: true, message: 'Proszę uzupełnić ośrodek wczasowy' }]} name="dctresort_id" relation="dctresorts" />,
							<Record.Field.Input title={'Ośrodek wczasowy - opis'} name="resort" />,
							<Record.Field.Input title={'Lokalizacja ośrodka'} rules={[{ required: true, message: 'Proszę uzupełnić lokalizację ośrodka' }]} name="place" />,
							<Record.Field.Input title={'Sposób dojazdu'} name="transport" />,
							<Record.Field.Date title="Termin od" name="from" rules={[{ required: true, message: 'Proszę wybrać termin' }, ({ getFieldValue }) => ({
								validator(_, value) {
									if(value.diff(getFieldValue('to'), 'days') <= 0) {
										return Promise.resolve();
									}
									return Promise.reject('Data początku terminu musi być wcześniejszą datą, niż koniec terminu');
								},
							})]} />,
							<Record.Field.Date title="Termin do" name="to" rules={[{ required: true, message: 'Proszę wybrać termin' }, ({ getFieldValue }) => ({
								validator(_, value) {
									if(value.diff(getFieldValue('from'), 'days') >= 0) {
										return Promise.resolve();
									}
									return Promise.reject('Data końca terminu musi być późniejszą datą, niż początek terminu');
								},
							})]} />,
							<Record.Field.Switch title={'Aktywna'} name="is_active" />,
							<Record.Field.Textarea title={'Uwagi'} name="description" span={{ span: 24 }} />
						],
						prices: [
							<Record.Field.TableRelation title={'Cennik'} showTitle={false} name="campprices" span={{ span: 24 }} relation={["services", "dctunits"]} fields={[
								{ name: 'id', title: 'ID', isHidden: true, removeEmpty: true },
								{ name: 'service_id', title: 'Usługa', type: 'creatableRelation', relation: 'services', filterData: (data, record) => {
									return data.filter(el => el.servicegroup_id === 1);
								}, span: { lg: 9, xl: 9 }, controller: "services", field: 'name', fields: [
									{ name: 'servicegroup_id', value: 1 },
									{ name: 'is_active', value: 1 },
									{ name: 'name', value: '' }
								] },
								{ name: 'price', type: 'number', title: 'Cena', span: { lg: 5, xl: 5 } },
								{ name: 'dctunit_id', title: 'Jednostka miary', type: 'relation', relation: 'dctunits', span: { lg: 6, xl: 6 }, copyValueFromPreviousRow: true }
							]} />
						],
						availability: [
							<Record.Field.TableCheck title={'Dostępność'} showTitle={false} name="contractors" span={{ span: 24 }} relation={["contractors"]} relatedTable="contractors" transformDataKey="contractors[id]" fields={[
								{ name: 'name', title: 'Kontrahent' },
							]} />
						]
					}} tabList={[
						{ key: 'general', tab: 'Dane podstawowe' },
						{ key: 'prices', tab: 'Cennik' },
						{ key: 'availability', tab: 'Dostępność' }
					]} activeTabKey={currentActiveTab} onTabChange={(key) => { setCurrentActiveTab(key) }} forceRender={true} />
				)}
			</Record.Consumer>
		</Record>
	</div>);
};

export default Dashboard_Offers_Camps;
