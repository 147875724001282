import React from 'react';
import PropTypes from 'prop-types';

import Record_Field from './Field';

import { Form, DatePicker } from 'antd';

import moment from 'moment';

class RecordFieldDate extends Record_Field {
	static propTypes = Object.assign({}, Record_Field.propTypes, {
		format: PropTypes.string
	});

	static defaultProps = Object.assign({}, Record_Field.defaultProps, {
		format: 'YYYY-MM-DD'
	});

	static displayName = 'RecordFieldDate';

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	getRawValue() {
		const {
			name,
			format,
			overrideValue = () => null,
		} = this.props;

		const overrided = overrideValue(this.data, Object.assign({}, this.relationData, this.dependencies));

		if(overrided) {
			if(this.form?.current) {
				this.form.current.setFieldsValue({
					[name]: moment(overrided, format) ?? null
				});
			}
			return moment(overrided, format) ?? null;
		}

		let value = super.getRawValue();
		let date = moment.isMoment(value) ? value : moment(value, format);

		return value ? (date.isValid() ? date : null) : null
	}

	getValue() {
		const {
			format
		} = this.props;

		const rawValue = this.getRawValue();

		return rawValue ? rawValue.format(format) : 'Nigdy';
	}

	renderEdit() {
		const {
			title,
			name,
			disabled,

			format
		} = this.props;

		return <Form.Item
			{...this.globalItemProps}
		>
			<DatePicker style={{ width: '100%' }} format={format} disabled={disabled} autoComplete="nope" defaultValue={null} />
		</Form.Item>;
	}
}

export default RecordFieldDate;
