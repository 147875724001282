import React from 'react';
import { useHistory } from 'react-router-dom';

import { captureException } from '@sentry/react';

import Moment from 'react-moment';

import { Row, Col, Card, Form, Select, Button, Alert, message, DatePicker, Checkbox } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { backendRoot, backendApi, csrfCookie, apiData } from '../../../config/paths';
import axios from 'axios';

import moment from 'moment';

import '../../../assets/scss/dashboard.scss';

const { Option } = Select;

const Dashboard_FillPaymentDate = (props) => {
	const history = useHistory();

	const [form] = Form.useForm();

	const [relationData, setRelationData] = React.useState(null);
	const [error, setError] = React.useState(false);

	React.useEffect(() => {
		axios.get(backendRoot + csrfCookie).then(() => {
			// Getting data of related records
			axios.get(`${backendApi}${apiData}?tables=${['contractors', 'cnfapps'].join(',')}`).then((response) => {
				// Assigning data of related records to state
				setRelationData(response.data);
				setError(false);
			}).catch((err) => {
				setError({
					errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED',
					message: err?.reponse?.data?.message
				});
			});
		}).catch((err) => {
			setError({
				errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED',
				message: err?.reponse?.data?.message
			});
		});
	}, []);

	const onSave = (values) => {
		const _data = new FormData();

		_data.append('contractor_id', values.contractor_id);
		_data.append('app_table', values.app_table);
		_data.append('date', values.date);
		_data.append('from', values.from.format('YYYY-MM-DD'));
		_data.append('to', values.to.format('YYYY-MM-DD'));
		_data.append('payment_date', values.payment_date.format('YYYY-MM-DD'));
		_data.append('change_if_exists', !!(values?.change_if_exists ?? false));

		axios.get(backendRoot + csrfCookie).then(() => {
			axios.post(`${backendApi}/batch/fillpaymentdate`, _data).then((response) => {
				message.success(<>Daty zostały pomyślnie zmienione. {response?.data?.message ?? null}</>);
				setTimeout(() => {
					history.push('/');
				}, 3500);
				setError(false);
			}).catch((err) => {
				setError({
					errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED',
					message: err?.response?.data?.message
				});
			});
		}).catch((err) => {
			setError({
				errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED',
				message: err?.response?.data?.message
			});
		});
	};

	return (<div className="content">
		<div className="subheader">
			<div className="d-flex">
				<h1 className="title">Data wypłaty</h1>
				<span className="subtitle">
					Dzisiaj jest <Moment locale="pl" format="dddd, D MMMM YYYY" />
				</span>
			</div>

			<div className="subheader_actions">
				<Button type="primary" onClick={() => { form.submit(); }}>Zapisz</Button>
			</div>
		</div>

		{
			error && <Alert type="error" message={error?.message ?? 'Wystąpił nieoczekiwany błąd podczas zapisywania lub pobierania danych'} style={{ marginBottom: 25 }} />
		}

		<Card loading={relationData === null}>
			<Form
				onFinish={onSave}
				layout="vertical"
				form={form}
			>
				<Row gutter={[16,16]}>
					<Col sm={24} md={12}>
						<Form.Item
							label="Kontrahent"
							name="contractor_id"
							rules={[
								{ required: true, message: 'Proszę wybrać kontrahenta' }
							]}
						>
							<Select
								showSearch
								optionFilterProp="children"
							>
								{relationData?.contractors?.map(el => (
									<Option key={['contractors', el.id]} value={el.id}>
										[{el.symbol}] {el.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>

					<Col sm={24} md={12}>
						<Form.Item
							label="Wniosek"
							name="app_table"
							rules={[
								{ required: true, message: 'Proszę wybrać wniosek' }
							]}
						>
							<Select
								// allowSearch
								optionFilterProp="children"
							>
								{relationData?.cnfapps?.map(el => (
									<Option key={['cnfapps', el.id]} value={el.table}>
										{el.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>

					<Col span={24}>
						<Form.Item
							label="Rodzaj dat"
							name="date"
							rules={[
								{ required: true, message: 'Proszę wybrać rodzaj dat' }
							]}
							initialValue="A"
						>
							<Select
								// allowSearch
								optionFilterProp="children"
							>
								<Option value="A">
									Data akceptacji wniosku
								</Option>
								<Option value="W">
									Data wyjazdu (dot. świadczeń wyjazdowych)
								</Option>
								<Option value="Z">
									Data złożenia wniosku
								</Option>
								<Option value="K">
									Data posiedzenia komisji
								</Option>
								<Option value="U">
									Data podpisania umowy (dot. pożyczek)
								</Option>
							</Select>
						</Form.Item>
					</Col>

					<Col sm={24} md={12}>
						<Form.Item
							label="Od dnia"
							name="from"
							rules={[
								{ required: true, message: 'Proszę wybrać zakres dat' }
							]}
						>
							<DatePicker style={{ width: '100%' }} />
						</Form.Item>
					</Col>

					<Col sm={24} md={12}>
						<Form.Item
							label="Do dnia"
							name="to"
							rules={[
								{ required: true, message: 'Proszę wybrać zakres dat' }
							]}
						>
							<DatePicker style={{ width: '100%' }} />
						</Form.Item>
					</Col>

					<Col sm={24} md={12}>
						<Form.Item
							label="Data wypłaty"
							name="payment_date"
							rules={[
								{ required: true, message: 'Proszę wybrać datę wypłaty' }
							]}
						>
							<DatePicker style={{ width: '100%' }} />
						</Form.Item>
					</Col>

					<Col sm={24} md={12}>
						<Form.Item
							label="Zamieniaj jeśli istnieje"
							name="change_if_exists"
							tooltip="Jeśli opcja jest zaznaczona, a we wniosku jest już uzupełniona data wypłaty to zostanie ona nadpisana"
							valuePropName="checked"
							initialValue={false}
						>
							<Checkbox />
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Card>
	</div>);
};

export default Dashboard_FillPaymentDate;
