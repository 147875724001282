import React from 'react';

import Moment from 'react-moment';

import Record from '../../../components/Record/index';
import ClientMember from '../../../components/Record/ClientMember';

import TabbedCard from '../../../components/TabbedCard';

import { useAuth, PERMISSIONS as USER_PERMISSIONS } from '../../../contexts/User';

import { Row, Col, Card, Button } from 'antd';

import moment from 'moment';

import '../../../assets/scss/dashboard.scss';

const Dashboard_Applications_Reftrips = (props) => {
	const [currentActiveTab, setCurrentActiveTab] = React.useState('general');

	const auth = useAuth();

	return (<div className="content">
		<Record
			controller="appreftrips"
			relations={['appreftripmembers', 'dcttriptypes', 'profiles', 'families', 'contractors', 'dctfiletypes', 'dctpaymethods', 'tripprices']}
			mode={props?.mode}
			defaultEditing={props?.editing}
			filters={[
				{
					title: 'Kontrahent',
					name: 'contractors.id',
					relation: 'contractors',
					type: 'enum'
				}
			]}
			onCreatedRow={(row, data) => {
				if(data?.is_seen === 0) {
					row.style.background = 'rgba(225, 166, 52, 0.1)';
				}
			}}
			technicalFields={[
				<Record.Field.Input title={'Status wniosku'} name="staapp_name" readonly />
			]}
			customButtons={[
				{ title: 'Akcje', dropdown: [
					{ title: 'Przekazanie wniosku do akceptacji', endpoint: '/appreftrips/status/toaccept', predicate: (el, data, relationData) => [1,4].includes(data?.staapp_id), modal: { title: 'Potwierdź akcję', content: 'Czy na pewno chcesz przekazać wniosek do akceptacji?' } },
					{ title: 'Przekazanie wniosku do komisji', endpoint: '/appreftrips/status/tocommission', predicate: (el, data, relationData) => [2].includes(data?.staapp_id), modal: { title: 'Potwierdź akcję', content: 'Czy na pewno chcesz przekazać wniosek do komisji socjalnej?', fields: ['to_commission'] } },
					{ title: 'Zwrot wniosku do korekty', endpoint: '/appreftrips/status/tocorrect', predicate: (el, data, relationData) => [2,6].includes(data?.staapp_id), modal: { title: 'Potwierdź akcję', content: 'Czy na pewno chcesz przekazać wniosek do korekty?', fields: ['to_correct'] } },
					{ title: 'Akceptuj wniosek', endpoint: '/appreftrips/status/accepted', predicate: (el, data, relationData) => [2,6].includes(data?.staapp_id) },
					{ title: 'Odrzuć wniosek', endpoint: '/appreftrips/status/refused', predicate: (el, data, relationData) => [2,6].includes(data?.staapp_id), modal: { title: 'Potwierdź akcję', content: 'Czy na pewno chcesz odrzucić wniosek?', fields: ['remarks'] } },
					{ title: 'Anuluj wniosek', endpoint: '/appreftrips/status/canceled', predicate: (el, data, relationData) => [1,2,3].includes(data?.staapp_id), modal: { title: 'Potwierdź akcję', content: 'Czy na pewno chcesz anulować wniosek?', fields: ['remarks'] } },
					{ title: 'Cofnij wniosek do akceptacji', endpoint: '/appreftrips/status/undoaccept', predicate: (_el, data) => auth.can(USER_PERMISSIONS.ADMIN) && [3,5,7].includes(data?.staapp_id), modal: { title: 'Potwierdź akcję', content: 'Czy na pewno chcesz cofnąć wniosek do akceptacji?', fields: ['remarks'] } },
				] },
				{
					title: 'Nadaj numer',
					endpoint: '/appreftrips/number/{#id}',
					requestMethod: 'put',
					predicate: (el, data, relationData) => (!data?.number) && data?.staapp_id === 2,
					successText: 'Numer wniosku został nadany',
					errorText: 'Wystąpił błąd podczas nadawania numeru'
				},
				{
					title: 'Oblicz refundację',
					endpoint: '/appreftrips/count/{#id}',
					requestMethod: 'get',
					successText: 'Obliczenie zostało wykonane',
					errorText: 'Wystąpił błąd podczas obliczania'
				},
				{
					title: 'Usuń',
					endpoint: '/appreftrips/{#id}',
					requestMethod: 'delete',
					predicate: (el, data, relationData) => [1].includes(data?.staapp_id) && auth.can(USER_PERMISSIONS.ADMIN),
					successText: 'Wniosek został usunięty',
					errorText: 'Wystąpił błąd podczas usuwania wniosku',
					onSuccess: () => {
						window.location.href = '/appreftrips/';
					},
					modal: {
						title: 'Potwierdź akcję',
						content: 'Czy na pewno chcesz usunąć wniosek?',
						okText: 'Tak, usuń'
					},
					buttonType: 'danger'
				}
			]}
			columns={[
				{
					name: 'id',
					title: '#',
					type: 'number'
				},
				{
					name: 'full_name',
					title: 'Wnioskujący',
					searchable: true,
					searchname: 'users.full_name'
				},
				{
					name: 'dcttriptype_name',
					title: 'Rodzaj wyjazdu',
					sortname: 'dcttriptypes.name'
				},
				{
					name: 'from',
					title: 'Terminy od'
				},
				{
					name: 'to',
					title: 'Terminy do'
				},
				{
					name: 'application_date',
					title: 'Data złożenia wniosku'
				},
				{
					name: 'staapp_name',
					title: 'Status',
					sortname: 'staapps.name'
				},
				{
					name: 'actions',
					data: null,
					sortable: false,
					title: '',
					render: function(data, type, row) {
						return `<a class="btn btn-table" href="/appreftrips/${data.id}">Podgląd</a><a class="btn btn-table" href="/appreftrips/${data.id}/edit">Edytuj</a>`;
					},
					className: 'text-right'
				}
			]}
			viewTitle="[{#number}] Wyjazdy - refundacja - PODGLĄD"
			listTitle="Wyjazdy - refundacja"
			editTitle="[{#number}] Wyjazdy - refundacja - EDYCJA"
			createTitle="Wyjazdy - refundacja - TWORZENIE"
			onValuesChange={(field, allFields, updateRelationData, updateDependency) => {
				if(['profile_id'].includes(field.name)) {
					updateRelationData(field.value);
					updateDependency('families');
				}
			}}
		>
			<Record.Consumer>
				{({ isLoading, mode, data, changes, relationData, form }) => (
					<TabbedCard key="generalCard" loading={isLoading} tabContent={{
						general: [
							<Record.Field.Relation title="Wnioskujący" relation="profiles" name="profile_id" labelFormat="[{#registration_number}] {#full_name} - {#contractor_symbol}" link filter={ (data, record) => {
								return data.filter((el, idx) => {
									return el.staprofile_id === 2;
								});
							} } allowSearch={true} />,
							<Record.Field.Date title="Data złożenia wniosku" name="application_date" defaultValue={moment()} />,
							<Record.Field.Relation title="Rodzaj wyjazdu" name="dcttriptype_id" relation="dcttriptypes" />,
							<Record.Field.Input title="Oferta / Obiekt" name="object" />,
							<Record.Field.Date title="Terminy od" name="from" rules={[{ required: true, message: 'Proszę wybrać termin' }, ({ getFieldValue }) => ({
								validator(_, value) {
									if(value.diff(getFieldValue('to'), 'days') <= 0) {
										return Promise.resolve();
									}
									return Promise.reject('Data początku terminu musi być wcześniejszą datą, niż koniec terminu');
								},
							})]} />,
							<Record.Field.Date title="Terminy do" name="to" rules={[{ required: true, message: 'Proszę wybrać termin' }, ({ getFieldValue }) => ({
								validator(_, value) {
									if(value.diff(getFieldValue('from'), 'days') >= 0) {
										return Promise.resolve();
									}
									return Promise.reject('Data końca terminu musi być późniejszą datą, niż początek terminu');
								},
							})]} />,
							<Record.Field.Input title="Ilość dni wyjazdu" name="count_days" disabled />,
							<Record.Field.Input title="Ilość noclegów wyjazdu" name="count_nights" disabled />,
							<Record.Field.Number title="Cena wyjazdu" name="real_price" precision={2} decimalSeparator="." rules={[{ required: true, message: 'Proszę uzupełnić cenę wyjazdu' }]} />,
							<Record.Field.Empty />,
							<Record.Field.Number title="Kwota podlegająca refundacji" name="subsidy_value" disabled precision={2} decimalSeparator="." />,
							<Record.Field.Number title="Wysokość refundacji" name="subsidy_total" precision={2} decimalSeparator="." rules={[({ getFieldValue }) => ({
								validator(_, value) {
									if(isNaN(parseFloat(getFieldValue('counted_subsidy_total'))) || parseFloat(getFieldValue('counted_subsidy_total')) >= parseFloat(value)) {
										return Promise.resolve();
									}
									return Promise.reject('Wartość nie może przekraczać wyliczonej kwoty refundacji ('+getFieldValue('counted_subsidy_total')+')');
								},
							})]} />,
							<Record.Field.Number title="Rzeczywista kwota do zapłaty" name="real_quote" disabled precision={2} decimalSeparator="." />,
							<Record.Field.Input title="Wyliczona wysokość refundacji" name="counted_subsidy_total" hidePredicate={() => true} />,
							<Record.Field.Empty />,
							<Record.Field.Date title="Data wypłaty refundacji" name="payment_date" />,
							<Record.Field.Switch title="Widoczny dla komisji" name="is_visible" />,
							<Record.Field.Textarea title="Wynik ostatniego obliczenia" name="control" span={{ span: 24 }} />,
							<Record.Field.Textarea title="Uwagi (do korekty)" name="to_correct" span={{ span: 24 }} />,
							<Record.Field.Textarea title="Uwagi (do komisji)" name="to_commission" span={{ span: 24 }} />,
							<Record.Field.Textarea title="Uwagi" name="remarks" span={{ span: 24 }} />

						],
						members: [
							<ClientMember
								form={form.current}
								mode={mode.value}
								data={data.value}
								changes={changes}
								relations={relationData.value}
								relation="tripprices"
								relationField="tripprice_id"
								field="appreftripmembers"
								priceField={null}
								priceFormat="[{#offtrip_resort}] {#service_name} ({#dctunit_name}, {#price}zł, {#offtrip_from} - {#offtrip_to})"
								withoutPrice
								withManualPrice
							/>,
							<Record.Field.TableRelation title={'Uczestnicy'} name="appreftripmembers" span={{ span: 24 }} fields={[
								{ name: 'id', title: 'ID', isHidden: true, removeEmpty: true },
								{ name: 'family_id', title: 'Uczestnik', relation: 'families', type: 'relation', labelFormat: '{#first_name} {#last_name} ({#birth_date})', valueKey: 'id', span: { lg: 12, xl: 12 }, filterData: (data, recordData, relationData) => {
									return data.filter(el => el?.profile_id === recordData?.profile_id);
								}},
								{
									name: 'price',
									title: 'Cena',
									type: 'number',
									span: {
										lg: 7,
										xl: 8
									}
								},
								{ name: 'is_member', isHidden: true, removeEmpty: true },
							]} showTitle={ false } filterRow={(row) => (row?.is_member === 1 || row?.is_member === true)} withHeader />
						],
						clientdata: [
							<Record.Field.Input title="Imię i nazwisko" name="full_name" readonly overrideValue={(record, relationData) => {
								if(record.profile_id) {
									let profile = relationData?.profiles?.find(el => el.id === record.profile_id);
									return profile?.full_name;
								}

								return null;
							}} />,
							<Record.Field.Input title="Data urodzenia" name="birth_date" readonly overrideValue={(record, relationData) => {
								if(record.profile_id) {
									let profile = relationData?.profiles?.find(el => el.id === record.profile_id);
									return profile?.birth_date;
								}

								return null;
							}} />,
							<Record.Field.Input title="Miejsce zamieszkania" name="address" readonly overrideValue={(record, relationData) => {
								if(record.profile_id) {
									let profile = relationData?.profiles?.find(el => el.id === record.profile_id);
									return profile?.address;
								}

								return null;
							}} />,
							<Record.Field.Input title="Numer kontaktowy" name="phone" readonly overrideValue={(record, relationData) => {
								if(record.profile_id) {
									let profile = relationData?.profiles?.find(el => el.id === record.profile_id);
									return profile?.phone;
								}

								return null;
							}} />,

							<Record.Field.Input title="Kontrahent" name="contractor_name" readonly overrideValue={(record, relationData) => {
								if(record.profile_id) {
									let profile = relationData?.profiles?.find(el => el.id === record.profile_id);
									return profile?.contractor_name;
								}

								return null;
							}} />,
							<Record.Field.Input title="Numer NIP kontrahenta" name="contractor_nip" readonly overrideValue={(record, relationData) => {
								if(record.profile_id) {
									let profile = relationData?.profiles?.find(el => el.id === record.profile_id);
									return profile?.contractor_nip;
								}

								return null;
							}} />,
							<Record.Field.Input title="Adres kontrahenta" name="branch_address" readonly overrideValue={(record, relationData) => {
								if(record.profile_id) {
									let profile = relationData?.profiles?.find(el => el.id === record.profile_id);
									return profile?.branch_address;
								}

								return null;
							}} />,
							<Record.Field.Input title="Średnie dochody" name="average_incomes" readonly overrideValue={(record, relationData) => {
								if(record.profile_id) {
									let profile = relationData?.profiles?.find(el => (el?.id ?? el?.value) === record.profile_id);
									return profile?.average_incomes;
								}

								return null;
							}} />,

							<Record.Field.TableRelation readonly title={'Rodzina'} name="families" span={{ span: 24 }} fields={[
								{ name: 'first_name', title: 'Imię' },
								{ name: 'last_name', title: 'Nazwisko' },
								{ name: 'birth_date', title: 'Data urodzenia' },
								{ name: 'pesel', title: 'PESEL' },
								{ name: 'dctrelationship_name', title: 'Rodzaj pokrewieństwa' }
							]} />
						],
						payments: [
							<Record.Field.TableRelation title={'Wpłaty'} name="payments" span={{ span: 24 }} fields={[
								{ name: 'id', title: 'ID', isHidden: true, removeEmpty: true },
								{ name: 'dctpaymethod_id', title: 'Metoda płatności', type: 'relation', relation: 'dctpaymethods', labelFormat: '{#name}', valueKey: 'id', span: { lg: 5, xl: 6 } },
								{ name: 'document_number', title: 'Nr dokumentu', span: { lg: 4, xl: 5 } },
								{ name: 'payment_quote', title: 'Kwota', type: 'number', span: { lg: 5, xl: 4 } },
								{ name: 'payment_date', title: 'Data wpłaty', span: { lg: 5, xl: 5 }, type: 'date' },
							]} showTitle={ false } defaultValues={{
								polpaymentable_type: 'appreftrips',
								polpaymentable_id: '{#id}'
							}} transformDataKey="polpayments" />
						],
						attachments: [
							<Record.Field.Attachments title={'Załączniki'} name="attachments" fileCategory="Ogólny" relation="dctfiletypes" />
						],
						history: [
							<Record.Field.TableRelation readonly title={'Historia statusów'} showTitle={false} name="workflow" span={{ span: 24 }} fields={[
								{ name: 'from_status', title: 'Poprzedni status' },
								{ name: 'to_status', title: 'Nowy status' },
								{ name: 'updater', title: 'Zaktualizowane przez' },
								{ name: 'change_date', title: 'Data', type: 'date' }
							]} />
						]
					}} tabList={mode.value === 'create' ? [
						{ key: 'general', tab: 'Dane podstawowe' },
						{ key: 'members', tab: 'Uczestnicy' },
						{ key: 'clientdata', tab: 'Dane klienta' },
						{ key: 'attachments', tab: 'Załączniki' }
					] : [
						{ key: 'general', tab: 'Dane podstawowe' },
						{ key: 'members', tab: 'Uczestnicy' },
						{ key: 'clientdata', tab: 'Dane klienta' },
						{ key: 'payments', tab: 'Rejestr wpłat' },
						{ key: 'attachments', tab: 'Załączniki' },
						{ key: 'history', tab: 'Historia statusów' }
					]} activeTabKey={currentActiveTab} onTabChange={(key) => { setCurrentActiveTab(key) }} forceRender={true} />
				)}
			</Record.Consumer>
		</Record>
	</div>);
};

export default Dashboard_Applications_Reftrips;
