import React from 'react';

import Moment from 'react-moment';

import Record from '../../components/Record/index';

import TabbedCard from '../../components/TabbedCard';

import { Row, Col, Card, Button } from 'antd';

import '../../assets/scss/dashboard.scss';

const Dashboard_Contractors = (props) => {
	const [currentActiveTab, setCurrentActiveTab] = React.useState('general');

	return (<div className="content">
		<Record
			controller="contractors"
			relations={['dctfiletypes', 'committees']}
			mode={props?.mode}
			defaultEditing={props?.editing}
			columns={[
				{
					name: 'id',
					title: '#',
					type: 'number'
				},
				{
					name: 'name',
					title: 'Nazwa',
					searchable: true
				},
				{
					name: 'town',
					title: 'Miasto',
					searchable: true
				},
				{
					name: 'street',
					title: 'Ulica',
					searchable: true
				},
				{
					name: 'nip',
					title: 'NIP',
					searchable: true
				},
				{
					name: 'actions',
					data: null,
					sortable: false,
					title: '',
					render: function(data, type, row) {
						return `<a class="btn btn-table" href="/contractors/${data.id}">Podgląd</a><a class="btn btn-table" href="/contractors/${data.id}/edit">Edytuj</a>`;
					},
					className: 'text-right'
				}
			]}
			confirmSave={{
				message: 'Nie dodano regulaminu kontrahenta, na pewno chcesz kontynuować?',
				predicate: (data, values) => {
					if(values.hasOwnProperty('attachments3')) {
						const regs = (values?.attachments3 ?? []).filter(el => el?.dctfiletype_id === 6 || el?.size);

						return regs.length ? false : true;
					}
					return true;
				}
			}}
			viewTitle="Podgląd kontrahenta"
			listTitle="Lista kontrahentów"
			createTitle="Tworzenie kontrahenta"
			editTitle="Edycja kontrahenta"
			createButtonText="Dodaj kontrahenta"
		>
			<Record.Consumer>
				{({ isLoading, form }) => (
					<TabbedCard key="generalCard" loading={isLoading} tabContent={{
						general: [
							<Record.Field.Input
								title="Nazwa"
								name="name"
								span={{ span: 24 }}
								rules={[
									{
										required: true,
										message: 'Proszę uzupełnić nazwę kontrahenta'
									}
								]}
							/>,
							<Record.Field.Input
								title="Ulica"
								name="street"
								span={{ sm: 24, md: 12 }}
								rules={[
									{
										required: true,
										message: 'Proszę uzupełnić adres siedziby kontrahenta'
									}
								]}
							/>,
							<Record.Field.Input
								title="Kod pocztowy"
								name="zip"
								span={{ sm: 24, md: 5 }}
								rules={[
									{
										required: true,
										message: 'Proszę uzupełnić adres siedziby kontrahenta'
									}
								]}
							/>,
							<Record.Field.Input
								title="Miasto"
								name="town"
								span={{ sm: 24, md: 7 }}
								rules={[
									{
										required: true,
										message: 'Proszę uzupełnić adres siedziby kontrahenta'
									}
								]}
							/>,
							<Record.Field.Group
								title="Osoba do kontaktu"
								name="_contact_person"
								fields={[
									{
										title: 'Imię i nazwisko',
										name: 'contact_name',
										width: '40%'
									},
									{
										title: 'Numer kontaktowy',
										name: 'contact_phone',
										width: '60%',
										rules: [{ pattern: /^(?<!\w)(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}(?!\w)$/, message: 'Proszę wprowadzić prawidłowy numer telefonu' }]
									}
								]}
							/>,
							<Record.Field.Input
								title="Symbol"
								name="symbol"
								rules={[
									{
										required: true,
										message: 'Proszę uzupełnić symbol kontrahenta'
									}
								]}
								maxLength={4}
							/>,
							<Record.Field.Input
								title="NIP"
								name="nip"
								dependencies={['is_outer']}
								rules={[
										// {
										// 	pattern: /^\d{3}[-]\d{3}[-]\d{2}[-]\d{2}$|^\d{10}$/,
										// 	message: 'Proszę wprowadzić prawidłowy numer NIP. Dozwolone formaty: XXXXXXXXXX oraz XXX-XXX-XX-XX, gdzie X oznacza cyfrę'
										// },
										{
											required: (form?.current?.getFieldValue?.('is_outer') ? false : true),
											message: 'Proszę uzupełnić numer NIP'
										}
								]}
							/>,
							<Record.Field.Input
								title="Numer rachunku bankowego"
								name="bank_account"
								rules={[
									{
										pattern: /^\d{2}[ ]\d{4}[ ]\d{4}[ ]\d{4}[ ]\d{4}[ ]\d{4}[ ]\d{4}$|^\d{26}$/,
										message: 'Proszę wprowadzić prawidłowy numer rachunku bankowego'
									}
								]}
							/>,
							<Record.Field.Switch
								title="Kontrahent zewnętrzny"
								name="is_outer"
							/>,
						],
						branches: [
							<Record.Field.TableRelation title={'Oddziały'} name="branches" span={{ span: 24 }} fields={[
								{ name: 'id', title: 'ID', isHidden: true, removeEmpty: true },
								{ name: 'name', title: 'Nazwa', span: { lg: 6, xl: 6 } },
								{ name: 'street', title: 'Ulica', span: { lg: 6, xl: 6 } },
								{ name: 'zip', title: 'Kod pocztowy', span: { lg: 3, xl: 3 } },
								{ name: 'town', title: 'Miasto', span: { lg: 4, xl: 5 } }
							]} showTitle={ false } />
						],
						infants: [
							<Record.Field.TableRelation title={'Przedszkola i żłobki'} name="infants" span={{ span: 24 }} fields={[
								{ name: 'id', title: 'ID', isHidden: true, removeEmpty: true },
								{ name: 'name', title: 'Nazwa', span: { lg: 5, xl: 5 } },
								{ name: 'infant_level', title: 'Rodzaj', type: 'select', options: [{ value: 'P', label: 'Przedszkole' }, { value: 'Z', label: 'Żłobek' }], span: { lg: 3, xl: 3 } },
								{ name: 'town', title: 'Miasto', span: { lg: 3, xl: 4 } },
								{ name: 'street', title: 'Ulica', span: { lg: 5, xl: 5 } },
								{ name: 'zip', title: 'Kod pocztowy', span: { lg: 3, xl: 3 } }
							]} showTitle={ false } />
						],
						committee: [
							<Record.Field.Table title={'Komisja'} name="committee" relation="users" filter={(data, recordData) => {
								return data.filter(user => {
									return user?.contractors && user.contractors.find(contractor => {
										return (contractor?.id ?? contractor?.value) === recordData?.id;
									}) && (user.permission_code === 30 || user.permission_code === 35);
								});
							}} span={{ span: 24 }} fields={[
								{ name: 'label', title: 'Imię i nazwisko' },
								{ name: 'phone', title: 'Numer kontaktowy' }
							]} showTitle={ false } alertMessage={ 'Edycja członków komisji możliwa jest z poziomu uprawnień użytkownika.' } />
						],
						attachments: [
							<Record.Field.Attachments title="Logotypy" name="attachments" valueName="attachments" fileCategory="Logotypy" relation="dctfiletypes" uploadProps={{
								accept: 'images/*',
								maxCount: 1
							}} />,
							<Record.Field.Attachments title="Dokumenty RODO" name="attachments2" valueName="attachments" fileCategory="RODO" relation="dctfiletypes" />,
							<Record.Field.Attachments title="Regulaminy" name="attachments3" valueName="attachments" fileCategory="Regulaminy" relation="dctfiletypes" />,
						],
					}} tabList={[
						{ key: 'general', tab: 'Dane podstawowe'},
						{ key: 'branches', tab: 'Oddziały' },
						{ key: 'infants', tab: 'Przedszkola i żłobki' },
						{ key: 'committee', tab: 'Komisja' },
						{ key: 'attachments', tab: 'Załączniki' }
					]} activeTabKey={currentActiveTab} onTabChange={(key) => { setCurrentActiveTab(key) }} forceRender={true} />
				)}
			</Record.Consumer>
		</Record>
	</div>);
};

export default Dashboard_Contractors;
