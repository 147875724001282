import React from 'react';

import PropTypes from 'prop-types';

import { assignDataToString } from '../../libs/Helpers';

import { Row, Col, Select, Checkbox, InputNumber } from 'antd';
import {FILTER_RELATIONS} from "../../config/constants";
import SearchSelect from "../SearchSelect";
import {APIBackend as API} from "../../api";
import {RecordContext} from "./context";

class ClientMember extends React.Component {
	state = {
		is_member: false,
		price_id: null,
		price: null,
		id: null,
		tickets_count: 0,
		dctmultitype_id: null
	};

	static contextType = RecordContext;

	static propTypes = {
		relation: PropTypes.string.isRequired,
		relationField: PropTypes.string.isRequired,
		field: PropTypes.string.isRequired,
		priceField: PropTypes.string.isRequired,
		withTickets: PropTypes.bool,
		withMultis: PropTypes.bool,
		withoutPrice: PropTypes.bool,
		withManualPrice: PropTypes.bool,
	};

	static defaultProps = {
		priceFormat: '{#service_name} ({#dctunit_name})',
		formatNullValue: '(brak)',
		withTickets: false,
		withMultis: false,
		withoutPrice: false,
		withManualPrice: false,
	};

	get loading() {
		const {
			data,
			relations
		} = this.props;

		return (!data || !relations);
	}

	get client() {
		const {
			relations
		} = this.props;

		if(this.loading) return null;

		const profile_id = this.data?.profile_id ?? null;

		return (relations && relations.hasOwnProperty('profiles') && relations.profiles)
			? relations.profiles.find(el => (el?.id ?? el?.value) === profile_id)
			: null;
	}

	get relation() {
		const {
			relations,
			relation,
			priceField,
			withMultis
		} = this.props;

		if(this.loading || (!relation && !withMultis)) return [];

		if(withMultis) {
			return (relations && relations.hasOwnProperty('dctmultitypes') && relations.dctmultitypes)
				? relations.dctmultitypes
				: [];
		} else {
			if(priceField) {
				return (relations && relations.hasOwnProperty(relation) && relations[relation])
					? relations[relation].filter(el => el[priceField] === this.data[priceField])
					: [];
			} else {
				return (relations && relations.hasOwnProperty(relation) && relations[relation])
					? relations[relation]
					: [];
			}
		}
	}

	get members() {
		const {
			field
		} = this.props;

		const members = this.loading
			? null
			: (this.data.hasOwnProperty(field)
				? this.data[field].filter(el => (!el?.is_member && !el?.family_id && !el?.id) ? false : true)
				: null);

		if(!members || !Array.isArray(members)) return [];


		return members;
	}

	get changes() {
		const {
			changes
		} = this.props;

		return changes.value;
	}

	set changes(value) {
		const {
			changes
		} = this.props;

		changes.set(value);
	}

	get data() {
		const {
			data
		} = this.props;

		return this.loading
			? null
			: Object.assign({}, data, this.changes);
	}

	get member() {
		const members = this.members;

		if(!members) return null;

		const member = members.find(el => el.is_member === 1 || el.is_member === true);

		return member ?? null;
	}

	get dependencies() {
		return (this.context && this.context.dependenciesData) ? this.context.dependenciesData.value : null;
	}

	set dependencies(value) {
		this.context && this.context.dependenciesData && this.context.dependenciesData.set(value);
	}

	constructor(props) {
		super(props);

		this.onCheckboxChange = this.onCheckboxChange.bind(this);
		this.onSelectChange = this.onSelectChange.bind(this);
		this.onMultisSelectChange = this.onMultisSelectChange.bind(this);
		this.onInputChange = this.onInputChange.bind(this);
		this.onPriceInputChange = this.onPriceInputChange.bind(this);
	}

	componentDidMount() {
		const {
			relationField
		} = this.props;

		this.setState({
			is_member: this.member ? 1 : 0,
			price_id: this.member ? this.member[relationField] : null,
			id: this.member ? this.member.id : null,
			tickets_count: this.member ? this.member?.tickets_count : null,
			dctmultitype_id: this.member ? this.member?.dctmultitype_id : null,
			price: this.member ? this.member?.price : null,
		});
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			field,
			relationField
		} = this.props;

		const {
			price_id,
			is_member,
			id,
			tickets_count,
			dctmultitype_id
		} = this.state;

		if(
			prevState.is_member !== this.state.is_member
			|| prevState.price_id !== this.state.price_id
			|| prevState.tickets_count !== this.state.tickets_count
			|| prevState.dctmultitype_id !== this.state.dctmultitype_id
			|| prevState.price !== this.state.price
		) {
			this.onValuesChange();
		}

		if(this.state.is_member && !this.member) {
			const members = this.members;

			members.push({
				family_id: null,
				[relationField]: price_id,
				is_member: is_member ? 1 : 0,
				id,
				tickets_count,
				dctmultitype_id
			});

			this.changes = {
				...this.changes,
				[field]: members
			};
		}
	}

	onValuesChange() {
		const {
			is_member,
			price_id,
			price,
			id,
			tickets_count,
			dctmultitype_id
		} = this.state;

		const {
			field,
			relationField,
			form
		} = this.props;

		let member = this.member;

		let members = this.members;


		if(!member && is_member) {
			const memberIdx = members.push({
				family_id: null,
				[relationField]: price_id,
				price,
				is_member: 1,
				id,
				tickets_count,
				dctmultitype_id
			}) - 1;

			member = members[memberIdx];
		} else {
			const memberIdx = members.findIndex(el => el === member);

			if(is_member) {
				members[memberIdx] = {
					family_id: null,
					[relationField]: price_id,
					price,
					is_member: 1,
					id,
					tickets_count,
					dctmultitype_id
				};
			} else {
				if(memberIdx >= 0) {
					members.splice(memberIdx, 1);
				}
			}

		}

		this.changes = {
			...this.changes,
			[field]: members
		};

		if(form) {
			form.setFieldsValue({
				[field]: members
			});
		}
	}

	onMultisSelectChange(val) {
		this.setState({
			dctmultitype_id: val
		});
	}

	onSelectChange(val) {
		this.setState({
			price_id: val
		});
	}

	onCheckboxChange(e) {
		this.setState({
			is_member: e.target.checked
		});
	}

	onInputChange(val) {
		this.setState({
			tickets_count: val
		});
	}

	onPriceInputChange(val) {
		this.setState({
			price: val
		});
	}

	getPrice() {
		const {
			relationField,
			priceFormat,
			formatNullValue,
			relation,
		} = this.props;

		const member = this.member;

		if(!this.loading && member) {
			if(this.relation || (this.dependencies && this.dependencies?.[relation])) {
				const price = this.dependencies?.[relation]?.find?.(el => (el?.value ?? el?.id) === member[relationField]) || this.relation?.find?.(el => (el?.value ?? el?.id) === member[relationField]);
				return price?.label ?? assignDataToString(priceFormat, price, formatNullValue);
			}
		}

		return '(brak)';
	}

	getCard() {
		const {
			priceFormat,
			formatNullValue
		} = this.props;

		const member = this.member;

		if(!this.loading && member) {
			if(this.relation) {
				const card = this.relation.find(el => el?.id === member?.dctmultitype_id);
				return assignDataToString(priceFormat, card, formatNullValue);
			}
		}

		return '(brak)';
	}

	fetchFilterOptions(value, relation) {
		if (!relation) {
			return Promise.reject();
		}

		return new Promise((resolve, reject) => {
			API.datacustom([{
				name: relation,
				query: value
			}]).then(response => {
				const currentOptions = this.dependencies;
				let newOptions = {...currentOptions};

				if (response.hasOwnProperty(relation)) {
					resolve(response[relation]);

					newOptions = {...newOptions, [relation]: response[relation]};
				} else {
					resolve([]);
					newOptions = {...newOptions, [relation]: []};
				}

				this.dependencies = newOptions;
			}).catch(error => {
				reject(error);
			});
		});
	}

	renderView() {
		const {
			client,
			member
		} = this;

		const {
			withTickets,
			withMultis,
			withoutPrice,
			withManualPrice
		} = this.props;

		return (
			<>
				<tr className="ant-table-row">
					<td className="ant-table-cell">{(client?.full_name ?? client?.label) ?? '?'}</td>
					{
						!withMultis
							? withoutPrice ? null : <td className="ant-table-cell">{this.getPrice()}</td>
							: <td className="ant-table-cell">{this.getCard()}</td>
					}
					{
						withTickets
							? <td className="ant-table-cell">{member?.tickets_count ?? '(brak)'}</td>
							: null
					}
					{
						withManualPrice
							? <td className="ant-table-cell">{member?.price ?? '(brak)'}</td>
							: null
					}
					<td className="ant-table-cell">{member?.is_member ? 'Tak' : 'Nie'}</td>
				</tr>
			</>
		);
	}

	renderEdit() {
		const {
			client,
			member
		} = this;

		const {
			priceFormat,
			formatNullValue,
			withTickets,
			withMultis,
			withoutPrice,
			withManualPrice
		} = this.props;

		const {
			is_member,
			price_id,
			price,
			tickets_count,
			dctmultitype_id
		} = this.state;

		const priceSelect = FILTER_RELATIONS.includes(this.props.relation)
				? <SearchSelect
					fetch={this.fetchFilterOptions.bind(this)}
					relation={this.props.relation}
					relationKey="id"
					autoComplete="nope"
					initialOptions={this.dependencies?.[this.props.relation] ?? []}
					style={{ width: '100%' }}
					value={price_id}
					onChange={this.onSelectChange}
				/>
				: <Select style={{ width: '100%' }} value={price_id} onChange={this.onSelectChange}>
					{
						this.relation.map((el, idx) => (
							<Select.Option
								key={['members_priceOption', idx]}
								value={el.id}
							>
								{assignDataToString(priceFormat, el, formatNullValue)}
							</Select.Option>
						))
					}
				</Select>;

		const priceInput = withoutPrice ? null : <td className="ant-table-cell">
			{priceSelect}
		</td>;

		return (
			<>
				<tr className="ant-table-row">
					<td className="ant-table-cell">{(client?.full_name ?? client?.label) ?? '?'}</td>
					{
						!withMultis
							? priceInput
							: <td className="ant-table-cell">
								<Select style={{ width: '100%' }} value={dctmultitype_id} onChange={this.onMultisSelectChange}>
									{
										this.relation.map((el, idx) => (
											<Select.Option
												key={['members_priceOption', idx]}
												value={el.id}
											>
												{assignDataToString(priceFormat, el, formatNullValue)}
											</Select.Option>
										))
									}
								</Select>
							</td>
					}
					{
						withTickets
							? <td className="ant-table-cell">
								<InputNumber style={{ width: '100%' }} precision={0} delimiter="." value={tickets_count} onChange={this.onInputChange} />
							</td>
							: null
					}
					{
						withManualPrice
							? <td className="ant-table-cell">
								<InputNumber style={{ width: '100%' }} precision={2} delimiter="." value={price} onChange={this.onPriceInputChange} />
							</td>
							: null
					}
					<td className="ant-table-cell">
						<Checkbox checked={is_member} onChange={this.onCheckboxChange} defaultChecked={false} />
					</td>
				</tr>
			</>
		);
	}

	render() {
		const {
			mode,
			withTickets,
			withMultis,
			withoutPrice,
			withManualPrice
		} = this.props;

		const {
			client,
			member
		} = this;

		const priceHeader = withoutPrice ? null : <th className="ant-table-cell">Pozycja cennika</th>;

		return (
			<>
				<Col sm={24} md={24}>
					<div className="ant-table">
						<div className="ant-table-container">
							<div className="ant-table-content">
								<table style={{ tableLayout: 'auto' }}>
									<thead className="ant-table-thead">
										<tr className="ant-table-row">
											<th className="ant-table-cell">Uczestnik</th>
											{
												!withMultis
													? priceHeader
													: <th className="ant-table-cell">Rodzaj karty</th>
											}
											{
												withTickets
													? <th className="ant-table-cell">Ilość biletów</th>
													: null
											}
											{
												withManualPrice
													? <th className="ant-table-cell">Cena</th>
													: null
											}
											<th className="ant-table-cell">Korzysta ze świadczenia?</th>
										</tr>
									</thead>
									<tbody className="ant-table-tbody">
										{
											mode === 'view'
												? this.renderView()
												: this.renderEdit()
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>

					{/*<pre>
						{JSON.stringify(this.members, null, 2)}
					</pre>*/}
				</Col>
			</>
		);
	}
};

export default ClientMember;
