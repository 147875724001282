import React, { useState } from 'react';
import { useHistory, Link } from "react-router-dom";

import axios from 'axios';

import { useAuth } from '../contexts/User';

import { Form, Input, Checkbox, Button, Row, Col, Card, Alert } from 'antd';

import '../assets/scss/login.scss';

axios.defaults.withCredentials = true;

const Register = (props) => {
	const [checked, setChecked] = useState(false);
    const [isError, setError] = useState(false);
    const [message, setMessage] = useState(false);
	const history = useHistory();
	const [form] = Form.useForm();

	const auth = useAuth();

	const onCheckboxChange = async (e) => {
		await setChecked(e.target.checked);
		form.validateFields(['regulation']);
	};

	const onFormFinished = (values) => {
		const referer = (props.location.state) ? props.location.state.from : null || '/';

		auth.register(values).then(({data, msg = false}) => {
			setMessage(msg ?? false);
			setError(false);
			setTimeout(() => {
				history.push('/login');
			}, 5000);
		}).catch(err => {
			setError(err);
		});
	};

	return (<main className="main h-100 w-100">
		<div className="container h-100">
			<Row className="h-100">
				<Col sm={20} md={16} lg={12} className="d-table mx-auto h-100">
					<div className="d-table-cell align-middle login-pane">
						{ isError && <Alert message={isError} type="error" style={{ marginBottom: '15px' }} /> }
						{ message && <Alert message={message} type="success" style={{ marginBottom: '15px' }} /> }
						<Card title={<><div className="login-card-title">Rejestracja konta</div></>} bordered={false} className="login-card">
							<Form form={form} name="login-form" layout="vertical" onFinish={onFormFinished}>
								<Form.Item
									label="Adres e-mail:"
									name="email"
									rules={[{ required: true, message: 'Proszę wprowadzić adres e-mail' },{ type: 'email', message: 'Proszę wprowadzić poprawny adres e-mail' }]}>
									<Input />
								</Form.Item>

								<Form.Item
									label="Imię:"
									name="first_name"
									rules={[{ required: true, message: 'Proszę wprowadzić imię' }]}>
									<Input />
								</Form.Item>

								<Form.Item
									label="Nazwisko:"
									name="last_name"
									rules={[{ required: true, message: 'Proszę wprowadzić nazwisko' }]}>
									<Input />
								</Form.Item>

								<Form.Item
									label="Numer telefonu:"
									name="phone"
									rules={[{ required: true, message: 'Proszę wprowadzić numer kontaktowy' }, { pattern: /^(?<!\w)(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}(?!\w)$/, message: 'Proszę wprowadzić prawidłowy numer telefonu' }]}>
									<Input />
								</Form.Item>

								<Form.Item
									label="PESEL:"
									name="pesel"
									rules={[{ required: true, message: 'Proszę wprowadzić PESEL' }, { pattern: /^\d{11}$/, message: 'Prosze wprowadzić prawidłowy numer PESEL' }]}>
									<Input />
								</Form.Item>

								<Form.Item
									label="Dodatkowe notatki:"
									name="remarks">
									<Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
								</Form.Item>

								<Form.Item
									initialValue={false}
									name="regulation"
									valuePropName="checked"
									rules={[{ validator: (rule, value, callback) => { if(checked) { return callback(); } return callback('Proszę zaakceptować regulamin serwisu'); } }]}>
									<Checkbox onChange={onCheckboxChange}>Zaakceptuj regulamin serwisu</Checkbox>
								</Form.Item>

								<Row>
									<Col span={24} style={{ textAlign: 'right' }}>
										<Form.Item>
											<Button type="primary" htmlType="submit">
												Załóż konto
											</Button>
										</Form.Item>
									</Col>
								</Row>

								<Row>
									<Col span={24}>
										Masz już konto? <Link to="/login">
											Zaloguj się
										</Link>
									</Col>
								</Row>
							</Form>
						</Card>
					</div>
				</Col>
			</Row>
		</div>
	</main>);
};

export default Register;
