import { BrowserRouter as Router } from 'react-router-dom';

import { UserProvider } from './contexts/User';
import { ConfigProvider } from 'antd';

import locale from 'antd/lib/locale/pl_PL';

import 'moment/locale/pl';

import axios from 'axios';

import Main from './Main';

axios.defaults.withCredentials = true;

const App = () => {
	return (<div className="app">
		<Router>
			<ConfigProvider locale={locale}>
				<UserProvider>
					<Main />
				</UserProvider>
			</ConfigProvider>
		</Router>
	</div>);
};

export default App;
