import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";

import axios from 'axios';

import { backendRoot, backendApi, csrfCookie, apiCheckToken, apiSaveNewPassword } from '../config/paths';

import prepareErrorMessage from '../libs/ErrorHelper.js';

import { Form, Input, Checkbox, Button, Row, Col, Card, Alert } from 'antd';

import '../assets/scss/login.scss';

axios.defaults.withCredentials = true;

const ResetPassword = (props) => {
	const { token } = useParams();

    const [isSent, setSent] = useState(false);
    const [isError, setError] = useState(false);
	const [isTokenValid, setTokenValid] = useState(null);

	const history = useHistory();

	useEffect(() => {
		axios.get(backendRoot + csrfCookie).then(() => {
			axios.post(backendApi + apiCheckToken, { token: token }).then((response) => {
				setTokenValid(response.data.valid);
			}).catch(err => {
				if(err.response) {
					setTokenValid(err.response.data.valid);
				} else {
					setSent(true);

					if(err.response) {
						setError(err.response.data.errcode);
					} else {
						setError('ERR-UNDEFINED');
					}
				}
			});
		}).catch(err => {
			setSent(true);

			if(err.response) {
				setError(err.response.data.errcode);
			} else {
				setError('ERR-UNDEFINED');
			}
		});
	}, []);

	const onFormFinished = (values) => {
		axios.get(backendRoot + csrfCookie).then(() => {
			axios.post(backendApi + apiSaveNewPassword, Object.assign({}, { token: token }, values)).then((response) => {
				setSent(true);
			}).catch(err => {
				setSent(true);
				
				if(err.response) {
					setError(err.response.data.errcode);
				} else {
					setError('ERR-UNDEFINED');
				}
			});
		}).catch(err => {
			setSent(true);

			if(err.response) {
				setError(err.response.data.errcode);
			} else {
				setError('ERR-UNDEFINED');
			}
		});
	};

	return (<main className="main h-100 w-100">
		<div className="container h-100">
			<Row className="h-100">
				<Col sm={20} md={16} lg={12} className="d-table mx-auto h-100">
					<div className="d-table-cell align-middle login-pane">
						<Card title={<><div className="login-card-title">Ustawianie nowego hasła</div></>} bordered={false} className="login-card">
							{ isSent ? (
								isError ? <Row>
									<Col span={24} style={{ textAlign: 'center' }} className="my-1">
										<Alert message={prepareErrorMessage(isError)} type="error" />
									</Col>
									<Col span={24} style={{ textAlign: 'center' }} className="my-1">
										<Link to="/login">
											Powrót do logowania
										</Link>
									</Col>
								</Row> : <Row>
									<Col span={24} style={{ textAlign: 'center' }} className="my-1">
										<Alert message="Twoje hasło zostało zmienione! Teraz możesz się zalogować podając nowe hasło." type="success" />
									</Col>
									<Col span={24} style={{ textAlign: 'center' }} className="my-1">
									<Link to="/login">
										Powrót do logowania
									</Link>
									</Col>
								</Row>
							) : ( isTokenValid === null ? null : (
								isTokenValid ? <Form name="login-form" layout="vertical" onFinish={onFormFinished}>
									<Form.Item
										label="Adres e-mail:"
										name="email"
										rules={[{ required: true, message: 'Proszę wprowadzić adres e-mail' },{ type: 'email', message: 'Proszę wprowadzić poprawny adres e-mail' }]}>
										<Input />
									</Form.Item>

									<Form.Item
										label="Nowe hasło:"
										name="password"
										rules={[{ required: true, message: 'Proszę wprowadzić hasło' }]}>
										<Input.Password />
									</Form.Item>

									<Form.Item
										label="Powtórz hasło:"
										name="confirm"
										rules={[{ required: true, message: 'Proszę powtórzyć wprowadzone hasło' }, ({ getFieldValue }) => ({
											validator(rule, value) {
												if(!value || getFieldValue('password') === value) {
													return Promise.resolve();
												}

												return Promise.reject('Podane hasła nie zgadzają się ze sobą');
											}
										})]}>
										<Input.Password />
									</Form.Item>

									<Row>
										<Col span={24} style={{ textAlign: 'right' }}>
											<Form.Item>
												<Button type="primary" htmlType="submit">
													Ustaw hasło
												</Button>
											</Form.Item>
										</Col>
									</Row>
								</Form> : <Row>
									<Col span={24} style={{ textAlign: 'center' }} className="my-1">
										<Alert message="Wybrany adres odzykiwania hasła jest nieprawidłowy lub wygasł. Prześlij ponownie swój adres e-mail, aby otrzymać nowy link do zresetowania hasła." type="error" />
									</Col>
									<Col span={24} style={{ textAlign: 'center' }} className="my-1">
										<Link to="/login">
											Powrót do logowania
										</Link>
									</Col>
								</Row>)
							) }
						</Card>
					</div>
				</Col>
			</Row>
		</div>
	</main>);
};

export default ResetPassword;
