import React from 'react';
import { useHistory, useParams } from "react-router-dom";

import Moment from 'react-moment';

import Record from '../../components/_Record';

import { Row, Col, Card, Button, Select } from 'antd';

import { compareValues } from '../../libs/Helpers';

import '../../assets/scss/dashboard.scss';

const objects = [
	{
		name: 'ikoobjects',
		title: 'Obiekty IKO',
		listTitle: 'Lista obiektów IKO',
		viewTitle: 'Podgląd obiektu IKO',
		createTitle: 'Tworzenie obiektu IKO',
		fields: [
			<Record.Field title={'Nazwa'} field="name" />,
			<Record.Field.Relation title={'Rodzaj obiektu'} field="dctikotype_id" relation="dctikotypes" />,
			<Record.Field title={'Ulica'} field="street" span={{ sm: 24, md: 12 }} />,
			<Record.Field title={'Kod pocztowy'} field="zip" span={{ sm: 24, md: 5 }} />,
			<Record.Field title={'Miejscowość'} field="town" span={{ sm: 24, md: 7 }} />,
			<Record.Field.Switch title={'Aktywny'} field="is_active" defaultValue={ 1 } />
		],
		relations: ['dctikotypes']
	},
	{
		name: 'iroobjects',
		title: 'Obiekty IRO',
		listTitle: 'Lista obiektów IRO',
		viewTitle: 'Podgląd obiektu IRO',
		createTitle: 'Tworzenie obiektu IRO',
		fields: [
			<Record.Field title={'Nazwa'} field="name" />,
			<Record.Field.Relation title={'Rodzaj obiektu'} field="dctirotype_id" relation="dctirotypes" />,
			<Record.Field title={'Ulica'} field="street" span={{ sm: 24, md: 12 }} />,
			<Record.Field title={'Kod pocztowy'} field="zip" span={{ sm: 24, md: 5 }} />,
			<Record.Field title={'Miejscowość'} field="town" span={{ sm: 24, md: 7 }} />,
			<Record.Field.Switch title={'Aktywny'} field="is_active" defaultValue={ 1 } />
		],
		relations: ['dctirotypes']
	},
	{
		name: 'touristobjects',
		title: 'Obiekty tur-rek',
		listTitle: 'Lista obiektów tur-rek',
		viewTitle: 'Podgląd obiektu tur-rek',
		createTitle: 'Tworzenie obiektu tur-rek',
		fields: [
			<Record.Field title={'Nazwa'} field="name" />,
			<Record.Field.Relation title={'Rodzaj obiektu'} field="dcttouristtype_id" relation="dcttouristtypes" />,
			<Record.Field title={'Ulica'} field="street" span={{ sm: 24, md: 12 }} />,
			<Record.Field title={'Kod pocztowy'} field="zip" span={{ sm: 24, md: 5 }} />,
			<Record.Field title={'Miejscowość'} field="town" span={{ sm: 24, md: 7 }} />,
			<Record.Field.Switch title={'Aktywny'} field="is_active" defaultValue={ 1 } />
		],
		relations: ['dcttouristtypes']
	}
];

const getObjectByName = (name) => objects.find(obj => obj.name === name) ?? objects[0].name ?? null;

const Dashboard_Objects = (props) => {
	const history = useHistory();
	const { object } = useParams();
	const [activeObject, setActiveObject] = React.useState(object ?? objects[0].name);
	const [mode, setMode] = React.useState(props.mode ?? 'list');

	return (<div className="content">
		{ mode === 'list' ? <Row style={{ marginBottom: '15px' }}>
			<Col>
				<Select
					showSearch
					defaultValue={ activeObject }
					placeholder="Wybierz rodzaj obiektów"
					optionFilterProp="children"
					onChange={(value) => {
						setActiveObject(value);
						history.push('/objects/'+value);
					}}
					style={{ minWidth: 200 }}
				>
					{ objects.slice().sort(compareValues('title')).map((el, _) => (
						<Select.Option key={`selectOptionObj~${_}`} value={ el.name }>{ el.title }</Select.Option>
					)) }
				</Select>
			</Col>
		</Row> : null }

		<Record
			key={`record~${mode}~${activeObject}`}
			controller={getObjectByName(activeObject).name}
			referer={`objects/${getObjectByName(activeObject).name}`}
			relations={ getObjectByName(activeObject).relations ?? [] }
			readonly={ getObjectByName(activeObject).readonly ?? false }
			defaultMode="list"
			mode={ props.mode || null }
			defaultEditing={ props.editing || null }
			info={false}
			pagination={false}
			columns={[
				{
					name: 'id',
					title: '#',
					type: 'number'
				},
				{
					name: 'name',
					title: 'Nazwa',
					searchable: true
				},
				{
					name: 'is_active',
					title: 'Aktywny',
					datatype: 'bool',
					searchable: true
				},
				{
					name: 'actions',
					data: null,
					sortable: false,
					title: '',
					render: function(data, type, row) {
						return `<a class="btn btn-table" href="/objects/${getObjectByName(activeObject).name}/${data.id}">Podgląd</a>${!(getObjectByName(activeObject).readonly ?? false) ? `<a class="btn btn-table" href="/objects/${getObjectByName(activeObject).name}/${data.id}/edit">Edytuj</a>` : ''}`;
					},
					className: 'text-right'
				}
			]}
			viewTitle={getObjectByName(activeObject).viewTitle ?? 'Podgląd rekordu'}
			listTitle={getObjectByName(activeObject).listTitle ?? 'Lista rekordów'}
			createTitle={getObjectByName(activeObject).createTitle ?? 'Tworzenie rekordu'}>
			{ getObjectByName(activeObject).fields ?? null }
		</Record>
	</div>);
};

export default Dashboard_Objects;
