import { API } from '../';

const logoutIgnore = ['WRONG-EMAIL-PASSWORD', 'LOGIN-FAILURE', 'CONFIG-FAILURE', 'PERMISSION-FAILURE', 'ACCOUNT-IS-LOCKED'];

const onResponse = (response) => {
	return response;
};

const onError = (error) => {
	// Logout when unauthorized
	if(error.hasOwnProperty('response')) {
		if(error.response?.status === 401 && !logoutIgnore.includes(error.response?.data?.errcode)) {
			API.logout().then(() => {}).catch(() => {}).finally(() => {
				localStorage.removeItem('token');
				window.location.href = '/';
			});
		}
	}

	// If responseType if Blob then return JSON if any
	if(
		error.request.responseType === 'blob' &&
		error.response &&
		error.response.data &&
		error.response.data instanceof Blob &&
		error.response.data.type &&
		error.response.data.type.toLowerCase().indexOf('json') !== -1
	) {
		return new Promise((resolve, reject) => {
			let reader = new FileReader();
			reader.onload = () => {
				error.response.data = JSON.parse(reader.result);
				resolve(Promise.reject(error));
			};

			reader.onerror = () => {
				reject(error);
			};

			reader.readAsText(error.response.data);
		});
	}

	return Promise.reject(error);
};

export default [onResponse, onError];
