import { APIInstance } from '../../';
import { API } from '../../api';

export default {
	appinfantpayments(filters, sort = null) {
		const serializeURI = (obj, prefix) => {
			let str = [],
				p;

			for(p in obj) {
				if(obj.hasOwnProperty(p)) {
					let k = prefix ? `${prefix}[${p}]` : p,
						v = obj[p];

					str.push(
						(v !== null && typeof v === 'object')
							? serializeURI(v, k)
							: encodeURIComponent(k)+'='+encodeURIComponent(v)
					);
				}
			}

			return str.join('&');
		};

		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.get(`/api/appinfantpayments${(filters && Object.keys(filters).length) ? `?${serializeURI({filter: filters, sort})}` : null}`).then(r => {
					resolve(r.data.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	createInfantPayment(values) {
		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.post(`/api/appinfantpayments`, values).then(r => {
					resolve(r.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},
};
