import React from 'react';

import { backendRoot, backendApi, csrfCookie, apiUser } from '../config/paths';

import axios from 'axios';

import '../assets/scss/datatable.scss';

const $ = require('jquery');
$.DataTable = require('datatables.net');

const { Query } = require('cogent-js/src/index');

class DataTable extends React.Component {
	constructor(props) {
		if(!props.controller && props.serverside) {
			throw new Error('You need to provide a controller for DataTable component.');
		}

		if(!props.columns) {
			throw new Error('You need to provide columns for DataTable component.');
		}

		super(props);

		this.table = null;
		this._table = null;

		this.setTableRef = element => {
			this.table = element;
		};
	}

	onError(errcode) {
		if(typeof this.props?.onError === 'function') {
			this.props.onError(errcode);
		}
	}

	componentDidMount() {
		const {
			onCreatedRow = () => {},
		} = this.props;

		let controller = this.props.controller;

		function arrayUnique(array) {
			var a = array.concat();
			for(var i = 0; i < a.length; ++i) {
				for(var j = i + 1; j < a.length; ++j) {
					if(a[i] === a[j])
					a.splice(j--, 1);
				}
			}

			return a;
		}

		let self = this;

		this._table = $(this.table).DataTable({
			language: {
				processing: 'Proszę czekać...',
				search: 'Szukaj:',
				lengthMenu: 'Pokaż _MENU_',
				info: 'Pozycje od _START_ do _END_ z _TOTAL_ łącznie',
				infoEmpty: 'Pozycji 0 z 0 dostępnych',
				infoFiltered: '(filtrowanie spośród _MAX_ dostępnych pozycji)',
				infoPostfix: '',
				loadingRecords: 'Wczytywanie...',
				zeroRecords: 'Nie znaleziono pasujących pozycji',
				emptyTable: 'Brak danych',
				paginate: {
					first: '&laquo;',
					previous: '&lsaquo;',
					next: '&rsaquo;',
					last: '&raquo;'
				},
				aria: {
					sortAscending: ': aktywuj, by posortować kolumnę rosnąco',
					sortDescending: ': aktywuj, by posortować kolumnę malejąco'
				}
			},
			ajax: function(data, cb, settings) {
				const query = new Query({
					base_url: backendApi
				});

				let url = query.for(controller);

				if(data.length) {
					url = url.params({ perPage: data.length });
				}

				if(data.order.length > 0) {
					let sort = [];
					for(let i = 0; i < data.order.length; ++i) {
						let order = data.order[i];
						let _column = settings.aoColumns.find(el => el.name === data.columns[order.column].name);
						let column_name = data.columns[order.column] ? ((_column?.sortname ?? data.columns[order.column].name) || null) : null;
						if(!column_name) continue;

						let dir = '';

						if(order.dir === 'desc') dir = '-';

						sort.push(dir+column_name);
					}

					url = url.sort(...sort);
				} else {
					url = url.sort('-created_at');
				}

				var searchable = {};

				for(let i = 0; i < data.columns.length; ++i) {
					let column = data.columns[i];

					let _column = settings.aoColumns.find(el => el.name === column.name);
					let _columnName = _column?.searchname ?? column?.name;

					if(!_columnName) continue;

					if(column.searchable) searchable[Object.keys(searchable).length] = {
						name: _columnName,
						value: column.search.value
					};
				}

				url = url.page((data.start / data.length) + 1);

				if(self.props.filters.length) {
					self.props.filters.forEach((el, _) => {
						if(el.value !== undefined)
							url = url.where(el.field, el.value);
					});
				}

				for(let key in searchable) {
					if(!searchable[key].value.length) continue;
					url = url.where(searchable[key].name, searchable[key].value);
				}

				if(data.search.value.length) {
					url = url.where('global', data.search.value);
				}

				axios.get(url.get()).then((response) => {
					let _data = [];

					if(self.props.pagination) {
						cb({
							draw: data.draw,
							recordsTotal: response.data.pagination.total,
							recordsFiltered: response.data.pagination.filtered,
							data: typeof self.props?.transformData === 'function' ? self.props.transformData(response.data.data) : response.data.data
						});
					} else {
						cb({
							draw: data.draw,
							data: typeof self.props?.transformData === 'function' ? self.props.transformData(response.data) : response.data
						});
					}
				}).catch((err) => {
					if(err.response) {
						self.onError(err.response.data.errcode);
					} else {
						self.onError('ERR-UNDEFINED');
					}
				});
			},
			dom: "<'ant-row dataTables_head'<'ant-col-sm-24 ant-col-md-12'l><'ant-col-sm-24 ant-col-md-12 text-right'f>>" +
				 "<'ant-row'<'ant-col-sm-24'tr>>" +
				 "<'ant-row dataTables_foot'<'ant-col-sm-24 ant-col-md-10'i><'ant-col-sm-24 ant-col-md-14 text-right'p>>",
			serverSide: this.props.serverside,
			layout: {
				scroll: false,
				footer: false
			},
			sortable: this.props.orderable,
			paging: this.props.pagination,
			info: this.props.info,
			processing: true,
	  		pagingType: 'full_numbers',
			lengthMenu: arrayUnique([10, 20, 50, 100].concat([this.props.perPage || 20])).sort(function(a, b) { return a - b; }),
			pageLength: this.props.perPage || 20,
			// search: {
			//
			// },
			columnDefs: [
				{
					targets: '_all',
					data: function(data, type, full, meta) {
						let column = meta.settings.aoColumns[meta.col];
						let name = column.name;
						let dataType = column.datatype;
						let value = '';

						switch(dataType) {
							case 'bool':
								value = parseInt(data[name]) === 1 ? '<span class="ant-tag ant-tag-success">TAK</span>' : '<span class="ant-tag ant-tag-error">NIE</span>';
								break;
							case 'ibool':
								value = parseInt(data[name]) !== 1 ? '<span class="ant-tag ant-tag-success">TAK</span>' : '<span class="ant-tag ant-tag-error">NIE</span>';
								break;
							case 'icbool':
								value = parseInt(data[name]) === 1 ? '<span class="ant-tag ant-tag-error">TAK</span>' : '<span class="ant-tag ant-tag-success">NIE</span>';
								break;
							case 'text':
							case 'string':
							default:
								value = data[name];
								break;
						}

						return value;
					},
					searchable: false
				}
			],
			columns: this.props.columns || [],
			order: (this.props?.defaultSort && Array.isArray(this.props.defaultSort) && this.props.defaultSort.length) ? this.props.defaultSort : [],
			initComplete: function() {
				let thisTable = this;
				let rowFilter = $('<tr class="filter"></tr>').appendTo($(self._table.table().header()));

				this.api().columns().every(function() {
					let input;

					let idx = this.index();
					let column = this.settings()[0].aoColumns[idx];

					let type = column.sType || 'string';
					let searchable = column.bSearchable || false;
					let isSticky = column.sticky || false;

					if(searchable) {
						switch(type) {
							case 'string':
							default:
								input = $(`<input type="text" class="form-control form-control-sm form-filter datatable-input" placeholder="Szukaj w ..." data-col-index="${idx}"/>`);
								break;
						};

						$(input).appendTo($('<th>').appendTo(rowFilter)).on('input', function(e) {
							e.preventDefault();

							thisTable.api().column(idx).search(e.target.value || '', false, false);
							self._table.table().draw();
						});
					} else if(isSticky) {
						$('').appendTo($('<th class="last-col-sticky">').appendTo(rowFilter));
					} else {
						$('').appendTo($('<th>').appendTo(rowFilter));
					}
				});
			},
			createdRow(row, data, dataIndex, cells) {
				if(typeof onCreatedRow === 'function') {
					onCreatedRow(row, data, dataIndex, cells);
				}
			}
		});
	}

	componentWillUnmount() {
		$('.data-table-wrapper').find('table').DataTable().destroy(true);
		this._table = null;
	}

	shouldComponentUpdate(nextProps, nextState) {
		return false;
	}

	render() {
		return (<table ref={ this.setTableRef }>
			{ this.props.children }
		</table>);
	}
}

DataTable.defaultProps = {
	pagination: true,
	info: true,
	serverside: true,
	orderable: true,
	filters: []
};

export default DataTable;
