import React from 'react';

import Moment from 'react-moment';

import Record from '../../../components/Record/index';

import TabbedCard from '../../../components/TabbedCard';

import { Row, Col, Card, Button } from 'antd';

import '../../../assets/scss/dashboard.scss';

const Dashboard_Offers_Iros = (props) => {
	const [currentActiveTab, setCurrentActiveTab] = React.useState('general');

	return (<div className="content">
		<Record
			controller="offiros"
			referrer="iros"
			relations={['services', 'dctirotypes', 'contractors']}
			relationsLimit={-1}
			mode={props?.mode}
			defaultEditing={props?.editing}
			columns={[
				{
					name: 'id',
					title: '#',
					type: 'number'
				},
				{
					name: 'name',
					title: 'Nazwa'
				},
				{
					name: 'dctirotype_name',
					title: 'Rodzaj imprezy',
					sortname: 'dctirotypes.name'
				},
				{
					name: 'town',
					title: 'Miejscowość'
				},
				{
					name: 'zip',
					title: 'Kod pocztowy'
				},
				{
					name: 'is_active',
					title: 'Aktywna',
					datatype: 'bool'
				},
				{
					name: 'actions',
					data: null,
					sortable: false,
					title: '',
					render: function(data, type, row) {
						return `<a class="btn btn-table" href="/iros/${data.id}">Podgląd</a><a class="btn btn-table" href="/iros/${data.id}/edit">Edytuj</a><a class="btn btn-table" href="/iros/${data.id}/clone">Skopiuj</a>`;
					},
					className: 'text-right'
				}
			]}
			viewTitle="Podgląd oferty imprezy IRO"
			listTitle="Imprezy Rekreacyjno-Oświatowe"
			createTitle="Tworzenie oferty imprezy IRO"
			editTitle="Edycja oferty imprezy IRO"
		>
			<Record.Consumer>
				{({ isLoading }) => (
					<TabbedCard key="generalCard" loading={isLoading} tabContent={{
						general: [
							<Record.Field.Input title={'Nazwa'} name="name" />,
							<Record.Field.Relation title={'Rodzaj imprezy'} name="dctirotype_id" relation="dctirotypes" />,
							<Record.Field.Input title={'Ulica'} name="street" span={{ sm: 24, md: 12 }} />,
							<Record.Field.Input title={'Kod pocztowy'} name="zip" span={{ sm: 24, md: 5 }} />,
							<Record.Field.Input title={'Miejscowość'} name="town" span={{ sm: 24, md: 7 }} />,
							<Record.Field.Switch title={'Aktywny'} name="is_active" defaultValue={ 1 } />,
							<Record.Field.Textarea title={'Informacje'} name="description" span={{ span: 24 }} />
						],
						prices: [
							<Record.Field.TableRelation title={'Cennik'} showTitle={false} name="iroprices" span={{ span: 24 }} fields={[
								{ name: 'id', title: 'ID', isHidden: true, removeEmpty: true },
								{ name: 'service_id', title: 'Usługa', type: 'creatableRelation', relation: 'services', filterData: (data, record) => {
									return data.filter(el => el.servicegroup_id === 3);
								}, span: { lg: 12, xl: 12 }, controller: "services", field: 'name', fields: [
									{ name: 'servicegroup_id', value: 3 },
									{ name: 'is_active', value: 1 },
									{ name: 'name', value: '' }
								] },
								{ name: 'price', type: 'number', title: 'Cena', type: 'number', span: { lg: 8, xl: 8 } }
							]} />
						],
						availability: [
							<Record.Field.TableCheck title={'Dostępność'} showTitle={false} name="contractors" span={{ span: 24 }} relatedTable="contractors" transformDataKey="contractors[id]" fields={[
								{ name: 'name', title: 'Kontrahent' },
							]} />
						]
					}} tabList={[
						{ key: 'general', tab: 'Dane podstawowe'},
						{ key: 'prices', tab: 'Cennik' },
						{ key: 'availability', tab: 'Dostępność' }
					]} activeTabKey={currentActiveTab} onTabChange={(key) => { setCurrentActiveTab(key) }} forceRender={true} />
				)}
			</Record.Consumer>
		</Record>
	</div>);
};

export default Dashboard_Offers_Iros;
