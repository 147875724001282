import React from 'react';

import RecordBase from './base';

import { Button } from 'antd';

class ModalRecord extends RecordBase {
	static propTypes = Object.assign({}, RecordBase.propTypes, {

	});

	static defaultProps = Object.assign({}, RecordBase.defaultProps, {
		withHeader: false
	});
}

export default ModalRecord;
