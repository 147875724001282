import React from "react";

import { Row, Col, Card, Tabs, Collapse, Button } from 'antd';

import { useAnimationFrame } from '../libs/Helpers';

/**
 * Card with extended functionality of collapsing.
 *
 */
const CardCollapse = ({ title = null, tabContent, onTabChange, onCollapse, collapsed = true, className = null, ...props }) => {
	const _id = '_' + Math.random().toString(36).substr(2, 9);

	/**
	 * State of card collapsing
	 * @type {Boolean}
	 */
	const [isCollapsed, setCollapsed] = React.useState(collapsed);

	/**
	 * Key of active tab
	 * @type {String}
	 */
	const [activeTab, setActiveTab] = React.useState(
		props?.activeTabKey
			?
			props?.activeTabKey
			:
			(props?.tabList && props.tabList.length)
				?
				(props?.tabList[0]?.key ?? null)
				:
				null
	);

	/**
	 * General callback for tab change.
	 * @param  {String} key Key of tab
	 */
	const _onTabChange = key => {
		setActiveTab(key);
	};

	/**
	 * Extra props needed for tabs.
	 * @type {Object}
	 */
	const {
		tabProps = {},
		activeTabKey,
		defaultActiveTabKey,
		tabBarExtraContent,
		forceRender
	} = props;

	/**
	 * Do component have activeTabKey prop.
	 * @type {Boolean}
	 */
	const hasActiveTabKey = activeTab !== null;

	/**
	 * Prepared props for tabs.
	 * @type {Object}
	 */
	const extraProps = {
		...tabProps,
		[hasActiveTabKey ? 'activeKey' : 'defaultActiveKey']: hasActiveTabKey
			? activeTab
			: defaultActiveTabKey,
		tabBarExtraContent,
	};

	const toggleCollapse = () => {
		if(isCollapsed) {
			expand();
		} else {
			collapse();
		}
	};

	const expand = () => {
		const el = document.getElementById(_id).querySelector('.ant-card-body');
		let sectionHeight = el.scrollHeight;

		el.style.height = sectionHeight + 'px';

		const onTransitionEnd = (e) => {
			el.removeEventListener('transitionend', onTransitionEnd);
			el.style.height = null;
		};

		el.addEventListener('transitionend', onTransitionEnd);

		setCollapsed(false);
	};

	const collapse = () => {
		const el = document.getElementById(_id).querySelector('.ant-card-body');
		let sectionHeight = el.scrollHeight;

		let elementTransition = el.style.transition;
		el.style.transition = '';

		requestAnimationFrame(() => {
			el.style.height = sectionHeight + 'px';
			el.style.transition = elementTransition;

			requestAnimationFrame(() => {
				el.style.height = 0 + 'px';
			});
		});

		const onTransitionEnd = (e) => {
			el.removeEventListener('transitionend', onTransitionEnd);
			setCollapsed(true);
		};

		el.addEventListener('transitionend', onTransitionEnd);
	};

	React.useEffect(() => {
		if(onTabChange && typeof onTabChange === 'function') {
			onTabChange(activeTab);
		}
	}, [activeTab, setActiveTab]);

	React.useEffect(() => {
		if(onCollapse && typeof onCollapse === 'function') {
			onCollapse(isCollapsed);
		}
	}, [isCollapsed, setCollapsed]);

	/**
	 * Prepared React.ReactNode[] of tabs.
	 * @type {React.ReactNode[]}
	 */
	const tabsContent = props?.tabList && props.tabList.length ? (
		<Tabs
			size="large"
			{...extraProps}
			onChange={_onTabChange}
			renderTabBar={ function() { return null; } }
		>
			{props.tabList.map(item => (
				<Tabs.TabPane tab={item.tab} disabled={item.disabled} key={item.key} forceRender={forceRender ?? false}>
					<div style={{ padding: 24 }}>
						{ tabContent[item.key] ?? null }
					</div>
				</Tabs.TabPane>
			))}
		</Tabs>
	) : null;

	return (
		<Card id={_id} title={<span onClick={toggleCollapse}>{title ?? null}</span>} extra={<Button onClick={toggleCollapse}>{isCollapsed ? 'Rozwiń' : 'Zwiń'}</Button>} className={className ? `card-collapse ${isCollapsed ? 'card-collapsed' : ''} ${className}` : `card-collapse ${isCollapsed ? 'card-collapsed' : ''}`} bodyStyle={{ padding: 0 }} onTabChange={_onTabChange} tabProps={tabProps} activeTabKey={ activeTab } defaultActiveTabKey= { defaultActiveTabKey } tabBarExtraContent={ tabBarExtraContent } {...props}>
			{ tabsContent }
		</Card>
	);
};

export default CardCollapse;
