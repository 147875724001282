import React from 'react';
import PropTypes from 'prop-types';

import reactComponentDebounce from 'react-component-debounce';

import Record_Field from './Field';

import { Form, Input } from 'antd';

const DebouncedTextarea = reactComponentDebounce({
	valuePropName: 'value',
	triggerMs: 250
})(Input.TextArea);

class RecordFieldTextarea extends Record_Field {
	static propTypes = Object.assign({}, Record_Field.propTypes, {
		autoSize: PropTypes.object,
		showCount: PropTypes.bool,
		maxLength: PropTypes.number
	});

	static defaultProps = Object.assign({}, Record_Field.defaultProps, {
		autoSize: { minRows: 3, maxRows: 6 },
		showCount: true,
		maxLength: null
	});

	static displayName = 'RecordFieldTextarea';

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	renderEdit() {
		const {
			title,
			name,
			disabled,

			autoSize,
			showCount,
			maxLength,
			debounced
		} = this.props;

		return <Form.Item
			{...this.globalItemProps}
		>
			{debounced ?
				<DebouncedTextarea autoComplete="nope" disabled={disabled} autoSize={autoSize} showCount={showCount} maxLength={maxLength} />
				:
				<Input.TextArea autoComplete="nope" disabled={disabled} autoSize={autoSize} showCount={showCount} maxLength={maxLength} />
			}
		</Form.Item>;
	}
}

export default RecordFieldTextarea;
