import React from 'react';
import PropTypes from 'prop-types';

import Record_Field from './Field';

import { Form } from 'antd';

class RecordFieldEmpty extends Record_Field {
	static propTypes = Object.assign({}, Record_Field.propTypes, {
		name: PropTypes.string,
		title: PropTypes.string
	});

	static defaultProps = Object.assign({}, Record_Field.defaultProps, {

	});

	static displayName = 'RecordFieldEmpty';

	constructor(props) {
		super(props);
	}

	componentDidMount() {

	}

	renderPreview() {
		return null;
	}

	renderEdit() {
		const {
			title,
			name,
			disabled
		} = this.props;

		return null;
	}
}

export default RecordFieldEmpty;
