import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { rgba, rgb } from 'polished';

import Moment from 'react-moment';

import { Row, Col, Card, Form, Select, Button, Alert, message, DatePicker, Menu, Empty, Input, Table, Tabs } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faFileAlt, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';

import { APIBackend as API } from '../../api';
import { convertTableToName } from '../../libs/AppsHelper';
import { useAuth, PERMISSIONS as USER_PERMISSIONS } from '../../contexts/User';

import moment from 'moment';

import '../../assets/scss/dashboard.scss';

const { TabPane } = Tabs;

const StatContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

const StyledStat = styled.div`
	display: flex;
	background: white;
	border-radius: 10px;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .07);
	min-height: 150px;
	width: 100%;
	flex: auto 1 1;
	margin-right: 24px;
	padding: 10px 25px;

	&:last-child {
		margin-right: 0;
	}
`;

const StatIcon = styled.div`
	width: 90px;
	height: 90px;
	align-self: center;
	margin-left: 25px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({ color }) => rgba(color ?? '#000000', .25)};
	color: ${({ color }) => color};
`;

const StatInfo = styled.div`
	display: flex;
	flex: auto 1 1;
	flex-direction: column;
	align-self: center;
	margin-left: 30px;
`;

const StatDesc = styled.h4`
	font-size: 24px;
	font-weight: 700;
	margin-bottom: 0;
`;

const StatTitle = styled.span`
	color: #9f9f9f;
`;

const TableButton = styled(Link)`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 5px 10px;
	color: #B5B5C3;
	background-color: transparent;
	outline: none;
	vertical-align: middle;
	cursor: pointer;
	font-family: 'Poppins', 'Open Sans', sans-serif;
	font-size: .925rem;
	line-height: 1.35;
	border-radius: 4px;
	transition: background-color .15s ease, color .15s ease;
	text-align: center;

	&:hover {
		color: #457b9d;
		background: #F3F6F9;
	}
`;

const Stat = ({ title, value = 0, color = '#0097d6', icon }) => {
	return (
		<StyledStat>
			<StatIcon color={color}>
				<FontAwesomeIcon icon={icon} size="3x" />
			</StatIcon>

			<StatInfo>
				<StatDesc>
					{value ?? 0}
				</StatDesc>
				<StatTitle>
					{title}
				</StatTitle>
			</StatInfo>
		</StyledStat>
	);
};

const newAppsColumns = [
	{
		title: 'Wnioskujący',
		render: (text, row) => {
			return `${row?.first_name ?? ''} ${row?.last_name ?? ''}`;
		}
	},
	{
		title: 'Rodzaj wniosku',
		dataIndex: 'table',
		render: (text, row) => {
			return convertTableToName(text);
		},
	},
	{
		title: 'Status',
		dataIndex: 'staapp_name'
	},
	{
		align: 'right',
		render: (text, data) => {
			const appId = data.id_table.split('-').shift();
			return <>
				<TableButton to={`/${data.table}/${appId}`}>Pokaż</TableButton>
			</>;
		},
	}
];

const newProfilesColumns = [
	{
		title: 'Imię i nazwisko',
		dataIndex: 'full_name',
	},
	{
		title: 'Kontrahent',
		dataIndex: 'contractor_name',
		render: (text, data) => {
			return `[${data.contractor_symbol ?? '-'}] ${text}`;
		},
	},
	{
		title: 'Nr ewidencyjny',
		dataIndex: 'registration_number',
	},
	{
		align: 'right',
		render: (text, data) => {
			return <>
				<TableButton to={`/profiles/${data.id}`}>Pokaż</TableButton>
			</>;
		},
	}
];

const newPaymentsColumns = [
	{
		title: 'Nr wniosku',
		dataIndex: 'number',
		render: (text, data) => {
			return text ?? '(brak)';
		},
	},
	{
		title: 'Nazwa wniosku',
		dataIndex: 'appinfant_name',
	},
	{
		title: 'Okres płatności',
		dataIndex: 'period',
	},
	{
		title: 'Data płatności',
		dataIndex: 'payment_date',
	},
	{
		align: 'right',
		render: (text, data) => {
			return <>
				<TableButton to={`/appinfants/${data.appinfant_id}`}>Pokaż wniosek</TableButton>
			</>;
		},
	}
];

const Dashboard_Tests = (props) => {
	const [unseenData, setUnseenData] = React.useState(null);
	const [newApps, setNewApps] = React.useState(null);
	const [newPayments, setNewPayments] = React.useState(null);
	const [appsData, setAppsData] = React.useState(null);
	const [profilesData, setProfilesData] = React.useState(null);
	const [paymentsData, setPaymentsData] = React.useState(null);

	const auth = useAuth();

	const isOperator = auth.can(USER_PERMISSIONS.OPERATOR);

	React.useEffect(() => {
		API.appsUnseen(isOperator ? 1 : 2, {
			staapp_id: isOperator ? 2 : 6,
			staprofile_id: isOperator ? 6 : 7,
		}).then(r => {
			delete r.data.debug;

			let countApps = 0;
			let countPayments = 0;

			for(let k in r.data) {
				if(['profiles'].includes(k)) continue;

				if(['appinfantpayments'].includes(k)) {
					countPayments += r.data[k];
				} else {
					countApps += r.data[k];
				}
			}

			setNewApps(countApps);
			setNewPayments(countPayments);
			setUnseenData(r.data);

			if(countApps > 0) {
				API.apps({
					is_seen: isOperator ? '0__' : '_0_',
					staapp_id: isOperator ? 2 : 6
				}, '-created_at').then(r => {
					setAppsData(r.data.data);
				}).catch(err => {
					setAppsData(null);
				});
			}

			if(r.data?.profiles > 0) {
				API.profiles({
					is_seen: isOperator ? '0__' : '_0_',
					'staprofiles.id': isOperator ? 6 : 7
				}, '-created_at').then(r => {
					setProfilesData(r);
				}).catch(err => {
					setProfilesData(null);
				});
			}

			if(countPayments > 0) {
				API.appinfantpayments({
					is_seen: isOperator ? '0__' : '_0_'
				}, '-created_at').then(r => {
					setPaymentsData(r);
				}).catch(err => {
					setPaymentsData(null);
				});
			}
		}).catch(err => {

		});
	}, [auth.user]); // eslint-disable-line react-hooks/exhaustive-deps

	return (<div className="content">
		<div className="subheader">
			<div className="d-flex">
				<h1 className="title">Pulpit</h1>
				<span className="subtitle">
					Dzisiaj jest <Moment locale="pl" format="dddd, D MMMM YYYY" />
				</span>
			</div>
		</div>

		<Row gutter={[24, 24]}>
			<Col sm={24} lg={12}>
				<Row gutter={[24, 24]}>
					<Col xs={24} xxl={12}>
						<StatContainer>
							<Stat color="#0097d6" icon={faUsers} title="Nowe profile klientów" value={unseenData?.profiles} />
						</StatContainer>
					</Col>

					<Col xs={24} xxl={12}>
						<StatContainer>
							<Stat color="#0097d6" icon={faFileAlt} title="Wnioski wymagające uwagi" value={newApps} />
						</StatContainer>
					</Col>

					<Col xs={24} xxl={12}>
						<StatContainer>
							<Stat color="#0097d6" icon={faFileInvoiceDollar} title="Nowe wpłaty" value={newPayments} />
						</StatContainer>
					</Col>
				</Row>
			</Col>

			<Col sm={24} lg={12}>
				<Card bodyStyle={{ paddingTop: 0 }}>
					<Tabs defaultActiveKey="1">
						<TabPane tab="Wnioski" key="1">
							<Table columns={newAppsColumns} dataSource={appsData} />
						</TabPane>
						<TabPane tab="Profile" key="2">
							<Table columns={newProfilesColumns} dataSource={profilesData} />
						</TabPane>
						<TabPane tab="Wpłaty" key="3">
							<Table columns={newPaymentsColumns} dataSource={paymentsData} />
						</TabPane>
					</Tabs>
				</Card>
			</Col>
		</Row>
	</div>);
};

export default Dashboard_Tests;
