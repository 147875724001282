import { useMemo, useRef, useState } from "react";
import { debounce } from "lodash";
import { Select, Spin } from "antd";
import { useRecord } from "./Record/context";

const SearchSelect = ({
  fetch,
  timeout = 500,
  relation,
  relationKey = "id",
  relationName = "name",
  initialOptions = [],
  ...props
}) => {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState(initialOptions);

  const record = useRecord();

  const fetchRef = useRef(0);

  const key = relationKey ?? "id";
  const name = relationName ?? "name";

  const fetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if(!record) {
        setOptions([]);
      }
      setFetching(true);
      fetch(value, relation, key, name)
        .then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            return;
          }

          if(!record) {
            setOptions(newOptions);
          }
          setFetching(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    return debounce(loadOptions, timeout);
  }, [fetch, timeout, relation, relationKey, relationName]);

  return (
    <Select
      showSearch
      allowClear
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      onSearch={fetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={record ? record?.dependenciesData?.value?.[relation] ?? [] : options}
      loading={fetching}
    />
  );
};

export default SearchSelect;
