import React from 'react';
import PropTypes from 'prop-types';

import Record_Field from './Field';

import { Form, Input } from 'antd';

import _ from 'lodash';

import { assignDataToString } from '../../../libs/Helpers.js';

class RecordFieldGroup extends Record_Field {
	static propTypes = Object.assign({}, Record_Field.propTypes, {
		separator: PropTypes.string,
		fields: PropTypes.array.isRequired,
		labelFormat: PropTypes.string
	});

	static defaultProps = Object.assign({}, Record_Field.defaultProps, {
		separator: ', '
	});

	static displayName = 'RecordFieldGroup';

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	getRawValue() {
		const {
			name,
			fields
		} = this.props;

		let value = {};

		if(this.data ?? null) {
			fields.forEach(field => {
				if(this.data.hasOwnProperty(field.name)) {
					value[field.name] = this.data[field.name];
				}
			});
		}

		return value;
	}

	getValue() {
		const {
			separator,
			labelFormat
		} = this.props;

		const values = Object.values(this.getRawValue());

		if(labelFormat) {
			return assignDataToString(labelFormat, this.getRawValue(), '(brak)');
		} else {
			return values.length ? values.join(separator) : '(brak)';
		}
	}

	renderEdit() {
		const {
			title,
			name,
			disabled,
			fields
		} = this.props;

		return <Input.Group compact>
			{fields.map(field => {
				let fieldValue = this.getRawValue()[field.name] ?? null;

				return <Form.Item style={{ width: field.width ?? 'auto' }} initialValue={fieldValue} rules={field.rules ?? null} name={field.name} key={_.uniqueId('field_')}>
					<Input placeholder={field?.title ?? null} disabled={disabled} autoComplete="nope" />
				</Form.Item>
			})}
		</Input.Group>;
	}
}

export default RecordFieldGroup;
