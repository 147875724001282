import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Record_Field from './Field';

import { Form, Input } from 'antd';

import _ from 'lodash';

import { prepareFieldInput, prepareFieldValue } from './utils/Helper';

class RecordFieldTable extends Record_Field {
	static propTypes = Object.assign({}, Record_Field.propTypes, {
		showActions: PropTypes.bool,
		fields: PropTypes.array.isRequired,
		relation: PropTypes.string,
		filter: PropTypes.func
	});

	static defaultProps = Object.assign({}, Record_Field.defaultProps, {
		readonly: true
	});

	static displayName = 'RecordFieldTable';

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	getValue() {
		const {
			name,
			fields,
			relation,
			showActions,
			filter
		} = this.props;

		let _data = [];
		if(this.relationData && this.relationData.hasOwnProperty(relation)) {
			_data = this.relationData[relation];

			if(typeof filter === 'function') {
				_data = filter(_data, this.data, this.relationData);
			}
		}

		return (
			<div className="ant-table" key={_.uniqueId('table_')}>
				<div className="ant-table-container">
					<div className="ant-table-content">
						<table style={{ tableLayout: 'auto' }}>
							<thead className="ant-table-thead">
								<tr>
									{ fields.map((field, idx) => (<React.Fragment key={_.uniqueId('theadCol_')}>
										{ !field.isHidden ? <th className="ant-table-cell">{ field.title }</th> : null }
									</React.Fragment>)) }
									{ showActions ? <th className="ant-table-cell">&nbsp;</th> : null }
								</tr>
							</thead>
							<tbody className="ant-table-tbody">
								{
									_data?.length ?
										_data.map(row => (
											<tr className="ant-table-row" key={_.uniqueId('row_')}>
												{
													fields.map((field, idx) => {
														let fieldValue = (row && row.hasOwnProperty(field.name)) ? row[field.name] : null;

														return field.isHidden ?
															null
															:
															<td className="ant-table-cell">
																{prepareFieldValue(field, fieldValue, this.relationData ?? {})}
															</td>;
													})
												}

												{
													showActions ?
														<td className="ant-table-cell">
															<Link to={`/${relation}/${row.id}`}>Przejdź</Link>
														</td>
														:
														null
												}
											</tr>
										))
										:
										null
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}

	renderEdit() {
		return null;
	}
}

export default RecordFieldTable;
