import React from 'react';
import { useHistory } from 'react-router-dom';

import { captureException } from '@sentry/react';

import Moment from 'react-moment';

import { Row, Col, Card, Form, Select, Button, Alert, message, DatePicker, Checkbox, Switch } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { STATUS as APPSTA, getStatusById as getAppStatusById, getStatusArray as getAppStatusArray } from '../../../libs/AppsHelper';
import { STATUS as PROFILESTA, getStatusById as getProfileStatusById, getStatusArray as getProfileStatusArray } from '../../../libs/ProfilesHelper';

import { APIBackend as API } from '../../../api';

import { backendRoot, backendApi, csrfCookie, apiData } from '../../../config/paths';
import axios from 'axios';

import moment from 'moment';

import '../../../assets/scss/dashboard.scss';

const { Option } = Select;

const Dashboard_MassSetVisible = (props) => {
	const history = useHistory();

	const [form] = Form.useForm();

	const [relationData, setRelationData] = React.useState(null);
	const [changes, setChanges] = React.useState({});
	const [error, setError] = React.useState(false);

	React.useEffect(() => {
		API.relations(['contractors', 'cnfapps']).then(r => {
			// Assigning data of related records to state
			setRelationData(r);
			setError(false);
		}).catch(err => {
			setError({
				errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED',
				message: err?.response?.data?.message
			});
		});
	}, []);

	const onSave = (values) => {
		values.contractors = Array.isArray(values.contractors) ? values.contractors.join(',') : values.contractors;

		values.statuses = Array.isArray(values.statuses) ? values.statuses.join(',') : values.statuses;

		values.app_table = Array.isArray(values.app_table) ? values.app_table.join(',') : values.app_table;

		values.state = values?.state ? 1 : 0;

		API.setVisibility(values).then(r => {
			message.success(<>
				Widoczność {values?.dest === 'W' ? 'wniosków' : 'profili'} została ustawiona na {values?.state ? 'widoczne' : 'niewidoczne'}
			</>);
			setError(false);
			setTimeout(() => {
				form.resetFields();
			}, 3000);
		}).catch(err => {
			setError({
				errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED',
				message: err?.response?.data?.message
			});
		});

		// const _data = new FormData();

		// _data.append('contractors', (values?.contractors && Array.isArray(values.contractors)) ? values.contractors.join(',') : null);
		// _data.append('dest', values.dest);
		// _data.append('from', values.from.format('YYYY-MM-DD'));
		// _data.append('to', values.to.format('YYYY-MM-DD'));
		// _data.append('statuses', (values?.statuses && Array.isArray(values.statuses)) ? values.statuses.join(',') : null);
		// _data.append('state', values?.state ?? false);

		// axios.get(backendRoot + csrfCookie).then(() => {
		// 	axios.post(`${backendApi}/batch/fillpaymentdate`, _data).then((response) => {
		// 		message.success(<>Daty zostały pomyślnie zmienione. {response?.data?.message ?? null}</>);
		// 		setTimeout(() => {
		// 			history.push('/');
		// 		}, 3500);
		// 		setError(false);
		// 	}).catch((err) => {
		// 		setError({
		// 			errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED',
		// 			message: err?.response?.data?.message
		// 		});
		// 	});
		// }).catch((err) => {
		// 	setError({
		// 		errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED',
		// 		message: err?.response?.data?.message
		// 	});
		// });
	};

	const onChange = (changedValues, allValues) => {
		setChanges(allValues);

		if(changedValues?.dest) {
			form.setFieldsValue({
				statuses: [],
				app_table: []
			});
		}
	};

	return (<div className="content">
		<div className="subheader">
			<div className="d-flex">
				<h1 className="title">Masowe ustawianie widoczności dla komisji</h1>
				<span className="subtitle">
					Dzisiaj jest <Moment locale="pl" format="dddd, D MMMM YYYY" />
				</span>
			</div>

			<div className="subheader_actions">
				<Button type="primary" onClick={() => { form.submit(); }}>Zapisz</Button>
			</div>
		</div>

		{
			error && <Alert type="error" message={error?.message ?? 'Wystąpił nieoczekiwany błąd podczas zapisywania lub pobierania danych'} style={{ marginBottom: 25 }} />
		}

		<Card loading={false}>
			<Form
				onFinish={onSave}
				layout="vertical"
				form={form}
				onValuesChange={onChange}
			>
				<Row gutter={[16,16]}>
					<Col sm={24} md={12}>
						<Form.Item
							label="Ustaw w"
							name="dest"
							rules={[
								{ required: true, message: 'Proszę wybrać, gdzie ustawić status widoczności' }
							]}
						>
							<Select
								showSearch
								optionFilterProp="children"
							>
								<Option value="W">Wnioski</Option>
								<Option value="P">Profile klientów</Option>
							</Select>
						</Form.Item>
					</Col>

					<Col sm={24} md={12}>
						<Form.Item
							label="Statusy"
							name="statuses"
							rules={[
								{ required: true, message: 'Proszę wybrać status profilu/wniosku' }
							]}
						>
							<Select
								showSearch
								optionFilterProp="children"
								mode="multiple"
							>
								{
									changes?.dest === 'W'
										? getAppStatusArray().map(el => (
											<Option key={['appsta', el.id]} value={el.id}>
												{el.label}
											</Option>
										))
										: changes?.dest === 'P'
											? getProfileStatusArray().map(el => (
												<Option key={['profilesta', el.id]} value={el.id}>
													{el.label}
												</Option>
											))
											: null
								}
							</Select>
						</Form.Item>
					</Col>

					{ form.getFieldValue('dest') === 'W' ? (
						<Col sm={24} md={24}>
							<Form.Item
								label="Wniosek"
								name="app_table"
								rules={[
									{ required: form.getFieldValue('dest') === 'W' ? true : false, message: 'Proszę wybrać rodzaj wniosku' }
								]}
							>
								<Select
									// allowSearch
									optionFilterProp="children"
									mode="multiple"
								>
									{
										relationData?.cnfapps?.map(el => (
											<Option key={['cnfapps', el.id]} value={el.table}>
												{el.name}
											</Option>
										))
									}
								</Select>
							</Form.Item>
						</Col>
					)
					: null }

					<Col sm={24} md={12}>
						<Form.Item
							label="Od dnia"
							name="from"
							rules={[
								{ required: true, message: 'Proszę wybrać zakres dat' }
							]}
						>
							<DatePicker style={{ width: '100%' }} />
						</Form.Item>
					</Col>

					<Col sm={24} md={12}>
						<Form.Item
							label="Do dnia"
							name="to"
							rules={[
								{ required: true, message: 'Proszę wybrać zakres dat' }
							]}
						>
							<DatePicker style={{ width: '100%' }} />
						</Form.Item>
					</Col>

					<Col sm={24} md={12}>
						<Form.Item
							label="Kontrahenci"
							name="contractors"
							rules={[
								{ required: true, message: 'Proszę wybrać kontrahenta/ów' }
							]}
						>
							<Select
								showSearch
								optionFilterProp="children"
								mode="multiple"
							>
								{relationData?.contractors?.map(el => (
									<Option key={['contractors', el.id]} value={el.id}>
										[{el.symbol}] {el.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>

					<Col sm={24} md={12}>
						<Form.Item
							label="Widoczny dla komisji?"
							name="state"
							valuePropName="checked"
						>
							<Switch checkedChildren="Tak" unCheckedChildren="Nie" />
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Card>
	</div>);
};

export default Dashboard_MassSetVisible;
