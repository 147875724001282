import React from 'react';
import styled from 'styled-components';

import { backendRoot, backendApi, csrfCookie } from '../config/paths';
import { RecordContext } from '../contexts/Record';

import axios from 'axios';

import { Table, Space, Button } from 'antd';

import { APIBackend as API } from '../api';

import { Query } from 'cogent-js/src/index';

const StyledTable = styled(Table)`
	${(props) => (props?.withPaginationPadding
		? `& .ant-pagination {
			padding: 0 20px;
		}`
		: ''
	)}
`;

const TableInfo = styled.div`
	margin-right: 15px;
`;

class RecordTable extends React.PureComponent {
	static defaultProps = {
		pagination: true,
		info: true,
		serverside: true,
		orderable: true,
		filters: [],
		extra: null
	};

	static contextType = RecordContext;

	state = {
		pagination: {
			current: 1,
			pageSize: 20,
			showTotal: this.paginationInfo
		},
		loading: true,
		data: []
	};

	get paginationInfo() {
		return (total, range) => {
			return <TableInfo>Pozycje od {range[0]} do {range[1]} z {total} łącznie</TableInfo>;
		};
	}

	get columns() {
		const {
			extra,
			controller,
			inModal
		} = this.props;

		const allowedKeys = ['render', 'align', 'fixed', 'sorter', 'width', 'filters', 'defaultSortOrder'];

		const columns = this.props.columns.map(el => {
			let column = {
				title: el?.title,
				dataIndex: el?.name,
				key: el?.name ?? el?.key ?? null
			};

			Object.keys(el).forEach(key => {
				if(allowedKeys.includes(key)) {
					column[key] = el[key];
				}
			});

			return column;
		});

		let actionsColumn = {
			key: 'actions',
			render: (text, record, idx) => {
				return <Space>
					<Button type="primary" size="small" onClick={() => {
						this.context.changeMode('view', record.id);
					}}>Podgląd</Button>
					<Button size="small" onClick={() => {
						this.context.changeMode('edit', record.id);
					}}>Edytuj</Button>
					{extra}
				</Space>;
			},
			align: 'right'
		};

		if(inModal) {
			actionsColumn.fixed = 'right';
		}

		columns.push(actionsColumn);

		return columns;
	}

	constructor(props) {
		if(!props.controller && props.serverside) {
			throw new Error('You need to provide a controller for DataTable component.');
		}

		if(!props.columns) {
			throw new Error('You need to provide columns for DataTable component.');
		}

		super(props);

		const {
			perPage = 20,
		} = props;

		this.setState({
			pagination: {
				current: 1,
				pageSize: perPage,
				showTotal: this.paginationInfo
			},
			loading: true,
			data: []
		});

		this.handleTableChange = this.handleTableChange.bind(this);
	}

	onError(errcode) {
		if(typeof this.props?.onError === 'function') {
			this.props.onError(errcode);
		}
	}

	componentDidMount() {
		const {
			pagination
		} = this.state;

		this.fetch({
			pagination,
			sortField: 'id',
			sortOrder: 'descend'
		});
	}

	getColumnByName(name) {
		const {
			columns
		} = this.props;

		return columns.find(el => el.name === name);
	}

	handleTableChange(pagination, filters, sorter) {
		this.fetch({
			pagination,
			sortField: sorter.field,
			sortOrder: sorter.order,
			...filters
		});
	}

	fetch(params = {}) {
		const {
			controller,
			filters
		} = this.props;

		this.setState({
			loading: true
		});

		const query = new Query({
			base_url: backendApi
		});

		let url = query.for(controller);

		url = url.params({
			perPage: params.pagination?.pageSize ?? 20
		})
		.page(params.pagination?.current ?? 1);

		if(params?.sortField && params?.sortOrder) {
			url = url.sort(`${params.sortOrder === 'descend' ? '-' : ''}${params.sortField}`);
		}

		if(filters.length) {
			filters.forEach(el => {
				if(el.value !== undefined) {
					url = url.where(el.field, el.value);
				}
			});
		}

		axios.get(url.get()).then(response => {
			this.setState({
				data: response.data.data.map(el => {
					el.key = el.id;

					return el;
				}),
				pagination: {
					...params.pagination,
					total: response.data.pagination.filtered,
					showTotal: this.paginationInfo
				}
			});
		}).catch(err => {

		}).finally(() => {
			this.setState({
				loading: false
			});
		});
	}

	render() {
		const {
			sticky = {
				offsetHeader: 54
			},
			withPaginationPadding = false,
			expandable = false,
			inModal = false,
		} = this.props;

		const {
			data,
			loading,
			pagination
		} = this.state;

		// console.log(inModal);

		return <div>
			<StyledTable
				loading={loading}
				dataSource={data}
				columns={this.columns}
				sticky={!inModal ? sticky : false}
				pagination={pagination}
				onChange={this.handleTableChange}
				withPaginationPadding={withPaginationPadding}
				expandable={expandable}
				scroll={inModal ? { x: 1440 } : {}}
			/>
		</div>;
	}
}

export default RecordTable;
