import React from 'react';
import { useHistory, useParams } from "react-router-dom";

import Moment from 'react-moment';

import Record from '../../components/_Record';

import { Row, Col, Card, Button, Select, Alert } from 'antd';

import { compareValues } from '../../libs/Helpers';

import '../../assets/scss/dashboard.scss';

const dictionaries = [
	{
		name: 'selectdictionary',
		title: 'Wybierz rodzaj słownika',
		listTitle: 'Wybierz rodzaj słownika',
		viewTitle: 'Wybierz rodzaj słownika',
		createTitle: 'Wybierz rodzaj słownika'
	},
	{
		name: 'dctrelationships',
		title: 'Rodzaje pokrewieństwa',
		listTitle: 'Lista rodzajów pokrewieństwa',
		viewTitle: 'Podgląd rodzaju pokrewieństwa',
		createTitle: 'Tworzenie rodzaju pokrewieństwa'
	},
	{
		name: 'dctfiletypes',
		title: 'Kategorie załączników',
		listTitle: 'Lista kategorii załączników',
		viewTitle: 'Podgląd kategorii załączników',
		createTitle: 'Tworzenie kategorii załączników'
	},
	{
		name: 'dctmaritials',
		title: 'Stany cywilne',
		listTitle: 'Lista stanów cywilnych',
		viewTitle: 'Podgląd stanu cywilnego',
		createTitle: 'Tworzenie stanu cywilnego'
	},
	{
		name: 'dctincometypes',
		title: 'Rodzaje przychodów',
		listTitle: 'Lista rodzajów przychodów',
		viewTitle: 'Podgląd rodzaju przychodów',
		createTitle: 'Tworzenie rodzaju przychodów'
	},
	{
		name: 'staprofiles',
		title: 'Statusy profili',
		listTitle: 'Lista statusów profili',
		viewTitle: 'Podgląd statusu profili',
		createTitle: 'Tworzenie statusu profili',
		readonly: true
	},
	{
		name: 'dcttriptypes',
		title: 'Rodzaje wyjazdów',
		listTitle: 'Lista rodzajów wyjazdów',
		viewTitle: 'Podgląd rodzaju wyjazdów',
		createTitle: 'Tworzenie rodzaju wyjazdów'
	},
	{
		name: 'dctunits',
		title: 'Jednostki miar',
		listTitle: 'Lista jednostek miar',
		viewTitle: 'Podgląd jednostki miar',
		createTitle: 'Tworzenie jednostki miar'
	},
	{
		name: 'dctmultitypes',
		title: 'Rodzaje kart Sportowych',
		listTitle: 'Lista rodzajów kart Sportowych',
		viewTitle: 'Podgląd rodzaju kart Sportowych',
		createTitle: 'Tworzenie rodzaju kart Sportowych'
	},
	{
		name: 'dctmultioperators',
		title: 'Rodzaje operatorów kart Sportowych',
		listTitle: 'Lista rodzajów operatorów kart Sportowych',
		viewTitle: 'Podgląd rodzaju operatorów kart Sportowych',
		createTitle: 'Tworzenie rodzaju operatorów kart Sportowych'
	},
	{
		name: 'dctikotypes',
		title: 'Rodzaje imprez Kulturalno-Oświatowych',
		listTitle: 'Lista rodzajów imprez Kulturalno-Oświatowych',
		viewTitle: 'Podgląd rodzaju imprez Kulturalno-Oświatowych',
		createTitle: 'Tworzenie rodzaju imprez Kulturalno-Oświatowych'
	},
	{
		name: 'dctirotypes',
		title: 'Rodzaje imprez Rekreacyjno-Oświatowych',
		listTitle: 'Lista rodzajów imprez Rekreacyjno-Oświatowych',
		viewTitle: 'Podgląd rodzaju imprez Rekreacyjno-Oświatowych',
		createTitle: 'Tworzenie rodzaju imprez Rekreacyjno-Oświatowych'
	},
	{
		name: 'dctconditions',
		title: 'Warunki szczególne',
		listTitle: 'Lista warunków szczególnych',
		viewTitle: 'Podgląd warunku szczególnego',
		createTitle: 'Tworzenie warunku szczególnego'
	},
	{
		name: 'dctextratypes',
		title: 'Rodzaj świadczeń dodatkowych',
		listTitle: 'Lista świadczeń dodatkowych',
		viewTitle: 'Podgląd świadczenia dodatkowego',
		createTitle: 'Tworzenie świadczenia dodatkowego'
	},
	{
		name: 'dctcamptypes',
		title: 'Rodzaje wypoczynku dzieci',
		listTitle: 'Lista wypoczynków dzieci',
		viewTitle: 'Podgląd wypoczynku dzieci',
		createTitle: 'Tworzenie wypoczynku dzieci'
	},
	{
		name: 'dctgranttypes',
		title: 'Rodzaje zapomóg',
		listTitle: 'Lista zapomóg',
		viewTitle: 'Podgląd zapomogi',
		createTitle: 'Tworzenie zapomogi'
	},
	{
		name: 'dctlawtitles',
		title: 'Tytuły prawne',
		listTitle: 'Lista tytułów prawnych',
		viewTitle: 'Podgląd tytułu prawnego',
		createTitle: 'Tworzenie tytułu prawnego'
	},
	{
		name: 'dctloanpurposes',
		title: 'Cele pożyczki',
		listTitle: 'Lista celi pożyczki',
		viewTitle: 'Podgląd celu pożyczki',
		createTitle: 'Tworzenie celu pożyczki'
	},
	{
		name: 'dcteducations',
		title: 'Stopnie edukacji',
		listTitle: 'Lista stopni edukacji',
		viewTitle: 'Podgląd stopnia edukacji',
		createTitle: 'Tworzenie stopnia edukacji'
	},
	{
		name: 'dctdisabilities',
		title: 'Stopnie niepełnosprawności',
		listTitle: 'Lista stopni niepełnosprawności',
		viewTitle: 'Podgląd stopnia niepełnosprawności',
		createTitle: 'Tworzenie stopnia niepełnosprawności'
	},
	{
		name: 'dctpaymethods',
		title: 'Metody płatności',
		listTitle: 'Lista metod płatności',
		viewTitle: 'Podgląd metody płatności',
		createTitle: 'Tworzenie metody płatności'
	},
	{
		name: 'dctresorts',
		title: 'Ośrodki wczasowe',
		listTitle: 'Lista ośrodków wczasowych',
		viewTitle: 'Podgląd ośrodka wczasowego',
		createTitle: 'Tworzenie ośrodka wczasowego'
	}
];

const getDictionaryByName = (name) => dictionaries.find(dict => dict.name === name) ?? dictionaries[0].name ?? null;

const Dashboard_Dictionaries = (props) => {
	const history = useHistory();
	const { dictionary } = useParams();
	const [activeDictionary, setActiveDictionary] = React.useState(dictionary ?? dictionaries[0].name);
	const [mode, setMode] = React.useState(props.mode ?? 'list');
	const dictUrl = window.location.href.replace(/\/+$/, '');
	const lastUrlPart = dictUrl.substring(dictUrl.lastIndexOf('/') + 1)

	return (<div className="content">
		{ mode === 'list' ? <Row style={{ marginBottom: '15px' }}>
			<Col>
				<Select
					showSearch
					allowClear
					defaultValue={ lastUrlPart !== 'dictionaries' ?  lastUrlPart : null }
					placeholder="Wybierz słownik"
					optionFilterProp="children"
					onChange={(value) => {
						setActiveDictionary(value);
						history.push('/dictionaries/'+value);
					}}
					style={{ minWidth: 200 }}
				>
					{ dictionaries.slice().sort(compareValues('title')).filter(dict => dict.name !== 'selectdictionary').map((el, _) => (
						<Select.Option key={`selectOptionDict~${_}`} value={ el.name }>{ el.title }</Select.Option>
					)) }
				</Select>
			</Col>
		</Row> : null }

		{ activeDictionary !== 'selectdictionary' && activeDictionary !== undefined ?
			<Record
				key={`record~${mode}~${activeDictionary}`}
				controller={getDictionaryByName(activeDictionary).name}
				referer={`dictionaries/${getDictionaryByName(activeDictionary).name}`}
				readonly={ getDictionaryByName(activeDictionary).readonly ?? false }
				defaultMode="list"
				mode={ props.mode || null }
				defaultEditing={ props.editing || null }
				info={false}
				pagination={false}
				columns={[
					{
						name: 'id',
						title: '#',
						type: 'number'
					},
					{
						name: 'name',
						title: 'Nazwa',
						searchable: true
					},
					{
						name: 'is_active',
						title: 'Aktywny',
						datatype: 'bool',
						searchable: true
					},
					{
						name: 'actions',
						data: null,
						sortable: false,
						title: '',
						render: function(data, type, row) {
							return `<a class="btn btn-table" href="/dictionaries/${getDictionaryByName(activeDictionary).name}/${data.id}">Podgląd</a>${!(getDictionaryByName(activeDictionary).readonly ?? false) ? `<a class="btn btn-table" href="/dictionaries/${getDictionaryByName(activeDictionary).name}/${data.id}/edit">Edytuj</a>` : ''}`;
						},
						className: 'text-right'
					}
				]}
				viewTitle={getDictionaryByName(activeDictionary).viewTitle ?? 'Podgląd rekordu'}
				listTitle={getDictionaryByName(activeDictionary).listTitle ?? 'Lista rekordów'}
				createTitle={getDictionaryByName(activeDictionary).createTitle ?? 'Tworzenie rekordu'}>
				<Record.Field title={'Nazwa'} field="name" />
				{
					dictionary === 'dctmultitypes'
					? <Record.Field title={'Typ'} field="type" />
					: null
				}
				<Record.Field.Switch title={'Aktywny'} field="is_active" defaultValue={ 1 } />
			</Record>
		:
			<Row>
				<Col span={24} style={{ textAlign: 'center' }} className="my-1">
					<Alert message="Wybierz rodzaj słownika aby kontynuować" type="success" className="mb-2" />
				</Col>
			</Row>
		}
	</div>);
};

export default Dashboard_Dictionaries;
