import React from 'react';
import PropTypes from 'prop-types';

import Observer from '../../Observer';

import { RecordContext } from '../../../contexts/Record';

import { Col } from 'antd';

import _ from 'lodash';
import objectHash from 'object-hash';

import { getValueFromArray } from '../../../libs/Helpers.js';
import shallowEqual from '../../../libs/shallowEqual';

class Record_Fields_Base extends React.Component {
	static propTypes = {
		name: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.array
		]).isRequired,
		valueName: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.array
		]),
		title: PropTypes.any.isRequired,
		disabled: PropTypes.bool,
		showTitle: PropTypes.bool,
		span: PropTypes.object,
		defaultValue: PropTypes.any,
		readonly: PropTypes.bool,
		hidePredicate: PropTypes.func,
		hideBehaviour: PropTypes.oneOf(['empty', 'hide']),
		disablePredicate: PropTypes.func,
		rules: PropTypes.array,
		tooltip: PropTypes.string,
		placeholder: PropTypes.string,
		overrideValue: PropTypes.func,
		defaultValues: PropTypes.oneOfType([
			PropTypes.object,
			PropTypes.array
		]),
		tab: PropTypes.string,
		namePrefix: PropTypes.array,
		debounced: PropTypes.bool,
		registerField: PropTypes.bool,
		dependencies: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.array
		]),
		shouldUpdate: PropTypes.func
	};

	static defaultProps = {
		valueName: null,
		showTitle: true,
		span: { lg: 24, xl: 12 },
		rules: [],
		disabled: false,
		tooltip: null,
		placeholder: null,
		tab: 'general',
		defaultValue: null,
		debounced: false,
		registerField: true,
		shouldUpdate: null,
		hideBehaviour: 'hide'
	};

	static contextType = RecordContext;

	static displayName = 'RecordField';

	ref = React.createRef();

	state = {
		value: null,
		container: {
			dataHash: objectHash(this.data)
		}
	};

	get key() {
		return `record_field_${this.props.name}`;
	}

	get globalItemProps() {
		const {
			title,
			name,
			disabled,
			rules,
			hidePredicate,
			tooltip,
			fieldKey = this.key,
			showTitle,
			dependencies,
			shouldUpdate
		} = this.props;

		let rProps = {
			name,
			label: showTitle ? title : null,
			initialValue: this.getInitialValue(),
			rules,
			hidden: (typeof hidePredicate === 'function') ? hidePredicate(this.getRawValue(), this.data, this.mode) : false,
			tooltip,
			fieldKey,
			key: this.key
		};

		if(dependencies) {
			rProps.dependencies = Array.isArray(dependencies) ? dependencies : [dependencies];
		}

		if(shouldUpdate && typeof shouldUpdate === 'function') {
			rProps.shouldUpdate = shouldUpdate;
		}

		return rProps;
	}

	get hash() {
		return objectHash(this.data);
	}

	get mode() {
		return (this.context && this.context.mode) ? this.context.mode : null;
	}

	get editing() {
		return (this.context && this.context.editing) ? this.context.editing : false;
	}

	get rawData() {
		return (this.context && this.context.data) ? this.context.data : {};
	}

	get data() {
		if(!this.context) {
			return {};
		}

		return Object.assign(this.rawData ?? {}, this.context.changes ?? {});
	}

	get relationData() {
		return (this.context && this.context.relationData) ? this.context.relationData : {};
	}

	get form() {
		return (this.context && this.context.form) ? this.context.form : null;
	}

	get name() {
		return this.props.valueName ?? this.props.name;
	}

	constructor(props) {
		super(props);

		this.onDataUpdate = this.onDataUpdate.bind(this);
		this.onChangesUpdate = this.onChangesUpdate.bind(this);
		this.onRelationDataUpdate = this.onRelationDataUpdate.bind(this);
	}

	componentDidMount() {
		this.registerField();
	}

	shouldComponentUpdate(newProps, newState) {
		return (
			!shallowEqual(this.props, newProps) || !shallowEqual(this.state, newState)
		);
	}

	onDataUpdate() {

	}

	onChangesUpdate() {
		this.setState({
			container: {
				dataHash: objectHash(this.data)
			}
		});
	}

	onRelationDataUpdate() {

	}

	getRawValue() {
		const {
			name,
			overrideValue = () => null,
			defaultValue
		} = this.props;

		const overrided = overrideValue(this.data, this.relationData, this.mode);
		if(overrided) {
			if(this.form?.current) {
				this.form.current.setFieldsValue({
					[this.name]: overrided ?? null
				});
			}
			return overrided ?? null;
		}

		let value = null;

		if(this.data ?? null) {
			if(Array.isArray(this.name)) {
				value = getValueFromArray(this.data, this.name);
			} else {
				if(this.data.hasOwnProperty(this.name)) {
					value = this.data[this.name];
				}
			}
		}

		this.setState({
			value
		});

		return value;
	}

	getInitialValue() {
		return this.getRawValue() ?? this.props.defaultValue ?? null;
	}

	getValue() {
		return this.getRawValue() ?? '(brak)';
	}

	registerField() {
		if(!this.props.registerField) return;

		this.context?.reducer?.dispatch({
			type: 'addField',
			payload: {
				displayName: this._reactInternals.type.displayName,
				name: this.props?.name,
				scrollTo: this.ref?.current ? () => {
					this.ref.current.scrollIntoView({
						behavior: 'smooth',
						block: 'center'
					});
				} : () => {},
				props: this.props
			}
		});
	}

	renderPreview() {
		const {
			title,
			showTitle,
			readonly,
			hidePredicate
		} = this.props;

		if((typeof hidePredicate === 'function' ? hidePredicate(this.getRawValue(), this.data, this.mode) : false)) {
			return null;
		}

		return <React.Fragment key={['_field', this.key]}>
			{
			showTitle ?
				<label>
					<strong>{title}</strong>
				</label>
				:
				null
			}
			<span className={!readonly ? 'editable' : ''}>{this.getValue()}</span>
		</React.Fragment>;
	}

	renderEdit() {
		return <>Not implemented</>;
	}

	renderField() {
		const {
			readonly
		} = this.props;

		return (this.editing && !readonly) ? this.renderEdit() : this.renderPreview();
	}

	view(content) {
		const {
			name,
			span = {},
			type,
			disablePredicate,
			hidePredicate,
			hideBehaviour = 'hide'
		} = this.props;

		const rowData = Array.isArray(name) ? getValueFromArray(this.data, name.slice(0, name.length - 1)) : null;

		if(!['technical'].includes(type)) {
			if(!(typeof hidePredicate === 'function' ? hidePredicate(this.getRawValue(), this.data, this.mode, rowData) : false)) {
				return (<Col
						ref={this.ref}
						className="form-group"
						key={['_fieldCont', this.key]}
						{...span}
					>
					{this.renderField()}
				</Col>);
			} else {
				if(hideBehaviour === 'empty') {
					return (
						<Col
							ref={this.ref}
							className="form-group"
							key={['_fieldCont', this.key]}
							{...span}
						>
							{this.renderField()}
						</Col>
					);
				} else {
					return this.renderField();
				}
			}
		}

		return null;
	}

	render() {
		return <React.Fragment key={['_fieldCon', this.key]}>
			<Observer dependencies={[this.context.data]} didUpdate={this.onDataUpdate} />
			<Observer dependencies={[this.context.relationData]} didUpdate={this.onRelationDataUpdate} />
			{this.view()}
		</React.Fragment>;
	}
}


export default Record_Fields_Base;
