import React, { useState } from 'react';
import { useHistory, Link } from "react-router-dom";

import axios from 'axios';

import { backendRoot, backendApi, csrfCookie, apiResetPasswordToken } from '../config/paths';

import prepareErrorMessage from '../libs/ErrorHelper.js';

import { Form, Input, Checkbox, Button, Row, Col, Card, Alert } from 'antd';

import '../assets/scss/login.scss';

axios.defaults.withCredentials = true;

const ForgottenPassword = (props) => {
    const [isSent, setSent] = useState(false);
    const [isError, setError] = useState(false);
	const history = useHistory();

	const onFormFinished = (values) => {
		axios.get(backendRoot + csrfCookie).then(() => {
			axios.post(backendApi + apiResetPasswordToken, values).then((response) => {
				setSent(true);
			}).catch(err => {
				setSent(true);

				if(err.response) {
					setError(err.response.data.errcode);
				} else {
					setError('ERR-UNDEFINED');
				}
			});
		}).catch(err => {
			setSent(true);

			if(err.response) {
				setError(err.response.data.errcode);
			} else {
				setError('ERR-UNDEFINED');
			}
		});
	};

	return (<main className="main h-100 w-100">
		<div className="container h-100">
			<Row className="h-100">
				<Col sm={20} md={16} lg={12} className="d-table mx-auto h-100">
					<div className="d-table-cell align-middle login-pane">
						<Card title={<><div className="login-card-title">Odzyskiwanie hasła</div></>} bordered={false} className="login-card">
							{ isSent ? (
								isError ? <Row>
									<Col span={24} style={{ textAlign: 'center' }} className="my-1">
										<Alert message={prepareErrorMessage(isError)} type="error" className="mb-2" />

										<Link to="/login">
											Wróć do logowania
										</Link>
									</Col>
								</Row> : <Row>
									<Col span={24} style={{ textAlign: 'center' }} className="my-1">
										<Alert message="Na wysłany adres e-mail zostały wysłane instrukcje resetowania hasła." type="success" className="mb-2" />

										<Link to="/login">
											Wróć do logowania
										</Link>
									</Col>
								</Row>
							) : <Form name="login-form" layout="vertical" onFinish={onFormFinished}>
								<Row>
									<Col span={24} style={{ textAlign: 'center' }} className="mt-1">
										<p>
											Wprowadź swój adres e-mail, który podałeś podczas zakładania konta, a my wyślemy Ci instrukcję jak zresetować Twoje hasło.
										</p>
									</Col>
								</Row>

								<Form.Item
									label="Adres e-mail:"
									name="email"
									rules={[{ required: true, message: 'Proszę wprowadzić adres e-mail' },{ type: 'email', message: 'Proszę wprowadzić poprawny adres e-mail' }]}>
									<Input />
								</Form.Item>

								<Row>
									<Col span={24} style={{ textAlign: 'right' }}>
										<Form.Item>
											<Button type="primary" htmlType="submit">
												Przypomnij hasło
											</Button>
										</Form.Item>
									</Col>
								</Row>

								<Row>
									<Col span={24}>
										<Link to="/login">
											Wróć do logowania
										</Link>
									</Col>
								</Row>
							</Form> }
						</Card>
					</div>
				</Col>
			</Row>
		</div>
	</main>);
};

export default ForgottenPassword;
