import React from 'react';
import { useHistory } from 'react-router-dom';

import Moment from 'react-moment';

import Record from '../../../components/Record/index';
// import ModalRecord from '../../../components/Record/modal';
import RecordNew from '../../../components/RecordNew';

import TabbedCard from '../../../components/TabbedCard';

import { useAuth, PERMISSIONS as USER_PERMISSIONS } from '../../../contexts/User';

import { Row, Col, Card, Button, Modal, Form, Input, InputNumber, DatePicker, message } from 'antd';

import moment from 'moment';

import '../../../assets/scss/dashboard.scss';

import { APIBackend as API } from '../../../api';

const listPropsPayments = {
	columns: [
		{
			name: 'id',
			title: '#',
			sorter: true,
			defaultSortOrder: 'descend'
		},
		{
			name: 'number',
			title: 'Nr dowodu',
			sorter: true,
		},
		{
			name: 'period',
			title: 'Okres płatności',
			sorter: true,
		},
		{
			name: 'real_price',
			title: 'Rzeczywista kwota płatności'
		},
		{
			name: 'subsidy_value',
			title: 'Kwota podlegająca dofinansowaniu'
		},
		{
			name: 'subsidy_total',
			title: 'Wysokość dofinansowania'
		},
		{
			name: 'staapp_name',
			title: 'Status'
		},
		// {
		// 	name: 'real_quote',
		// 	title: 'Rzeczywista kwota do zapłaty'
		// },
	],
};

const listPropsAgreements = {
	columns: [
		{
			name: 'id',
			title: '#',
			sorter: true,
			defaultSortOrder: 'descend'
		},
		{
			name: 'number',
			title: 'Nr umowy',
			sorter: true,
		},
		{
			name: 'signing_date',
			title: 'Data podpisania',
			sorter: true,
		},
		{
			name: 'valid_date',
			title: 'Data obowiązywania',
			sorter: true,
		},
	],
};

const Dashboard_Applications_Infants = (props) => {
	const [currentActiveTab, setCurrentActiveTab] = React.useState('general');

	// Modal states
	const [paymentsModalVisible, setPaymentsModalVisible] = React.useState(false);
	const [agreementsModalVisible, setAgreementsModalVisible] = React.useState(false);

	// Used for modal table filters
	const [paymentsId, setPaymentsId] = React.useState(null);
	const [agreementsId, setAgreementsId] = React.useState(null);

	const auth = useAuth();
	const history = useHistory();

	const handlePaymentsCancel = () => {
		setPaymentsId(null);
		setPaymentsModalVisible(false);
	};

	const handleAgreementsCancel = () => {
		setAgreementsId(null);
		setAgreementsModalVisible(false);
	};

	return (<div className="content">
		<Modal
			title="Rejestr wpłat"
			visible={paymentsModalVisible}
			onCancel={handlePaymentsCancel}
			width={1200}
			cancelText="Zamknij"
			okButtonProps={{
				style: { display: 'none' }
			}}
		>
			<RecordNew
				modal
				mode="list"
				controller="appinfantpayments"
				relations={['dctfiletypes']}
				filter={[
					{
						field: 'appinfant_id',
						value: paymentsId
					}
				]}
				listProps={listPropsPayments}
				defaultValues={{
					appinfant_id: paymentsId
				}}
				customButtons={[
					{ title: 'Akcje', dropdown: [
						{ title: 'Przekazanie wpłaty do akceptacji', endpoint: '/api/appinfantpayments/status/toaccept', predicate: (el, data, relationData) => [1,4].includes(data?.staapp_id), modal: { title: 'Potwierdź akcję', content: 'Czy na pewno chcesz przekazać wpłatę do akceptacji?' } },
						{ title: 'Przekazanie wpłaty do komisji', endpoint: '/api/appinfantpayments/status/tocommission', predicate: (el, data, relationData) => [2].includes(data?.staapp_id), modal: { title: 'Potwierdź akcję', content: 'Czy na pewno chcesz przekazać wpłatę do komisji socjalnej?', fields: ['to_commission'] } },
						{ title: 'Zwrot wpłaty do korekty', endpoint: '/api/appinfantpayments/status/tocorrect', predicate: (el, data, relationData) => [2,6].includes(data?.staapp_id), modal: { title: 'Potwierdź akcję', content: 'Czy na pewno chcesz przekazać wpłatę do korekty?', fields: ['to_correct'] } },
						{ title: 'Akceptuj wpłatę', endpoint: '/api/appinfantpayments/status/accepted', predicate: (el, data, relationData) => [2,6].includes(data?.staapp_id) },
						{ title: 'Odrzuć wpłatę', endpoint: '/api/appinfantpayments/status/refused', predicate: (el, data, relationData) => [2,6].includes(data?.staapp_id), modal: { title: 'Potwierdź akcję', content: 'Czy na pewno chcesz odrzucić wpłatę?', fields: ['remarks'] } },
						{ title: 'Anuluj wpłatę', endpoint: '/api/appinfantpayments/status/canceled', predicate: (el, data, relationData) => [1,2,3].includes(data?.staapp_id), modal: { title: 'Potwierdź akcję', content: 'Czy na pewno chcesz anulować wpłatę?', fields: ['remarks'] } },
					] },
					{
						title: 'Oblicz dofinansowanie',
						endpoint: '/api/appinfantpayments/count/{#id}',
						requestMethod: 'get',
						successText: 'Obliczenie zostało wykonane',
						errorText: 'Wystąpił błąd podczas obliczania',
						disablePredicate: (data) => data?.id ? false : true
					},
				]}
			>
				<RecordNew.Consumer>
					{({ loading }) => (
						<TabbedCard key="infantpayments_generalCard" loading={loading} tabContent={{
							infantpayments_general: [
								<RecordNew.Field.Input title="Nr umowy" name="number" span={{ span: 24 }} readonly />,
								<RecordNew.Field.Input title="Okres płatności" name="period" placeholder="RRRR/MM"
								rules={
									[
										{
											type: 'string',
											max: 7,
											message: '[Rejestr wpłat] Pole Okres płatności może zawierać maksymalnie 7 znaków. Format: RRRR/MM, np. 2021/07'
										},
										{
											pattern: /(\d{4}\/\d{2})/g,
											message: '[Rejestr wpłat] Nieprawidłowy format, proszę uzupełnić pole w następującym formacie: RRRR/MM, np. 2021/07'
										},
										{
											required: true,
											message: '[Rejestr wpłat] Proszę uzupełnić okres płatności'
										}
									]
								} />,
								<RecordNew.Field.Number name="subsidy_value"
								title="Kwota podlegająca dofinansowaniu" precision={2} delimiter="." />,
								<RecordNew.Field.Number name="subsidy_total"
								title="Wysokość dofinansowania" precision={2} delimiter="." />,
								<RecordNew.Field.Number name="real_price"
								title="Rzeczywista kwota płatności"
								rules={
									[
										{
											required: true,
											message: '[Rejestr wpłat] Proszę uzupełnić kwotę płatności'
										}
									]
								} precision={2} delimiter="." />,
								<RecordNew.Field.Date name="payment_date"
								title="Data wypłaty dofinansowania" />,
								<RecordNew.Field.Input title="Status" name="staapp_name" span={{ span: 24 }} readonly />,
								<RecordNew.Field.Textarea title="Wynik ostatniego obliczenia" name="control" span={{ span: 24 }} readonly />,
								<RecordNew.Field.Textarea title="Uwagi (do korekty)" name="to_correct" span={{ span: 24 }} />,
								<RecordNew.Field.Textarea title="Uwagi (do komisji)" name="to_commission" span={{ span: 24 }} />,
								<RecordNew.Field.Textarea title="Uwagi" name="remarks" span={{ span: 24 }} />
							],
							infantpayments_attachments: [
								<RecordNew.Field.Attachments title={'Załączniki'} name="attachments1" valueName="attachments" fileCategory="Ogólny" relation="dctfiletypes" />
							]
						}} tabList={[
							{ key: 'infantpayments_general', tab: 'Dane podstawowe' },
							{ key: 'infantpayments_attachments', tab: 'Załączniki' }
						]} activeTabKey="infantpayments_general" onTabChange={(key) => {  }} forceRender={true} />
					)}
				</RecordNew.Consumer>
			</RecordNew>
		</Modal>

		<Modal
			title="Rejestr umów"
			visible={agreementsModalVisible}
			onCancel={handleAgreementsCancel}
			width={1200}
			cancelText="Zamknij"
			okButtonProps={{
				style: { display: 'none' }
			}}
		>
			<RecordNew
				modal
				mode="list"
				controller="appinfantagreements"
				filter={[
					{
						field: 'appinfant_id',
						value: agreementsId
					}
				]}
				listProps={listPropsAgreements}
				defaultValues={{
					appinfant_id: agreementsId
				}}
				debugLevel={2}
			>
				<RecordNew.Consumer>
					{({ loading }) => (
						<TabbedCard key="infantagreements_generalCard" loading={loading} tabContent={{
							infantagreements_general: [
								<RecordNew.Field.Input name="number"
								title="Nr umowy"
								rules={
									[
										{
											required: true,
											message: '[Rejestr umów] Proszę uzupełnić numer umowy'
										}
									]
								} />,
								<RecordNew.Field.Date name="signing_date"
								title="Data podpisania"
								rules={
									[
										{
											required: true,
											message: '[Rejestr umów] Proszę uzupełnić datę podpisania'
										}
									]
								} />,
								<RecordNew.Field.Date name="valid_date"
								title="Data obowiązywania"
								rules={
									[
										{
											required: true,
											message: '[Rejestr umów] Proszę uzupełnić datę obowiązywania'
										}
									]
								} />,
							]
						}} tabList={[
							{ key: 'infantagreements_general', tab: 'Dane podstawowe'}
						]} activeTabKey="infantagreements_general" onTabChange={(key) => {  }} forceRender={true} />
					)}
				</RecordNew.Consumer>
			</RecordNew>
		</Modal>

		<Record
			controller="appinfants"
			relations={['profiles', 'families', 'contractors', 'infants', 'dctfiletypes']}
			mode={props?.mode}
			defaultEditing={props?.editing}
			filters={[
				{
					title: 'Kontrahent',
					name: 'contractors.id',
					relation: 'contractors',
					type: 'enum'
				}
			]}
			technicalFields={[
				<Record.Field.Input title={'Status wniosku'} name="staapp_name" readonly />
			]}
			includeFields={[
				'appinfantpayments'
			]}
			onCreatedRow={(row, data) => {
				if(data?.is_seen === 0) {
					row.style.background = 'rgba(225, 166, 52, 0.1)';
				}
			}}
			customButtons={[
				{ title: 'Akcje', dropdown: [
					{ title: 'Przekazanie wniosku do akceptacji', endpoint: '/appinfants/status/toaccept', predicate: (el, data, relationData) => [1,4].includes(data?.staapp_id), modal: { title: 'Potwierdź akcję', content: 'Czy na pewno chcesz przekazać wniosek do akceptacji?' } },
					{ title: 'Przekazanie wniosku do komisji', endpoint: '/appinfants/status/tocommission', predicate: (el, data, relationData) => [2].includes(data?.staapp_id), modal: { title: 'Potwierdź akcję', content: 'Czy na pewno chcesz przekazać wniosek do komisji socjalnej?', fields: ['to_commission'] } },
					{ title: 'Zwrot wniosku do korekty', endpoint: '/appinfants/status/tocorrect', predicate: (el, data, relationData) => [2,6].includes(data?.staapp_id), modal: { title: 'Potwierdź akcję', content: 'Czy na pewno chcesz przekazać wniosek do korekty?', fields: ['to_correct'] } },
					{ title: 'Akceptuj wniosek', endpoint: '/appinfants/status/accepted', predicate: (el, data, relationData) => [2,6].includes(data?.staapp_id) },
					{ title: 'Odrzuć wniosek', endpoint: '/appinfants/status/refused', predicate: (el, data, relationData) => [2,6].includes(data?.staapp_id), modal: { title: 'Potwierdź akcję', content: 'Czy na pewno chcesz odrzucić wniosek?', fields: ['remarks'] } },
					{ title: 'Anuluj wniosek', endpoint: '/appinfants/status/canceled', predicate: (el, data, relationData) => [1,2,3].includes(data?.staapp_id), modal: { title: 'Potwierdź akcję', content: 'Czy na pewno chcesz anulować wniosek?', fields: ['remarks'] } },
					{ title: 'Cofnij wniosek do akceptacji', endpoint: '/appinfants/status/undoaccept', predicate: (_el, data) => auth.can(USER_PERMISSIONS.ADMIN) && [3,5,7].includes(data?.staapp_id), modal: { title: 'Potwierdź akcję', content: 'Czy na pewno chcesz cofnąć wniosek do akceptacji?', fields: ['remarks'] } },
				] },
				{
					title: 'Nadaj numer',
					endpoint: '/appinfants/number/{#id}',
					requestMethod: 'put',
					predicate: (el, data, relationData) => (!data?.number) && data?.staapp_id === 2,
					successText: 'Numer wniosku został nadany',
					errorText: 'Wystąpił błąd podczas nadawania numeru'
				},
				{
					title: 'Wpłaty',
					onClick: (e, id, data) => {
						setPaymentsId(id);
						setPaymentsModalVisible(true);
					}
				},
				{
					title: 'Usuń',
					endpoint: '/appinfants/{#id}',
					requestMethod: 'delete',
					predicate: (el, data, relationData) => [1].includes(data?.staapp_id) && auth.can(USER_PERMISSIONS.ADMIN),
					successText: 'Wniosek został usunięty',
					errorText: 'Wystąpił błąd podczas usuwania wniosku',
					onSuccess: () => {
						window.location.href = '/appinfants/';
					},
					modal: {
						title: 'Potwierdź akcję',
						content: 'Czy na pewno chcesz usunąć wniosek?',
						okText: 'Tak, usuń'
					},
					buttonType: 'danger'
				}
				// {
				// 	title: 'Umowy',
				// 	onClick: (e, id, data) => {
				// 		setAgreementsId(id);
				// 		setAgreementsModalVisible(true);
				// 	}
				// }
			]}
			columns={[
				{
					name: 'id',
					title: '#',
					type: 'number'
				},
				{
					name: 'full_name',
					title: 'Wnioskujący',
					searchable: true,
					searchname: 'users.full_name'
				},
				{
					name: 'application_date',
					title: 'Data złożenia wniosku'
				},
				{
					name: 'staapp_name',
					title: 'Status',
					sortname: 'staapps.name'
				},
				{
					name: 'actions',
					align: 'right',
					data: null,
					sortable: false,
					title: '',
					render: function(data, type, row) {
						return `<a class="btn btn-table" href="/appinfants/${data.id}">Podgląd</a><a class="btn btn-table" href="/appinfants/${data.id}/edit">Edytuj</a>`;
					},
					className: 'text-right'
				}
			]}
			viewTitle="[{#number}] Przedszkola i żłobki - PODGLĄD"
			listTitle="Przedszkola i żłobki"
			editTitle="[{#number}] Przedszkola i żłobki - EDYCJA"
			createTitle="Przedszkola i żłobki - TWORZENIE"
			onValuesChange={(field, allFields, updateRelationData, updateDependency) => {
				if(field.name === 'profile_id') {
					updateRelationData(field.value);
					updateDependency('families');
				}
			}}
		>
			<Record.Consumer>
				{({ isLoading, mode }) => (
					<TabbedCard key="generalCard" loading={isLoading} tabContent={{
						general: [
							<Record.Field.Relation title="Wnioskujący" relation="profiles" name="profile_id" labelFormat="[{#registration_number}] {#full_name} - {#contractor_symbol}" link filter={ (data, record) => {
								return data.filter((el, idx) => {
									return el.staprofile_id === 2;
								});
							} } allowSearch={true} rules={[{ required: true, message: 'Proszę uzupełnić wnioskującego' }]} />,
							<Record.Field.Date title="Data złożenia wniosku" name="application_date" defaultValue={moment()} />,
							<Record.Field.Relation title="Uczestnik" name="family_id" relation="families" relatedKey="family_id" relationValueColumnPrefix="family_" labelFormat="{#first_name} {#last_name} ({#birth_date})" filter={ (data, record) => {
								return data.filter((el, idx) => {
									if(record) {
										if(el.profile_id === parseInt(record.profile_id)) {
											return true;
										} else {
											return false;
										}
									}

									return true;
								});
							} } />,
							<Record.Field.Input title="Rok szkolny" name="school_year" />,
							<Record.Field.Select title="Rodzaj placówki" name="infant_type" options={[
								{ label: 'Wewnętrzna', value: 'I' },
								{ label: 'Zewnętrzna', value: 'E' }
							]} />,
							<Record.Field.Relation title="Przedszkole/żłobek" name="infant_id" relation="infants" hidePredicate={(value, data, mode) => {
								return data?.infant_type !== 'I'
							}} filter={(data, record, relationData) => {
								return data.filter((el, idx) => {
									let profile = relationData && relationData.hasOwnProperty('profiles') && relationData.profiles.find(el => el.id === record?.profile_id);

									return profile && el.contractor_id === profile?.contractor_id;
								});
							}} />,
							<Record.Field.Select title="Rodzaj obiektu" name="infant_level" options={[
								{ label: 'Przedszkole', value: 'P' },
								{ label: 'Żłobek', value: 'Z' }
							]} hidePredicate={(value, data, mode) => {
								return data?.infant_type !== 'E'
							}} span={{ span: 24 }} />,
							<Record.Field.Input title="Nazwa obiektu" name="name" hidePredicate={(value, data, mode) => {
								return data?.infant_type !== 'E'
							}} span={{ span: 24 }} />,
							<Record.Field.Input title="Ulica obiektu" name="street" hidePredicate={(value, data, mode) => {
								return data?.infant_type !== 'E'
							}} span={{ sm: 24, md: 12 }} />,
							<Record.Field.Input title="Kod pocztowy obiektu" name="zip" hidePredicate={(value, data, mode) => {
								return data?.infant_type !== 'E'
							}} span={{ sm: 24, md: 5 }} />,
							<Record.Field.Input title="Miejscowość obiektu" name="town" hidePredicate={(value, data, mode) => {
								return data?.infant_type !== 'E'
							}} span={{ sm: 24, md: 7 }} />,
							<Record.Field.Empty />,
							<Record.Field.Switch title="Widoczny dla komisji" name="is_visible" />,
							<Record.Field.Textarea title="Wynik ostatniego obliczenia" name="control" span={{ span: 24 }} />,
							<Record.Field.Textarea title="Uwagi (do korekty)" name="to_correct" span={{ span: 24 }} />,
							<Record.Field.Textarea title="Uwagi (do komisji)" name="to_commission" span={{ span: 24 }} />,
							<Record.Field.Textarea title="Uwagi" name="remarks" span={{ span: 24 }} />

						],
						clientdata: [
							<Record.Field.Input title="Imię i nazwisko" name="full_name" readonly overrideValue={(record, relationData) => {
								if(record.profile_id) {
									let profile = relationData?.profiles?.find(el => el.id === record.profile_id);
									return profile?.full_name;
								}

								return null;
							}} />,
							<Record.Field.Input title="Data urodzenia" name="birth_date" readonly overrideValue={(record, relationData) => {
								if(record.profile_id) {
									let profile = relationData?.profiles?.find(el => el.id === record.profile_id);
									return profile?.birth_date;
								}

								return null;
							}} />,
							<Record.Field.Input title="Miejsce zamieszkania" name="address" readonly overrideValue={(record, relationData) => {
								if(record.profile_id) {
									let profile = relationData?.profiles?.find(el => el.id === record.profile_id);
									return profile?.address;
								}

								return null;
							}} />,
							<Record.Field.Input title="Numer kontaktowy" name="phone" readonly overrideValue={(record, relationData) => {
								if(record.profile_id) {
									let profile = relationData?.profiles?.find(el => el.id === record.profile_id);
									return profile?.phone;
								}

								return null;
							}} />,

							<Record.Field.Input title="Kontrahent" name="contractor_name" readonly overrideValue={(record, relationData) => {
								if(record.profile_id) {
									let profile = relationData?.profiles?.find(el => el.id === record.profile_id);
									return profile?.contractor_name;
								}

								return null;
							}} />,
							<Record.Field.Input title="Numer NIP kontrahenta" name="contractor_nip" readonly overrideValue={(record, relationData) => {
								if(record.profile_id) {
									let profile = relationData?.profiles?.find(el => el.id === record.profile_id);
									return profile?.contractor_nip;
								}

								return null;
							}} />,
							<Record.Field.Input title="Adres kontrahenta" name="branch_address" readonly overrideValue={(record, relationData) => {
								if(record.profile_id) {
									let profile = relationData?.profiles?.find(el => el.id === record.profile_id);
									return profile?.branch_address;
								}

								return null;
							}} />,
							<Record.Field.Input title="Średnie dochody" name="average_incomes" readonly overrideValue={(record, relationData) => {
								if(record.profile_id) {
									let profile = relationData?.profiles?.find(el => (el?.id ?? el?.value) === record.profile_id);
									return profile?.average_incomes;
								}

								return null;
							}} />,

							<Record.Field.TableRelation readonly title={'Rodzina'} name="families" span={{ span: 24 }} fields={[
								{ name: 'first_name', title: 'Imię' },
								{ name: 'last_name', title: 'Nazwisko' },
								{ name: 'birth_date', title: 'Data urodzenia' },
								{ name: 'pesel', title: 'PESEL' },
								{ name: 'dctrelationship_name', title: 'Rodzaj pokrewieństwa' }
							]} />
						],
						payments: [
							<Record.Field.TableRelation title={'Wpłaty'} name="appinfantpayments" span={{ span: 24 }} fields={[
								{ name: 'id', title: 'ID', isHidden: true, removeEmpty: true },
								{ name: 'number', title: 'Nr dowodu', span: { lg: 9, xl: 10 } },
								{ name: 'period', title: 'Okres płatności', span: { lg: 5, xl: 5 } },
								{ name: 'real_price', title: 'Rzeczywista kwota płatności', type: 'number', span: { lg: 5, xl: 5 } },
								{ name: 'subsidy_value', title: 'Kwota podlegająca dofinansowaniu', type: 'number', span: { lg: 5, xl: 5 } },
								{ name: 'subsidy_total', title: 'Wysokość dofinansowania', type: 'number', span: { lg: 5, xl: 5 } },
								{ name: 'real_quote', title: 'Rzeczywista kwota do zapłaty', type: 'number', span: { lg: 5, xl: 5 } },
							]} showTitle={ false } readonly />
						],
						agreements: [
							<Record.Field.TableRelation title={'Umowy'} name="appinfantagreements" span={{ span: 24 }} fields={[
								{ name: 'id', title: 'ID', isHidden: true, removeEmpty: true },
								{ name: 'number', title: 'Nr umowy', span: { lg: 9, xl: 10 } },
								{ name: 'signing_date', title: 'Data podpisania', span: { lg: 5, xl: 5 }, type: 'date' },
								{ name: 'valid_date', title: 'Data obowiązywania', span: { lg: 5, xl: 5 }, type: 'date' },
							]} showTitle={ false } readonly />
						],
						attachments: [
							<Record.Field.Attachments title={'Załączniki'} name="attachments1" valueName="attachments" fileCategory="Ogólny" relation="dctfiletypes" />,
							<Record.Field.Attachments title={'Umowy'} name="attachments2" valueName="attachments" fileCategory="Umowy" relation="dctfiletypes" />
						],
						history: [
							<Record.Field.TableRelation readonly title={'Historia statusów'} showTitle={false} name="workflow" span={{ span: 24 }} fields={[
								{ name: 'from_status', title: 'Poprzedni status' },
								{ name: 'to_status', title: 'Nowy status' },
								{ name: 'updater', title: 'Zaktualizowane przez' },
								{ name: 'change_date', title: 'Data', type: 'date' }
							]} />
						]
					}} tabList={mode.value === 'create' ? [
						{ key: 'general', tab: 'Dane podstawowe' },
						{ key: 'clientdata', tab: 'Dane klienta' },
						// { key: 'agreements', tab: 'Rejestr umów' },
						{ key: 'attachments', tab: 'Załączniki' }
					] : [
						{ key: 'general', tab: 'Dane podstawowe' },
						{ key: 'clientdata', tab: 'Dane klienta' },
						// { key: 'payments', tab: 'Rejestr wpłat' },
						// { key: 'agreements', tab: 'Rejestr umów' },
						{ key: 'attachments', tab: 'Załączniki' },
						{ key: 'history', tab: 'Historia statusów' }
					]} activeTabKey={currentActiveTab} onTabChange={(key) => { setCurrentActiveTab(key) }} forceRender={true} />
				)}
			</Record.Consumer>
		</Record>
	</div>);
};

export default Dashboard_Applications_Infants;
