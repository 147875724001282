import React from 'react';

import Moment from 'react-moment';

import { Row, Col, Card, Form, Select, Button, Alert, Modal, Space, message, DatePicker } from 'antd';

import { ExclamationCircleFilled } from '@ant-design/icons';

import { APIBackend as API } from '../../../api';

import '../../../assets/scss/dashboard.scss';

const { Option } = Select;

const Dashboard_ForceIncomeChange = (props) => {

	const [form] = Form.useForm();
	const [saving, setSaving] = React.useState(false);
	const [relationData, setRelationData] = React.useState(null);
	const [error, setError] = React.useState(false);
	const { confirm } = Modal;

	React.useEffect(() => {
		API.relations(['contractors']).then(r => {
			setRelationData(r);
			setError(false);
		}).catch(err => {
			setError({
				errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED',
				message: err?.response?.data?.message
			});
		});
	}, []);

	const onSave = (values) => {

		setSaving(true);
		const _data = new FormData();

		_data.append('contractor_id', values.contractor_id);
		_data.append('from', values.from.format('YYYY-MM-DD'));

		confirm({
			title: 'Czy na pewno chcesz wykonać tę operację?',
			icon: <ExclamationCircleFilled />,
			content: 'Operacja jest nieodwracalna. Wszystkie profile klientów wybranego kontrahenta zmienią status na archiwalny!',
			onOk() {
				API.setForceIncomeChange(_data).then(r => {
					message.success(<>Wymuszenie aktualizacji dochodów dla kontrahenta zakończona sukcesem.
					</>);
					setError(false);
					setTimeout(() => {
						form.resetFields();
					}, 3000);
				}).catch(err => {
					message.error(<>Wymuszenie aktualizacji dochodów dla kontrahenta zakończona niepowodzeniem.</>);
					setError({
						errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED',
						message: err?.response?.data?.message
					});
				}).finally(() => {
					setSaving(false);
				});
			},
			onCancel() {
				setSaving(false);
			},
		  });
	};

	return (<div className="content">
		<div className="subheader">
			<div className="d-flex">
				<h1 className="title">Wymuś aktualizację dochodów w profilach wybranego kontrahenta</h1>
				<span className="subtitle">
					Dzisiaj jest <Moment locale="pl" format="dddd, D MMMM YYYY" />
				</span>
			</div>

			<div className="subheader_actions">
				<Button type="primary" onClick={() => { form.submit(); }} loading={saving}>Wykonaj</Button>
			</div>
		</div>

		{
			error && <Alert type="error" message={error?.message ?? 'Wystąpił nieoczekiwany błąd podczas zapisywania lub pobierania danych'} style={{ marginBottom: 25 }} />
		}

		<Card loading={false}>
			<Form
				onFinish={onSave}
				layout="vertical"
				form={form}
			>
				<Row gutter={[16,16]}>

					<Col sm={24} md={12}>
						<Form.Item
							label="Od dnia"
							name="from"
							rules={[
								{ required: true, message: 'Proszę wybrać datę' }
							]}
						>
							<DatePicker style={{ width: '100%' }} />
						</Form.Item>
					</Col>

					<Col sm={24} md={12}>
						<Form.Item
							label="Kontrahent"
							name="contractor_id"
							rules={[
								{ required: true, message: 'Proszę wybrać kontrahenta' }
							]}
						>
							<Select
								showSearch
								optionFilterProp="children"
							>
								{relationData?.contractors?.map(el => (
									<Option key={['contractors', el.id]} value={el.id}>
										[{el.symbol}] {el.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>

				</Row>
			</Form>
		</Card>
	</div>);
};

export default Dashboard_ForceIncomeChange;
