import React from 'react';
import PropTypes from 'prop-types';

import Record_Field from './Field';

import DraggerUpload from '../../../components/DraggerUpload';

import { Form, Input } from 'antd';

import { InboxOutlined } from '@ant-design/icons';

import { backendRoot, backendApi } from '../../../config/paths';

import _ from 'lodash';

class RecordFieldAttachments extends Record_Field {
	static propTypes = Object.assign({}, Record_Field.propTypes, {
		fileCategory: PropTypes.string.isRequired
	});

	static defaultProps = Object.assign({}, Record_Field.defaultProps, {

	});

	static displayName = 'RecordFieldAttachments';

	get relation() {
		const {
			relation
		} = this.props;

		if(this.relationData && relation && this.relationData.hasOwnProperty(relation)) {
			return this.relationData[relation] ?? [];
		}

		return [];
	}

	get fileType() {
		return this.relation ? this.relation.find(el => el.name === this.props.fileCategory) ?? null : null;
	}

	constructor(props) {
		super(props);

		this.onAttachmentsUpdate = this.onAttachmentsUpdate.bind(this);
		this.normFile = this.normFile.bind(this);

		this.state = {
			attachments: {
				list: [],
				add: [],
				del: []
			}
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	onRelationDataUpdate() {
		super.onRelationDataUpdate();

		const {
			attachments
		} = this.state;

		const values = {...attachments};

		const rawValue = this.getRawValue();

		if(Array.isArray(rawValue) && this.fileType) {
			rawValue.forEach((el, idx) => {
				if(el.dctfiletype_id === this.fileType?.id) {
					// Check if file already exists in list
					if(values?.list && !values.list?.find?.(_el => _el.uid === el.id)) {
						values.list.push({
							uid: el.id,
							name: el.file_name,
							file: el,
							category: el.dctfiletype_id
						});
					}
				}
			});
		}

		if(!_.isEqual(this.state.attachments, values)) {
			this.setState({
				attachments: values
			});
		}
	}

	onAttachmentsUpdate(data) {
		const {
			name,
			fileCategory
		} = this.props;

		this.setState({
			attachments: data
		});

		if(this.form?.current) {
			this.form.current?.setFieldsValue({
				[`${name}~${fileCategory}`]: data
			});
		}
	}

	normFile(e) {
		if(Array.isArray(e)) {
			return e;
		}

		return e && e.fileList;
	}

	getValue() {
		return (
			<ul className="upload-file-list">
				{this.getRawValue() ? this.getRawValue().filter(file => file.dctfiletype_id === this.fileType?.id).map(file => (
					<li key={_.uniqueId('file_')}>
						<a href={`${backendApi}/storage/app${file.file_path}`}>
							{file.file_name}
						</a>
					</li>
				)) : null}
			</ul>
		);
	}

	renderEdit() {
		const {
			title,
			name,
			disabled,
			fileCategory,
			uploadProps = {}
		} = this.props;

		const {
			attachments
		} = this.state;

		return <>
			<Form.Item
				{...this.globalItemProps}
				getValueFromEvent={this.normFile}
			>
				<DraggerUpload
					id={this.key}
					disabled={disabled}
					data={attachments}
					updateAttachments={this.onAttachmentsUpdate}
					fileType={this.fileType}
					{...uploadProps}
				>
					<p className="ant-upload-drag-icon">
						<InboxOutlined />
					</p>

					<p className="ant-upload-text">Kliknij lub przeciągnij plik do tego obszaru, aby dodać załącznik</p>
					{/*<p className="ant-upload-hint">
						TBA
					</p>*/}
				</DraggerUpload>
			</Form.Item>
			<Form.Item name={`${name}~${fileCategory}`}>

			</Form.Item>
		</>;
	}
}

export default RecordFieldAttachments;
