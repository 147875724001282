import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { useAuth } from './contexts/User';

import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';

import Home from './pages/Home';
import Login from './pages/Login';
import AzureAuth from './pages/AzureAuth';
import Register from './pages/Register';
import Logout from './pages/Logout';
import ForgottenPassword from './pages/ForgottenPassword';
import ResetPassword from './pages/ResetPassword';

const Main = () => {
	const auth = useAuth();

	const removeLoadingSpinner = () => {
		setTimeout(() => {
			const el = document.getElementById('zfss-loading');

			if(el) {
				el.classList.add('hide');
				setTimeout(() => {
					el.outerHTML = '';
				}, 1000);
			}
		}, 1000);
	};

	React.useEffect(() => {
		auth.authenticate().then(data => {}).catch(err => {}).finally(() => {
			removeLoadingSpinner();
		});
	}, []);

	return (
		<>
			<Switch>
				<PublicRoute path="/login" component={Login} />
				<PublicRoute path="/auth/azure" component={AzureAuth} />
				<PublicRoute path="/register" component={Register} />
				<PrivateRoute path="/logout" component={Logout} />
				<PublicRoute path="/reset-password/:token" component={ResetPassword} />
				<PublicRoute path="/forgot-password" component={ForgottenPassword} />
				<PrivateRoute path="/" component={Home} />
			</Switch>
		</>
	);
};

export default Main;
