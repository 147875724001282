import React from 'react';

import Moment from 'react-moment';

import Record from '../../components/_Record';

import { Row, Col, Card, Button } from 'antd';

import '../../assets/scss/dashboard.scss';

const Dashboard_Services = (props) => {
	return (<div className="content">
		<Record
			controller="services"
			defaultMode="list"
			mode={ props.mode || null }
			defaultEditing={ props.editing || null }
			relations={['servicegroups', 'dctunits']}
			columns={[
				{
					name: 'id',
					title: '#',
					type: 'number'
				},
				{
					name: 'name',
					title: 'Nazwa',
					searchable: true
				},
				{
					name: 'servicegroup_name',
					title: 'Grupa usług',
					searchable: true
				},
				{
					name: 'is_active',
					title: 'Aktywne',
					datatype: 'bool'
				},
				{
					name: 'actions',
					data: null,
					sortable: false,
					title: '',
					render: function(data, type, row) {
						return `<a class="btn btn-table" href="/services/${data.id}">Podgląd</a><a class="btn btn-table" href="/services/${data.id}/edit">Edytuj</a>`;
					},
					className: 'text-right'
				}
			]}
			viewTitle="Podgląd usługi"
			listTitle="Lista usług">
			<Record.Field title={'Nazwa'} field="name" />
			<Record.Field.Relation title={'Jednostka miary'} field="dctunit_id" relation="dctunits" />
			<Record.Field.Relation title={'Grupa usług'} field="servicegroup_id" relation="servicegroups" />
			<Record.Field.Switch title={'Aktywne'} field="is_active" />
		</Record>
	</div>);
};

export default Dashboard_Services;
