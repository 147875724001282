import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { RecordContext, RecordProvider } from './context';

import Record from './record';

import Record_Fields from './Fields';

import _ from 'lodash';

const RecordContainer = ({ technicalFields, ...props }) => {
	const _technicalFields = [
		<RecordContainer.Field.Date key={_.uniqueId('techField_')} title="Data ostatniej aktualizacji" name="updated_at" readonly span={{ span: 24 }} format="YYYY-MM-DD HH:mm" />,
		<RecordContainer.Field.Input key={_.uniqueId('techField_')} title="Osoba aktualizująca" name="updater_full_name" readonly span={{ span: 24 }} />,
		<RecordContainer.Field.Date key={_.uniqueId('techField_')} title="Data utworzenia" name="created_at" readonly span={{ span: 24 }} />,
	].concat(technicalFields ?? []);

	return <RecordProvider
		mode={props.mode}
		defaultMode={props.defaultMode}
		withRelations={props?.relations?.length ? true : false}
		editing={props?.defaultEditing ?? null}
	>
		<Record technicalFields={_technicalFields} {...props} />
	</RecordProvider>;
};

RecordContainer.Field = Record_Fields;
RecordContainer.Consumer = RecordContext.Consumer;

export default RecordContainer;
