import React, { useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import styled from 'styled-components';

import axios from 'axios';

import { APIBackend as API } from '../api';
import { useAuth } from '../contexts/User';

import prepareErrorMessage from '../libs/ErrorHelper.js';

import { Form, Input, Checkbox, Button, Row, Col, Card, Alert, message } from 'antd';

import '../assets/scss/login.scss';

const AuthAzure = (props) => {
    const [isError, setError] = useState(false);
	const history = useHistory();

	const auth = useAuth();

	const search = props.location.search;
	const query = new URLSearchParams(search);
	const code = query.get('code');

	React.useEffect(() => {
		if(code) {
			auth.azureAuth(code).then(r => {
				const {
					data
				} = r;

				localStorage.setItem('token', data.user.id);
				localStorage.setItem('adToken', data.access_token);
				auth.setToken(data.user.id);
				auth.setAdToken(data.access_token);
				auth.setUser(data.user);
				message.success('Zostałeś/łaś pomyślnie zalogowany');
				history.push('/');
			}).catch(err => {
				message.error('Wystąpił błąd podczas logowania');
				history.push('/login');
			});
		} else {
			window.location.href = '/';
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (<main className="main h-100 w-100">
		<div className="container h-100">
			<Row className="h-100">
				<Col sm={20} md={16} lg={12} className="d-table mx-auto h-100">
					<div className="d-table-cell align-middle login-pane">
						{ isError && <Alert message={prepareErrorMessage(isError)} type="error" style={{ marginBottom: '15px' }} /> }
						<Card title={<><div className="login-card-title">Logowanie</div><p className="login-card-subtitle">Logowanie przez Microsoft Azure</p></>} bordered={false} className="login-card">
							<p>Trwa logowanie, proszę czekać...</p>
						</Card>
					</div>
				</Col>
			</Row>
		</div>
	</main>);
};

export default AuthAzure;
