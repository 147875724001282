import React from 'react';
import { useHistory } from 'react-router-dom';

import { Row, Col, Dropdown, Menu } from 'antd';

import { useAuth, PERMISSIONS as USER_PERMISSIONS } from '../../contexts/User';
import { backendRoot, backendApi, csrfCookie, apiData } from '../../config/paths';
import axios from 'axios';

import '../../assets/scss/dashboard.scss';

import logo from '../../assets/images/logos/impel_group_logo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { faBell } from '@fortawesome/free-regular-svg-icons'

import { BellOutlined, DownOutlined } from '@ant-design/icons';

import ProfileImage from '../../components/ProfileImage';

const Dashboard_Header = () => {
	const auth = useAuth();
	const history = useHistory();

	const Notifications = (<>
		Powiadomienia
	</>);

	const onLogout = () => {
		auth.logout().then(() => {
			history.push('/login');
		}).catch(err => console.log(err));
	};

	const onPoligon = () => {
		axios.get(backendRoot + csrfCookie).then(() => {
			axios.get(`${backendApi}/poligon`).then((response) => {
				console.log(response);
			}).catch((err) => {
				const error = {
					errcode: err?.response?.data?.errcode,
					message: err?.response?.data?.message,
					response: err?.response,
				};
				console.log(error);
			});
		}).catch((err) => {
			const error = {
				errcode: err?.response?.data?.errcode,
				message: err?.response?.data?.message,
				response: err?.response,
			};
			console.log(error);
		});
	};

	const onLogs = () => {
		window.open('https://api.socialpartner.pl/laravel_9549gfk94kgfks4.log', '_blank');
	};

	const Profile = (
		<Menu>
			<Menu.Item onClick={onLogout} key="logout">
				Wyloguj się
			</Menu.Item>

			{
				[1, 2, 3].includes(auth.user?.id) && auth.can(USER_PERMISSIONS.ADMIN)
					? <>
						<Menu.Item onClick={onPoligon} key="sandbox">
							Sandbox
						</Menu.Item>

						<Menu.Item onClick={onLogs} key="logi">
							Logi
						</Menu.Item>
					</>
					: null
			}
		</Menu>
	);

	return (<>
		<div className="d-flex h-100">
			<div className="logo">
				<a href="/">
					<img src={logo} alt="logo" />
				</a>
			</div>

			{/*<div className="header__actions ml-auto">
				<Dropdown overlay={Notifications}>
					<a href="#" className="header__notification_wrapper" onClick={e => e.preventDefault()}>
						<FontAwesomeIcon icon={faBell} style={{ fontSize: '16px' }} />
						<span className="indicator"></span>
					</a>
				</Dropdown>
			</div>*/}

			{ auth?.user?.first_name ?
			<div className="header__userPane ml-auto">
				<Dropdown overlay={Profile}>
					<a href="#" onClick={e => e.preventDefault()} className="d-flex h-100">
						<ProfileImage name={ auth.user.first_name+' '+auth.user.last_name } size="34" />
						<span className="d-inline-block">
							<h4 className="username my-0">{ auth.user.first_name+' '+auth.user.last_name }</h4>
							<span className="d-block text-muted">{ auth.user.permission_name }</span>
						</span>
						<FontAwesomeIcon icon={faAngleDown} />
					</a>
				</Dropdown>
			</div>
			: null }
		</div>
	</>);
};

export default Dashboard_Header;
