import React from 'react';
import PropTypes from 'prop-types';

import Record_Field from './Field';

import { Form, Input } from 'antd';

import { assignDataToString } from '../../../libs/Helpers.js';

class RecordFieldRelatedField extends Record_Field {
	static propTypes = Object.assign({}, Record_Field.propTypes, {
		relation: PropTypes.string.isRequired,
		labelFormat: PropTypes.string.isRequired,
		filter: PropTypes.func,
		separator: PropTypes.string,
		idKey: PropTypes.string
	});

	static defaultProps = Object.assign({}, Record_Field.defaultProps, {
		readonly: true,
		separator: ', ',
		idKey: 'id'
	});

	static displayName = 'RecordFieldRelatedField';

	get relation() {
		const {
			relation
		} = this.props;

		if(this.relationData && relation && this.relationData.hasOwnProperty(relation)) {
			return this.relationData[relation] ?? [];
		}

		return [];
	}

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	getValue() {
		const {
			filter,
			labelFormat,
			relation,
			separator,
			idKey
		} = this.props;

		let _data = this.relation;

		if(typeof filter === 'function') {
			_data = filter(_data, this.data, this.relationData);
		}

		_data = _data.sort((a, b) => a[idKey] - b[idKey]);

		return _data.map(el => assignDataToString(labelFormat, el, '(brak)')).join(separator);
	}

	renderEdit() {
		const {
			title,
			name,
			disabled
		} = this.props;

		return <Form.Item
			{...this.globalItemProps}
		>
			<Input autoComplete="nope" disabled={disabled} />
		</Form.Item>;
	}
}

export default RecordFieldRelatedField;
