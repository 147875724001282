import React from 'react';
import { Route, Switch, Link, Redirect } from 'react-router-dom';
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

import axios from 'axios';

import PrivateRoute from '../components/PrivateRoute';
import PermissionRoute from '../components/PermissionRoute';
import { RecordRoute, RecordSubRoutes } from '../components/RecordRoute';

import { useAuth, PERMISSIONS as USER_PERMISSIONS } from '../contexts/User';

import Dashboard_Header from './dashboard/Header';

import Dashboard_NotFound from './dashboard/NotFound';

// Dashboard page
import Dashboard_Home from './dashboard/Home';
import Dashboard_Tests from './dashboard/Tests';

// Configuration page
import Dashboard_Config from './dashboard/Config';

// Tax codes
import Dashboard_TaxCodes from './dashboard/TaxCodes';

// Reports page
import Dashboard_Reports from './dashboard/Reports';
import Dashboard_Reports_Bank from './dashboard/Reports/bank';

// Fill payment date in apps page
import Dashboard_FillPaymentDate from './dashboard/FillPaymentDate';

// Mass change visible to commission state in apps/profiles
import Dashboard_MassSetVisible from './dashboard/MassSetVisible';

// Force users to income change in apps/profiles
import Dashboard_ForceIncomeChange from './dashboard/ForceIncomeChange';

// Create inherited profiles
import Dashboard_InheritedProfile from './dashboard/InheritedProfile';

// Basic pages with users, clients and imported clients data
import Dashboard_Users from './dashboard/Users';
import Dashboard_Clients from './dashboard/Clients';
import Dashboard_Pesels from './dashboard/Pesels';

// Notices for users
import Dashboard_Notices from './dashboard/Notices';

// Page with list of dictionaries
import Dashboard_Dictionaries from './dashboard/Dictionaries';

// Page with list of objects
import Dashboard_Objects from './dashboard/Objects';

// Dictionary page with list of services used in offers
import Dashboard_Services from './dashboard/Services';

// Pages connected with users and clients
import Dashboard_Contractors from './dashboard/Contractors';
import Dashboard_Profiles from './dashboard/Profiles';

// Offers
import Dashboard_Offers_Camps from './dashboard/Offers/Camps';
import Dashboard_Offers_Trips from './dashboard/Offers/Trips';
import Dashboard_Offers_Ikos from './dashboard/Offers/Ikos';
import Dashboard_Offers_Iros from './dashboard/Offers/Iros';
import Dashboard_Offers_Recreations from './dashboard/Offers/Recreations';
import Dashboard_Offers_Multisports from './dashboard/Offers/Multisports';

// Applications
import Dashboard_Applications_Trips from './dashboard/Applications/Trips';
import Dashboard_Applications_Reftrips from './dashboard/Applications/Reftrips';
import Dashboard_Applications_Camps from './dashboard/Applications/Camps';
import Dashboard_Applications_Refcamps from './dashboard/Applications/Refcamps';
import Dashboard_Applications_Tourists from './dashboard/Applications/Tourists';
import Dashboard_Applications_Reftourists from './dashboard/Applications/Reftourists';
import Dashboard_Applications_Pears from './dashboard/Applications/Pears';
import Dashboard_Applications_Grants from './dashboard/Applications/Grants';
import Dashboard_Applications_Loans from './dashboard/Applications/Loans';
import Dashboard_Applications_Rehabs from './dashboard/Applications/Rehabs';
import Dashboard_Applications_Tickets from './dashboard/Applications/Tickets';
import Dashboard_Applications_Extras from './dashboard/Applications/Extras';
import Dashboard_Applications_Multisports from './dashboard/Applications/Multisports';
import Dashboard_Applications_Infants from './dashboard/Applications/Infants';

// Regulations
import Dashboard_Regulations_General_old from './dashboard/Regulations/_General';
import Dashboard_Regulations_General_list from './dashboard/Regulations/General/index';
import Dashboard_Regulations_General_view from './dashboard/Regulations/General/view';
import Dashboard_Regulations_General_create from './dashboard/Regulations/General/create';
import Dashboard_Regulations_General_edit from './dashboard/Regulations/General/edit';

import { Row, Col, Layout, Button, Drawer, Modal, Affix } from 'antd';

import Menu from '../components/Menu/Menu';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faUsers, faBriefcase, faAddressCard, faAddressBook, faBook, faBars, faFileAlt, faChartBar, faTags, faUserCog, faUniversity, faTools, faCogs, faMoneyCheck, faCalendarCheck, faClipboardList, faMoneyCheckAlt, faIdCard, faEnvelope, faCheckDouble } from '@fortawesome/free-solid-svg-icons';

import logo_si from '../assets/images/logos/logo_si_kolor.svg';

import '../assets/scss/dashboard.scss';

const { Header, Footer, Content } = Layout;

const Home = () => {
	const [isVisible, setVisible] = React.useState(false);
	const [changelog, setChangelog] = React.useState(null);
	const [isChangelogVisible, setChangelogVisible] = React.useState(false);
	const auth = useAuth();

	React.useEffect(() => {
		// In the future changelog will be loading here
		setChangelog(`# CHANGELOG

Wszystkie znaczące zmiany w projekcie zostaną udokumentowane w tym miejscu.

**Legenda:**

\`FE\` - FRONTEND

\`BE\` - BACKEND

\`GE\` - GENERAL

\`ACP\` - Wersja administracyjna

\`UCP\` - Wersja kliencka

\`PL\` - Piotr Listosz

\`KK\` - Kacper Komorowski

## 2022-09-28
- \`GE\` Dodano system zawiadomień dla klientów. Dwie wersje: komunikat w oknie logowania, oraz komunikaty po zalogowaniu.

## 2021-06-23

### Added
- \`[UCP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Umożliwiono składanie wniosku "Zajęcia rekreacyjne"
- \`[UCP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Umożliwiono składanie wniosku "Świadczenia dodatkowe"

## 2021-06-08 - [ACP v2.4.128]

### Fixed
- \`[UCP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Naprawiono tworzenie oraz edycję regulaminów

## 2021-06-07 - [ACP v2.4.127] [UCP v0.2.1]

### Added
- \`[UCP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano samouczek po zalogowaniu, podczas tworzenia profilu oraz na liście profili (obecnie pokazuje się raz, jeśli kliknie się "Rozumiem!" na liście profili to samouczek nie pokaże się przez kolejne 30 dni - zostanie to jeszcze zmienione)

### Changed
- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Podzielono pola na proponowane i przyznane we wniosku o pożyczki

### Fixed
- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Naprawiono pole "Nr rachunku organizatora" na wniosku "Wypoczynek dzieci - refundacja"

## 2021-05-26 - [ACP v2.4.123]

### Added
- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano walidację pól "Termin od/do" we wnioskach (nie można wybrać późniejszej daty początku terminu od daty końca terminu i odwrotnie)

### Fixed
- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Naprawiono filtr "Rodzaj wycieczki" w ofertach wyjazdów
- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Poprawiono pobieranie kart Multisport podczas tworzenia wniosku

## 2021-05-25 - [ACP v2.4.12]

### Added
- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano pole "Pozycja cennika" do wniosków:
	- Wypoczynek dzieci (dofinansowanie i refundacja)
	- Świadczenia TUR-REK (dofinansowanie i refundacja)

### Changed
- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Na wniosku o bilety i karnety zmieniono etykietkę pola "Cena rzeczywista" na "Wartość rzeczywista"
- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Zmieniono widok zakładki "Rodzina" w profilu oraz dodano pola "Stopień edukacji", "Stopień niepełnosprawności", "Czy rodzic zmarł?", "Edukacja", "Niepełnosprawność"

### Fixed
- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Zoptymalizowano listę regulaminów
- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] We wniosku o zajęcia rekreacyjne naprawiono walidację pola "Wysokość dofinansowania"


## 2021-05-23 - [ACP v2.3.96] [UCP v0.1.28]

### Added
- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano pole "Typ" w słowniku kart Multisport
- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano możliwość masowego ustawienia daty wypłaty świadczenia
- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano pole "Numer konta bankowego" w konfiguracji systemu
- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano raport przelewów bankowych dostępny pod pozycją w menu "Administracja"
- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano pole "Dochody (ręcznie)" umożliwiające nadpisanie średnich dochodów klienta

- \`[UCP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano pozostałe kafelki na pulpicie (połączone z regulaminami)

### Changed
- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Zmieniono oraz poprawiono uczestników we wnioskach (Wyjazdy, Bilety i karnety oraz Świadczenia Multisport)
- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Zmieniono format wartości w polu "Okres płatności" we wnioskach o przedszkole/żłobek
- \`[UCP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Jeśli kontrahent nie ma wgranego logotypu pokazuje się jego nazwa

- \`[UCP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Zmieniono docelowy widok po zapisie profilu (zależnie od tego czy został użyty w jakimś wniosku i/lub jest aktywny czy nie)

### Fixed
- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Wyświetlanie pola "Rajd" w regułach wyjazdów

## 2021-05-21 - [ACP v2.3.955]

### Changed
- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Zmieniono nazwę w menu Administracja z Baza klientów na Baza uprawnionych

### Fixed
- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Naprawiono ilości biletów w \`Bilety i karnety\` (zostały przeniesione do uczestników)

## 2021-05-01 - [2.3.94]

### Added

- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano walidację oraz format wartości pola \`Okres płatności\` na zakładce \`Rejestr wpłat\` we wnioskach o przedszkole lub żłobek

- \`[UCP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano wstępny widok strony startowej

### Changed

- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Zmieniono obsługę uczestników we wnioskach o wyjazdy (dofinansowanie oraz refundacja) oraz bilety i karnety - dodano możliwość wybrania pozycji z cennika dla konkretnego uczestnika


### Removed

- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Usunięto uczestników z wniosków tur-rek

### Fixed

- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Poprawiono pole uczestnika w przypadku wniosków o wypoczynek dzieci

## 2021-04-27 - [2.3.93]

### Added

- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Do klienta dodano pole \`Numer komórki organizacyjnej\`

### Changed

- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Na wniosku pożyczki zmieniono pole \`Nr komórki organizacyjnej\` (nie pokazuje już błędnej wartości, czyli symbolu kontrahenta)
- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Na wniosku pożyczki umożliwiono edycję pola \`Ilość rat\`
- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Na wniosku pożyczki poprawino etykietę pola

- \`[UCP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Poprawiono kod aplikacji oraz umożliwiono logowanie do systemu

### Fixed

- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Poprawiono działanie pola wyboru uczestników we wnioskach

## 2021-04-26 - [2.3.92]

### Added

- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano walidację większości pól podczas edycji regulaminu

## 2021-04-22 - [2.3.9]

### Added

- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano możliwość anulowania wniosku, jeśli ma status: \`Roboczy\`, \`W akceptacji\` lub \`Akceptowany\`. Wniosek może anulować klient, jeżeli status wniosku to \`Roboczy\`. W innym razie wniosek może anulować operator systemu
- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano brakujące pola \`Cena wyjazdu\` we wnioskach

### Changed

- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Umożliwiono edycję pól z ceną we wnioskach

### Fixed

- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Poprawiono błąd z polem \`Uczestnicy\`

## 2021-04-17 - [2.3.8]

### Added

- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Jeśli jest jeden kontrahent/oddział dla danego klienta/kontrahenta wtedy pole uzupełnia się automatycznie (Klienci)
- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] PESEL i numer kontaktowy uzupełnia się automatycznie podczas tworzenia nowego klienta
- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano komunikaty zwrotne dla przycisków akcji (np. zmiana statusu wniosku, nadanie numeru wniosku)
- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Dane powinny się od teraz odświeżać po wykonaniu akcji, np. zmianie statusu wniosku, nadaniu numeru wniosku)

### Changed

- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Zmieniono etykietkę dla pola na liście rodziny klienta

### Fixed

- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Naprawiono pole Telefon kontaktowy w Użytkownikach (Klientach)
- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Naprawiono wymiar etatu na zakładce kontrahentów w Użytkownikach (klientach)
- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Poprawiono filtrowanie po imieniu oraz nazwisku na liście klientów
- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Poprawiono domyślną wartość dla pól (m.in. data złożenia wniosku już poprawnie ustawia się domyślnie na bieżącą datę)
- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Wprowadzone dane podczas edycji nie powinny już pozostawać wizualnie po anulowaniu edycji (dane się odświeżają)

### Removed

- \`[ACP]\` \`[FE]\` [[KK](https://github.com/SilentHillft)] Usunięto przycisk "Skopiuj do edycji" na liście klientów

## 2021-04-14 - [2.3.73]

**UWAGA:** *Wszystkie poniższe wpisy dotyczą \`[ACP]\` (wersji administracyjnej)*

### Added

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Uzupełniono komunikaty o błędach podczas obliczania dofinansowania we wnioskach

### Changed

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Udostępniono możliwość edycji profilu - edytowany profil zostanie zarchiwizowany, a na jego miejsce trafi wyedytowany
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Rozdzielono pole "Dojazd własny/pobyt" w regułach Wycieczek i wyjazdów z dojazdem własnym
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Po zapisaniu rekordu przenosi do podglądu, przez co wizualnie dane się aktualizują
- \`[GE]\` [[KK](https://github.com/SilentHillft)] Zaktualizowano legendę w changelogu

### Fixed

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Naprawiono przyciski akcji we wnioskach

## 2021-04-12 - [2.3.7]

### Added

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano pole wyboru \`Dojazd własny/Pobyt\` w regułach Wyjazdów i wczasów z dojazdem własnym

### Changed

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Zmieniono etykiety w regułach Wypoczynku dzieci i młodzieży dot. dzieci niepełnosprawnych stopnia znacznego
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Rozbito przedszkola i żłobki w kontrahentach, regulaminach oraz wnioskach


## 2021-04-11 - [2.3.6]

### Changed

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Zoptymalizowano widok regulaminów

## 2021-03-31 - [2.3.5]

### Added

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano pole \`Minimalny procent odpłatności\` w regulaminach w \`Świadczenia tur-rek i zajęcia rekreacyjne\`
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano pole \`Rodzaj imprezy\` w regułach biletów i karnetów

### Fixed

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Naprawiono załączniki

## 2021-03-31 - [2.3.4]

### Added

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano zakładkę \`Progi dochodowe\` w regułach pożyczek

### Changed

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Zmieniono nazwę słownika \`Rodzaje obozów\` na \`Rodzaje wypoczynku dzieci\`

### Fixed

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Naprawiono pole RecordFieldAccordion (rozdzielenie na pracownika i emeryta)

### Removed

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Usunięto zakładkę \`Słownik\` w regułach pożyczek

## 2021-03-30 - [2.3.3]

### Added

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Komunikat w profilach, jeśli profil ma status \`Aktywny\`

### Changed

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Zmieniono rodzaj niektórych pól w regulaminach na numeryczne

### Fixed

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Naprawiono pole techniczne w profilach \`Status profilu\`

## 2021-03-29 - [2.3.2]

### Added

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano możliwość kopiowania regulaminów

### Fixed

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Zoptymalizowano widok regulaminów

## 2021-03-28 - [2.3.1]

### Added

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano \`Przedszkola i żłobki\` do kontrahentów
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano w regule \`Bilety i karnety\` pole "Kwota dofinansowania dla emeryta/pracownika"

### Changed

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Rozdzielono zakładki \`Kwotowe limity świadczeń\` oraz \`Ilościowe limity świadczeń\` w regułach ogólnych na pracownika oraz emeryta
- \`[FE]\` [[KK](https://github.com/SilentHillft)] W \`Wycieczki i wczasy z dojazdem własnym\` zmieniono pola dot. maksymalnego dofinansowania - usunięte zostały pola "Za osobę za wycieczkę" oraz "Maksymalne dofinansowanie" zostało rozdzielone na pracownika i dziecko
- \`[FE]\` [[KK](https://github.com/SilentHillft)] W regule \`Wycieczki i wczasy z dojazdem własnym\` dodano pola "Ilość dni od" oraz "Ilość dni do"
- \`[FE]\` [[KK](https://github.com/SilentHillft)] W regule \`Świadczenia dodatkowe\` zmieniono zakładkę \`Definicja okresów\` - od teraz jest lista wyboru świadczeń oraz okres od/do

### Removed

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Usunięto \`Przedszkola i żłobki\` z regulaminów (słownik) oraz listy słowników

## 2021-03-23 - [2.3.0]

### Added

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano wniosek \`Przedszkola i żłobki\`
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano numery wniosków w tytułach podstron we wnioskach
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano pozostałe reguły do regulaminów

### Changed

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Terminy we wnioskach uzupełniają się po wybranej ofercie (jeśli termin nie jest uzupełniony)
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Podczas obliczania wniosku i wystąpienia błędu, zamiast ogólnego błędu pokazuje się informacja zwrotna (np. jakich wymagań regulaminu nie spełnia wniosek)

### Fixed

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Poprawiony został przycisk "Nadaj numer" (pokazuje sie tylko jeśli numer nie został nadany oraz jeśli status wniosku jest równy "W akceptacji"

## 2021-03-16 - [0.0.0]

### Added

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano \`Rejestr wpłat\` we wnioskach
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano słownik \`Metody płatności\`

### Changed

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Zmieniono pola we wnioskach
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Menu oraz przyciski razem z tytułem są od teraz przyklejone do górnej części ekranu
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Zmieniono tytuły podstron wniosków
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Po wybraniu klienta z listy wyboru we wnioskach, dane na zakładce "Dane klienta" aktualizują się

### Fixed

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Naprawiono parę drobnych błędów

## 2021-03-08 - [0.0.0]

### Changed

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Zmieniono tytuły podstron wniosków
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Rozdzielono pola pracownika oraz emeryta w regułach RG_08 oraz RG_09

### Fixed

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Poprawiono pole \`Uczestnicy\` we wnioskach

## 2021-03-07 - [0.0.0]

### Changed

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Zmieniono tytuł podstron z "Lista wniosków ..." na nazwę wniosku, np. "Wyjazdy - dofinansowanie"
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Zdjęto ograniczenia z pola \`Rodzaj wyjazdu\`
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Na liście profili w \`Użytkownicy - Klienci\` zmieniono kolumnę \`Numer telefonu\` na \`Kontrahent\`
- \`[FE]\` [[KK](https://github.com/SilentHillft)] W polu \`Klient\` w profilach pokazują się jedynie użytkownicy-klienci
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Pola we wnioskach zostały wstępnie ułożone według ustalonej kolejności

### Fixed

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Poprawiono pole \`Rodzaj klienta\` w profilach

## 2021-03-04 - [0.0.0]

### Added

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano pola na zakładce \`Dochody\` w profilu klienta:
	- Średnie dochody (tylko do odczytu)
	- Data ostatniej zmiany dochodów (tylko do odczytu)

### Changed

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Zmieniono podstronę \`Klienci\` na listę profili
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Zmieniono nazwę pola \`Liczba dni roboczych\` na \`Ważność wniosku w dniach\` w regułach \`Bilety i karnety\`
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Stworzono podział na pracownika i emeryta w regułach \`Wycieczki i wczasy z dojazdem własnym\`
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Ograniczono ilość wierszy w regułach:
	- Terminy ważności dochodów
	- Dostępne świadczenia
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Zmieniono sposób wyświetlania reguł \`Wypoczynek dzieci i młodzieży\` - dodano zakładki:
	- Obozy kolonie
	- Zielona szkoła
	- Wypoczynek specjalistyczny

## 2021-03-01 - [0.0.0]

### Added

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano w regulaminach:
	- Obsługę reguł ogólnych (RG_04, RG_05, RG_11, RG_12, RG_30)
	- Obsługę wycieczek i wczasów z dojazdem własnym (RG_07, RG_16)
	- Obsługę wczasów pod gruszą (RG_06)
	- Obsługę biletów i karnetów (RG_08, RG_18)
	- Obsługę wypoczynku dzieci i młodzieży (RG_17)
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano nowe słowniki:
	- Stopnie edukacji
	- Stopnie niepełnosprawności
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano możliwość wyszukiwania po kolumnie \`Wnioskujący\` na listach wniosków
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano kolumnę \`Data ostatniej zmiany\` na liście profili
- \`[FE]\` [[KK](https://github.com/SilentHillft)] We wnioskach można wyszukiwać w polu \`Oferta\`
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano odpowiednie reguły sprawdzania pól we wnioskach \`Świadczenia tur-rek - dofinansowanie\`

### Changed

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Zmieniono we wnioskach nazwę pola \`Data zapłaty\` na \`Data wypłaty świadczenia\`
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Zmieniono kolumnę \`Adres e-mail\` na \`Numer ewidencyjny\` na liście klientów
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Poprawiono nazwę pola \`infant\` na \`Przedszkole i żłobki\` w regulaminach
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Pole \`Data złożenia wniosku\` przy wnioskach domyślnie ma bieżącą datę
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Nazwa pola \`Terminy od/do\` we wniosku \`Wczasy pod gruszą\` została zmieniona na \`Urlop od/do\`
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Po wybraniu rodzaju wyjazdu we wnioskach o dofinansowanie wyjazdu, pokazują się jedynie te oferty, które dotyczą wybranego rodzaju wyjazdu

### Fixed

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Naprawiono obsługę słownika \`Tytuły prawne\`
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Naprawiono listę aktywnych i historycznych profili na zakładce \`Profile\` w klientach
- \`[BE]\` [[PL](https://github.com/plistosz)] Poprawiono filtr \`Kontrahent\` na liście klientów

### Removed

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Usunięto słowniki:
	- Rodzaje zajęć rekreacyjnych
	- Typy zabiegów

## 2021-02-25 - [0.0.0]

### Changed

- \`[FE]\` [[KK](https://github.com/SilentHillft)] W profilach można wyszukiwać w polu \`Klient\`
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Pole wyboru kontrahenta w profilach pokazuje jedynie tych kontrahentów, których klient ma przypisanych
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Pole \`Oddział\` jest wymagane
- \`[FE]\` [[KK](https://github.com/SilentHillft)] We wnioskach można wyszukiwać w polu \`Wnioskujący\`
- \`[FE]\` [[KK](https://github.com/SilentHillft)] We wnioskach w polu \`Wnioskujący\` nazwy są według formatu: \`[Nr ewidencyjny] Imię i nazwisko - Symbol kontrahenta\`

### Fixed

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Wyłączono sprawdzanie wymagań w polu \`Kwota dofinansowania\`, jeśli pole \`Obliczona kwota dofinansowania\` jest puste lub wartość jest nieprawidłowa

## 2021-02-24 - [0.0.0]

### Added

- \`[GE]\` [[KK](https://github.com/SilentHillft)] Dodano plik \`CHANGELOG.md\` zawierający historię zmian w projekcie oraz \`changelog-template.md\` zawierający szablon zawartości pliku \`CHANGELOG.md\`

### Changed

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Zmieniono nazwy w polach wyboru oferty we wnioskach:
- Wypoczynek dzieci - dofinansowanie
- Wyjazdy - dofinansowanie
- Bilety i karnety
- Świadczenia tur-rek - dofinansowanie
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Wyłączono możliwość edycji wartości pól we wniosku o dofinansowanie wycieczki:
- Kwota dofinansowania dla jednej osoby

## 2021-02-23 - [0.0.0]

### Added

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano nowe pola do wniosku o dofinansowanie wycieczki:
- Wartość dofinansowania dla wycieczki *(tylko do odczytu)*
- Kwota dofinansowania *(wartość nie może być większa, niż wartość z pola "Obliczona kwota dofinansowania")*
- Obliczona kwota dofinansowania *(tylko do odczytu)*
- Rzeczywista kwota do zapłaty *(tylko do odczytu)*
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Wprowadzono wstępny widok regulaminów
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano przycisk \`Nadaj numer\` we wniosku o dofinansowanie wycieczki *(status wniosku musi być jako "W akceptacji" i wniosek nie może posiadać nadanego numeru)*
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano przycisk \`Oblicz dofinansowanie\`
- \`[FE]\` [[KK](https://github.com/SilentHillft)] W profilach dodano pole \`Numer ewidencyjny\`
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Na liście klientów od teraz każdy przypisany do klienta kontrahent pokazuje się jako osobny wiersz
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano filtry w liście profili:
- Status profilu
- Numer dokumentu
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Dodano kolumnę \`Status profilu\` na liście profili

### Changed

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Wyłączono możliwość edycji wartości pól we wniosku o dofinansowanie wycieczki:
- Kwota podlegająca dofinansowaniu
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Zmieniono nazewnictwo pól w celu jego ujednolicenia
- \`[FE]\` [[KK](https://github.com/SilentHillft)] Na liście klientów zamiast kolumny \`Numer kontaktowy\` pojawiła się kolumna \`Kontrahent\`

### Fixed

- \`[FE]\` [[KK](https://github.com/SilentHillft)] Naprawiono błąd związany z polem wyboru daty w przypadku niektórych modułów`);
	}, []);

	return (<>
		<Layout>
			<Header>
				<div className="container h-100">
					<Dashboard_Header />
				</div>
			</Header>

			<Affix offsetTop={0}>
				<div className="navigation">
					<div className="navbar">
						<div className="container h-100" style={{ display: 'flex', alignItems: 'center' }}>
							<Button
								type="primary"
						        className="menu-btn"
						        icon={<FontAwesomeIcon icon={faBars}
								onClick={ () => { setVisible(true) } } />}
						      />

							<Menu isVisible={ isVisible } setVisible={ setVisible }>
								{
									auth.can(USER_PERMISSIONS.COMMITTEE)
										? <>
										<Menu.Item to="/profiles" icon={<FontAwesomeIcon icon={faAddressBook} />}>
											Klienci
										</Menu.Item>
										</>
										: null
								}

								{
									auth.can(USER_PERMISSIONS.COMMITTEE)
										? <>
										<Menu.Item submenu={true} title="Wnioski" icon={<FontAwesomeIcon icon={faFileAlt} />}>
											<Menu.Submenu>
												<Menu.Item to="/apptrips" badge={auth.unseenApps?.apptrips ?? null}>
													Wyjazdy - dofinansowanie
												</Menu.Item>
												<Menu.Item to="/appreftrips" badge={auth.unseenApps?.appreftrips ?? null}>
													Wyjazdy - refundacja
												</Menu.Item>
												<Menu.Item to="/appcamps" badge={auth.unseenApps?.appcamps ?? null}>
													Wypoczynek dzieci - dofinansowanie
												</Menu.Item>
												<Menu.Item to="/apprefcamps" badge={auth.unseenApps?.apprefcamps ?? null}>
													Wypoczynek dzieci - refundacja
												</Menu.Item>
												<Menu.Item to="/apptourists" badge={auth.unseenApps?.apptourists ?? null}>
													Świadczenia tur-rek - dofinansowanie
												</Menu.Item>
												<Menu.Item to="/appreftourists" badge={auth.unseenApps?.appreftourists ?? null}>
													Świadczenia tur-rek - refundacja
												</Menu.Item>
												<Menu.Item to="/apppears" badge={auth.unseenApps?.apppears ?? null}>
													Wczasy pod gruszą
												</Menu.Item>
												<Menu.Item to="/appgrants" badge={auth.unseenApps?.appgrants ?? null}>
													Zapomogi
												</Menu.Item>
												<Menu.Item to="/apploans" badge={auth.unseenApps?.apploans ?? null}>
													Pożyczki
												</Menu.Item>
												<Menu.Item to="/apprehabs" badge={auth.unseenApps?.apprehabs ?? null}>
													Zajęcia rekreacyjne
												</Menu.Item>
												<Menu.Item to="/apptickets" badge={auth.unseenApps?.apptickets ?? null}>
													Bilety i karnety
												</Menu.Item>
												<Menu.Item to="/appmultis" badge={auth.unseenApps?.appmultis ?? null}>
													Karty Sportowe
												</Menu.Item>
												<Menu.Item to="/appinfants" badge={auth.unseenApps?.appinfants ?? null}>
													Przedszkola i żłobki
												</Menu.Item>
												<Menu.Item to="/appextras" badge={auth.unseenApps?.appextras ?? null}>
													Świadczenia dodatkowe
												</Menu.Item>
											</Menu.Submenu>
										</Menu.Item>
										</>
										: null
								}

								{
									auth.can(USER_PERMISSIONS.OPERATOR)
										? <>
										<Menu.Item submenu={true} title="Oferty" icon={<FontAwesomeIcon icon={faTags} />}>
											<Menu.Submenu>
												<Menu.Item to="/trips">
													Wyjazdy
												</Menu.Item>
												<Menu.Item to="/camps">
													Wypoczynek dzieci
												</Menu.Item>
												<Menu.Item to="/ikos">
													Imprezy Kulturalno-Oświatowe
												</Menu.Item>
												<Menu.Item to="/iros">
													Imprezy Rekreacyjno-Oświatowe
												</Menu.Item>
												<Menu.Item to="/tourists">
													Tur-rek
												</Menu.Item>
											</Menu.Submenu>
										</Menu.Item>
										</>
										: null
								}

								{
									auth.can(USER_PERMISSIONS.OPERATOR)
										? <>
										<Menu.Item submenu={true} title="Raporty" icon={<FontAwesomeIcon icon={faChartBar} />}>
											<Menu.Submenu>
												<Menu.Item to="/reports" icon={<FontAwesomeIcon icon={faFileAlt} />}>
													Raporty ogólne
												</Menu.Item>

												<Menu.Item to="/bankreport" icon={<FontAwesomeIcon icon={faMoneyCheck} />}>
													Przelewy bankowe
												</Menu.Item>
											</Menu.Submenu>
										</Menu.Item>

										<Menu.Item submenu={true} title="Administracja" icon={<FontAwesomeIcon icon={faTools} />}>
											<Menu.Submenu>
												<Menu.Item to="/clients" icon={<FontAwesomeIcon icon={faAddressCard} />}>
													Użytkownicy - Klienci
												</Menu.Item>

												{
													auth.can(USER_PERMISSIONS.ADMIN)
														? <>
															<Menu.Item to="/users" icon={<FontAwesomeIcon icon={faUsers} />}>
																Użytkownicy
															</Menu.Item>

															<Menu.Item to="/contractors" icon={<FontAwesomeIcon icon={faBriefcase} />}>
																Kontrahenci
															</Menu.Item>

															<Menu.Item to="/regulations" icon={<FontAwesomeIcon icon={faFileAlt} />}>
																Regulaminy
															</Menu.Item>

                                                            <Menu.Item to="/notices" icon={<FontAwesomeIcon icon={faEnvelope} />}>
                                                                Zawiadomienia systemu
                                                            </Menu.Item>

														</>
														: null
												}

												{
													auth.can(USER_PERMISSIONS.OPERATOR)
														? <>
															<Menu.Item to="/dictionaries" icon={<FontAwesomeIcon icon={faBook} />}>
																Słowniki
															</Menu.Item>

															<Menu.Item to="/pesels" icon={<FontAwesomeIcon icon={faUserCog} />}>
																Baza uprawnionych
															</Menu.Item>

															<Menu.Item to="/wages" icon={<FontAwesomeIcon icon={faMoneyCheckAlt} />}>
																Kody podatkowe
															</Menu.Item>
														</>
														: null
												}

												<Menu.Item to="/inheritedprofile" icon={<FontAwesomeIcon icon={faIdCard} />}>
													Profil dziedziczony
												</Menu.Item>

												{
													auth.can(USER_PERMISSIONS.ADMIN)
														? <>
															<Menu.Item to="/settings" icon={<FontAwesomeIcon icon={faCogs} />}>
																Konfiguracja systemu
															</Menu.Item>
															<Menu.Item to="/forceincomechange" icon={<FontAwesomeIcon icon={faCheckDouble} />}>
																Wymuś aktualizację dochodów
															</Menu.Item>
														</>
														: null
												}

												<Menu.Item to="/fillpaymentdate" icon={<FontAwesomeIcon icon={faCalendarCheck} />}>
													Data wypłaty
												</Menu.Item>

												<Menu.Item to="/commissionstate" icon={<FontAwesomeIcon icon={faCalendarCheck} />}>
													Masowe ustawianie widoczności dla komisji
												</Menu.Item>
											</Menu.Submenu>
										</Menu.Item>
										</>
										: null
								}
							</Menu>
						</div>
					</div>
				</div>
			</Affix>

			<Content>
				<main className="main w-100">
					<div className="container h-100">
						<Switch>
							<PermissionRoute path="/regulations/create" component={Dashboard_Regulations_General_create} />
							<PermissionRoute path="/regulations/:id/clone">
								<Dashboard_Regulations_General_old mode="clone" />
							</PermissionRoute>
							<PermissionRoute path="/regulations/:id/edit" component={Dashboard_Regulations_General_edit} />
							<PermissionRoute permission={USER_PERMISSIONS.OPERATOR} path="/regulations/:id">
								<Dashboard_Regulations_General_old mode="view" readonly={true} linkButtons />
							</PermissionRoute>
							<PermissionRoute permission={USER_PERMISSIONS.OPERATOR} path="/regulations" component={Dashboard_Regulations_General_list} />

							<PermissionRoute permission={USER_PERMISSIONS.COMMITTEE} path="/appinfants">
								<RecordSubRoutes controller="apptourists" component={Dashboard_Applications_Infants} />
							</PermissionRoute>
							<PermissionRoute permission={USER_PERMISSIONS.COMMITTEE} path="/apptourists">
								<RecordSubRoutes controller="apptourists" component={Dashboard_Applications_Tourists} />
							</PermissionRoute>
							<PermissionRoute permission={USER_PERMISSIONS.COMMITTEE} path="/appmultis">
								<RecordSubRoutes controller="appmultis" component={Dashboard_Applications_Multisports} />
							</PermissionRoute>
							<PermissionRoute permission={USER_PERMISSIONS.COMMITTEE} path="/appextras">
								<RecordSubRoutes controller="appextras" component={Dashboard_Applications_Extras} />
							</PermissionRoute>
							<PermissionRoute permission={USER_PERMISSIONS.COMMITTEE} path="/apptickets">
								<RecordSubRoutes controller="apptickets" component={Dashboard_Applications_Tickets} />
							</PermissionRoute>
							<PermissionRoute permission={USER_PERMISSIONS.COMMITTEE} path="/apploans">
								<RecordSubRoutes controller="apploans" component={Dashboard_Applications_Loans} />
							</PermissionRoute>
							<PermissionRoute permission={USER_PERMISSIONS.COMMITTEE} path="/apprehabs">
								<RecordSubRoutes controller="apprehabs" component={Dashboard_Applications_Rehabs} />
							</PermissionRoute>
							<PermissionRoute permission={USER_PERMISSIONS.COMMITTEE} path="/appgrants">
								<RecordSubRoutes controller="appgrants" component={Dashboard_Applications_Grants} />
							</PermissionRoute>
							<PermissionRoute permission={USER_PERMISSIONS.COMMITTEE} path="/apppears">
								<RecordSubRoutes controller="apppears" component={Dashboard_Applications_Pears} />
							</PermissionRoute>
							<PermissionRoute permission={USER_PERMISSIONS.COMMITTEE} path="/appreftourists">
								<RecordSubRoutes controller="appreftourists" component={Dashboard_Applications_Reftourists} />
							</PermissionRoute>
							<PermissionRoute permission={USER_PERMISSIONS.COMMITTEE} path="/apprefcamps">
								<RecordSubRoutes controller="apprefcamps" component={Dashboard_Applications_Refcamps} />
							</PermissionRoute>
							<PermissionRoute permission={USER_PERMISSIONS.COMMITTEE} path="/appcamps">
								<RecordSubRoutes controller="appcamps" component={Dashboard_Applications_Camps} />
							</PermissionRoute>
							<PermissionRoute permission={USER_PERMISSIONS.COMMITTEE} path="/appreftrips">
								<RecordSubRoutes controller="appreftrips" component={Dashboard_Applications_Reftrips} />
							</PermissionRoute>
							<PermissionRoute permission={USER_PERMISSIONS.COMMITTEE} path="/apptrips">
								<RecordSubRoutes controller="apptrips" component={Dashboard_Applications_Trips} />
							</PermissionRoute>

							<PermissionRoute permission={USER_PERMISSIONS.COMMITTEE} path="/tourists">
								<RecordSubRoutes controller="tourists" cloneAllowed={true} component={Dashboard_Offers_Recreations} />
							</PermissionRoute>
							<PermissionRoute permission={USER_PERMISSIONS.COMMITTEE} path="/multis">
								<RecordSubRoutes controller="multis" cloneAllowed={true} component={Dashboard_Offers_Multisports} />
							</PermissionRoute>
							<PermissionRoute permission={USER_PERMISSIONS.COMMITTEE} path="/iros">
								<RecordSubRoutes controller="iros" cloneAllowed={true} component={Dashboard_Offers_Iros} />
							</PermissionRoute>
							<PermissionRoute permission={USER_PERMISSIONS.COMMITTEE} path="/ikos">
								<RecordSubRoutes controller="ikos" cloneAllowed={true} component={Dashboard_Offers_Ikos} />
							</PermissionRoute>
							<PermissionRoute permission={USER_PERMISSIONS.COMMITTEE} path="/camps">
								<RecordSubRoutes controller="camps" cloneAllowed={true} component={Dashboard_Offers_Camps} />
							</PermissionRoute>
							<PermissionRoute permission={USER_PERMISSIONS.COMMITTEE} path="/trips">
								<RecordSubRoutes controller="trips" cloneAllowed={true} component={Dashboard_Offers_Trips} />
							</PermissionRoute>

							<PermissionRoute permission={USER_PERMISSIONS.OPERATOR} path="/objects">
								<RecordSubRoutes
									customPaths={[{ path: '/:object/create', mode: 'create' }, { path: '/:object/:id/edit', mode: 'edit', editing: true }, { path: '/:object/:id', mode: 'view' }, { path: '/:object?', mode: 'list' }]}
									controller="objects"
									component={Dashboard_Objects}
								/>
							</PermissionRoute>
							<PermissionRoute permission={USER_PERMISSIONS.OPERATOR} path="/dictionaries">
								<RecordSubRoutes
									customPaths={[{ path: '/:dictionary/create', mode: 'create' }, { path: '/:dictionary/:id/edit', mode: 'edit', editing: true }, { path: '/:dictionary/:id', mode: 'view' }, { path: '/:dictionary?', mode: 'list' }]}
									controller="dictionaries"
									component={Dashboard_Dictionaries}
								/>
							</PermissionRoute>

							<PermissionRoute permission={USER_PERMISSIONS.OPERATOR} path="/services">
								<RecordSubRoutes controller="services" component={Dashboard_Services} />
							</PermissionRoute>

							<PermissionRoute permission={USER_PERMISSIONS.COMMITTEE} path="/profiles">
								<RecordSubRoutes controller="profiles" component={Dashboard_Profiles} cloneAllowed />
							</PermissionRoute>
							<PermissionRoute permission={USER_PERMISSIONS.ADMIN} path="/contractors">
								<RecordSubRoutes controller="contractors" component={Dashboard_Contractors} />
							</PermissionRoute>

							<PermissionRoute permission={USER_PERMISSIONS.OPERATOR} path="/clients">
								<RecordSubRoutes controller="clients" component={Dashboard_Clients} />
							</PermissionRoute>
							<PermissionRoute permission={USER_PERMISSIONS.OPERATOR} path="/pesels">
								<RecordSubRoutes controller="pesels" component={Dashboard_Pesels} readonly />
							</PermissionRoute>
							<PermissionRoute path="/users">
								<RecordSubRoutes controller="users" component={Dashboard_Users} />
							</PermissionRoute>

                            <PermissionRoute permission={USER_PERMISSIONS.ADMIN} path="/notices">
                                <RecordSubRoutes controller="notices" component={Dashboard_Notices} />
                            </PermissionRoute>

							<PermissionRoute path="/bankreport" permission={USER_PERMISSIONS.OPERATOR} component={Dashboard_Reports_Bank} />
							<PermissionRoute path="/fillpaymentdate" permission={USER_PERMISSIONS.OPERATOR} component={Dashboard_FillPaymentDate} />
							<PermissionRoute path="/commissionstate" permission={USER_PERMISSIONS.OPERATOR} component={Dashboard_MassSetVisible} />
							<PermissionRoute path="/inheritedprofile" permission={USER_PERMISSIONS.OPERATOR} component={Dashboard_InheritedProfile} />
							<PermissionRoute path="/wages" permission={USER_PERMISSIONS.OPERATOR}>
								<RecordSubRoutes controller="wages" component={Dashboard_TaxCodes} />
							</PermissionRoute>

							<PermissionRoute path="/reports" permission={USER_PERMISSIONS.OPERATOR} component={Dashboard_Reports} />
							<PermissionRoute path="/tests" permission={USER_PERMISSIONS.OPERATOR} component={Dashboard_Tests} />

							<PermissionRoute path="/settings" component={Dashboard_Config} />
                            <PermissionRoute path="/forceincomechange" permission={USER_PERMISSIONS.ADMIN} component={Dashboard_ForceIncomeChange} />

							{/*<PrivateRoute path="/tests" component={Dashboard_Home} />*/}
							{/*
							<Route path="/tests">
								<RecordSubRoutes controller="tests" component={Dashboard_Home} />
							</Route>
							*/}

							<Route path="/404" component={Dashboard_NotFound} />

							<Route exact path="/">
								<Dashboard_Home />
							</Route>

							<Redirect to="/404" />
						</Switch>
					</div>
				</main>
			</Content>

			<Footer>
				<div className="container">
					<div className="footer">
						<div className="footer__meta">
							<div>
								<span className="text-muted footer__build">version {process.env.REACT_APP_VERSION}</span>
							</div>
						</div>

						<ul className="menu-nav">
							<li className="menu-item">
								<Link to="/" className="menu-link">
									<span className="menu-text">
										Pomoc
									</span>
								</Link>
							</li>

							<li className="menu-item">
								<Link to="#" className="menu-link" onClick={(e) => { setChangelogVisible(true); e.preventDefault(); }}>
									<span className="menu-text">
										Changelog
									</span>
								</Link>
							</li>

							<li className="menu-item">
								<Link to="/" className="menu-link">
									<span className="menu-text">
										Polityka prywatności
									</span>
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</Footer>
		</Layout>

		<Modal title="Lista zmian" visible={isChangelogVisible} onCancel={() => setChangelogVisible(false)} okButtonProps={{ style: { display: 'none' }}} cancelText="Zamknij" width={800}>
			<ReactMarkdown plugins={[gfm]} children={changelog} className="zfss-markdown" />
		</Modal>
	</>);
};

export default Home;
