import React from 'react';

import Moment from 'react-moment';
import moment from 'moment';

import { filterObject, objectToFormData } from '../../../libs/Helpers.js';
import prepareErrorMessage from '../../../libs/ErrorHelper.js';

import Record from '../../../components/Record/index';

import TabbedCard from '../../../components/TabbedCard';

import { Row, Col, Card, Form, Input, Button, Space, Alert, Tooltip, message } from 'antd';
import { MinusCircleOutlined, PlusOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import '../../../assets/scss/dashboard.scss';

import { APIBackend as API } from '../../../api';

const Dashboard_TaxCodes = ({...props}) => {
	const [currentActiveTab, setCurrentActiveTab] = React.useState('general');

	return <div className="content">
		<Record
			controller="wages"
			relations={['contractors', 'dctviews']}
			mode={props?.mode}
			defaultEditing={props?.editing}
			columns={[
				{
					name: 'id',
					title: '#',
					type: 'number'
				},
				{
					name: 'contractor_name',
					title: 'Kontrahent'
				},
				{
					name: 'dctview_name',
					title: 'Świadczenie'
				},
				{
					name: 'client_type_name',
					title: 'Rodzaj klienta'
				},
				{
					name: 'app_type_name',
					title: 'Rodzaj wniosku'
				},
				{
					name: 'tax_group',
					title: 'Grupa podatkowa'
				},
				{
					name: 'additional_criterion_name',
					title: 'Kryterium'
				},
				{
					name: 'is_active',
					title: 'Aktywne',
					datatype: 'bool'
				},
				{
					name: 'actions',
					data: null,
					sortable: false,
					title: '',
					render: function(data, type, row) {
						return `<a class="btn btn-table" href="/wages/${data.id}">Podgląd</a><a class="btn btn-table" href="/wages/${data.id}/edit">Edytuj</a>`;
					},
					className: 'text-right'
				}
			]}
			viewTitle="Kody podatkowe - PODGLĄD"
			listTitle="Kody podatkowe"
			editTitle="Kody podatkowe - EDYCJA"
			createTitle="Kody podatkowe - TWORZENIE"
		>
			<Record.Consumer>
				{({ isLoading, mode, data, changes, relationData, form }) => (
					<TabbedCard key="generalCard" loading={isLoading} tabContent={{
						general: [
							<Record.Field.Input title="Nazwa skrócona" name="short_name" span={{ span: 24 }} maxLength={100} />,
							<Record.Field.Relation title="Kontrahent" relation="contractors" name="contractor_id" labelFormat="[{#symbol}] {#name}" link allowSearch={true} rules={[{ required: true, message: 'Proszę uzupełnić kontrahenta' }]} />,
							<Record.Field.Relation title="Świadczenie" relation="dctviews" idKey="id_table" relatedKey="id_table" name="dctview_tbl_id" labelFormat="{#name}" link allowSearch={true} />,
							<Record.Field.Select title="Rodzaj klienta" name="client_type" options={[
								{ label: 'Pracownik', value: 'P' },
								{ label: 'Emeryt', value: 'E' },
							]} rules={[{ required: true, message: 'Proszę uzupełnić rodzaj klienta' }]} />,
							<Record.Field.Select title="Rodzaj wniosku" name="app_type" options={[
								{ label: 'Dofinansowanie', value: 'D' },
								{ label: 'Refundacja', value: 'R' },
							]} rules={[{ required: true, message: 'Proszę uzupełnić wniosku' }]} />,
							<Record.Field.Select title="Kryterium" name="additional_criterion" options={[
								{ label: 'Brak', value: '0' },
								{ label: 'do 18 lat', value: 'A' },
								{ label: 'powyżej 18 lat', value: 'B' },
							]} rules={[{ required: true, message: 'Proszę uzupełnić kryterium' }]} />,
							<Record.Field.Select title="Grupa podatkowa" name="tax_group" options={[
								{ label: '1', value: '1' },
								{ label: '2', value: '2' },
								{ label: '11', value: '11' },
								{ label: '12', value: '12' },
								{ label: '1 lub 2', value: '1 lub 2' },
								{ label: '11 lub 12', value: '11 lub 12' }
							]} rules={[{ required: true, message: 'Proszę uzupełnić grupę podatkową' }]} />,
							<Record.Field.Input title="Kod składnika płacowego" name="wage_code" maxLength={50} />,
							<Record.Field.Input title="Kod księgowy" name="accounting_code" maxLength={100} />,
							<Record.Field.Input title="Przepis regulaminu ZFŚS" name="regulation_rule" maxLength={100} span={{ span: 24 }} />,
							<Record.Field.Switch title="Aktywne" name="is_active" defaultValue={1} />,
							<Record.Field.Textarea title="Uwagi" name="remarks" span={{ span: 24 }} />
						],
					}} tabList={[
						{ key: 'general', tab: 'Dane podstawowe' },
					]} activeTabKey={currentActiveTab} onTabChange={(key) => { setCurrentActiveTab(key) }} forceRender={true} />
				)}
			</Record.Consumer>
		</Record>
	</div>;
};

export default Dashboard_TaxCodes;
