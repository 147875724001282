
let backendRoot = 'http://localhost:5000'
let backendApi = 'http://localhost:5000/api'

if (process.env.NODE_ENV === 'production') {
    backendRoot = 'https://api.socialpartner.pl'
    backendApi = 'https://api.socialpartner.pl/api'
}

export const csrfCookie = '/sanctum/csrf-cookie'

// User endpoints
export const apiLogin = '/login'
export const apiLogout = '/logout'
export const apiRegister = '/register'

export const apiUser = '/users'

export const apiData = '/data'

export const apiResetPasswordToken = '/reset-password-token';
export const apiSaveNewPassword = '/save-new-password';
export const apiCheckToken = '/check-token';

export { backendRoot, backendApi }
