import React from 'react';
import styled from 'styled-components';

import { captureException } from '@sentry/react';

import Moment from 'react-moment';

import { Row, Col, Card, Form, Select, Button, Alert, message, DatePicker, Menu, Empty, Input } from 'antd';
import { MinusCircleOutlined, PlusOutlined, AuditOutlined, FileTextOutlined, CalculatorOutlined, TeamOutlined, ScheduleOutlined } from '@ant-design/icons';

import { APIBackend as API } from '../../../api';
import { backendRoot, backendApi, csrfCookie, apiData } from '../../../config/paths';
import axios from 'axios';

import moment from 'moment';

import FileDownload from 'js-file-download';

import '../../../assets/scss/dashboard.scss';
import SearchSelect from "../../../components/SearchSelect";

const { Option } = Select;
const { SubMenu } = Menu;

const StyledEmpty = styled(Empty)`
	color: #00000060;
`;

const SearchContainer = styled.div`
	padding: 15px 15px;
`;

const MIMES = {
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
	'application/vnd.ms-excel': 'xls',
	'text/csv': 'csv',
	'application/json': 'json',
	'application/rtf': 'rtf',
	'application/xml': 'xml',
	'text/xml': 'xml',
	'text/plain': 'txt'
};

const REPORTS = [
	{
		label: 'Ogólne',
		key: 'general',
		props: {
			icon: <AuditOutlined />
		},
		children: [
			{
				name: 'count',
				label: 'Raport ilościowy',
				endpoint: 'appsubsidy/count'
			},
			{
				name: 'quote',
				label: 'Raport wartościowy',
				endpoint: 'appsubsidy/quote'
			},
			{
				name: 'count_limits',
				label: 'Raport ilościowy - limity',
				endpoint: 'appsubsidy/limitcount'
			},
			{
				name: 'quote_limits',
				label: 'Raport wartościowy - limity',
				endpoint: 'appsubsidy/limitquote'
			},
			{
				name: 'profile_agreement',
				label: 'Zgody marketingowe',
				endpoint: 'profile/agreement'
			},
		]
	},
	{
		label: 'Wnioski',
		key: 'apps',
		props: {
			icon: <FileTextOutlined />
		},
		children: [
			{
				name: 'application_stats',
				label: 'Statystyki wniosków',
				endpoint: 'application/stats'
			},
			{
				name: 'payment_multisport',
				label: 'Wpłaty Sportowe',
				endpoint: 'payment/multisport'
			},
			{
				name: 'payment_infant',
				label: 'Wpłaty - przedszkola i żłobki',
				endpoint: 'payment/infant'
			},
			{
				name: 'payment_other',
				label: 'Wpłaty - wyjazdy, wypoczynek dzieci, świadczenia tur-rek',
				endpoint: 'payment/other'
			},
			{
				label: 'Wypoczynek dzieci - kolonie/obozy/zimowiska',
				key: 'apps_camps',
				children: [
					{
						name: 'camp',
						label: 'Dofinansowanie',
						endpoint: 'appsubsidy/camp'
					},
					{
						name: 'refcamp',
						label: 'Refundacja',
						endpoint: 'appsubsidy/refcamp'
					},
				]
			},
			{
				label: 'Wypoczynek po pracy - wyjazdy',
				key: 'apps_trips',
				children: [
					{
						name: 'trip',
						label: 'Dofinansowanie',
						endpoint: 'appsubsidy/trip'
					},
					{
						name: 'reftrip',
						label: 'Refundacja',
						endpoint: 'appsubsidy/reftrip'
					},
				]
			},
			{
				label: 'Bilety i karnety',
				key: 'apps_tourists',
				children: [
					{
						name: 'iko',
						label: 'Zestawienie - wypoczynek po pracy (IKO)',
						endpoint: 'appsubsidy/iko'
					},
					{
						name: 'iro',
						label: 'Zestawienie - wypoczynek po pracy (IRO)',
						endpoint: 'appsubsidy/iro'
					}
				]
			},
			{
				name: 'loan',
				label: 'Zestawienie - pożyczki',
				endpoint: 'appsubsidy/loan'
			},
			{
				name: 'grant',
				label: 'Zestawienie - zapomogi',
				endpoint: 'appsubsidy/grant'
			},
			{
				name: 'pear',
				label: 'Zestawienie - wczasy pod gruszą',
				endpoint: 'appsubsidy/pear'
			},
			{
				name: 'tourist',
				label: 'Zestawienie - świadczenia turystyczno-rekreacyjne',
				endpoint: 'appsubsidy/tourist'
			},
			{
				name: 'rehab',
				label: 'Zestawienie - zajęcia rekreacyjne',
				endpoint: 'appsubsidy/rehab'
			},
			{
				name: 'infant',
				label: 'Zestawienie - przedszkola i żłobki',
				endpoint: 'appsubsidy/infant'
			},
			{
				name: 'multi',
				label: 'Zestawienie - Sportowe',
				endpoint: 'appsubsidy/multi'
			},
			{
				name: 'extra',
				label: 'Zestawienie - świadczenia dodatkowe',
				endpoint: 'appsubsidy/extra'
			},
		]
	},
	{
		label: 'Profile',
		key: 'profiles',
		props: {
			icon: <TeamOutlined />
		},
		children: [
			{
				name: 'profile_stats',
				label: 'Statystyki profili',
				endpoint: 'profile/stats'
			},
			{
				name: 'income_average',
				label: 'Zestawienie dochodów',
				endpoint: 'income/average'
			},
			{
				name: 'profile_active',
				label: 'Zestawienie - aktywne profile',
				endpoint: 'profile/active'
			},
			{
				name: 'profile_inheritance',
				label: 'Zestawienie - profile dziedziczone',
				endpoint: 'profile/inheritance'
			},
		]
	},
	{
		label: 'Komisja',
		key: 'commissions',
		props: {
			icon: <ScheduleOutlined />
		},
		children: [
			{
				name: 'application_tocommission',
				label: 'Zestawienie - wnioski przekazane do komisji',
				endpoint: 'application/tocommission'
			},
			{
				name: 'grant_tocommission',
				label: 'Zestawienie - zapomogi przekazane do komisji',
				endpoint: 'grant/tocommission'
			},
			{
				name: 'loan_tocommission',
				label: 'Zestawienie - pożyczki przekazane do komisji',
				endpoint: 'loan/tocommission'
			},
			{
				name: 'mailing_tocommission',
				label: 'Mailing do komisji - wnioski przekazane do komisji',
				endpoint: 'mailing/tocommission'
			},
			{
				name: 'profile_tocommission',
				label: 'Zestawienie - profile przekazane do komisji',
				endpoint: 'profile/tocommission'
			},
		]
	},
	{
		label: 'Podatkowe',
		key: 'taxes',
		props: {
			icon: <CalculatorOutlined />
		},
		children: [
			{
				name: 'tax_payment',
				label: 'Raport podatkowy',
				endpoint: 'tax/payment'
			},
			{
				name: 'tax_codes',
				label: 'Zestawienie - kody podatkowe',
				endpoint: 'tax/codes'
			},
		]
	}
];

const flattenArray = (arr) => {
	return arr.reduce((acc, val) => val.hasOwnProperty('children') ? acc.concat(flattenArray(val?.children ?? [])) : acc.concat(val), []);
};

const searchReportsByName = (name) => {
	return new Promise((resolve, reject) => {
		let results = [];

		const search = (entries, str, parents = []) => {
			if(Array.isArray(entries)) {
				entries.forEach(el => {
					if(el.hasOwnProperty('children')) {
						search(el.children, name, parents.concat(el?.key ?? null));
					} else {
						if(el.label.toLocaleLowerCase().includes(str)) {
							results.push(parents);
						}
					}
				});
			}
		};

		search(REPORTS, name);

		resolve([...new Set(results.flat())]);
	});
};

const getReportByName = (name) => {
	return flattenArray(REPORTS).find(el => el.name === name);
};

const buildMenuItem = (entry) => {
	if(entry.hasOwnProperty('children')) {
		const subMenuProps = entry.hasOwnProperty('props') ? (entry.props ?? {}) : {};

		return <SubMenu {...subMenuProps} key={entry.key} title={entry.label}>
			{entry.children.map((el, idx) => buildMenuItem(el))}
		</SubMenu>;
	} else {
		const itemProps = entry.hasOwnProperty('props') ? (entry.props ?? {}) : {};
		return <Menu.Item {...itemProps} key={entry.name}>
			{entry.label}
		</Menu.Item>;
	}
};

const Dashboard_Reports = (props) => {
	const [form] = Form.useForm();

	const [relationData, setRelationData] = React.useState(null);
	const [error, setError] = React.useState(false);

	const [reportType, setReportType] = React.useState(null);
	const [openKeys, setOpenKeys] = React.useState([]);
	const [searchValue, setSearchValue] = React.useState(null);

	const onMenuItemSelect = (item) => {
		setReportType(item.key);
	};

	const onOpenChange = keys => {
		setOpenKeys(keys);
	};

	const onSearchChange = e => {
		setSearchValue(e.target.value);
	};

	const onGenerate = (values) => {
		const _data = new FormData();

		if(!values?.contractors) {
			values.contractors = [];
		}
		if(!Array.isArray(values?.users)) {
			values.users = [];
		}

		if(Array.isArray(values?.contractors)) {
			_data.append('contractors', values.contractors.join(','));
		} else {
			_data.append('contractors', values.contractors);
		}

		if(values?.users) {
			_data.append('users', values.users.join(','));
		}

		if(values?.from) {
			_data.append('from', values.from.format('YYYY-MM-DD'));
		}

		if(values?.to) {
			_data.append('to', values.to.format('YYYY-MM-DD'));
		}

		if(values?.client_type) {
			_data.append('client_type', values.client_type);
		}

		const _report = getReportByName(reportType);

		if(_report) {
			API.getReport(_report.endpoint, _data).then(data => {
				setError(false);
				message.success('Raport został wygenerowany');
				// console.log(data);
				FileDownload(data, `raport-${moment().format('YYYY-MM-DD-hh-mm-ss')}.${MIMES[data.type] ?? 'xlsx'}`);
			}).catch(err => {
				setError({
					errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED',
					message: err?.response?.data?.message ?? null
				});
			});
		}
	};

	React.useEffect(() => {
		if(searchValue) {
			searchReportsByName(searchValue).then(r => setOpenKeys(r)).catch(err => setOpenKeys([]));
		} else {
			setOpenKeys([]);
		}
	}, [searchValue, setSearchValue]);

	React.useEffect(() => {
		API.datacustom([
			{
				name: 'contractors',
				query: '',
			},
			{
				name: 'userswithnumbers',
				query: '',
			}
		]).then((data) => {
			setRelationData(data);
			setError(false);
		}).catch((err) => {
			setError({
				errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED',
				message: err?.response?.data?.message
			});
		});
		// axios.get(backendRoot + csrfCookie).then(() => {
		// 	// Getting data of related records
		// 	axios.get(`${backendApi}${apiData}?tables=${['contractors', 'users'].join(',')}`).then((response) => {
		// 		// Assigning data of related records to state
		// 		setRelationData(response.data);
		// 		setError(false);
		// 	}).catch((err) => {
		// 		setError({
		// 			errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED',
		// 			message: err?.reponse?.data?.message
		// 		});
		// 	});
		// }).catch((err) => {
		// 	setError({
		// 		errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED',
		// 		message: err?.reponse?.data?.message
		// 	});
		// });
	}, []);

	const fetchOptions = (value, relation) => {
		if(!relation) {
			return Promise.reject();
		}

		return new Promise((resolve, reject) => {
			API.datacustom([{
				name: relation,
				query: value
			}]).then(response => {
				const currentOptions = relationData;
				let newOptions = {...currentOptions};

				if(response.hasOwnProperty(relation)) {
					resolve(response[relation]);

					newOptions = {...newOptions, [relation]: response[relation]};
				} else {
					resolve([]);
					newOptions = {...newOptions, [relation]: []};
				}

				setRelationData(newOptions);
			}).catch(error => {
				reject(error);
			});
		});
	};

	return (<div className="content">
		<div className="subheader">
			<div className="d-flex">
				<h1 className="title">Raporty</h1>
				<span className="subtitle">
					Dzisiaj jest <Moment locale="pl" format="dddd, D MMMM YYYY" />
				</span>
			</div>

			<div className="subheader_actions">
				<Button type="primary" onClick={() => { form.submit(); }}>Wygeneruj</Button>
			</div>
		</div>

		<Row gutter={[24, 24]}>
			<Col md={24} lg={6}>
				<Card bodyStyle={{ padding: '0 0' }}>
					<SearchContainer>
						<Input placeholder="Wyszukaj raport po nazwie" value={searchValue} onChange={onSearchChange} />
					</SearchContainer>

					<Menu
						openKeys={openKeys}
						onOpenChange={onOpenChange}
						mode="inline"
						onSelect={onMenuItemSelect}
					>
						{
							REPORTS.map(el => buildMenuItem(el))
						}
					</Menu>
				</Card>
			</Col>

			<Col md={24} lg={18}>
				{
					error && <Alert type="error" message={error?.message ?? 'Wystąpił nieoczekiwany błąd podczas generowania raportu lub pobierania danych'} style={{ marginBottom: 25 }} />
				}

				<Card
					title={reportType ? getReportByName(reportType).label : null}
				>
					{
						!reportType
							? <StyledEmpty description="Proszę wybrać rodzaj raportu z menu po lewej stronie" />
							: null
					}

					{
						reportType
							? <>
								<Form
									onFinish={onGenerate}
									layout="vertical"
									form={form}
								>

								<>
									<Form.Item
										label={['tax_payment'].includes(reportType) ? 'Kontrahent' : 'Kontrahenci'}
										name="contractors"
										rules={['tax_payment'].includes(reportType) ? [
											{
												required: true,
												message: 'Proszę uzupełnić kontrahenta/ów'
											}
										] : []}
									>
										<SearchSelect
											fetch={fetchOptions}
											relation="contractors"
											mode={['tax_payment'].includes(reportType) ? undefined : 'multiple'}
											initialOptions={relationData?.contractors}
										/>
										{/*<Select*/}
										{/*	mode={['tax_payment'].includes(reportType) ? undefined : 'multiple'}*/}
										{/*	showSearch*/}
										{/*	optionFilterProp="children"*/}
										{/*>*/}
										{/*	{relationData?.contractors?.map(el => (*/}
										{/*		<Option key={['contractors', el.value]} value={el.value}>*/}
										{/*			{el.label}*/}
										{/*		</Option>*/}
										{/*	))}*/}
										{/*</Select>*/}
									</Form.Item>

									{
										!['tax_payment', 'profile_active', 'tax_codes', 'profile_tocommission'].includes(reportType) && relationData
											? (<>
												<Form.Item
													label="Klienci"
													name="users"
												>
													<SearchSelect
														fetch={fetchOptions}
														relation="userswithnumbers"
														mode="multiple"
														initialOptions={relationData?.userswithnumbers}
													/>
													{/*<Select*/}
													{/*	mode="multiple"*/}
													{/*	showSearch*/}
													{/*	optionFilterProp="children"*/}
													{/*>*/}
													{/*	{relationData?.users?.filter(el => el.permission_id === 1).map(el => (*/}
													{/*		<Option key={['users', el.value]} value={el.value}>*/}
													{/*			{el.label}*/}
													{/*		</Option>*/}
													{/*	))}*/}
													{/*</Select>*/}
												</Form.Item>
											</>)
											: null
									}
								</>


									{
										['camp', 'refcamp', 'trip', 'reftrip', 'loan', 'grant', 'pear', 'tourist', 'rehab', 'infant', 'iko', 'iro', 'multi', 'profile_stats', 'application_stats', 'payment_multisport', 'payment_infant', 'payment_other', 'application_tocommission', 'grant_tocommission', 'loan_tocommission', 'mailing_tocommission', 'count', 'quote', 'count_limits', 'quote_limits', 'tax_payment', 'profile_active', 'extra'].includes(reportType)
											? (
												<>
													<Form.Item
														label="Data od"
														name="from"
														rules={[
															{
																required: true,
																message: 'Proszę uzupełnić początek okresu'
															}
														]}
													>
														<DatePicker placeholder="Wybierz datę..." style={{ width: '100%' }} />
													</Form.Item>

													<Form.Item
														label="Data do"
														name="to"
														rules={[
															{
																required: true,
																message: 'Proszę uzupełnić koniec okresu'
															}
														]}
													>
														<DatePicker placeholder="Wybierz datę..." style={{ width: '100%' }} />
													</Form.Item>
												</>
											)
											: null
									}

									{
										['tax_payment'].includes(reportType)
											? (
												<>
													<Form.Item
														label="Rodzaj klienta"
														name="client_type"
														rules={[
															{
																required: true,
																message: 'Proszę uzupełnić rodzaj klienta'
															}
														]}
													>
														<Select
															showSearch
															optionFilterProp="children"
														>
															<Option value="P">
																Pracownik
															</Option>
															<Option value="E">
																Emeryt
															</Option>
														</Select>
													</Form.Item>
												</>
											)
											: null
									}
								</Form>
							</>
							: null
					}
				</Card>
			</Col>
		</Row>
	</div>);
};

export default Dashboard_Reports;
