import React from 'react';
import { useHistory } from 'react-router-dom';

import Moment from 'react-moment';

import Record from '../../components/Record';
import RecordNew from '../../components/RecordNew';

import TabbedCard from '../../components/TabbedCard';

import { Row, Col, Card, Button, Tag, Space, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import '../../assets/scss/dashboard.scss';

const Dashboard_Clients = (props) => {
	const CONTROLLER = 'users';
	const REFERRER = 'clients';

	const [currentActiveTab, setCurrentActiveTab] = React.useState('general');

	const history = useHistory();

	const listProps = {
		columns: [
			{
				name: 'id',
				title: '#',
				sorter: true,
				defaultSortOrder: 'descend'
			},
			{
				name: 'first_name',
				title: 'Imię',
				searchable: true
			},
			{
				name: 'last_name',
				title: 'Nazwisko',
				searchable: true
			},
			{
				name: 'pesel',
				title: 'Numer PESEL',
				searchable: true
			},
			{
				name: 'phone',
				title: 'Numer kontaktowy'
			},
			{
				name: 'is_locked',
				title: 'Zablokowany',
				searchable: false,
				render: (text, record, idx) => {
					return text ? <Tag color="red">Tak</Tag> : <Tag color="green">Nie</Tag>;
				}
			}
		],
		// expandable: {
		// 	expandedRowRender: record => <p style={{ margin: 0 }}>{record.id}</p>,
		// 	rowExpandable: () => true,
		// }
	};

	// return (
	// 	<div className="content">
	// 		<RecordNew
	// 			mode={props?.mode ?? 'list'}
	// 			controller={CONTROLLER}
	// 			referrer={REFERRER}
	// 			relations={[
	// 				'permissions',
	// 				'contractors',
	// 				'profiles',
	// 				'worktime'
	// 			]}
	// 			filter={[
	// 				{
	// 					field: 'is_client',
	// 					value: 'Y'
	// 				}
	// 			]}
	// 			listProps={listProps}
	// 			listTitle="Lista użytkowników (klientów)"
	// 			viewTitle="Podgląd użytkownika (klienta)"
	// 			createTitle="Tworzenie użytkownika (klienta)"
	// 			editTitle="Edycja użytkownika (klienta)"
	// 			createButtonText="Dodaj klienta"
	// 		>
	// 			<RecordNew.Consumer>
	// 				{({ loading }) => (
	// 					<TabbedCard key="generalCard" loading={loading} tabContent={{
	// 						general: [
	// 							<RecordNew.Field.Input title={'Imię'} name="first_name" rules={[ { required: true, message: 'Proszę uzupełnić imię klienta' } ]} />,
	// 							<RecordNew.Field.Input title={'Nazwisko'} name="last_name" rules={[ { required: true, message: 'Proszę uzupełnić imię klienta' } ]} />,
	// 							<RecordNew.Field.Input title={'Adres e-mail'} name="email" rules={[{ type: 'email', message: 'Proszę podać prawidłowy adres e-mail' }, { required: true, message: 'Proszę uzupełnić adres e-mail klienta' }]} />,
	// 							<RecordNew.Field.Input title={'PESEL'} name="pesel" rules={[{ len: 11, message: 'Numer PESEL musi się składać z 11 cyfr!' }]} />,
	// 							<RecordNew.Field.Switch title={'Zablokowane'} name="is_locked" />,
	// 							<RecordNew.Field.Switch title={'Aktywne'} name="is_activated" hidePredicate={ (value, rawData, mode, rowData) => { return (mode !== 'create' && (rawData?.is_activated == true)) } } />,
	// 							<RecordNew.Field.Input title={'Telefon kontaktowy'} name="phone" rules={[{ pattern: /^(?<!\w)(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}(?!\w)$/, message: 'Proszę wprowadzić prawidłowy numer telefonu' }]} />,
	// 							<RecordNew.Field.Relation title={'Uprawnienia'} name="permission_id" relation="permissions" filter={ (data, recordData) => { if(Array.isArray(data)) {
	// 								return data.filter(el => el.code < 50);
	// 							} return data; } } rules={[ { required: true, message: 'Proszę wybrać uprawnienia klienta' } ]} />,
	// 							<RecordNew.Field.Textarea title={'Dodatkowe notatki'} name="remarks" span={{ span: 24 }} />
	// 						],
	// 						contractors: [
	// 							<RecordNew.Field.TableRelation title={'Kontrahenci'} name="contractors" span={{ span: 24 }} fields={[
	// 								{ name: 'id', title: 'Nazwa', span: { lg: 13, xl: 14 }, type: 'relation', relation: 'contractors', relationValueColumn: 'name', relationIdColumn: 'id' },
	// 								{ name: 'worktime_id', title: 'Wymiar pracy', span: { lg: 5, xl: 6 }, type: 'relation', relation: 'worktime' }
	// 							]} showTitle={ false } data={(data, record) => {
	// 								return data.map(row => {
	// 									if(row && record?.worktime) {
	// 										row.worktime_id = record.worktime.filter(el => el.contractor_id === row.id)[0]?.id ?? null;
	// 									}
	//
	// 									return row;
	// 								});
	// 							}} />
	// 						],
	// 						profiles: [
	// 							<RecordNew.Field.Table title={'Aktywny profil'} name={null} filter={(data, recordData) => {
	// 								return data.filter(profile => {
	// 									return profile?.user_id === recordData?.id && profile?.staprofile_id === 2;
	// 								});
	// 							}} span={{ span: 24 }} fields={[
	// 								{ name: 'first_name', title: 'Imię' },
	// 								{ name: 'last_name', title: 'Nazwisko' },
	// 								{ name: 'contractor_name', title: 'Kontrahent' },
	// 								{ name: 'updated_at', title: 'Ostatnia aktualizacja' }
	// 							]} showActions />,
	//
	// 							<RecordNew.Field.Table title={'Historia profili'} name={null} filter={(data, recordData) => {
	// 								return data.filter(profile => {
	// 									return profile?.user_id === recordData?.id && profile?.staprofile_id !== 2;
	// 								});
	// 							}} span={{ span: 24 }} fields={[
	// 								{ name: 'first_name', title: 'Imię' },
	// 								{ name: 'last_name', title: 'Nazwisko' },
	// 								{ name: 'contractor_name', title: 'Kontrahent' },
	// 								{ name: 'updated_at', title: 'Ostatnia aktualizacja' }
	// 							]} showActions />
	// 						]
	// 					}} tabList={[
	// 						{ key: 'general', tab: 'Dane podstawowe'},
	// 						{ key: 'contractors', tab: 'Kontrahenci' },
	// 						{ key: 'profiles', tab: 'Profile' }
	// 					]} activeTabKey={currentActiveTab} onTabChange={(key) => { setCurrentActiveTab(key) }} forceRender={true} />
	// 				)}
	// 			</RecordNew.Consumer>
	// 		</RecordNew>
	// 	</div>
	// );

	return (<div className="content">
		<Record
			controller="users"
			referrer="clients"
			relations={[ 'permissions', 'contractors', 'profiles', 'worktime' ]}
			mode={props?.mode}
			defaultEditing={props?.editing}
			filter={[
				{ field: 'is_client', value: 'Y' }
			]}
			filters={[
				{
					title: 'Kontrahent',
					name: 'contractor_id',
					relation: 'contractors',
					type: 'enum'
				},
				{
					title: 'Zablokowany',
					name: 'is_locked',
					type: 'preenum',
					enum: [
						{ label: '- wybierz -', value: null },
						{ label: 'TAK', value: 1 },
						{ label: 'NIE', value: 0 }
					]
				}
			]}
			onCreatedRow={(row, data) => {
				if(data?.is_seen === 0) {
					row.style.background = 'rgba(225, 166, 52, 0.1)';
				}
			}}
			customButtons={[
				{ title: 'Resetuj hasło', endpoint: '/reset-password-token', modal: { title: 'Potwierdź akcję', content: 'Czy na pewno chcesz zresetować hasło użytkownika?' }, formDataFromRecord: ['email'], successText: 'Hasło zostało pomyślnie zresetowane', errorText: 'Wystąpił błąd podczas resetowania hasła' }
			]}
			columns={[
				{
					name: 'id',
					title: '#',
					type: 'number'
				},
				{
					name: 'first_name',
					title: 'Imię',
					searchable: true
				},
				{
					name: 'last_name',
					title: 'Nazwisko',
					searchable: true
				},
				{
					name: 'pesel',
					title: 'Numer PESEL',
					searchable: true
				},
				{
					name: 'phone',
					title: 'Numer kontaktowy'
				},
				{
					name: 'is_locked',
					title: 'Zablokowany',
					datatype: 'icbool',
					searchable: false
				},
				{
					name: 'actions',
					data: null,
					sortable: false,
					title: '',
					render: function(data, type, row) {
						return `<a class="btn btn-table" href="/clients/${data.id}">Podgląd</a><a class="btn btn-table" href="/clients/${data.id}/edit">Edytuj</a>`;
					},
					className: 'text-right'
				}
			]}
			viewTitle="Podgląd użytkownika (klienta)"
			listTitle="Lista użytkowników (klientów)"
			createTitle="Tworzenie użytkownika (klienta)"
			editTitle="Edycja użytkownika (klienta)"
			createButtonText="Dodaj klienta"
		>
			<Record.Consumer>
				{({ isLoading }) => (
					<TabbedCard key="generalCard" loading={isLoading} tabContent={{
						general: [
							<Record.Field.Input title={'Imię'} name="first_name" rules={[ { required: true, message: 'Proszę uzupełnić imię klienta' } ]} />,
							<Record.Field.Input title={'Nazwisko'} name="last_name" rules={[ { required: true, message: 'Proszę uzupełnić imię klienta' } ]} />,
							<Record.Field.Input title={'Adres e-mail'} name="email" rules={[{ type: 'email', message: 'Proszę podać prawidłowy adres e-mail' }]} />,
							<Record.Field.Input title={'PESEL'} name="pesel" rules={[{ len: 11, message: 'Numer PESEL musi się składać z 11 cyfr!' }]} />,
							<Record.Field.Switch title={'Zablokowane'} name="is_locked" />,
							<Record.Field.Switch title={'Aktywne'} name="is_activated" hidePredicate={ (value, rawData, recordData, mode) => { return (mode !== 'create' && (rawData?.is_activated === true)) } } />,
							<Record.Field.Input title={'Telefon kontaktowy'} name="phone" rules={[{ pattern: /^(?<!\w)(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}(?!\w)$/, message: 'Proszę wprowadzić prawidłowy numer telefonu' }]} />,
							<Record.Field.Relation title={'Uprawnienia'} name="permission_id" relation="permissions" filter={ (data, recordData) => { if(Array.isArray(data)) {
								return data.filter(el => el.code < 50);
							} return data; } } rules={[ { required: true, message: 'Proszę wybrać uprawnienia klienta' } ]} />,
							<Record.Field.Switch title="Zgoda na anonimowe ankiety służące ustalaniu preferencji wypoczynkowych" name="is_agree_preference" />,
							<Record.Field.Switch title="Zgoda na wysyłanie aktualnej oferty wypoczynku turystyczno-rekreacyjnego" name="is_agree_offer" />,
							<Record.Field.Switch title="Zgoda na kontakt celem przeprowadzenia ankiety satysfakcji" name="is_agree_satisfaction" />,
							<Record.Field.Textarea title={'Dodatkowe notatki'} name="remarks" span={{ span: 24 }} />
						],
						contractors: [
							<Record.Field.TableRelation title={'Kontrahenci'} name="contractors" span={{ span: 24 }} fields={[
								{ name: 'id', title: 'Nazwa', span: { lg: 13, xl: 14 }, type: 'relation', relation: 'contractors', relationValueColumn: 'name', relationIdColumn: 'id' },
								{ name: 'worktime_id', title: 'Wymiar pracy', span: { lg: 5, xl: 6 }, type: 'relation', relation: 'worktime' }
							]} showTitle={ false } data={(data, record) => {
								return data.map(row => {
									if(row && record?.worktime) {
										row.worktime_id = record.worktime.filter(el => el.contractor_id === row.id)[0]?.id ?? null;
									}

									return row;
								});
							}} />
						],
						profiles: [
							<Record.Field.Table title={'Aktywny profil'} name={null} filter={(data, recordData) => {
								return data.filter(profile => {
									return profile?.user_id === recordData?.id && profile?.staprofile_id === 2;
								});
							}} span={{ span: 24 }} fields={[
								{ name: 'first_name', title: 'Imię' },
								{ name: 'last_name', title: 'Nazwisko' },
								{ name: 'contractor_name', title: 'Kontrahent' },
								{ name: 'updated_at', title: 'Ostatnia aktualizacja' }
							]} showActions />,

							<Record.Field.Table title={'Historia profili'} name={null} filter={(data, recordData) => {
								return data.filter(profile => {
									return profile?.user_id === recordData?.id && profile?.staprofile_id !== 2;
								});
							}} span={{ span: 24 }} fields={[
								{ name: 'first_name', title: 'Imię' },
								{ name: 'last_name', title: 'Nazwisko' },
								{ name: 'contractor_name', title: 'Kontrahent' },
								{ name: 'updated_at', title: 'Ostatnia aktualizacja' }
							]} showActions />
						]
					}} tabList={[
						{ key: 'general', tab: 'Dane podstawowe'},
						{ key: 'contractors', tab: 'Kontrahenci' },
						{ key: 'profiles', tab: 'Profile' }
					]} activeTabKey={currentActiveTab} onTabChange={(key) => { setCurrentActiveTab(key) }} forceRender={true} />
				)}
			</Record.Consumer>
		</Record>
	</div>);
};

export default Dashboard_Clients;
