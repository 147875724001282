import React, { useState } from 'react';

import { Select, Modal, Button, message } from 'antd';

import axios from 'axios';

import { backendRoot, backendApi, csrfCookie, apiData } from '../config/paths';

const { Option } = Select;

const NEW_ITEM = 'CREATE_NEW_ITEM';

const CreatableSelect = ({ children, serverside, controller, fields, field, modalTitle, onCreated, onChange, onSearch, ...props }) => {
	const [value, setValue] = useState();
	const [inputValue, setInputValue] = useState();
	const [options, setOptions] = useState([]);
	const [creating, setCreating] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
	const [error, setError] = useState(false);

	const showModal = () => {
		setModalVisible(true);
	};

	const hideModal = () => {
		setModalVisible(false);
		setCreating(false);
		// Clear the searchbox value
		setInputValue('');
		setValue('');
	};

	/**
	 * Options for axios, provided while sending an request.
	 * @type {Object}
	 */
	const axiosOptions = {
		transformRequest: function(data, headers) {
			// Browser will detect proper Content-Type
			headers['Content-Type'] = undefined;

			return data;
		}
	};

	const handleModalConfirm = () => {
		const _data = new FormData();

		fields.length && fields.forEach(_field => {
			if(_field.name === field) return;
			_data.append(_field.name, _field.value);
		});

		_data.append(field, inputValue);

		axios.post(backendApi + '/' + controller, _data, axiosOptions).then((response) => {
			setOptions([...options, {
				value: response.data.id,
				node: (<Option key={`option~${response.data.id}`} value={response.data.id}>{response.data[field]}</Option>)
			}]);
			(typeof onCreated === 'function' && onCreated(response.data));
			setValue(response.data.id);
			setInputValue('');
			setCreating(false);
			setError(false);
			setModalVisible(false);
			message.success('Utworzono nową pozycję');

			(typeof onChange === 'function' && onChange(response.data.id));
		}).catch((err) => {
			console.log(err);
			if(err.response) {
				setError(err.response.data.message);
				message.error('Wystąpił błąd podczas tworzenia rekordu, spróbuj ponownie');
				hideModal();
			} else {
				setError('Wystąpił błąd podczas zapisu rekordu, spróbuj ponownie później lub skontaktuj się z administratorem');
				message.error('Wystąpił błąd podczas tworzenia rekordu, spróbuj ponownie');
				hideModal();
			}
		});
	};

	const handleModalCancel = () => {
		hideModal();
	};

	/**
	 * Get initial options and assign them to the state
	 */
	React.useEffect(() => {
		const values = [...options];

		// Fetch provided options
		React.Children.forEach(children, (child, idx) => {
			// If it isn't an CreatableSelectOption, then ignore it
			if(child.type?.displayName !== 'CreatableSelectOption') return;

			// Assign an option to the state if it doesn't exists
			if(!values.find(o => o.node?.props?.value === child.props?.value ?? null)) {
				values.push({
					value: child.props?.children ?? null,
					node: React.cloneElement(child, Object.assign({}, {
						key: `option~${idx}`
					}, child.props))
				});
			}
		});

		setOptions(values);
	}, []);

	// Handle changes in field
	const handleChange = val => {
		// Check if item needs to be created or just selected
		if(val === NEW_ITEM) {
			// Set the field to creating state
			setCreating(true);

			if(serverside) {
				// Show modal and wait for creation
				showModal();
			} else {
				// Just create a new entry
				setOptions([...options, {
					value: inputValue,
					node: (<Option key={`option~${inputValue}`} value={inputValue}>{inputValue}</Option>)
				}]);

				// Set a proper value of field
				setValue(inputValue);
				setCreating(false);

				// Call the callback with the new field value
				(typeof onChange === 'function' && onChange(inputValue));
			}
		} else {
			// Set the new value
			setValue(val);

			// Call the callback with the new field value
			(typeof onChange === 'function' && onChange(val));
		}
	};

	return (<>
			<Select
				value={value}
				showSearch
				loading={creating}
				disabled={creating}
				optionFilterProp="children"
				onChange={handleChange}
				onSearch={e => { setInputValue(e); }}
				{...props}
			>
			{ inputValue && options.filter(o => o.value === inputValue).length === 0 && (
				<Option key={inputValue} value={NEW_ITEM}>
					{`Utwórz ${inputValue}`}
				</Option>
			) }
			{ options.map(o => (
				o.node
			)) }
		</Select>

		{serverside ? <Modal
				title={modalTitle ?? 'Tworzenie nowej pozycji'}
				okText={`Tak, utwórz`}
				onOk={handleModalConfirm}
				onCancel={handleModalCancel}
				visible={modalVisible}
			>
			Czy na pewno chcesz utworzyć nową pozycję o nazwie: <strong>{inputValue}</strong>?
		</Modal> : null}
	</>);
};

CreatableSelect.defaultProps = {
	placeholder: "Wybierz lub wpisz nazwę nowej pozycji",
	serverside: false
};

CreatableSelect.Option = Option;
CreatableSelect.Option.displayName = 'CreatableSelectOption';

export default CreatableSelect;
