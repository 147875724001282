import React from 'react';

import { captureException } from '@sentry/react';

import Moment from 'react-moment';

import { Row, Col, Card, Form, Select, Button, Alert, message, DatePicker, Input } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { APIBackend as API } from '../../../api';
import { backendRoot, backendApi, csrfCookie, apiData } from '../../../config/paths';
import axios from 'axios';

import moment from 'moment';

import '../../../assets/scss/dashboard.scss';

const { Option } = Select;

const Dashboard_InheritedProfile = (props) => {
	const [form] = Form.useForm();

	const [relationData, setRelationData] = React.useState(null);
	const [error, setError] = React.useState(false);

	React.useEffect(() => {
		API.relations(['dctmaritials', 'users', 'contractors']).then(r => {
			// Assigning data of related records to state
			setRelationData(r);
			setError(false);
		}).catch(err => {
			setError({
				errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED',
				message: err?.response?.data?.message
			});
		});
	}, []);

	const onFinish = (values) => {
		API.createSuccessor(values).then(data => {
			message.success('Profil następcy został utworzony');
			form.resetFields();
			setError(null);
		}).catch(err => {
			setError({
				errcode: err?.response?.data?.errcode ?? 'ERR-UNDEFINED',
				message: err?.response?.data?.message
			});
		});
	};

	return (<div className="content">
		<div className="subheader">
			<div className="d-flex">
				<h1 className="title">Profil dziedziczony</h1>
				<span className="subtitle">
					Dzisiaj jest <Moment locale="pl" format="dddd, D MMMM YYYY" />
				</span>
			</div>

			<div className="subheader_actions">
				<Button type="primary" onClick={() => { form.submit(); }}>Utwórz</Button>
			</div>
		</div>

		{
			error && <Alert type="error" message={error?.message ?? 'Wystąpił nieoczekiwany błąd podczas tworzenia profilu dziedziczonego lub pobierania danych'} style={{ marginBottom: 25 }} />
		}

		<Card loading={relationData === null}>
			<Form
				onFinish={onFinish}
				layout="vertical"
				form={form}
			>
				<Form.Item
					label="Klient (przodek)"
					name="user_ancestor_id"
					rules={[
						{ required: true, message: 'Proszę wybrać klienta, z którego ma zostać odziedziczony profil' }
					]}
				>
					<Select
						showSearch
						optionFilterProp="children"
					>
						{
							relationData?.users
							&&
							relationData.users.filter(el => el.permission_code < 30).map(el => (
								<Option key={['ancestor', el.id]} value={el.id}>
									{el.first_name} {el.last_name} [{el.pesel}]
								</Option>
							))
						}
					</Select>
				</Form.Item>

				<Form.Item
					label="Kontrahent"
					name="contractor_id"
					rules={[
						{ required: true, message: 'Proszę wybrać kontrahenta' }
					]}
				>
					<Select
						showSearch
						optionFilterProp="children"
					>
						{
							relationData?.contractors
							&&
							relationData.contractors.map(el => (
								<Option key={['ancestor', el.id]} value={el.id}>
									[{el.symbol}] {el.name}
								</Option>
							))
						}
					</Select>
				</Form.Item>

				<Form.Item
					label="Imię następcy"
					name="first_name"
					rules={[
						{ required: true, message: 'Proszę uzupełnić imię następcy' }
					]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					label="Nazwisko następcy"
					name="last_name"
					rules={[
						{ required: true, message: 'Proszę uzupełnić imię następcy' }
					]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					label="Numer PESEL następcy"
					name="pesel"
				>
					<Input />
				</Form.Item>

				<Form.Item
					label="Adres email następcy"
					name="email"
					rules={[
						{ required: true, message: 'Proszę uzupełnić adres email następcy' }
					]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					label="Numer telefonu następcy"
					name="phone"
					rules={[
						{ required: true, message: 'Proszę uzupełnić numer telefonu następcy' }
					]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					label="Data urodzenia następcy"
					name="birth_date"
					rules={[
						{ required: true, message: 'Proszę uzupełnić datę urodzenia następcy' }
					]}
				>
					<DatePicker placeholder="RRRR-MM-DD" style={{ width: '100%' }} />
				</Form.Item>

				<Form.Item
					label="Stan cywilny następcy"
					name="dctmaritial_id"
					rules={[
						{ required: true, message: 'Proszę wybrać stan cywilny następcy' }
					]}
				>
					<Select
						showSearch
					>
						{
							relationData?.dctmaritials
							&&
							relationData.dctmaritials.filter(el => el.is_active).map(el => (
								<Option key={['dctmaritial', el.id]} value={el.id}>
									{el.name}
								</Option>
							))
						}
					</Select>
				</Form.Item>
			</Form>
		</Card>
	</div>);
};

export default Dashboard_InheritedProfile;
