import { APIInstance } from '../../';
import { API } from '../../api';

export default {
	getBankReport(data) {
		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.post(`/api/reports/bank`, data, {
					responseType: 'blob'
				}).then(r => {
					resolve(r.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	getReport(endpoint, data) {
		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.post(`/api/reports/${endpoint}`, data, {
					responseType: 'blob'
				}).then(r => {
					resolve(r.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	}
};
