import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { UserContext } from './';

import { APIBackend as API, APIBackendConfig as APIConfig } from '../../api';

const PERMISSIONS = {
	USER: 0,
	EXTERNAL_CLIENT: 5,
	COMMITTEE: [30, 35],
	OPERATOR: 60,
	ADMIN: 70
};

const UserProvider = ({ children, ...props }) => {
	const [token, setToken] = React.useState(localStorage.getItem('token') ?? null);
	const [adToken, setAdToken] = React.useState(localStorage.getItem('adToken') ?? null);
	const [user, setUser] = React.useState();
	const [error, setError] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [unseenApps, setUnseenApps] = React.useState(null);
	const [isOperator, setIsOperator] = React.useState(null);

	const history = useHistory();
	const location = useLocation();

	React.useEffect(() => {
		if(error) setError(null);
	}, [location.pathname]);

	React.useEffect(() => {
		if(user) {
			setIsOperator(can(PERMISSIONS.OPERATOR));

			API.appsUnseen(can(PERMISSIONS.OPERATOR) ? 1 : 2, {
				staapp_id: can(PERMISSIONS.OPERATOR) ? 2 : 6,
				staprofile_id: can(PERMISSIONS.OPERATOR) ? 6 : 7,
			}).then(r => {
				if(r.data.hasOwnProperty('debug')) {
					delete r.data.debug;
				}
				setUnseenApps(r.data);
			}).catch(err => {

			});
		}
	}, [user]);

	React.useEffect(() => {

	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const can = (permission_code) => {
		if(!user) return false;

		if(!Array.isArray(permission_code)) permission_code = [permission_code];

		// console.log(`User (${user?.permission_code}) can do ${permission_code}`, permission_code.some(el => user?.permission_code >= el));

		return permission_code.some(el => user?.permission_code >= el);
	};

	const authenticate = () => {
		setLoading(true);

		return new Promise((resolve, reject) => {
			if(token) {
				API.getMe().then(data => {
					setUser(data);
					resolve(data);
				}).catch(err => {
					logout().then(() => {
						reject();
					}).catch(err => {
						reject();
					}).finally(() => {
						localStorage.removeItem('token');
						localStorage.removeItem('adToken');
						setToken(null);
					});
				}).finally(() => {
					setLoading(false);
				});
			} else if(adToken) {
				setLoading(false);
				reject(false);
			} else {
				setLoading(false);
				reject(false);
			}
		});
	};

	const login = (values) => {
		setLoading(true);

		return new Promise((resolve, reject) => {
			API.login(values.email, values.password).then(data => {
				localStorage.setItem('token', data.user.id);
				setToken(data.user.id);
				setUser(data.user);
				resolve(data);
			}).catch(err => {
				setUser(null);
				reject(err);
			}).finally(() => {
				setLoading(false);
			});
		});
	};

	const azureLogin = () => {
		window.location.href = `${APIConfig.baseURL}/api/azure/redirect`
	};

	const azureAuth = (code) => {
		return API.azureAuth(code);
	};

	const register = (values) => {
		setLoading(true);

		return new Promise((resolve, reject) => {
			API.register(values).then(data => {
				resolve(data);
			}).catch(err => {
				reject(err);
			}).finally(() => {
				setLoading(false);
			});
		});
	};

	const logout = () => {
		setLoading(true);

		return new Promise((resolve, reject) => {
			API.logout().then(data => {
				localStorage.removeItem('token');
				localStorage.removeItem('adToken');
				setToken(null);
				setUser(null);
				resolve();
			}).catch(err => {
				reject(err);
			}).finally(() => {
				setLoading(false);
			});
		});
	};

	const memoedValue = React.useMemo(() => ({
		token,
		setToken,
		adToken,
		setAdToken,
		user,
		setUser,
		loading,
		error,
		unseenApps,

		authenticate,
		login,
		azureLogin,
		azureAuth,
		register,
		logout,
		can,
		isOperator
	}), [token, setToken, adToken, setAdToken, user, setUser, loading, error, unseenApps, setUnseenApps, isOperator, setIsOperator]);

	return (
		<UserContext.Provider value={memoedValue}>
			{children}
		</UserContext.Provider>
	);
};

export default UserProvider;

export function useAuth() {
	return React.useContext(UserContext);
};

export { PERMISSIONS };
