import React from 'react';

import moment from 'moment';

import { DatePicker as AntPicker } from 'antd';

class DatePicker extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		let {
			value
		} = this.props;

		if(value) {
			if(!(value instanceof moment)) {
				if(moment(value).isValid()) {
					value = moment(value);
				}
			}
		}

		return (
			<AntPicker defaultValue={null} value={value ?? null} {...this.props} />
		);
	}
}

DatePicker.defaultProps = {

};

export default DatePicker;
