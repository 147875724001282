import React from 'react';

import Moment from 'react-moment';

import Record from '../../../components/Record/index';

import TabbedCard from '../../../components/TabbedCard';

import { useAuth, PERMISSIONS as USER_PERMISSIONS } from '../../../contexts/User';

import { Row, Col, Card, Button } from 'antd';

import moment from 'moment';

import '../../../assets/scss/dashboard.scss';

const Dashboard_Offers_Recreations = (props) => {
	const [currentActiveTab, setCurrentActiveTab] = React.useState('general');

	const auth = useAuth();

	return (<div className="content">
		<Record
			controller="offtourists"
			referrer="tourists"
			relations={['services', 'contractors']}
			relationsLimit={-1}
			mode={props?.mode}
			defaultEditing={props?.editing}
			filters={[
				{
					title: 'Kontrahent',
					name: 'contractors.id',
					relation: 'contractors',
					type: 'enum'
				}
			]}
			columns={[
				{
					name: 'id',
					title: '#',
					type: 'number'
				},
				{
					name: 'name',
					title: 'Nazwa'
				},
				{
					name: 'nights',
					title: 'Ilość noclegów'
				},
				{
					name: 'town',
					title: 'Miejscowość'
				},
				{
					name: 'zip',
					title: 'Kod pocztowy'
				},
				{
					name: 'from',
					title: 'Terminy od'
				},
				{
					name: 'to',
					title: 'Terminy do'
				},
				{
					name: 'is_active',
					title: 'Aktywna',
					datatype: 'bool'
				},
				{
					name: 'actions',
					data: null,
					sortable: false,
					title: '',
					render: function(data, type, row) {
						return `<a class="btn btn-table" href="/tourists/${data.id}">Podgląd</a><a class="btn btn-table" href="/tourists/${data.id}/edit">Edytuj</a><a class="btn btn-table" href="/tourists/${data.id}/clone">Skopiuj</a>`;
					},
					className: 'text-right'
				}
			]}
			viewTitle="Podgląd oferty tur-rek"
			listTitle="Lista ofert tur-rek"
			editTitle="Oferta tur-rek - EDYCJA"
			createTitle="Oferta tur-rek - TWORZENIE"
		>
			<Record.Consumer>
				{({ isLoading, mode }) => (
					<TabbedCard key="generalCard" loading={isLoading} tabContent={{
						general: [
							<Record.Field.Input title={'Nazwa'} name="name" />,
							<Record.Field.Input title={'Ilość noclegów'} name="nights" />,
							<Record.Field.Input title={'Ulica'} name="street" span={{ sm: 24, md: 12 }} />,
							<Record.Field.Input title={'Kod pocztowy'} name="zip" span={{ sm: 24, md: 5 }} />,
							<Record.Field.Input title={'Miejscowość'} name="town" span={{ sm: 24, md: 7 }} />,
							<Record.Field.Date title={'Data rozpoczęcia'} name="from" />,
							<Record.Field.Date title={'Data zakończenia'} name="to" />,
							<Record.Field.Switch title={'Aktywny'} name="is_active" defaultValue={ 1 } />,
							<Record.Field.Textarea title={'Informacje'} name="description" span={{ span: 24 }} />
						],
						prices: [
							<Record.Field.TableRelation title={'Cennik'} showTitle={false} name="touristprices" span={{ span: 24 }} tab="prices" relation="services" fields={[
								{ name: 'id', title: 'ID', isHidden: true, removeEmpty: true },
								{ name: 'service_id', title: 'Usługa', type: 'creatableRelation', relation: 'services', filterData: (data, record) => {
										return data.filter(el => el.servicegroup_id === 1);
									}, span: { lg: 12, xl: 12 }, controller: "services", field: 'name', fields: [
										{ name: 'servicegroup_id', value: 1 },
										{ name: 'is_active', value: 1 },
										{ name: 'name', value: '' }
									] },
								{ name: 'price', type: 'number', title: 'Cena', span: { lg: 8, xl: 8 } }
							]} />
						],
						availability: [
							<Record.Field.TableCheck title={'Dostępność'} showTitle={false} name="contractors" span={{ span: 24 }} tab="availability" relation={["contractors"]} relatedTable="contractors" transformDataKey="contractors[id]" fields={[
								{ name: 'name', title: 'Kontrahent' },
							]} />
						]
					}} tabList={[
						{ key: 'general', tab: 'Dane podstawowe' },
						{ key: 'prices', tab: 'Cennik' },
						{ key: 'availability', tab: 'Dostępność' }
					]} activeTabKey={currentActiveTab} onTabChange={(key) => { setCurrentActiveTab(key) }} forceRender={true} />
				)}
			</Record.Consumer>
		</Record>
	</div>);
};

export default Dashboard_Offers_Recreations;
