export const STATUS = {
	REGISTERED: {
		id: 1,
		label: 'Roboczy',
	},
	IN_ACCEPTATION: {
		id: 2,
		label: 'W akceptacji'
	},
	ACCEPTED: {
		id: 3,
		label: 'Zaakceptowany'
	},
	BACK_TO_CORRECTION: {
		id: 4,
		label: 'Zwrócony do korekty'
	},
	REFUSED: {
		id: 5,
		label: 'Odrzucony'
	},
	IN_COMMISSION: {
		id: 6,
		label: 'Przekazany do Komisji'
	},
	CANCELED: {
		id: 7,
		label: 'Anulowany'
	},
	OUTSIDE_SERVICE: {
		id: 8,
		label: 'Usługa zewnętrzna'
	}
};

export const getStatusArray = () => {
	const arr = [];

	for(let k in STATUS) {
		let STA = STATUS[k];

		arr.push({
			name: k,
			id: STA.id,
			label: STA.label
		});
	}

	return arr;
};

export const getStatusById = (id) => {
	for(let k in STATUS) {
		let STA = STATUS[k];

		if(STA.id === id) return STA;
	}

	return null;
};

export const convertTableToName = (table) => {
	switch(table) {
		case 'apppears':
			return 'Wczasy pod gruszą';
		case 'appmultis':
			return 'Karty Sportowe';
		case 'appcamps':
			return 'Wypoczynek dzieci';
		case 'appgrants':
			return 'Zapomogi';
		case 'appinfants':
			return 'Przedszkola i żłobki';
		case 'apptickets':
			return 'Bilety i karnety';
		case 'apptrips':
			return 'Wyjazdy';
		case 'apptourists':
			return 'Świadczenia TUR-REK';
		case 'apploans':
			return 'Pożyczki';
		case 'appextras':
			return 'Świadczenia dodatkowe';
		case 'appreftourists':
			return 'Świadczenia TUR-REK - refundacja';
		case 'appreftrips':
			return 'Wyjazdy - refundacja';
		case 'apprefcamps':
			return 'Wypoczynek dzieci - refundacja';
		case 'apprehabs':
			return 'Zajęcia rekreacyjne';
		default:
			return table;
	};
};
